import _ from "lodash";
import { getObjectPropertyNames } from "../../../../infrastructure/helpers";

const defaultApplication = {
  id: undefined,
  treatmentType: undefined,
  amount: undefined,
  applicantInfo: {
    firstName: null,
    lastName: null,
    middleName: null,
    ssn: null,
    ssnLast4: null,
    noSsn: false,
    doB: null,
    identificationProvided: true,
    identificationType: "DriversLicense",
    identificationImage: null,
    preferredLanguage: null,
    cellPhone: null,
    homePhone: null,
    workPhone: null,
    annualIncome: 0,
    preferredMonthlyPayment: 0,
    emailAddress: null,
    street1: null,
    street2: null,
    zipcode: null,
    state: null,
    city: null,
    apartmentOrUnit: null,
    hasAvaiableCreditLine: null,
    availableCreditLine: 0,
    initialConsentTimestamp: null,
    hgPatientReferenceId: null,
  },
  applicationStatus: "Initiated",
  hg_SiteId: 0,
  submitApplication: true,
  updateKey: null,
};
const mockData = {
  id: undefined,
  treatmentType: "GENERALDENTISTRY",
  amount: 2500,
  applicantInfo: {
    firstName: "Alex",
    lastName: "Kh",
    middleName: null,
    ssn: "123456788",
    ssnLast4: null,
    noSsn: false,
    doB: "2006-02-02",
    identificationProvided: true,
    identificationType: "DriversLicense",
    identificationImage: null,
    preferredLanguage: "en",
    cellPhone: "4695346099",
    homePhone: null,
    workPhone: null,
    annualIncome: 0,
    preferredMonthlyPayment: 0,
    emailAddress: "alex.baqeri@gmail.com",
    street1: "1239 Bernard St",
    street2: null,
    zipcode: "76201",
    state: "TX",
    city: "Dallas",
    apartmentOrUnit: null,
    hasAvaiableCreditLine: true,
    availableCreditLine: 2500,
    initialConsentTimestamp: "2024-05-09T16:04:23Z",
    hgPatientReferenceId: "9",
  },
  applicationStatus: "Initiated",
  hg_SiteId: 2,
  submitApplication: true,
  updateKey: null,
};
const getDefaultApplication = () => _.cloneDeep(defaultApplication);
// const getDefaultApplication = () => _.cloneDeep(mockData);
const defaultApplicationValidtions = (() => {
  const propertyNames = getObjectPropertyNames(defaultApplication);
  const output = _.cloneDeep(defaultApplication);
  propertyNames.forEach((propertyName) => {
    _.set(output, propertyName, true);
  });
  return output;
})();
const getDefaultApplicationValidations = () =>
  _.cloneDeep(defaultApplicationValidtions);

export { getDefaultApplication, getDefaultApplicationValidations };

import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Form, FormControl } from "react-bootstrap";
import SelectControl from "../../../components/controls/select-control/select-control";
import { NumericFormat, PatternFormat } from "react-number-format";
import styles from "./styles.module.scss";
import { stateCollection } from "../../../infrastructure/constants";
import _ from "lodash";
import {
  getDefaultApplication,
  getDefaultApplicationValidations,
} from "./utils/utils";
import { commaSeperatedCurrency } from "../../../infrastructure/helpers";
import ValidationWrapper from "../../../components/validation-wrapper";
import { isMobile } from "react-device-detect";
import moment from "moment";

const treatmentOptions = [
  { value: "GENERALDENTISTRY", label: "General dentistry" },
  { value: "ORTHODONTICS", label: "Orthodontics" },
  { value: "OTHERSPECIALTIES", label: "Other specialties" },
];
const preferredLanguageCollection = [
  { value: "en", label: "English" },
  { value: "es", label: "Spanish" },
];
const ConsumerApplicationInputs = ({
  user = {},
  onChange = (name, value) => {},
  application = getDefaultApplication(),
  applicationValidations = getDefaultApplicationValidations(),
  validateInputs = (name, value) => {},
  isExistingApplication = false,
}) => {
  const [hideSsn, setHideSsn] = useState(false);
  const locationCollection = useMemo(() => {
    return (user?.data?.user?.hgSiteCollection || []).map((i) => ({
      value: i.id,
      label: i.nickname,
    }));
  }, [user]);
  //   console.log(locationCollection);
  const innerChange = (name, newValue) => {
    const temp = { ...application };
    let value = newValue || null;
    switch (name) {
      case "applicantInfo.preferredLanguage":
      case "applicantInfo.state":
      case "treatmentType":
      case "hg_SiteId":
        value = newValue?.value || null;
        break;
      case "applicantInfo.hasAvaiableCreditLine":
        value = newValue;
        temp.applicantInfo.availableCreditLine = value ? temp.amount : 0;
        break;
      case "applicantInfo.noSsn":
        if (!!value) {
          temp.applicantInfo.ssn = null;
          temp.applicantInfo.ssnLast4 = null;
          validateInputs("applicantInfo.ssn", undefined, {
            ...application,
            applicantInfo: {
              ...application.applicantInfo,
              noSsn: true,
            },
          });
        }

        break;
    }
    validateInputs(name, value, application);
    if (!!name) {
      _.set(temp, name, value);
      onChange(temp);
    }
  };

  useEffect(() => {}, [application]);
  useEffect(() => {
    document.title = "New Application | DentiRate";
  }, []);

  return (
    <>
      <div className={styles["section"]}>
        <h2 className={styles["section-title"]}>Contract Information</h2>
        <Row>
          <Col className='relative' xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.hg_SiteId}
              title={"Office*"}
              errorMessage='Invalid office'
            >
              <SelectControl
                options={locationCollection}
                value={(locationCollection || []).find(
                  (i) => i.value === application.hg_SiteId
                )}
                onChange={(v) => innerChange("hg_SiteId", v)}
                placeholder=''
                disabled={isExistingApplication}
              />
            </ValidationWrapper>
          </Col>
          <Col className='relative' xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.treatmentType}
              title='Treatment Type*'
              errorMessage='Invalid treatment type'
            >
              <SelectControl
                options={treatmentOptions}
                value={
                  !application.treatmentType
                    ? null
                    : (treatmentOptions || []).find(
                        (i) =>
                          i.value.toUpperCase() ===
                          application.treatmentType.toUpperCase()
                      )
                }
                onChange={(v) => innerChange("treatmentType", v)}
                placeholder=''
              />
            </ValidationWrapper>
          </Col>
          <Col className='relative' xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.amount}
              title='$ Amount*'
              errorMessage='Invalid contract value'
            >
              <NumericFormat
                name='number'
                className='form-control'
                thousandSeparator={true}
                prefix={"$"}
                placeholder=''
                autoComplete='off'
                customInput={FormControl}
                allowNegative={false}
                decimalScale={0}
                fixedDecimalScale={true}
                value={application.amount || ""}
                onValueChange={(values) => {
                  innerChange("amount", values.floatValue);
                }}
                maxLength={8}
              />
            </ValidationWrapper>
          </Col>
          <Col className='relative' xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={
                applicationValidations.applicantInfo?.hgPatientReferenceId ||
                true
              }
              title='Chart No'
              errorMessage='Invalid patient reference ID'
            >
              <Form.Control
                type='text'
                className='form-control'
                value={application.applicantInfo.hgPatientReferenceId || ""}
                onChange={(e) => {
                  innerChange(
                    "applicantInfo.hgPatientReferenceId",
                    e.target.value
                  );
                }}
                autoComplete='off'
                maxLength={50}
              />
            </ValidationWrapper>
          </Col>
        </Row>
      </div>

      <hr />
      <div className={styles["section"]}>
        <h2 className={styles["section-title"]}>Personal Information</h2>
        <Row>
          <Col className='relative' xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.firstName}
              title={"First Name*"}
              errorMessage='Invalid first name'
            >
              <Form.Control
                value={application.applicantInfo?.firstName || ""}
                onChange={(e) =>
                  innerChange("applicantInfo.firstName", e.target.value)
                }
                autoComplete='off'
              />
            </ValidationWrapper>
          </Col>
          <Col className='relative' xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.lastName}
              title={"Last Name*"}
              errorMessage='Invalid last name'
            >
              <Form.Control
                value={application.applicantInfo?.lastName || ""}
                onChange={(e) =>
                  innerChange("applicantInfo.lastName", e.target.value)
                }
                autoComplete='off'
              />
            </ValidationWrapper>
          </Col>
          <Col className='relative' xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.doB}
              title={"Date of Birth*"}
              errorMessage='Invalid date of birth (between 18 and 100)'
            >
              <Form.Control
                value={
                  !!application.applicantInfo?.doB &&
                  moment(application.applicantInfo?.doB) &&
                  moment(application.applicantInfo?.doB).isValid()
                    ?  moment(application.applicantInfo?.doB).format("yyyy-MM-DD")
                    : ""
                }
                onChange={(e) =>
                  innerChange("applicantInfo.doB", e.target.value)
                }
                type='date'
                autoComplete='off'
              />
            </ValidationWrapper>
          </Col>
          <Col className='relative' xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.preferredLanguage}
              title={"Preferred Language*"}
              errorMessage='Invalid preferred language'
            >
              <SelectControl
                options={preferredLanguageCollection}
                value={(preferredLanguageCollection || []).find(
                  (i) => i.value === application.applicantInfo.preferredLanguage
                )}
                onChange={(v) =>
                  innerChange("applicantInfo.preferredLanguage", v)
                }
                placeholder=''
              />
            </ValidationWrapper>
          </Col>
        </Row>
        <Row>
          <Col className='relative' xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.street1}
              title={"Street Line 1*"}
              errorMessage='Invalid street line'
            >
              <Form.Control
                value={application.applicantInfo?.street1 || ""}
                onChange={(e) =>
                  innerChange("applicantInfo.street1", e.target.value)
                }
                autoComplete='off'
              />
            </ValidationWrapper>
          </Col>
          <Col className='relative' xs={12} md={6} lg={2}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.street2}
              title={"Street Line 2"}
              errorMessage='Invalid street line'
            >
              <Form.Control
                value={application.applicantInfo?.street2 || ""}
                onChange={(e) =>
                  innerChange("applicantInfo.street2", e.target.value)
                }
                autoComplete='off'
              />
            </ValidationWrapper>
          </Col>
          <Col className='relative' xs={12} md={6} lg={1}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.apartmentOrUnit}
              title={"Apt/Unit"}
              errorMessage='Invalid apt'
            >
              <Form.Control
                value={application.applicantInfo?.apartmentOrUnit || ""}
                onChange={(e) =>
                  innerChange("applicantInfo.apartmentOrUnit", e.target.value)
                }
                autoComplete='off'
              />
            </ValidationWrapper>
          </Col>
          <Col className='relative' xs={12} md={6} lg={2}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.city}
              title={"City*"}
              errorMessage='Invalid city'
            >
              <Form.Control
                value={application.applicantInfo?.city || ""}
                onChange={(e) =>
                  innerChange("applicantInfo.city", e.target.value)
                }
                autoComplete='off'
              />
            </ValidationWrapper>
          </Col>
          <Col className='relative' xs={12} md={6} lg={2}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.state}
              title={"State*"}
              errorMessage='Invalid state'
            >
              <SelectControl
                options={stateCollection}
                value={(stateCollection || []).find(
                  (i) => i.value === application.applicantInfo.state
                )}
                onChange={(v) => innerChange("applicantInfo.state", v)}
                placeholder=''
              />
            </ValidationWrapper>
          </Col>
          <Col className='relative' xs={12} md={6} lg={2}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.zipcode}
              title={"Zip Code*"}
              inputId='input-zipcode'
              errorMessage='Invalid zip code'
            >
              <Form.Control
                maxLength={5}
                value={application.applicantInfo?.zipcode || ""}
                onChange={(e) => {
                  if (e.target.value && e.target.value.match(/\D/gi)) {
                    return false;
                  }
                  innerChange("applicantInfo.zipcode", e.target.value);
                }}
                id='input-zipcode'
                autoComplete='off'
              />
            </ValidationWrapper>
          </Col>
        </Row>
        <Row>
          <Col className='relative' xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.cellPhone}
              title='Cell Phone Number*'
              inputId='input-cell-phone'
              errorMessage='Invalid cell phone'
            >
              <PatternFormat
                id='input-cell-phone'
                name='number'
                className='form-control'
                autoComplete='off'
                customInput={FormControl}
                value={application.applicantInfo.cellPhone || ""}
                onValueChange={(values) => {
                  innerChange("applicantInfo.cellPhone", values.value);
                }}
                type='tel'
                format='+1 (###) ###-####'
                mask='_'
              />
            </ValidationWrapper>
          </Col>
          <Col className='relative' xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.emailAddress}
              title={"Email Address*"}
              inputId='input-emailAddress'
              errorMessage='Invalid email address'
            >
              <Form.Control
                type='email'
                value={application.applicantInfo?.emailAddress || ""}
                onChange={(e) => {
                  innerChange("applicantInfo.emailAddress", e.target.value);
                }}
                id='input-emailAddress'
                autoComplete='off'
              />
            </ValidationWrapper>
          </Col>
          <Col className='relative' xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo?.homePhone || true}
              title='Home Phone Number'
              inputId='input-home-phone'
              errorMessage='Invalid home phone'
            >
              <PatternFormat
                id='input-home-phone'
                name='number'
                className='form-control'
                autoComplete='off'
                customInput={FormControl}
                value={application.applicantInfo.homePhone || ""}
                onValueChange={(values) => {
                  innerChange("applicantInfo.homePhone", values.value);
                }}
                type='tel'
                format='+1 (###) ###-####'
                mask='_'
              />
            </ValidationWrapper>
          </Col>
          <Col className='relative' xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo?.workPhone || true}
              title='Work Phone Number'
              inputId='input-work-phone'
              errorMessage='Invalid work phone'
            >
              <PatternFormat
                id='input-work-phone'
                name='number'
                className='form-control'
                autoComplete='off'
                customInput={FormControl}
                value={application.applicantInfo.workPhone || ""}
                onValueChange={(values) => {
                  innerChange("applicantInfo.workPhone", values.value);
                }}
                type='tel'
                format='+1 (###) ###-####'
                mask='_'
              />
            </ValidationWrapper>
          </Col>
        </Row>
      </div>
      <hr />
      <div className={`${styles["section"]} ${isMobile ? "" : "pb-4"}`}>
        <h2 className={styles["section-title"]}>
          Credit & Financial Information
        </h2>
        <Row>
          <Col className='relative' xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.ssn}
              title={"SSN*"}
              inputId='input-ssn'
              errorMessage={"Invalid SSN"}
            >
              <PatternFormat
                id='input-ssn'
                className='form-control'
                autoComplete='off'
                value={application.applicantInfo.ssn || ""}
                onValueChange={(values) => {
                  innerChange("applicantInfo.ssn", values.value);
                }}
                format='###-##-####'
                mask='_'
                disabled={application.applicantInfo.noSsn}
              />
              <div className={styles["checkbox-container"]}>
                <Form.Check
                  label='SSN is not available'
                  value={application.applicantInfo?.noSsn || ""}
                  onChange={(e) =>
                    innerChange(
                      "applicantInfo.noSsn",
                      !(application.applicantInfo?.noSsn || false)
                    )
                  }
                  id='input-nossn'
                  autoComplete='off'
                  checked={application.applicantInfo?.noSsn || false}
                />
              </div>
            </ValidationWrapper>
          </Col>
          <Col className='relative' xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={applicationValidations.applicantInfo.annualIncome}
              title={"Annual Income"}
              inputId='input-annual-income'
              errorMessage='Invalid annual income'
            >
              <NumericFormat
                id='input-annual-income'
                name='number'
                className='form-control'
                thousandSeparator={true}
                prefix={"$"}
                placeholder=''
                autoComplete='off'
                customInput={FormControl}
                allowNegative={false}
                decimalScale={0}
                fixedDecimalScale={true}
                value={application.applicantInfo.annualIncome || ""}
                onValueChange={(values) => {
                  innerChange("applicantInfo.annualIncome", values.floatValue);
                }}
                maxLength={12}
              />
            </ValidationWrapper>
          </Col>
          <Col className='relative' xs={12} md={6} lg={3}>
            <ValidationWrapper
              isValid={
                applicationValidations.applicantInfo.preferredMonthlyPayment
              }
              title={"Preferred Monthly Payment"}
              inputId='input-preferred-payment'
              errorMessage='Invalid monthly payment'
            >
              <NumericFormat
                id='input-preferred-payment'
                name='number'
                className='form-control'
                thousandSeparator={true}
                prefix={"$"}
                placeholder=''
                autoComplete='off'
                customInput={FormControl}
                allowNegative={false}
                decimalScale={0}
                fixedDecimalScale={true}
                value={application.applicantInfo.preferredMonthlyPayment || ""}
                onValueChange={(values) => {
                  innerChange(
                    "applicantInfo.preferredMonthlyPayment",
                    values.floatValue
                  );
                }}
                maxLength={6}
              />
            </ValidationWrapper>
          </Col>
          {application.amount > 0 && (
            <Col className='relative' xs={12} md={6} lg={3}>
              <ValidationWrapper
                isValid={
                  applicationValidations.applicantInfo.hasAvaiableCreditLine
                }
                title={""}
                inputId=''
                errorMessage='Invalid selection'
              >
                <div className={styles["yes-no-container"]}>
                  <div>
                    Does the applicant have a credit card that has{" "}
                    {commaSeperatedCurrency(application?.amount || 0, 0)}{" "}
                    available?*
                  </div>
                  <Form.Check
                    inline
                    id='enough-credit-yes'
                    label='Yes'
                    onChange={(e) =>
                      innerChange("applicantInfo.hasAvaiableCreditLine", true)
                    }
                    checked={
                      application.applicantInfo?.hasAvaiableCreditLine != null
                        ? application.applicantInfo.hasAvaiableCreditLine
                        : false
                    }
                  />
                  <Form.Check
                    inline
                    id='enough-credit-no'
                    label='No'
                    onChange={(e) =>
                      innerChange("applicantInfo.hasAvaiableCreditLine", false)
                    }
                    checked={
                      application.applicantInfo?.hasAvaiableCreditLine != null
                        ? !application.applicantInfo.hasAvaiableCreditLine
                        : false
                    }
                  />
                </div>
              </ValidationWrapper>
            </Col>
          )}
        </Row>
        <span className={styles["required-note"]}>* required fields</span>
      </div>
    </>
  );
};

ConsumerApplicationInputs.propTypes = {};

export default ConsumerApplicationInputs;

import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component"; //mult select
import {
  checkIsEmail,
  toastInfo,
  toastError,
  toastSuccess,
} from "../../utils/check.js";
import {
  sendGet,
  sendGetKeyValue,
  sendPostByJson,
} from "../../utils/httpUtils.js";
import { getUserInfo, getItem } from "../../utils/storage.js";
// import Select from "react-select/dist/declarations/src/Select.js";
import Select from "react-select";
import TitleBar from "../../components/header/titleBar.js";

// const defaultRoleId = {
//     "SuperAdministrator": 101,
//     "bank": 201
// }
const UserManagementForm = (props) => {
  const toastId = useRef(null);
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [hcgList, setHCGList] = useState([]);
  const [roleType, setRoleType] = useState([]);

  const [user, setUser] = useState(
    {
      IsActiveByOrganization: true,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      // "password": "",
      invitationCode: "",
      organizationId: 0,
      province: "",
      city: "",
      address1: "",
      address2: "",
      zipCode: "",
      reEmail: "",
      locationfilter: "",
    }
    // {
    //     "email": "string",
    //     "firstName": "string",
    //     "lastName": "string",
    //     "organizationId": 0,
    //     "roleId": 0,
    //     "senderUserId": 0
    //   }
  );
  const [lsStr, setLsStr] = useState("");
  // const [locationList, setLocationList] = useState([])

  useEffect(() => {
    // get user list
    if (getItem("source") == "200" || getItem("source") == "201") {
      getHCGList();
    }
  }, []);

  const getHCGList = async () => {
    const url = "/Lender/GetLenderHgsPaginated";
    try {
      const res = await sendGetKeyValue(url, { start: 0, records: 99999 });

      if (res.status === 200) {
        let hcgList = res.data.data.hgCollection.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setHCGList(hcgList);
      }
    } catch (error) {}
  };
  // const [step,setStep] =useState();
  const nextStep = (e) => {
    e.preventDefault();

    if (!user.firstName) {
      toastError("Please enter firstName", toastId);
      return false;
    }
    if (!user.lastName) {
      toastError("Please enter lastName", toastId);
      return false;
    }
    if (!checkIsEmail(user.email)) {
      toastError("Please enter a correct format email address", toastId);
      return false;
    }
    if (!checkIsEmail(user.reEmail)) {
      toastError("Please enter a correct format email address", toastId);
      return false;
    }
    if (user.email !== user.reEmail) {
      toastError("Please enter the same email address", toastId);
      return false;
    }

    if (user.email && user.reEmail && user.email != user.reEmail) {
      toastError("The mailboxes you entered twice are not the same", toastId);
      return false;
    }
    // let pjpneStr= /^\d{10}$/
    // if(! pjpneStr.test( user.phoneNumber)){
    //        toastError('Please enter a 10 digit phone number', toastId)
    //        return false
    // }
    // if(!user.password){
    //        toastError('Please enter password', toastId)
    //        return false
    // }
    // let str=/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,30}$/
    // if(!str.test(user.password)){
    //     let errStr='Please enter passwords between 8 and 30, including uppercase and lowercase letters, numbers, and special symbols'
    //        toastError(errStr, toastId)
    //        return false
    // }
    sendEmail();
  };
  const closePopup = () => {
    // $(".popup").removeClass("show");
    setShowToast(false);
  };
  const openPopup = () => {
    // $(".popup").addClass("show");
    setShowToast(true);
  };
  // const saveDate=async(invitationCode)=>{
  //     const url="/Account/CreateUser"
  //     try {
  //         user.invitationCode=invitationCode
  //         const res= await sendPostByJson(url,user)
  //         if (res.data.code===200){
  //             toastSuccess("Create new user success",toastId)
  //             setTimeout(() => {
  //                 navigate("/user-management")
  //             }, 1200);

  //         }else if(res.data.code===409){
  //             toastError("Email already duplicated",toastId)

  //         }

  //     } catch (error) {
  //         if (error.response.status===409){
  //             toastError("Email already duplicated",toastId)

  //         }else{
  //             toastError("Create new user failed,please try again latter!",toastId)

  //         }

  //     }

  // }
  const sendEmail = async () => {
    // {
    //     "email": "string",
    //     "firstName": "string",
    //     "lastName": "string",
    //     "organizationId": 0,
    //     "roleType": "None",
    //     "hgSiteIdCollection": [
    //       0
    //     ]
    //   }
    // const roleId = getItem('source') === 100 ? 101 : 201

    const organizationId = getItem("organizationId");

    let param = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      organizationId: organizationId,
      // roleType: roleType,
      hgSiteIdCollection: [],
    };

    for (let i = 0; i < locations.length; i++) {
      if (locations[i].checked) {
        param.hgSiteIdCollection.push(locations[i].id);
      }
    }

    // return;
    try {
      const url = "/Account/SendInvitation";
      const res = await sendPostByJson(url, param);

      if (res.status == 201) {
        toastSuccess("send invitation email success!", toastId);
        setTimeout(() => {
          navigate("/user-management");
        }, 1200);
      }
    } catch (error) {
      if (error.response.status === 409) {
        toastError("The same email user already exists. ", toastId);
      } else if (error.response.status === 400) {
        toastError(
          "The account has insufficient permissions or is locked!",
          toastId
        );
      } else {
        toastError(error.response.statusText, toastId);
      }
    }
  };
  const filterLocation = (e) => {
    e.preventDefault();
  };

  const changeAct = () => {
    let f = user.IsActiveByOrganization;
    setUser({ ...user, IsActiveByOrganization: !f });
  };
  const goback = (arg) => {
    if (arg == 1) {
      navigate("/user-management");
    }
    setShowToast(false);
  };
  //draft
  const saveInvitation = async () => {
    // const loginUser = getUserInfo()
    const id = getItem("id");

    try {
      const url = "/Account/SendInvitation";
      const res = await sendPostByJson(url, {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        organizationId: 0,
        roleId: 0,
        senderUserId: id,
      });

      if (res.status === 201) {
        // toastSuccess("send invitation email success!", toastId)
        setTimeout(() => {
          navigate("/user-management");
        }, 1200);
      }
    } catch (error) {
      if (error.response.status === 409) {
        toastError("The same email user already exists. ", toastId);
      } else if (error.response.status === 400) {
        toastError(
          "The account has insufficient permissions or is locked!",
          toastId
        );
      } else {
        toastError(error.response.statusText, toastId);
      }
    }
  };

  // const hcgList = [
  //     { label: "Grapes ", value: "grapes" },
  //     { label: "Mango ", value: "mango" },
  //     { label: "Strawberry ", value: "strawberry" },
  //     { label: "Watermelon ", value: "watermelon" },
  //     { label: "Pear ", value: "pear", disabled: true },
  //     { label: "Apple ", value: "apple" },
  //     { label: "Tangerine ", value: "tangerine" },
  //     { label: "Pineapple ", value: "pineapple" },
  //     { label: "Peach ", value: "peach" }
  // ];
  const roleList = [
    // { label: "Administrator ", value: "Administrator" },
    { label: "Moderator ", value: "Moderator" },
  ];
  // const loginUser = getUserInfo()
  const userHgSiteCollection = JSON.parse(getItem("locations"));
  const source = parseInt(getItem("source"));
  const [locations, setLoations] = useState(
    source == 100 ? userHgSiteCollection : []
  );

  const serchLocationList = async () => {
    const url = "/Account/User";
    // const data={}
    try {
      const res = await sendGet(url);

      if (res.status == 200) {
        setLoations(res.data.data.membershipUser.hgSiteCollection);
      }
    } catch (error) {}
  };
  const searchLocations = async (e) => {
    const url = "/Lender/GetLenderHgSitesPaginated";
    try {
      let fifterObj = {
        start: 0,
        records: 999999,
      };
      const filteredEntries = Object.entries(fifterObj).filter(
        ([key, value]) => value !== undefined
      );
      const params = new URLSearchParams(filteredEntries);
      for (let i = 0; i < e.length; i++) {
        params.append("hgIdCollection", e[i].value);
      }
      const res = await sendGetKeyValue(url, params);

      // data.data.hg_SiteCollection
      if (res.status === 200) {
        // let hcgList = res.data.data.hg_SiteCollection.map(item => ({
        //     label: item.name,
        //     value: item.id
        // }))
        //
        setLoations(res.data.data.hg_SiteCollection);
      }
    } catch (error) {}
  };

  const renderLocationList = () => {
    if (!locations || locations.length == 0) {
      return false;
    }
    return locations.map((v, i) => {
      return (
        <li className='my_3 my_5_sm d_flex' key={i}>
          <input
            className='form_checkbox'
            type='checkbox'
            value={v.checked}
            checked={v.checked}
            onChange={(e) => changeCheck(i, e)}
          />
          <label className='checkbox_label mt_1 fs_sm ms_2'>
            <div className='color_primary700'>
              <span className='fw_medium'>{v.nickname || ""}</span> (
              {v.ownerFirstName || ""}&nbsp;{v.ownerLastName}&nbsp;)
            </div>
            <div className='mt_2 fs_sm'>
              {v.address1 ? v.address1 + "," : " "}
              {v.address2 ? v.address2 + "," : " "}
              {v.city ? v.city + "," : " "}
              {v.state || " "}
              {v.zipCode || " "}
            </div>
          </label>
        </li>
      );
    });
  };
  const changeCheck = (i, e) => {
    let val = e.target.checked;

    let item = locations[i];
    item.checked = val;
    locations.splice(i, 1, item);
    setLoations([...locations]);
  };
  const [selected, setSelected] = useState([]);

  return (
    <>
      <ToastContainer limit={1} />
      <div className='layout'>
        <TitleBar title={"User management"} />
        {/* <header>
                <div className="nav container">
                    <button className="btn_return me_3" onClick={openPopup} />
                    <div className="title">User management</div>
                </div>

            </header> */}

        <div className='container'>
          <div className='filter_box mt_2  d_none d_block_sm'>
            <div className='breadcrumbs'>
              <div className='nav'>
                <button
                  className='btn_return me_3'
                  onClick={openPopup}
                  style={{
                    width: "24px",
                    height: "24px",
                    background:
                      "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNS40ODgxIDQuNDMwNTdDMTUuODAyNiA0LjcwMDE0IDE1LjgzOSA1LjE3MzYxIDE1LjU2OTQgNS40ODgxMUw5Ljk4NzgxIDEyTDE1LjU2OTQgMTguNTExOUMxNS44MzkgMTguODI2NCAxNS44MDI2IDE5LjI5OTkgMTUuNDg4MSAxOS41Njk1QzE1LjE3MzYgMTkuODM5IDE0LjcwMDEgMTkuODAyNiAxNC40MzA2IDE5LjQ4ODFMOC40MzA1NiAxMi40ODgxQzguMTg5ODEgMTIuMjA3MiA4LjE4OTgxIDExLjc5MjggOC40MzA1NiAxMS41MTE5TDE0LjQzMDYgNC41MTE5MkMxNC43MDAxIDQuMTk3NDMgMTUuMTczNiA0LjE2MSAxNS40ODgxIDQuNDMwNTdaIiBmaWxsPSIjMzMzMzMzIi8+Cjwvc3ZnPgo=')",
                  }}
                />
                <div className='title'>User management</div>
              </div>
            </div>
          </div>
          <div
            className=' mb_4'
            style={{ marginTop: "4vh", display: "flex" }}
          ></div>
          <form className='form mb_6' id='formOne'>
            <div className='row'>
              <div className='mb_4 col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  First Name
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='Enter first name'
                  value={user.firstName}
                  onChange={(e) => {
                    setUser({ ...user, firstName: e.target.value });
                  }}
                />
              </div>
              <div className='mb_4 col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  Last Name
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='Enter last name'
                  value={user.lastName}
                  onChange={(e) => {
                    setUser({ ...user, lastName: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='mb_4 col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  Email
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='Enter email address'
                  value={user.email}
                  onChange={(e) => {
                    setUser({ ...user, email: e.target.value.trim() });
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='mb_4 col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  Re-enter Email
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='Re-Enter email address'
                  value={user.reEmail}
                  onChange={(e) => {
                    setUser({ ...user, reEmail: e.target.value.trim() });
                  }}
                />
              </div>
            </div>
            {/* getItem('source') */}
            {(getItem("source") == "200" || getItem("source") == "201") && (
              <div className='row'>
                <div className='mb_4 col col_2_sm col_2_md'>
                  <label className='form_label' htmlFor=''>
                    HCG
                  </label>
                  <Select
                    // defaultValue={[hcgList[2], hcgList[3]]}
                    isMulti
                    name='colors'
                    options={hcgList}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    onChange={(e) => {
                      searchLocations(e);
                    }}
                  />
                </div>
              </div>
            )}

            {/* <div className="row">
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                RoleType
                            </label>
                            <Select
                                defaultValue={roleList[0]}
                                name="colors"
                                options={roleList}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(e) => { setRoleType(e.value) }}
                                disabled
                            />

                        </div>
                    </div> */}
            <div className='row'>
              <div className='mb_4 col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  Locations
                </label>
                {/* <div className="search_bar mt_3">
                                <input
                                    className="form_control"
                                    type="text"
                                    placeholder="Search locations..."
                                // value={searchObj.locationStr}
                                // onChange={(e) => { setSearchObj({ ...searchObj, locationStr: e.target.value.trim() }) }}
                                />
                                <button className="btn btn_icon" onClick={serchLocationList}>
                                    <img
                                        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzQ0MDhfMjUyNykiPgo8cGF0aCBkPSJNMTUuNTAwNiAxNC4wMDA2SDE0LjcxMDZMMTQuNDMwNiAxMy43MzA2QzE1LjYzMDYgMTIuMzMwNiAxNi4yNTA2IDEwLjQyMDYgMTUuOTEwNiA4LjM5MDYzQzE1LjQ0MDYgNS42MTA2MyAxMy4xMjA2IDMuMzkwNjMgMTAuMzIwNiAzLjA1MDYzQzYuMDkwNjMgMi41MzA2MyAyLjUzMDYzIDYuMDkwNjMgMy4wNTA2MyAxMC4zMjA2QzMuMzkwNjMgMTMuMTIwNiA1LjYxMDYzIDE1LjQ0MDYgOC4zOTA2MyAxNS45MTA2QzEwLjQyMDYgMTYuMjUwNiAxMi4zMzA2IDE1LjYzMDYgMTMuNzMwNiAxNC40MzA2TDE0LjAwMDYgMTQuNzEwNlYxNS41MDA2TDE4LjI1MDYgMTkuNzUwNkMxOC42NjA2IDIwLjE2MDYgMTkuMzMwNiAyMC4xNjA2IDE5Ljc0MDYgMTkuNzUwNkMyMC4xNTA2IDE5LjM0MDYgMjAuMTUwNiAxOC42NzA2IDE5Ljc0MDYgMTguMjYwNkwxNS41MDA2IDE0LjAwMDZaTTkuNTAwNjMgMTQuMDAwNkM3LjAxMDYzIDE0LjAwMDYgNS4wMDA2MyAxMS45OTA2IDUuMDAwNjMgOS41MDA2M0M1LjAwMDYzIDcuMDEwNjMgNy4wMTA2MyA1LjAwMDYzIDkuNTAwNjMgNS4wMDA2M0MxMS45OTA2IDUuMDAwNjMgMTQuMDAwNiA3LjAxMDYzIDE0LjAwMDYgOS41MDA2M0MxNC4wMDA2IDExLjk5MDYgMTEuOTkwNiAxNC4wMDA2IDkuNTAwNjMgMTQuMDAwNloiIGZpbGw9IiM4RThFOEUiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF80NDA4XzI1MjciPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg=="
                                        alt=""
                                    />
                                </button>
                            </div> */}
                <ul
                  className=''
                  style={{ maxHeight: "400px", overflow: "auto" }}
                >
                  {renderLocationList()}
                </ul>
              </div>
            </div>

            {/*   <div className="row">
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                              Password
                            </label>

                            <input
                                className="form_control"
                                type="password"
                                placeholder="Enter password"
                                value={user.password}
                                onChange={(e)=>{setUser({...user,password:e.target.value.trim()})}}
                            />
                        </div>
                    </div> */}
            {/* <p className="mb_2">Locations</p>
                    <div className="row">
                        <div className="col col_2_sm col_2_md">
                            <div className="search_bar">
                                <input
                                    className="form_control"
                                    type="text"
                                    value={lsStr}
                                    onChange={(e)=>{setLsStr(e.target.value.trim())}}
                                    placeholder="Search location..."
                                />
                                <button className="btn btn_icon" onClick={filterLocation}>
                                    <img
                                        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzQ0MDhfMjUyNykiPgo8cGF0aCBkPSJNMTUuNTAwNiAxNC4wMDA2SDE0LjcxMDZMMTQuNDMwNiAxMy43MzA2QzE1LjYzMDYgMTIuMzMwNiAxNi4yNTA2IDEwLjQyMDYgMTUuOTEwNiA4LjM5MDYzQzE1LjQ0MDYgNS42MTA2MyAxMy4xMjA2IDMuMzkwNjMgMTAuMzIwNiAzLjA1MDYzQzYuMDkwNjMgMi41MzA2MyAyLjUzMDYzIDYuMDkwNjMgMy4wNTA2MyAxMC4zMjA2QzMuMzkwNjMgMTMuMTIwNiA1LjYxMDYzIDE1LjQ0MDYgOC4zOTA2MyAxNS45MTA2QzEwLjQyMDYgMTYuMjUwNiAxMi4zMzA2IDE1LjYzMDYgMTMuNzMwNiAxNC40MzA2TDE0LjAwMDYgMTQuNzEwNlYxNS41MDA2TDE4LjI1MDYgMTkuNzUwNkMxOC42NjA2IDIwLjE2MDYgMTkuMzMwNiAyMC4xNjA2IDE5Ljc0MDYgMTkuNzUwNkMyMC4xNTA2IDE5LjM0MDYgMjAuMTUwNiAxOC42NzA2IDE5Ljc0MDYgMTguMjYwNkwxNS41MDA2IDE0LjAwMDZaTTkuNTAwNjMgMTQuMDAwNkM3LjAxMDYzIDE0LjAwMDYgNS4wMDA2MyAxMS45OTA2IDUuMDAwNjMgOS41MDA2M0M1LjAwMDYzIDcuMDEwNjMgNy4wMTA2MyA1LjAwMDYzIDkuNTAwNjMgNS4wMDA2M0MxMS45OTA2IDUuMDAwNjMgMTQuMDAwNiA3LjAxMDYzIDE0LjAwMDYgOS41MDA2M0MxNC4wMDA2IDExLjk5MDYgMTEuOTkwNiAxNC4wMDA2IDkuNTAwNjMgMTQuMDAwNloiIGZpbGw9IiM4RThFOEUiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF80NDA4XzI1MjciPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg=="
                                        alt=""
                                    />
                                </button>
                                <ul className="">
                                    <li className="my_3 my_5_sm d_flex">
                                        <input className="form_checkbox" type="checkbox" />
                                        <label className="checkbox_label mt_1 ms_2">
                                            <div className="color_primary700">
                                                <span className="fw_medium">legal name</span> (owner name)
                                            </div>
                                            <div className="mt_2 fs_sm">
                                                4000 La Rica Ave Suite D Baldwin Park, CA 91706
                                            </div>
                                        </label>
                                    </li>
                                    <li className="my_3 my_5_sm d_flex">
                                        <input className="form_checkbox" type="checkbox" />
                                        <label className="checkbox_label mt_1 fs_sm ms_2">
                                            <div className="color_primary700">
                                                <span className="fw_medium">legal name</span> (owner name)
                                            </div>
                                            <div className="mt_2 fs_sm">
                                                4000 La Rica Ave Suite D Baldwin Park, CA 91706
                                            </div>
                                        </label>
                                    </li>
                                </ul>
                            </div>

                        </div>


                    </div> */}
            <div className='offcanvas_footer filter_footer'>
              <div className='btn_group'>
                {/* <button className="btn btn-primary_line_transparent" onClick={getBack}>Cancel</button> */}
                <button
                  className='btn btn-primary ms_auto col'
                  onClick={nextStep}
                >
                  Create User
                </button>
              </div>
            </div>
          </form>

          {/* <button className="btn btn-primary w_100 mt_3" onClick={() => nextStep()}>Create User</button> */}
        </div>
      </div>

      <div className={`popup ${showToast ? "show" : ""}`}>
        <div className='popup_container'>
          <div className='popup_box'>
            <button className='btn btn_close' onClick={closePopup}></button>
            <div className='popup_content'>
              <div>do you want to save informations?</div>
              {/* <input className="form_control mt_5 mt_6_md" type="text" placeholder="Tax ID"></input> */}
              <div className='btn_group mt_5 mt_6_md'>
                <button
                  className='btn btn_ok btn-primary_line'
                  onClick={() => goback(1)}
                >
                  No
                </button>
                <button
                  className='btn btn_ok btn-primary ms_auto'
                  onClick={() => goback(2)}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

UserManagementForm.propTypes = {};

export default UserManagementForm;

const TokenKey="Token"
const UserKey="User"

export function getToken() {
    return localStorage.getItem(TokenKey)
  }
  
  export function setToken(token) {
    return localStorage.setItem(TokenKey, token)
  }
  
  export function removeToken() {
    return localStorage.removeItem(TokenKey)
  }
  
  export function getUserInfo(){
    return JSON.parse(localStorage.getItem(UserKey))
  }
  
  export function setUserInfo(userInfo){
    // setToken(userInfo.token)
    return localStorage.setItem(UserKey,JSON.stringify(userInfo))
  }
  
  export function removeUserInfo(){
    return localStorage.removeItem(UserKey)
  }

  export function getItem(key) {
    return localStorage.getItem(key)
  }
  
  export function setItem(key,value) {
    return localStorage.setItem(key, JSON.stringify(value));
  }
  export function clearAllStorages() {
    return localStorage.clear()
  }

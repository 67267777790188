import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import Logo from "./logo";
import ConsentItem from "./consent-item";
import ValidatorMessage from "../../components/validation-wrapper/validator-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { FormattedMessage, useIntl } from "react-intl";

const ApplicantConsent = ({ onConsent, lenderCollection = [] }) => {
  const intl = useIntl();

  const [agree, setAgree] = useState(null);
  const [agreeValid, setAgreeValid] = useState(true);

  const getIntlMessage = useCallback(
    (id) => intl.formatMessage({ id }),
    [intl]
  );
  const innerClick = () => {
    const isValid = agree;
    setAgreeValid(isValid);
    if (isValid) {
      onConsent();
    }
  };
  return (
    <div className={`${styles["container"]} ${styles["consent-container"]}`}>
      <Logo />
      <div className='t_welcome text-center'>
        {getIntlMessage("Consent.WeNeedYourConsent")}
      </div>
      <p className='fw_bold '>{getIntlMessage("Consent.ReadLenderPartners")}</p>
      <div className='form'>
        <ul className={styles["policy-list"]}>
          {lenderCollection.map((item, index) => (
            <li key={index}>
              <ConsentItem
                lenderName={item.lenderName}
                lenderLink={item.website}
                privacyPolicy={item.privacyPolicy}
                termsConditions={item.termsConditions}
              />
            </li>
          ))}
        </ul>
        <div className='service mt-2'>
          <label htmlFor='agree-chk' className={styles["agree-label"]}>
            <input
              type='checkbox'
              onClick={(e) => {
                setAgreeValid(!agree);
                setAgree(!agree);
              }}
              className={`${styles["agree-check"]} ${
                agreeValid ? "" : "input-invalid"
              }`}
              id='agree-chk'
            />
            <span>
              <FormattedMessage id='Consent.ContinuingAgreement.Part1' />
            </span>
            <a
              className='link_primary fw_bold'
              href='/terms-of-service'
              target='_blank'
            >
              <FormattedMessage id='Consent.ContinuingAgreement.Part2' />
              <FontAwesomeIcon icon={faExternalLink} />
            </a>
            .
          </label>
          <ValidatorMessage
            errorMessage={getIntlMessage("Errors.Validation.Consent")}
            isValid={agreeValid}
          />
        </div>
        <div className='text-center'>
          <button className='btn btn-primary w-50 mt_3' onClick={innerClick}>
            {getIntlMessage("Submit")}
          </button>
        </div>
      </div>
    </div>
  );
};

ApplicantConsent.propTypes = {};

export default ApplicantConsent;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TitleBar from "../../components/header/titleBar.js";
import SearchBbox, {
  ApplicationList,
} from "../../components/ApplicationModule/index.js";
import $ from "jquery";
import { getItem, getUserInfo, setUserInfo } from "../../utils/storage.js";
import { sendPostByJson, sendGetKeyValue } from "../../utils/httpUtils.js";
import LocationFilter from "../../components/locationSearch";
import InfiniteScroll from "react-infinite-scroll-component";
import { useCommonContext } from "../../common-context/common-context-provider.js";
import Footer from '../../components/Footer/footer.js';

// const style = {
//   height: 30,
//   border: "1px solid green",
//   margin: 6,
//   padding: 8,
// };
const Leads = (props) => {
  const source = parseInt(getItem("source"));
  const [{ user }] = useCommonContext();
  const organizationId = getItem("organizationId");
  const [searchObj, setSearchObj] = useState({
    start: 0,
    records: 10,
    // healthCareGroupId: organizationId ? organizationId : 0,
    healthCareGroupId: undefined,
    applicationStatus: "ApplicantApplied",
    maxAmount: undefined,
    minAmount: undefined,
    treatmentType: undefined,
    query: undefined,
    sortField: "CreateDate",
  });
  const [dataList, setDataList] = useState([]);

  const updateSearch = (searchObj) => {
    setSearchObj(searchObj);
  };
  const updateUserList = (dataList) => {
    setDataList(dataList);
  };
  const [hasMore, sethasMore] = useState(false);

  const updateHashMore = (hasMore) => {
    sethasMore(hasMore);
  };
  const [current, setCurrent] = useState(0);
  const updateCurrent = (current) => {
    setCurrent(current);
  };
  useEffect(() => {
    // getApplicationList()
  }, []);
  const getApplicationList = async () => {
    const url = "/Application/GetPaginated";
    // const data = { start: 0, records: 1,  healthCareGroupId: 1 }
    const res = await sendGetKeyValue(url, searchObj);
    if (res.status === 200) {
      updateUserList(res.data.data.consumerApplicationCollection);
      updateHashMore(res.data.data.hasMore);
    }
  };
  const getMoreData = () => {
    setTimeout(async () => {
      const url = "/Application/GetPaginated";
      const data = searchObj;
      data.start = current;
      const res = await sendGetKeyValue(url, data);
      if (res.status === 200) {
        setTimeout(() => {
          updateUserList(
            dataList.concat(res.data.data.consumerApplicationCollection)
          );
          updateHashMore(res.data.data.hasMore);
          updateCurrent(current + 10);
        }, 500);
      }
    }, 500);
  };
  console.log(user);
  // const [state, setState] = useState({
  //   items: Array.from({ length: 30 }),
  // })
  // const fetchMoreData = () => {
  //
  //   setTimeout(() => {
  //     setState({
  //       items: state.items.concat(Array.from({ length: 20 })),
  //     });
  //   }, 1500);
  // };
  return (
    <>
      <div className='layout'>
        {source === 101 ? (
          <TitleBar user={user} title={"Home"} />
        ) : (
          <TitleBar user={user} title={"Leads"} />
        )}
        <div className='main' id='morePage'>
          <div className='container'>
            <SearchBbox
              updateSearch={updateSearch}
              updateUserList={updateUserList}
              searchObj={searchObj}
              updateHashMore={updateHashMore}
              updateCurrent={updateCurrent}
              locationCollection={user}
            />
            <InfiniteScroll
              dataLength={dataList.length}
              next={getMoreData}
              hasMore={hasMore}
              style={{ overflow: "" }}
              scrollableTarget='morePage'
              loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
            >
              <ApplicationList
                dataList={dataList}
                updateUserList={updateUserList}
                searchObj={searchObj}
                updateHashMore={updateHashMore}
                updateCurrent={updateCurrent}
              />
              {/* <InfiniteScroll
              dataLength={state.items.length}
              next={fetchMoreData}
              hasMore={true}
              loader={<h4>Loading...</h4>}
              scrollableTarget="morePage"
            >
              {state.items.map((i, index) => (
                <div style={style} key={index}>
                  div - #{index}
                </div>
              ))} */}
            </InfiniteScroll>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

Leads.propTypes = {};

export default Leads;

import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import _, { values } from "lodash";

const UserLocations = ({
  locationCollection = [],
  enableDeletion = false,
  onDelete = () => {},
}) => {
  const groups = useMemo(() => {
    if (!Array.isArray(locationCollection) || locationCollection.length === 0) {
      return [];
    }
    return _.mapValues(_.groupBy(locationCollection, "hG_Id"), (group) =>
      _.sortBy(group, "nickname")
    );
  }, [locationCollection]);
  if (!Array.isArray(locationCollection) || locationCollection.length === 0) {
    return <></>;
  }

  return (
    <div className={styles["user-location-container"]}>
      {Object.keys(groups).map((k) => (
        <ul key={k} className={styles.locations}>
          {groups[k].map((item, index) => (
            <li
              key={index}
            >
              <div
                className={[
                  styles.item,
                  item.notSaved ? styles["not-saved"] : "",
                ].join(" ")}
              >
                <div className='color_primary700'>
                  <span className='fw_medium'>{item.nickname || ""}</span> (
                  {item.ownerFirstName || ""} {item.ownerLastName})
                </div>
                <div className='mt_2 fs_sm'>
                  {item.address1 ? item.address1 + "," : " "}
                  {item.address2 ? item.address2 + "," : " "}
                  {item.city ? item.city + "," : " "}
                  {item.state || " "}
                  {item.zipCode || " "}
                </div>
                {enableDeletion && (
                  <div className={styles["delete-container"]}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        onDelete(item.id);
                      }}
                      type='button'
                    >
                      X
                    </button>
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
};

UserLocations.propTypes = {
  locationCollection: PropTypes.arrayOf(PropTypes.any),
  enableDeletion: PropTypes.bool,
  onDelete: PropTypes.func,
};

export default UserLocations;

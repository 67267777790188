import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  checkIsEmail,
  toastInfo,
  toastError,
  ToastSucces,
  toastSuccess,
} from "../../utils/check.js";
import { sendGet, sendPost, sendPostByJson } from "../../utils/httpUtils.js";
import { getItem, getUserInfo } from "../../utils/storage.js";
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";

import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import step_0_Img from "../../assets/st_0.png";
import step_1_Img from "../../assets/st_1.png";
import warn_Img from "../../assets/warn.png";
import TitleBar from "../../components/header/titleBar.js";

const ApplicationsForm = (props) => {
  const toastId = useRef(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const userHgSiteCollection = JSON.parse(getItem("locations"));
  const [locations, setLoations] = useState(
    userHgSiteCollection && userHgSiteCollection.length > 0
      ? userHgSiteCollection.map((item) => ({
          value: item.id,
          label: item.nickname,
        }))
      : []
  );
  // const loginUser = getUserInfo();
  // const [locations, setLoations] = useState(
  //   (loginUser.hgSiteCollection&&
  //   loginUser.hgSiteCollection.length>0)&&
  //   loginUser.hgSiteCollection.map((item) => ({
  //     label: item.nickname,
  //     value: item.id,
  //   }))
  // );

  const [stepImg, setStepImg] = useState(step_0_Img);
  const [step, setStep] = useState(1);
  const [application, setApplication] = useState({
    treatmentType: "GeneralDentistry",
    amount: undefined,
    applicantInfo: {
      firstName: "",
      lastName: "",
      middleName: "",
      ssn: "",
      ssnLast4: "",
      doB: "",
      identificationProvided: true,
      identificationType: "",
      identificationImage: "",
      preferredLanguage: "",
      cellPhone: "",
      homePhone: "",
      workPhone: "",
      annualIncome: undefined,
      preferredMonthlyPayment: undefined,
      emailAddress: "",
    },
    hg_SiteId: 0,
    submitApplication: true,
  });
  const [applicantInfo, setApplicantInfo] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    ssn: "",
    ssnLast4: "",
    doB: "",
    identificationProvided: true,
    identificationType: "Passport",
    identificationImage: "",
    preferredLanguage: "en",
    cellPhone: "",
    homePhone: "",
    workPhone: "",
    annualIncome: undefined,
    preferredMonthlyPayment: undefined,
    emailAddress: "",
    street1: "",
    street2: "",
    zipcode: "",
    state: "",
    city: "",
  });
  const ActiveStatusList = [
    "Initiated",
    "Submitted",
    "PendingApplicantSubmission",
    "Processing",
    "ProcessedSuccessfuly",
    "ProcessingError",
    "OpenedByApplicant",
    "ApplicantApplied",
    "Abandoned",
  ];
  const [birthDay, setBirthDay] = useState(
    moment(new Date().setFullYear(new Date().getFullYear() - 18)).format(
      "YYYY-MM-DD"
    )
  );

  const nextStep = (e) => {
    //check first page input
    if (!application.hg_SiteId) {
      toastError("Please select locations!", toastId);
      return false;
    }

    if (
      !application.amount ||
      isNaN(parseFloat(application.amount)) ||
      application.amount < 1
    ) {
      toastError("Amout cannot be less than to 1!", toastId);
      return false;
    }
    if (
      !applicantInfo.preferredMonthlyPayment ||
      isNaN(parseFloat(applicantInfo.preferredMonthlyPayment)) ||
      applicantInfo.preferredMonthlyPayment <= 0
    ) {
      toastError(
        "The preferredMonthlyPayment must be greater than 0!",
        toastId
      );
      return false;
    }
    setStepImg(step_1_Img);
    setStep(2);
  };
  const getBack = async () => {
    navigate("/applications");
  };
  const add = async () => {
    if (!applicantInfo.firstName) {
      toastError("Please enter your first name!", toastId);
      return false;
    }
    if (!applicantInfo.lastName) {
      toastError("Please enter your last name!", toastId);
      return false;
    }
    if (
      !applicantInfo.emailAddress ||
      !checkIsEmail(applicantInfo.emailAddress)
    ) {
      toastError("Please enter the correct email address format!", toastId);
      return false;
    }
    if (applicantInfo.workPhone && applicantInfo.workPhone.length != 10) {
      toastError("Please enter a 10 digit primary workPhone number!", toastId);
      return false;
    }
    if (!applicantInfo.cellPhone || applicantInfo.cellPhone.length != 10) {
      toastError(
        "Please enter a 10 digit alternate Primary phone number!",
        toastId
      );
      return false;
    }
    if (applicantInfo.homePhone && applicantInfo.homePhone.length != 10) {
      toastError(
        "Please enter a 10 digit alternate Alternate phone number!",
        toastId
      );
      return false;
    }
    if (!applicantInfo.ssn || !/^\d{9}$/.test(applicantInfo.ssn)) {
      toastError(
        "Please enter a valid 9-digit SSN consisting only of numbers!",
        toastId
      );
      return false;
    }
    // if (!applicantInfo.ssnLast4 || applicantInfo.ssn.slice(-4) != applicantInfo.ssnLast4) {
    //     toastError("last 4 ssn does not match, please enter again!", toastId)
    //     return false
    // }
    if (
      isNaN(parseFloat(applicantInfo.annualIncome)) ||
      !applicantInfo.annualIncome ||
      applicantInfo.annualIncome <= 0
    ) {
      toastError("The annualIncome must be greater than 0!", toastId);
      return false;
    }
    if (!applicantInfo.street1) {
      toastError("Please enter your street1!", toastId);
      return false;
    }
    let check = /^\d{5}$/;

    if (!applicantInfo.zipcode || !check.test(applicantInfo.zipcode)) {
      toastError("Please enter your zipCode is five digits!", toastId);
      return false;
    }
    if (!applicantInfo.city) {
      toastError("Please enter your city!", toastId);
      return false;
    }
    if (!applicantInfo.state) {
      toastError("Please enter your state!", toastId);
      return false;
    }
    let dob = birthDay;
    const isEighteen = isEighteenYearsOld(dob);
    if (!isEighteen) {
      toastError("Not 18 years old!", toastId);
      return false;
    }
    saveDate();
    // navigate("/applications", { state: { sortBy: "Date", statuse: "All", treatmentType: "1 week" } })
  };
  const saveDate = async () => {
    setIsLoading(true);

    applicantInfo.doB = birthDay;
    try {
      const url = "/Application/AddConsumerApplication";
      const data = application;
      data.applicantInfo = applicantInfo;
      const res = await sendPostByJson(url, data);
      if (res.status == 200) {
        toastSuccess("Add application info success!", toastId);

        setTimeout(() => {
          navigate("/applications");
        }, 1000);
      }
    } catch (error) {
      toastError(error.response.statusText, toastId);
    }
    setIsLoading(false);
  };
  function isEighteenYearsOld(birthDateString) {
    const birthDate = new Date(birthDateString);
    const currentDate = new Date();
    const age = currentDate.getFullYear() - birthDate.getFullYear();
    const isLeapYear =
      (currentDate.getFullYear() % 4 === 0 &&
        currentDate.getFullYear() % 100 !== 0) ||
      currentDate.getFullYear() % 400 === 0;
    let ageInFullYears = age;
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      ageInFullYears--;
    } else if (
      isLeapYear &&
      birthDate.getMonth() === 1 &&
      birthDate.getDate() === 29
    ) {
      if (
        currentDate.getMonth() === 2 &&
        currentDate.getDate() < birthDate.getDate()
      ) {
        ageInFullYears--;
      }
    }

    return ageInFullYears >= 18;
  }
  const backStep = () => {
    if (step == 1) {
      return false;
    }
    setStep(1);
    setStepImg(step_0_Img);
  };
  const languageOptions = [
    { value: "en", label: "en" },
    { value: "es", label: "es" },
  ];
  const treatmentOptions = [
    { value: "GENERALDENTISTRY", label: "General dentistry" },
    { value: "ORTHODONTICS", label: "Orthodontics" },
    { value: "OTHERSPECIALTIES", label: "Other specialties" },
  ];
  const selectStyle = {
    borderRadius: "16px ",
    height: "48px ",
  };
  const seletTest = (e) => {};
  const stateCollection = [
    { label: "Alabama", value: "AL" },
    { label: "Alaska", value: "AK" },
    { label: "Arizona", value: "AZ" },
    { label: "Arkansas", value: "AR" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "Delaware", value: "DE" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Hawaii", value: "HI" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Iowa", value: "IA" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Maine", value: "ME" },
    { label: "Maryland", value: "MD" },
    { label: "Massachusetts", value: "MA" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Mississippi", value: "MS" },
    { label: "Missouri", value: "MO" },
    { label: "Montana", value: "MT" },
    { label: "Nebraska", value: "NE" },
    { label: "Nevada", value: "NV" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "New York", value: "NY" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Vermont", value: "VT" },
    { label: "Virginia", value: "VA" },
    { label: "Washington", value: "WA" },
    { label: "West Virginia", value: "WV" },
    { label: "Wisconsin", value: "WI" },
    { label: "Wyoming", value: "WY" },
  ];

  return (
    <>
      <ToastContainer limit={1} />
      <div className='layout'>
        {/* <header style={{ position: "fixed", zIndex: "1" }}>
          <div className="nav container">
            <button
              className="btn_return me_3"
              onClick={() => navigate("/applications")}
            />
            <div className="title">New applications</div>
          </div>
        </header> */}
        <TitleBar title={"New applications"} />

        <div className='filter_box mt_2  d_none d_block_sm'>
          <div className='breadcrumbs'>
            <div className='nav container'>
              <button
                className='btn_return me_3'
                onClick={() => navigate("/applications")}
                style={{
                  width: "24px",
                  height: "24px",
                  background:
                    "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNS40ODgxIDQuNDMwNTdDMTUuODAyNiA0LjcwMDE0IDE1LjgzOSA1LjE3MzYxIDE1LjU2OTQgNS40ODgxMUw5Ljk4NzgxIDEyTDE1LjU2OTQgMTguNTExOUMxNS44MzkgMTguODI2NCAxNS44MDI2IDE5LjI5OTkgMTUuNDg4MSAxOS41Njk1QzE1LjE3MzYgMTkuODM5IDE0LjcwMDEgMTkuODAyNiAxNC40MzA2IDE5LjQ4ODFMOC40MzA1NiAxMi40ODgxQzguMTg5ODEgMTIuMjA3MiA4LjE4OTgxIDExLjc5MjggOC40MzA1NiAxMS41MTE5TDE0LjQzMDYgNC41MTE5MkMxNC43MDAxIDQuMTk3NDMgMTUuMTczNiA0LjE2MSAxNS40ODgxIDQuNDMwNTdaIiBmaWxsPSIjMzMzMzMzIi8+Cjwvc3ZnPgo=')",
                }}
              />
              <div className='title'>New applications</div>
            </div>
          </div>
        </div>
        <div
          className='login_container'
          style={{ maxWidth: "100%", maxHeight: "150px" }}
        >
          {" "}
          <div className=' mb_5' style={{ marginTop: "3vh" }}>
            <img id='imgOne' onClick={backStep} src={stepImg} />
          </div>
        </div>

        <div className='container'>
          <form
            className='form mb_5'
            id='formOne'
            style={{ display: `${step == 1 ? "block" : "none"}` }}
          >
            <div className='row'>
              {/* <div className="mb_5 col col_2_sm col_2_md"> */}
              {/* <div className="row"> */}
              <div className='mb_5 col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  Location*
                </label>
                {/* <select className="form_control"
                                value={application.hg_SiteId}
                                onChange={(e) => { setApplication({ ...application, hg_SiteId: e.target.value.trim() }) }}
                            >
                                {
                                    locations.map((item, index) => (
                                        <option key={item.value} value={item.id}>
                                            {item.nickname}
                                        </option>
                                    ))
                                }
                            </select> */}

                <Select
                  options={locations}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: "48px",
                      borderRadius: "16px",
                    }),
                  }}
                  // value={applicantInfo.preferredLanguage}
                  defaultValue={application.hg_SiteId}
                  // onChange={(e) => { setApplication({ ...application, hg_SiteId: e.value.trim() }) }}
                  onChange={(e) => {
                    setApplication({ ...application, hg_SiteId: e.value });
                  }}
                />
              </div>
              {/* <div className="mb_5 col col_2_sm col_3_md">
                            <label className="form_label" htmlFor="">
                                overrideAccess
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                placeholder="Please enter street address"
                                value={application.overrideAccess}
                                onChange={(e) => { setApplication({ ...application, overrideAccess: e.target.value.trim() }) }}
                            />
                        </div> */}
            </div>
            <div className='row'>
              {" "}
              <div className='mb_5 col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  Treatment Type*
                </label>
                {/* <select className="form_control" value={application.treatmentType} onChange={(e) => { setApplication({ ...application, treatmentType: e.target.value }) }}
                            placeholder="Please select treatment type">
                            <option value='GENERALDENTISTRY' >GeneralDentistry</option>
                            <option value='ORTHODONTICS' >Orthodontics</option>
                            <option value='OTHERSPECIALTIES' >Otherspecialties</option>
                        </select> */}

                <Select
                  options={treatmentOptions}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: "48px",
                      borderRadius: "16px",
                    }),
                  }}
                  // value={applicantInfo.preferredLanguage}
                  defaultValue={treatmentOptions[0]}
                  onChange={(e) => {
                    setApplication({
                      ...application,
                      treatmentType: e.value.trim(),
                    });
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='mb_5 col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  Amount*
                </label>

                {/* <input
                            className="form_control"
                            type="select"
                            placeholder="Please enter amount"
                            value={application.amount}
                            onChange={(e) => { setApplication({ ...application, amount: e.target.value.trim() }) }}
                        /> */}
                <CurrencyInput
                  id='input-example'
                  name='input-name'
                  placeholder='Please enter amount'
                  defaultValue={application.amount}
                  value={application.amount}
                  decimalsLimit={2}
                  className='form_control'
                  prefix='$'
                  onValueChange={(value, name) => {
                    setApplication({
                      ...application,
                      amount: value === 0 || value ? value * 1 : 0,
                    });
                  }}
                />
              </div>
            </div>
            <div className='row'>
              {" "}
              <div className='mb_5 col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  Preferred Monthly Payment*
                </label>

                {/* <input
                            className="form_control"
                            type="select"
                            placeholder="Please enter  preferred monthly paymentunt"
                            value={applicantInfo.preferredMonthlyPayment}
                            onChange={(e) => { setApplicantInfo({ ...applicantInfo, preferredMonthlyPayment: e.target.value.trim() }) }}
                        /> */}

                <CurrencyInput
                  id='input-example'
                  name='input-name'
                  placeholder='Please enter  preferred monthly paymentunt'
                  defaultValue={applicantInfo.preferredMonthlyPayment}
                  value={applicantInfo.preferredMonthlyPayment}
                  decimalsLimit={2}
                  className='form_control'
                  prefix='$'
                  onValueChange={(value, name) =>
                    setApplicantInfo({
                      ...applicantInfo,
                      preferredMonthlyPayment:
                        value === 0 || value ? value * 1 : "",
                    })
                  }
                />
              </div>
            </div>
          </form>

          <form
            className='form mb_5'
            id='formTwo'
            style={{ display: `${step == 2 ? "block" : "none"}` }}
          >
            <div className='row'>
              <div className='mb_5 col col_2_sm col_3_md'>
                <label className='form_label' htmlFor=''>
                  First Name*
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='Please enter first name'
                  value={applicantInfo.firstName}
                  onChange={(e) => {
                    setApplicantInfo({
                      ...applicantInfo,
                      firstName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className='mb_5 col col_2_sm col_3_md'>
                <label className='form_label' htmlFor=''>
                  Last Name*
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='Please enter last name'
                  value={applicantInfo.lastName}
                  onChange={(e) => {
                    setApplicantInfo({
                      ...applicantInfo,
                      lastName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className='mb_5 col col_sm col_3_md'>
                <label className='form_label' htmlFor=''>
                  Middle Name
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='Please enter middle name'
                  value={applicantInfo.middleName}
                  onChange={(e) => {
                    setApplicantInfo({
                      ...applicantInfo,
                      middleName: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className=' col col_sm col_3_md'>
                <label className='form_label' htmlFor=''>
                  Preferred Language*
                </label>
                {/* <select className="form_control"
                                value={applicantInfo.preferredLanguage}
                                onChange={(e) => { setApplicantInfo({ ...applicantInfo, preferredLanguage: e.target.value  }) }}
                            >
                                <option >en</option>
                                <option >es</option>

                            </select> */}
                <Select
                  options={languageOptions}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: "48px",
                      borderRadius: "16px",
                    }),
                  }}
                  // value={applicantInfo.preferredLanguage}
                  defaultValue={languageOptions[0]}
                  onChange={(e) => {
                    setApplicantInfo({
                      ...applicantInfo,
                      preferredLanguage: e.value.trim(),
                    });
                  }}
                />
              </div>

              <div className='mb_5 col col_sm col_3_md'>
                <label className='form_label' htmlFor=''>
                  SSN*
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='Please enter your SSN number'
                  value={applicantInfo.ssn}
                  onChange={(e) => {
                    setApplicantInfo({
                      ...applicantInfo,
                      ssn: e.target.value.trim(),
                    });
                  }}
                />
              </div>
              {/* <div className="mb_5 col col_sm col_3_md">
                            <label className="form_label" htmlFor="">
                                SSN Last 4 Digit*
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                placeholder="Please enter your SSN last 4 digit"
                                value={applicantInfo.ssnLast4}
                                onChange={(e) => { setApplicantInfo({ ...applicantInfo, ssnLast4: e.target.value.trim() }) }}
                            />
                        </div> */}
            </div>

            <div className='row'>
              <div className='mb_5 col col_sm col_3_md'>
                <label className='form_label' htmlFor=''>
                  Date of Birth
                </label>

                <div>
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    style={{ with: "100%" }}
                  >
                    <DatePicker
                      slotProps={{
                        textField: { size: "small", fullWidth: true },
                      }}
                      value={moment(birthDay)}
                      onChange={(newValue) =>
                        setBirthDay(moment(newValue).format("YYYY-MM-DD"))
                      }
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='mb_5 col col_sm col_1_md'>
                <label className='form_label' htmlFor=''>
                  Annual Income*
                </label>

                {/* <input
                                className="form_control"
                                type="text"
                                placeholder="Please enter  annual income"
                                value={applicantInfo.annualIncome}
                                onChange={(e) => { setApplicantInfo({ ...applicantInfo, annualIncome: e.target.value.trim() }) }}
                            /> */}

                <CurrencyInput
                  id='input-example'
                  name='input-name'
                  placeholder='Please enter  annual income'
                  defaultValue={applicantInfo.annualIncome}
                  value={applicantInfo.annualIncome}
                  decimalsLimit={2}
                  className='form_control'
                  prefix='$'
                  onValueChange={(value, name) =>
                    setApplicantInfo({
                      ...applicantInfo,
                      annualIncome: value ? value * 1 : 0,
                    })
                  }
                />
              </div>
            </div>
            <div className='row'>
              <div className='mb_5 col col_sm col_1_md'>
                <label className='form_label' htmlFor=''>
                  Email*
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='Please enter email address'
                  value={applicantInfo.emailAddress}
                  onChange={(e) => {
                    setApplicantInfo({
                      ...applicantInfo,
                      emailAddress: e.target.value.trim(),
                    });
                  }}
                />
              </div>
            </div>

            <hr
              className='mb_5'
              style={{ borderTop: "1px dashed #BDBDBD " }}
            ></hr>
            <h5 className='mb_5'>Address</h5>

            <div className='row'>
              {/* <div className=" col_2 col_4_sm col_4_md">
                            <label className="form_label" htmlFor="">
                                identificationProvided
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                placeholder="please enter city name "
                                value={applicantInfo.identificationProvided}
                                onChange={(e) => { setApplicantInfo({ ...applicantInfo, identificationProvided: e.target.value.trim() }) }}
                            />
                        </div> */}
              {/* <div className=" col_2 col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                identification Type*
                            </label>
                            <select className="form_control"
                                value={applicantInfo.identificationType}
                                onChange={(e) => { setApplicantInfo({ ...applicantInfo, identificationType: e.target.value.trim() }) }}
                            >
                                <option >Passport</option>
                                <option >DriversLicense</option>

                            </select>
                        </div> */}
              <div className='mb_5 col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  Street Line 1*
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='Please enter  street1'
                  value={applicantInfo.street1}
                  onChange={(e) => {
                    setApplicantInfo({
                      ...applicantInfo,
                      street1: e.target.value,
                    });
                  }}
                />
              </div>
              <div className='mb_5 col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  Street Line 2
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='Please enter  street2'
                  value={applicantInfo.street2}
                  onChange={(e) => {
                    setApplicantInfo({
                      ...applicantInfo,
                      street2: e.target.value,
                    });
                  }}
                />
              </div>

              {/* <div className="mb_5 col_2 col_4_sm col_4_md">
                            <label className="form_label" htmlFor="">
                                identificationImage
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                placeholder="Please enter identificationImage"
                                value={applicantInfo.identificationImage}
                                onChange={(e) => { setApplicantInfo({ ...applicantInfo, identificationImage: e.target.value.trim() }) }}
                            />
                        </div> */}
            </div>
            <div className='row'>
              <div className=' col_2 col_4_sm col_4_md'>
                <label className='form_label' htmlFor=''>
                  State*
                </label>

                {/* <input
                                className="form_control"
                                type="text"
                                placeholder="Please enter city"
                                value={applicantInfo.city}
                                onChange={(e) => { setApplicantInfo({ ...applicantInfo, city: e.target.value.trim() }) }}
                            /> */}

                <Select
                  options={stateCollection}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: "48px",
                      borderRadius: "16px",
                    }),
                  }}
                  // value={applicantInfo.preferredLanguage}
                  // defaultValue={stateCollection[0]}
                  onChange={(e) => {
                    setApplicantInfo({
                      ...applicantInfo,
                      state: e.value.trim(),
                    });
                  }}
                />
              </div>
              <div className=' col_2 col_4_sm col_4_md'>
                <label className='form_label' htmlFor=''>
                  City*
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='Please enter city'
                  value={applicantInfo.city}
                  onChange={(e) => {
                    setApplicantInfo({
                      ...applicantInfo,
                      city: e.target.value,
                    });
                  }}
                />
              </div>
              <div className=' col_2 col_4_sm col_4_md'>
                <label className='form_label' htmlFor=''>
                  Zip Code*
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='Please enter zipcode'
                  value={applicantInfo.zipcode}
                  onChange={(e) => {
                    setApplicantInfo({
                      ...applicantInfo,
                      zipcode: e.target.value.trim(),
                    });
                  }}
                />
              </div>
              <div className='mb_5 col_2 col_4_sm col_4_md'>
                <label className='form_label' htmlFor=''>
                  Apartment or Unit
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='Apartment or unit'
                  value={applicantInfo.apartmentOrUnit}
                  onChange={(e) => {
                    setApplicantInfo({
                      ...applicantInfo,
                      apartmentOrUnit: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className=' col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  Primary Phone Number*
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='Primary phone number'
                  value={applicantInfo.cellPhone}
                  onChange={(e) => {
                    setApplicantInfo({
                      ...applicantInfo,
                      cellPhone: e.target.value.trim(),
                    });
                  }}
                />
              </div>

              <div className=' col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  Alternate Phone Number
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='Alternate phone number'
                  value={applicantInfo.homePhone}
                  onChange={(e) => {
                    setApplicantInfo({
                      ...applicantInfo,
                      homePhone: e.target.value.trim(),
                    });
                  }}
                />
              </div>
            </div>
          </form>

          <div
            className='offcanvas_footer filter_footer'
            id='buttonOne'
            style={{ display: `${step == 1 ? "block" : "none"}` }}
          >
            <div className='btn_group'>
              {/* <button className="btn btn-primary_line_transparent" onClick={getBack}>Cancel</button> */}
              <button
                className='btn btn-primary ms_auto col'
                onClick={nextStep}
              >
                Next
              </button>
            </div>
          </div>

          <div
            className='offcanvas_footer filter_footer'
            id='buttonTwo'
            style={{ display: `${step == 2 ? "block" : "none"}` }}
          >
            <div className='btn_group'>
              <button
                className='btn btn-primary_line_transparent'
                onClick={getBack}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary ms_auto col'
                onClick={add}
                disabled={isLoading}
              >
                {" "}
                {isLoading ? "Loading..." : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ApplicationsForm.propTypes = {};

export default ApplicationsForm;

import React from "react";
import { ToastContainer } from 'react-toastify';
import { Header,ChangePasswordSubject } from "../../components/ChangePasswordModeule";
import TitleBar from "../../components/header/titleBar.js";
import { useNavigate, useLocation } from "react-router-dom";

const ChangePassword = (props) => {
    const navigate = useNavigate();

    
    return (
        <>
        
            <div className="layout">
                {/* <Header/> */}
                <TitleBar title={"Change password"} />
                <div className="main">
                    <div className="container ">
                    <div className="filter_box mt_2  d_none d_block_sm">
                    <div className="breadcrumbs">
                        <div className="nav">
                            <button className="btn_return me_3" onClick={() => navigate("/user-profile")} style={{ width: "24px", height: "24px", background: "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNS40ODgxIDQuNDMwNTdDMTUuODAyNiA0LjcwMDE0IDE1LjgzOSA1LjE3MzYxIDE1LjU2OTQgNS40ODgxMUw5Ljk4NzgxIDEyTDE1LjU2OTQgMTguNTExOUMxNS44MzkgMTguODI2NCAxNS44MDI2IDE5LjI5OTkgMTUuNDg4MSAxOS41Njk1QzE1LjE3MzYgMTkuODM5IDE0LjcwMDEgMTkuODAyNiAxNC40MzA2IDE5LjQ4ODFMOC40MzA1NiAxMi40ODgxQzguMTg5ODEgMTIuMjA3MiA4LjE4OTgxIDExLjc5MjggOC40MzA1NiAxMS41MTE5TDE0LjQzMDYgNC41MTE5MkMxNC43MDAxIDQuMTk3NDMgMTUuMTczNiA0LjE2MSAxNS40ODgxIDQuNDMwNTdaIiBmaWxsPSIjMzMzMzMzIi8+Cjwvc3ZnPgo=')" }} />
                            <div className="title">Change password</div>
                        </div>
                    </div>
                </div>
                <div className=" mb_4" style={{ marginTop: "4vh", display: 'flex' }}>

                </div>
                        <ChangePasswordSubject/>
                    </div>
                </div>
                <ToastContainer limit={1} />
            </div>
        </>

    );
};

ChangePassword.propTypes = {};

export default ChangePassword;

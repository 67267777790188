import { useNavigate } from "react-router-dom"
import { Header, AdvertiserDisclosureText } from "../../components/PrivacyAgreementModule/index.js"



const Advertiser_Disclosure = (props) => {

    return (
        <>

            <div className="layout">
                <Header />
                <div className="main">
                    <div className="container ">

                        <AdvertiserDisclosureText />

                    </div>
                </div>
            </div>
        </>

    );
};

Advertiser_Disclosure.propTypes = {};

export default Advertiser_Disclosure;

import React, { useState } from "react";
import SelectLang from "../../components/selectLanguge";
import tip from "../../assets/tip.png";
import { FormattedMessage } from "react-intl";

import { useLocation, useNavigate } from "react-router-dom";

export function Header({ patient }) {
  const navigate = useNavigate();
  return (
    <>
      <header className='header_patient'>
        <div className='nav container'>
          <div className='title'>
            {patient && patient.consumerApplication.applicantInfo.firstName}
            {"  "}
            {patient && patient.consumerApplication.applicantInfo.lastName}
          </div>
          {/* <div className="title">firstName lastName</div> */}
          <SelectLang />
        </div>
      </header>
    </>
  );
}

export function Advertisement({ patient }) {
  return (
    <>
      <div className='bg_white'>
        <div className='container'>
          <div className='patient_top'>
            <table className='table'>
              <tbody className=''>
                <tr>
                  <td className='color_gray fs_sm'>
                    <FormattedMessage id='Amount' />:
                  </td>
                  <td className='fw_bold'>
                    ${patient && patient.consumerApplication.amount}
                  </td>
                </tr>
                <tr>
                  <td className='color_gray fs_sm'>
                    <FormattedMessage id='TreatmentType' />:
                  </td>
                  <td className='fw_bold'>
                    {patient && patient.consumerApplication.treatmentType}
                  </td>
                </tr>
                <tr>
                  <td className='color_gray fs_sm'>
                    <FormattedMessage id='preferredMonthlyPayment' /> :
                  </td>
                  <td className='fw_bold'>
                    {" "}
                    $
                    {patient &&
                      patient.consumerApplication.applicantInfo
                        .preferredMonthlyPayment}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='d_flex'>
            <a
              className='link_primary ms_auto mb_3'
              href='/advertiser-disclosure'
            >
              <FormattedMessage id='AdvertisementDisclosure' />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export function PatientError() {
  return (
    <>
      {/* <div style={{ display: showTip ? "true" : 'none' }} id="patientError"> */}

      <div>
        <div className='patient_error mt_5 mt_3_sm mt_6_md'>
          <img className='icon_error my_0_sm' src={tip} alt='' />
          <div>
            <span className='d_block d_inline_sm'>
              <FormattedMessage id='there is no preapproval for your request' />
            </span>{" "}
            <span className='d_block d_inline_sm'>
              <FormattedMessage id='contact suggested lender for further processing' />
            </span>
          </div>
        </div>
        <div className='t_center fs_lg color_gray fw_medium mt_3 mt_5_sm'>
          <div className='d_inline_sm'>
            <FormattedMessage id='You can fill your ptreferred application' />
          </div>
          <div className='color_primary fs_lg fw_bold d_inline_sm'>
            {" "}
            <FormattedMessage id='here' />{" "}
          </div>
          <div className='d_inline_sm'>
            <FormattedMessage id='it may help you with lenders' />
          </div>
        </div>
      </div>
    </>
  );
}

export function PatientOk({ dataList }) {
  return (
    <>
      {dataList.length > 1 ? (
        <div className='t_center fs_lg mt_5 mt_6_sm'>
          <div className='fs_gt_2 fw_bold me_1_sm d_inline_sm'>
            <FormattedMessage id='Congratulation' />!
          </div>
          <div className='mt_2 fw_medium mt_0_sm d_inline_sm'>
            {" "}
            <FormattedMessage id='We got you pre-approved with' />{" "}
            <span className='fs_lg color_primary fw_bold'>
              {dataList.length}{" "}
            </span>
            <FormattedMessage id='lenders' />{" "}
          </div>
        </div>
      ) : (
        <div className='t_center fs_lg mt_5 mt_6_sm'>
          <div className='fs_gt_2 fw_bold me_1_sm d_inline_sm'>
            {" "}
            <FormattedMessage id='Congratulation' />!
          </div>
          {/* <FormattedMessage id="Andhara bank" /> */}
          <div className='mt_2 fw_medium mt_0_sm d_inline_sm'>
            <FormattedMessage id='We got you pre-approved with' />{" "}
            <span className='fs_lg color_primary fw_bold'>
              {dataList[0].bankName}{" "}
            </span>
          </div>
        </div>
      )}
    </>
  );
}

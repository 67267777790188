import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getItem, setUserInfo } from "../../utils/storage.js";

const TitleBreadcrumbs = (props) => {
  const navigater = useNavigate();
  const backHome = () => {
    if (typeof (props || {}).upStep === "function") {
      props.upStep("Applications");
    }
    navigater("/");
  };
  let roleInOrganization = JSON.parse(getItem("roleInOrganization"));

  const flag =
    (roleInOrganization || "").toLowerCase() == "administrator" ||
    (roleInOrganization || "").toLowerCase() == "superadministrator";
  const isHome = () => {
    return (
      <>
        {/* <div className='breadcrumbs d_none d_block_sm '>
          <a onClick={backHome}>Home</a>
        </div> */}
      </>
    );
  };
  const showNreadCrumbs = (menu) => {
    return (
      <>
        {flag && (
          <div className='breadcrumbs d_none d_block_sm'>
            <a onClick={backHome}>Home</a> {">"} <span>{menu}</span>
          </div>
        )}
      </>
    );
  };
  return <>{!props.menu ? isHome() : showNreadCrumbs(props.menu)}</>;
};
export default TitleBreadcrumbs;

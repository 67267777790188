import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { checkIsEmail, toastError, ToastSucces } from "../../utils/check.js";
import { sendGet, sendPost, sendPostByJson } from "../../utils/httpUtils.js";
import logo from "../../assets/logo-1.svg"
import { ReturnButton, ForgotPasswordSubject, ForgotPassword2Subject } from "../../components/ForgotPasswordModule/index.js"

const ForgotPassword = (props) => {
  const [step, setStep] = useState("forgotPwd")
  const upStep = (step) => {
    setStep(step)
  }

  return (
    <>
      {step == "forgotPwd" &&
        <div className="container">
          <ReturnButton />
          <div className="login_container">
            <ForgotPasswordSubject upStep={upStep} />
          </div>
        </div>}

      {step == "forgotPwd2" && <ForgotPassword2Subject upStep={upStep} />}
      <ToastContainer limit={1} />

    </>

  );
};

ForgotPassword.propTypes = {};

export default ForgotPassword;

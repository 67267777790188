import React, { useState, PureComponent, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Title,
} from "recharts";
import PropTypes from "prop-types";
import { sendGet, sendPost, sendPostByJson } from "../../../utils/httpUtils.js";
import _, { groupBy } from "lodash";
import moment from "moment";

const getDateArray = (dateType) => {
  const now = new Date();
  let result = [];

  switch ((dateType || "").toUpperCase()) {
    case "WTD":
      const start = new Date(
        _.cloneDeep(now).setDate(now.getDate() - now.getDay())
      );
      const end = now;
      for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
        result.push(new Date(d));
        // result.push(new Date(d).getDate());
      }
      break;
    case "MTD": {
      const start =
        dateType === "MTD"
          ? new Date(now.getFullYear(), now.getMonth(), 1)
          : new Date(
              now.getFullYear(),
              now.getMonth(),
              now.getDate() - now.getDay()
            );
      const end = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() //> 1 ? now.getDate() - 1 : 1
      );
      for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
        result.push(new Date(d).getDate());
      }
      break;
    }
    case "QTD": {
      // Current Week Number
      const startOfYear = new Date(now.getFullYear(), 0, 1);
      const daysSinceStartOfYear = Math.floor((now - startOfYear) / 86400000);
      const currentWeekNumber = Math.ceil(
        (daysSinceStartOfYear + startOfYear.getDay() + 1) / 7
      );

      // Week Number at Start of Quarter
      const quarterStart = new Date(
        now.getFullYear(),
        Math.floor(now.getMonth() / 3) * 3,
        1
      );
      const daysSinceStartOfQuarter = Math.floor(
        (quarterStart - startOfYear) / 86400000
      );
      const weekOfQuarterStart = Math.ceil(
        (daysSinceStartOfQuarter + startOfYear.getDay() + 1) / 7
      );
      result.push(
        ...Array.from(
          { length: currentWeekNumber - weekOfQuarterStart + 1 },
          (_, i) => weekOfQuarterStart + i
        )
      );
      break;
    }
    case "YTD": {
      let startMonth =
        dateType.toUpperCase() === "QTD"
          ? Math.floor(now.getMonth() / 3) * 3
          : 0;
      for (let m = startMonth; m <= now.getMonth(); m++) {
        result.push(m + 1);
      }
      break;
    }
  }
  return result;
};
const getTickFormatter = (viewType, xValue, graphData) => {
  switch ((viewType || "").toUpperCase()) {
    default:
      return "";
    case "WTD":
      const tempDate = (graphData || []).find((o) => o.x === xValue)?.date;

      if (tempDate) {
        return moment(tempDate).format("ddd");
      }
      return "";
    case "MTD":
      return `Day ${xValue}`;
    case "QTD":
      return `Week ${xValue}`;
    case "YTD":
      return `${moment(`${xValue}`, "MM").format("MMM")}`;
  }
};
const getLabelFormatter = (viewType, graphData, x) => {
  const item = graphData.find((i) => i.x === x);
  if (!item) {
    return "";
  }
  switch ((viewType || "").toUpperCase()) {
    default:
      return "";
    case "WTD":
    case "MTD":
      if (!item?.date) {
        return "";
      }
      return moment(item.date).format("yyyy/MM/DD");
    case "QTD":
      return `Week ${item.x}`;
    case "YTD":
      return `${moment(`${item.year}-${item.x}`, "YYYY-MM").format(
        "MMMM YYYY"
      )}`;
  }
};

const getXInterval = (viewType, graphData) => {
  switch ((viewType || "").toUpperCase()) {
    default:
      return 0;
    case "WTD":
      return 0;
    case "MTD":
      return 4;
    case "QTD":
      return graphData.length > 6 ? 1 : 0;
  }
};
// const getXAxisLabel = (viewType) => {
//   switch ((viewType || "").toUpperCase()) {
//     default:
//       return "";
//     case "WTD":
//       return "";
//   }
// };
const viewLabels = {
  WTD: "Week-to-Date",
  MTD: "Month-to-Date",
  QTD: "Quarter-to-Date",
  YTD: "Year-to-Date",
};
const HomeChart = ({
  viewType = "WTD",
  trendCollection = [],
  userType = "Lender",
}) => {
  const [graphData, setGraphData] = useState([]);
  useEffect(() => {
    if ((trendCollection || []).lenght === 0) {
      setGraphData([]);
    }

    let temp = [];
    const xVariable = getDateArray(viewType);
    const now = new Date();
    const currentMonth = now.getMonth() + 1;
    const currentYear = now.getFullYear();
    switch ((viewType || "WTD").toUpperCase()) {
      default:
        break;
      case "WTD":
        xVariable.forEach((dt) => {
          const t = trendCollection.find(
            (i) =>
              i.day === dt.getDate() &&
              i.month === dt.getMonth() + 1 &&
              i.year === new Date().getFullYear()
          );

          // if (day >= new Date().getDate()) {
          //   temp.push({
          //     x: day,
          //     totalSubmittedCount: 0,
          //     totalPreApprovedCount: 0,
          //     totalLeadCount: 0,
          //   });
          //   return;
          // }
          if (!t) {
            temp.push({
              x: dt.getDate(),
              date: dt,
              totalSubmittedCount: 0,
              totalPreApprovedCount: 0,
              totalLeadCount: 0,
            });
          } else {
            temp.push({
              x: dt.getDate(),
              date: dt,
              totalSubmittedCount: t.totalSubmittedCount,
              totalPreApprovedCount: t.totalPreApprovedCount,
              totalLeadCount: t.totalLeadCount,
            });
          }
        });
        break;
      case "MTD": {
        xVariable.forEach((day) => {
          const t = trendCollection.find(
            (i) =>
              i.day === day &&
              i.month === currentMonth &&
              i.year === currentYear
          );
          if (day >= new Date().getDate()) {
            temp.push({
              x: day,
            });
            return;
          }
          if (!t) {
            temp.push({
              x: day,
              date: new Date(`${currentYear}-${currentMonth}-${day}`),
              totalSubmittedCount: 0,
              totalPreApprovedCount: 0,
              totalLeadCount: 0,
            });
          } else {
            temp.push({
              x: day,
              date: new Date(`${currentYear}-${currentMonth}-${day}`),
              totalSubmittedCount: t.totalSubmittedCount,
              totalPreApprovedCount: t.totalPreApprovedCount,
              totalLeadCount: t.totalLeadCount,
            });
          }
        });
        break;
      }
      case "QTD":
        xVariable.forEach((week) => {
          const t = trendCollection.filter(
            (i) => i.week === week && i.year === currentYear
          );
          if (t.length === 0) {
            temp.push({
              x: week,
              name: `${week}`,
              totalSubmittedCount: 0,
              totalPreApprovedCount: 0,
              totalLeadCount: 0,
            });
          } else {
            temp.push({
              x: week,
              name: `${week}`,
              totalSubmittedCount: _.sumBy(t, (i) => i.totalSubmittedCount),
              totalPreApprovedCount: _.sumBy(t, (i) => i.totalPreApprovedCount),
              totalLeadCount: _.sumBy(t, (i) => i.totalLeadCount),
            });
          }
        });
        break;
      case "YTD": {
        xVariable.forEach((month) => {
          const t = trendCollection.filter(
            (i) => i.month === month && i.year === currentYear
          );
          if (t.length === 0) {
            temp.push({
              x: month,
              name: `${month}`,
              year: currentYear,
              totalSubmittedCount: 0,
              totalPreApprovedCount: 0,
              totalLeadCount: 0,
            });
          } else {
            temp.push({
              x: month,
              name: `${month}`,
              year: currentYear,
              totalSubmittedCount: _.sumBy(t, (i) => i.totalSubmittedCount),
              totalPreApprovedCount: _.sumBy(t, (i) => i.totalPreApprovedCount),
              totalLeadCount: _.sumBy(t, (i) => i.totalLeadCount),
            });
          }
        });
        break;
      }
    }
    setGraphData([...temp]);
  }, [viewType]);

  return (
    <>
      <h1 className='graph_title'>{viewLabels[viewType]} OVERVIEW</h1>
      <ResponsiveContainer width='100%' height='100%'>
        <LineChart
          width={500}
          height={300}
          data={graphData}
          margin={{ top: 5, right: 20, bottom: 5, left: -15 }}
        >
          {/* Add title using text element */}
          <CartesianGrid />
          <XAxis
            domain={[0, "auto"]}
            allowDecimals={false}
            dataKey='x'
            interval={getXInterval(viewType, graphData)}
            angle={-45}
            dy={12}
            style={{
              fontSize: 13,
            }}
            tickFormatter={(value) =>
              getTickFormatter(viewType, value, graphData)
            }
          />
          <YAxis
            domain={[0, "auto"]}
            allowDecimals={false}
            label={{
              value: "Count",
              angle: -90,
              position: "insideLeft",
              dx: +20,
              dy: 22,
              style: {
                fill: "#027070",
                fontWeight: 500,
              },
            }}
          />

          {/* Y-axis starting at 0 and whole numbers only */}
          <Tooltip
            labelFormatter={(label) =>
              getLabelFormatter(viewType, graphData, label)
            } // Custom label format for the tooltip
            formatter={(value, name) => {
              // Custom value and label format for the tooltip
              switch (name) {
                case "totalSubmittedCount":
                  return [value, "Submitted"];
                case "totalPreApprovedCount":
                  return [value, "Pre-approved"];
                case "totalLeadCount":
                  return [
                    value,
                    (userType || "").toUpperCase() === "LENDER"
                      ? "Leads"
                      : "Prospects",
                  ];
                default:
                  return [value, name];
              }
            }}
          />
          <Legend
            formatter={(value) => {
              // Custom legend labels
              switch (value) {
                case "totalSubmittedCount":
                  return "Submitted";
                case "totalPreApprovedCount":
                  return "Pre-approved";
                case "totalLeadCount":
                  return (userType || "").toUpperCase() === "LENDER"
                    ? "Leads"
                    : "Prospects";
                default:
                  return value;
              }
            }}
            wrapperStyle={{ bottom: -20 }}
          />
          <Line
            type='linear'
            dataKey='totalSubmittedCount'
            stroke='#6d70c6'
            activeDot={{ r: 8 }}
            strokeWidth={2}
            dot={{ stroke: "#6d70c6", strokeWidth: 2 }}
          />
          <Line
            type='linear'
            dataKey='totalPreApprovedCount'
            stroke='#14a38b'
            activeDot={{ r: 8 }}
            strokeWidth={4}
            dot={{ stroke: "#14a38b", strokeWidth: 2 }}
            strokeDasharray='5 5' // Dashed line style
          />
          <Line
            type='linear'
            dataKey='totalLeadCount'
            stroke='#ff7171'
            activeDot={{ r: 8 }}
            strokeWidth={4}
            dot={{ stroke: "#ff7171", strokeWidth: 2 }}
            strokeDasharray='3 4 5 2' // Custom dash style
          />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

HomeChart.propTypes = {
  viewType: PropTypes.string,
  trendCollection: PropTypes.arrayOf(
    PropTypes.shape({
      lenderId: PropTypes.number,
      year: PropTypes.number,
      month: PropTypes.number,
      week: PropTypes.number,
      day: PropTypes.number,
      totalSubmittedCount: PropTypes.number,
      totalPreApprovedCount: PropTypes.number,
      totalLeadCount: PropTypes.number,
    })
  ),
  userType: PropTypes.string,
};

export default HomeChart;

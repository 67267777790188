import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { sendPostByJson } from "../../utils/httpUtils.js";

export function Header({ upStep }) {
    const navigate = useNavigate();
    const searchValue = useLocation();

    // const [locationInfo, setLocationInfo] = useState(JSON.parse(sessionStorage.getItem("locationDetail")))
    // 
        const [locationInfo, setLocationInfo] = useState({})
    useEffect(() => {
        if (searchValue.state && searchValue.state != null) {
            var data = searchValue.state.item
            if (data) {
                setLocationInfo(data)
            }
        }

    }, [])


    const editPage = () => {
        let item = locationInfo
        navigate(`/account/new-form?typeId=2`, { state: { item } })
    }

    return (
        <>
            <header>
                <div className="nav container">

                    <div className="title">{locationInfo.nickname || ''}&nbsp;&nbsp; ({locationInfo.ownerFirstName || ''}&nbsp;{locationInfo.ownerLastName}&nbsp;) </div>
                    <div className="nav_right ">
                        <button className=" btn btn_edit ms_auto" onClick={editPage}></button>
                        <button className=" btn btn_close ms_auto" onClick={() => navigate('/locations')}></button>
                        {/* <button className=" btn btn_close ms_auto" onClick={() => upStep("locations")}></button> */}

                    </div>
                </div>
            </header>
        </>
    )
}

export function LocationDetail({ upStep }) {
    const searchValue = useLocation();

    const [locationInfo, setLocationInfo] = useState({
        id: "",
        corporateName: "",
        storeName: "",
        officeName: "",
        taxId: "",
        typeOfOwnership: "",
        numberOfEmployees: "",
        businessStartedDate: "",
        businessType: "",
        practiceManagementSoftware: "",
        phoneNumber: "",
        officeWebsite: "",
        faxNumber: '',
    })
    const [physicalAddress, setPhysicalAddress] = useState({
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",

    })
    const [mailing, setMailing] = useState({
        mailingAddress1: "",
        mailingAddress2: "",
        mailingCity: "",
        mailingState: "",
        mailingZipCode: "",
    })
    const [owner, setOwner] = useState({
        ownerFirstName: "",
        ownerLastName: "",
        ownerLicenseNumber: "",
        ownerAddress1: "",
        ownerAddress2: "",

        ownerCity: "",
        ownerState: "",
        ownerZipCode: "",
        ownerPhoneNumber: "",
        ownerEmail: "",

    })
    const [managingDoctor, setManagingDoctor] = useState({
        managerFirstName: "",
        managerLastName: "",
        managerLicenseNumber: "",
        managerLicenseState: ""
    })
    const [concate, setConcate] = useState({
        contactFirstName: "",
        contactLastName: "",
        contactPhoneNumber: "",
        contactEmail: ""
    })
    const [bankInfo, setBankInfo] = useState({
        bankName: "",
        bankRoutingNumber: "",
        bankAccountNumber: "",
        bankLetterImage: ""
    })
    // const [location, setLocation] = useState(JSON.parse(sessionStorage.getItem("locationDetail")))

    useEffect(() => {
        if (searchValue.state && searchValue.state != null) {
        var location=    searchValue.state.item
        if (location) {
            // setId(props.lid)
            setLocationInfo(location)
            setBankInfo(location)
            setConcate(location)
            setManagingDoctor(location)
            setOwner(location)
            setMailing(location)
            setPhysicalAddress(location)

        }
        }




    }, [])
    return (
        <>
            <div className="container d_flex justify_between d_flex_col_row" >
                <div className="content_left">
                    <div className="mb_2">
                        <div className="tbl_title title_fs">Location information</div>
                        <div className="table_dis">
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Corporate/Legal Name:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{locationInfo.corporateName}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Associated Practice Name or Store Number:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text ">{locationInfo.storeName}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Office/DBA Name:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{locationInfo.officeName}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Tax ID Number:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{locationInfo.taxId}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Total Number of Employees:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{locationInfo.numberOfEmployees}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Date Business Started:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{locationInfo.businessStartedDate}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Practice/Medical Specialty, or Business Type:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{locationInfo.businessType}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Office Phone Number:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{locationInfo.phoneNumber}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Office Website:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{locationInfo.officeWebsite}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Fax Number:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{locationInfo.faxNumber}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Practice Management Software:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{locationInfo.practiceManagementSoftware}</div>
                            </div>
                        </div>
                    </div>

                    <div className="mb_2">
                        <div className="tbl_title title_fs">Physical Address</div>
                        <div className="table_dis">
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text tbl_width">Address Line 1:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{physicalAddress.address1}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Address Line2:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{physicalAddress.address2}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">City:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{physicalAddress.city}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">State:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{physicalAddress.state}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Zip Code:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text"> {physicalAddress.zipCode}</div>
                            </div>

                        </div>
                    </div>
                    <div className="mb_2">
                        <div className="tbl_title title_fs">Mailing Address</div>
                        <div className="table_dis">
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text tbl_width">Address Line 1:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{mailing.mailingAddress1}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Address Line 2:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{mailing.mailingAddress2}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">City:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{mailing.mailingCity}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">State:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{mailing.mailingState}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Zip Code:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{mailing.mailingZipCode}</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="content_right">
                    <div className="mb_2">
                        <div className="tbl_title title_fs">Owner</div>
                        <div className="table_dis">
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text tbl_width">First Name:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{owner.ownerFirstName}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Last Name:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{owner.ownerLastName}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Medical/Business/State License Number:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{owner.ownerLicenseNumber}</div>
                            </div>
                            {/* <div className="table_row">
                                <div className="table_cell tbl_cl_text">Social Security Number:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{owner.ownerFirstName}</div>
                            </div> */}
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Address Line 1:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{owner.ownerAddress1}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Address Line 2:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{owner.ownerAddress2}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">City:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{owner.ownerCity}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">State:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{owner.ownerState}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Zip Code:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{owner.ownerZipCode}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Phone Number:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{owner.ownerPhoneNumber}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Email:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{owner.ownerEmail}</div>
                            </div>
                        </div>
                    </div>

                    <div className="mb_2">
                        <div className="tbl_title title_fs">Managing Doctor</div>
                        <div className="table_dis">
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text tbl_width">First Name:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{managingDoctor.managerFirstName}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Last Name:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{managingDoctor.managerLastName}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Medical License Number:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{managingDoctor.managerLicenseNumber}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Medical License State:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{managingDoctor.managerLicenseState}</div>
                            </div>

                        </div>
                    </div>
                    <div className="mb_2">
                        <div className="tbl_title title_fs">Contact</div>
                        <div className="table_dis">
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text tbl_width">First Name:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{concate.contactFirstName}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Last Name:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{concate.contactLastName}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Email:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{concate.contactPhoneNumber}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Phone:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{concate.contactEmail}</div>
                            </div>

                        </div>
                    </div>
                    <div className="mb_2">
                        <div className="tbl_title title_fs">Bank information</div>
                        <div className="table_dis">
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text tbl_width">Bank Name:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{bankInfo.bankName}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Routing Number:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{bankInfo.bankRoutingNumber}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Account Number:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{bankInfo.bankAccountNumber}</div>
                            </div>
                            <div className="table_row">
                                <div className="table_cell tbl_cl_text">Bank Letter or Void Check as An Attachment:</div>
                                <div className="table_cell tbl_cell_text tbl_cr_text">{bankInfo.bankLetterImage}</div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  json,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Alert } from "react-bootstrap";
import styles from "./styles.module.scss";
import { sendPostByJson } from "../../utils/httpUtils";
import { getDefaultApplication } from "../applications/form/utils/utils";
import ApplicantVerification from "./applicant-verification";
import logo from "../../assets/logo-1.svg";
import { apiLoadingStatus } from "../../infrastructure/constants/api";
import Loading from "../../components/Loading/loading";
import ApplicantConsent from "./applicant-consent";
import Logo from "./logo";
import ApplicantResults from "./results";
import { commonContextActions, useCommonContext } from "../../common-context";
import { FormattedMessage, useIntl } from "react-intl";

const steps = {
  errorInFlow: -1,
  none: 0,
  verification: 1,
  consent: 2,
  results: 3,
};
const Applicant = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [{ config }, dispatchCommonContext] = useCommonContext();
  const { applicationId: id } = useParams();
  const [searchParams] = useSearchParams();
  const [step, setStep] = useState(steps.none);
  const [loadingStatus, setLoadingStatus] = useState(apiLoadingStatus.unloaded);
  const accessKey = useMemo(() => searchParams.get("accessKey"), []);
  const [application, setApplication] = useState(getDefaultApplication());
  const [lenderCollection, setLenderCollection] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [verificationReset, setVerificationReset] = useState(0);

  const getIntlMessage = useCallback(
    (id) => intl.formatMessage({ id }),
    [intl]
  );

  const initiateApplication = async () => {
    if (loadingStatus === apiLoadingStatus.loading) {
      return;
    }
    setLoadingStatus(apiLoadingStatus.loading);
    setErrorMessage(null);
    const url = "/Applicant/InitiateApplicationByAccessKey";
    sendPostByJson(url, {
      id,
      accessKey,
    })
      .then((res) => {
        if (res.status != 200 || !res.data?.data?.status) {
          return Promise.resolve(getIntlMessage("Errors.UnknownError"));
        }
        if (!res.data.data.isAccessible) {
          setErrorMessage(getIntlMessage("Errors.NotAccessible"));
          setStep(steps.errorInFlow);
          return;
        }
        dispatchCommonContext({
          type: commonContextActions.updateConfig,
          payload: {
            ...config,
            lang: res.data.data.language || "en",
          },
        });
        setStep(steps.verification);
      })
      .catch((err) => {
        setStep(steps.errorInFlow);
        if (!err?.response?.status) {
          getIntlMessage("Errors.UnableToLoadApplication");
        } else if (
          err.response.headers.get("Action-Status") ===
          "UnsuccessfulAttemptsReached"
        ) {
          setErrorMessage(getIntlMessage("Errors.ApplicantTooManyAttempts"));
        } else {
          setErrorMessage(getIntlMessage("Errors.InvalidLink"));
        }
      })
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.unloaded);
      });
  };

  const onContinue = ({ zipCode, yearOfBirth }) => {
    if (loadingStatus === apiLoadingStatus.loading) {
      return;
    }
    setLoadingStatus(apiLoadingStatus.loading);
    setErrorMessage(null);

    const url = "/Applicant/ApplicationByAccessKey";
    sendPostByJson(url, {
      id,
      accessKey,
      zipCode,
      yearOfBirth,
    })
      .then((res) => {
        if (res.status != 200 || !res.data?.data?.status) {
          return Promise.resolve(getIntlMessage("Errors.UnknownError"));
        }
        if (!res.data.data.isAccessible) {
          setErrorMessage(getIntlMessage("Errors.ApplicationExpired"));
          setStep(steps.errorInFlow);
          return;
        }
        switch (res.data.data.status) {
          case "None":
            setErrorMessage(getIntlMessage("Errors.InvalidApplication"));
            setStep(steps.errorInFlow);
            return;
          case "Initiated":
            setErrorMessage(getIntlMessage("Errors.Initiated"));

            setStep(steps.errorInFlow);
            return;
          case "PendingApplicantSubmission":
            setStep(steps.consent);
            setApplication(res.data.data?.consumerApplication || null);
            setLenderCollection(res.data.data?.lenderCollection || []);
            break;
          case "Submitted":
            setErrorMessage(getIntlMessage("Errors.Submitted"));
            setStep(steps.errorInFlow);
            break;
          case "Processing":
            setErrorMessage(getIntlMessage("Errors.Processing"));
            setStep(steps.errorInFlow);
            break;
          case "LenderLinkClicked":
          case "ApplicantApplied":
          case "ProcessedSuccessfulyWithApproval":
          case "ProcessedNoApproval":
          case "ProcessedNoLender":
            setApplication(res.data.data?.consumerApplication || null);
            setLenderCollection(res.data.data?.lenderCollection || []);
            setStep(steps.results);
            break;

          case "Abandoned":
            setErrorMessage(getIntlMessage("Errors.Abandoned"));
            setStep(steps.errorInFlow);
            break;
          default:
          case "ProcessingError":
            setErrorMessage(getIntlMessage("Errors.ProcessingError"));
            setStep(steps.errorInFlow);
            break;
        }
      })
      .catch((err) => {
        if (!err?.response?.status) {
          setErrorMessage(getIntlMessage("Errors.UnableToLoadApplication"));
        } else if (
          err.response.headers.get("Action-Status") ===
          "UnsuccessfulAttemptsReached"
        ) {
          setErrorMessage(getIntlMessage("Errors.ApplicantTooManyAttempts"));

          setStep(steps.errorInFlow);
        } else if (err.response.status === 403) {
          setVerificationReset(Math.random());
          setErrorMessage(getIntlMessage("Errors.InvalidEntries"));
        } else if (err.response.status >= 500) {
          setErrorMessage(getIntlMessage("Errors.UnknownError"));
          setStep(steps.errorInFlow);
        } else {
          setErrorMessage(getIntlMessage("Errors.InvalidLink"));
          setStep(steps.errorInFlow);
        }
      })
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.unloaded);
      });

    return;
  };

  const submitApplication = () => {
    if (loadingStatus === apiLoadingStatus.loading) {
      return;
    }
    setLoadingStatus(apiLoadingStatus.loading);
    setErrorMessage(null);
    const url = "/Applicant/SubmitApplicationByApplicant";
    const data = {
      accessKey,
      id,
      treatmentType: application?.treatmentType,
      applicantInfo: application?.applicantInfo,
      updateKey: application?.updateKey,
      submitApplication: true,
      amount: application.amount,
    };
    // window.json = JSON.stringify(data);

    sendPostByJson(url, data)
      .then((res) => {
        if (res.status != 200) {
          return Promise.resolve(getIntlMessage("Errors.UnknownError"));
        }
        if (!res.data.data.isAccessible) {
          setErrorMessage(getIntlMessage("Errors.ApplicationExpired"));

          setStep(steps.errorInFlow);
          return;
        }
        setStep(steps.results);
        setLenderCollection(res.data?.data?.lenderCollection || []);
        setApplication(res.data.data?.consumerApplication || null);
      })
      .catch((err) => {
        setStep(steps.errorInFlow);
        if (!err?.response?.status) {
          setErrorMessage(getIntlMessage("Errors.UnableToLoadApplication"));
        } else if (
          err.response.headers.get("Action-Status") ===
          "UnsuccessfulAttemptsReached"
        ) {
          setErrorMessage(getIntlMessage("Errors.ApplicantTooManyAttempts"));

          setStep(steps.errorInFlow);
        } else if (err.response.status === 403) {
          setErrorMessage(getIntlMessage("Errors.InvalidEntries"));
        } else {
          setErrorMessage(getIntlMessage("Errors.InvalidLink"));
        }
      })
      .finally(() => {
        setLoadingStatus(apiLoadingStatus.unloaded);
      });
  };

  const isInitiatedValid = useMemo(
    () => id && accessKey && id.length > 0 && accessKey.length > 0,
    []
  );
  useEffect(() => {
    if (isInitiatedValid) {
      initiateApplication();
    } else {
      setStep(steps.errorInFlow);
    }
  }, [isInitiatedValid]);

  // useEffect(() => {
  //   if (step === steps.verification) {
  //     onContinue({
  //       zipCode: "76201",
  //       yearOfBirth: 2006,
  //     });
  //   } else if (step === steps.consent) {
  //     submitApplication();
  //   }
  // }, [step]);
  return (
    <div className={styles["container"]}>
      <Loading loading={apiLoadingStatus.loading === loadingStatus} />
      {step === steps.errorInFlow && (
        <div
          className={`text-center ${styles["container"]} ${styles["error"]} pb-0`}
        >
          <Logo />
          <Alert variant='danger'>
            {errorMessage || <FormattedMessage id='Errors.InvalidLink' />}
          </Alert>
        </div>
      )}

      {step === steps.verification && (
        <ApplicantVerification
          reset={verificationReset}
          onContinue={onContinue}
          errorMessage={errorMessage}
        />
      )}

      {step === steps.consent && (
        <ApplicantConsent
          lenderCollection={lenderCollection}
          errorMessage={errorMessage}
          onConsent={submitApplication}
        />
      )}

      {step === steps.results && (
        <ApplicantResults
          application={application}
          lenderCollection={lenderCollection}
        />
      )}
    </div>
  );
};

Applicant.propTypes = {};

export default Applicant;

import React, { useState, useEffect,useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sendGetKeyValue, sendPostByJson } from "../../utils/httpUtils.js";
import Select from 'react-select'
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TitleBar from "../../components/header/titleBar.js";
import {
    setItem,
    setUserInfo,
    setToken,
    clearAllStorages,
  } from "../../utils/storage.js";
  import {
    checkIsEmail,
    toastInfo,
    toastError,
    toastSuccess,
  } from "../../utils/check.js";
  
import step_1 from '../../assets/step_1.png'
import step_2 from '../../assets/step_2.png'
import step_3 from '../../assets/step_3.png'
import step_4 from '../../assets/step_4.png'
import step_5 from '../../assets/step_5.png'
import step_6 from '../../assets/step_6.png'
import step_7 from '../../assets/step_7.png'
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";


const LocationForm = (props) => {

    // 
    const toastId = useRef(null);
    const navigate = useNavigate();
    const [id, setId] = useState("")
    const [stepImg, setStepImg] = useState(step_1)
    let [count, setCount] = useState(1)
    const [locationInfo, setLocationInfo] = useState({
        id: "",
        corporateName: "",
        storeName: "",
        officeName: "",
        taxId: "",
        typeOfOwnership: "",
        numberOfEmployees: "",
        businessStartedDate: "",
        businessType: "",
        practiceManagementSoftware: "",
        phoneNumber: "",
        officeWebsite: "",
        faxNumber: '',

        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",

        mailingAddress1: "",
        mailingAddress2: "",
        mailingCity: "",
        mailingState: "",
        mailingZipCode: "",

        ownerFirstName: "",
        ownerLastName: "",
        ownerLicenseNumber: "",
        ownerAddress1: "",
        ownerAddress2: "",

        ownerCity: "",
        ownerState: "",
        ownerZipCode: "",
        ownerPhoneNumber: "",
        ownerEmail: "",

        managerFirstName: "",
        managerLastName: "",
        managerLicenseNumber: "",
        managerLicenseState: "",

        contactFirstName: "",
        contactLastName: "",
        contactPhoneNumber: "",
        contactEmail: "",

        bankName: "",
        bankRoutingNumber: "",
        bankAccountNumber: "",
        bankLetterImage: "",
    })
    const [physicalAddress, setPhysicalAddress] = useState({
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",

    })
    const [mailing, setMailing] = useState({
        mailingAddress1: "",
        mailingAddress2: "",
        mailingCity: "",
        mailingState: "",
        mailingZipCode: "",
    })
    const [owner, setOwner] = useState({
        ownerFirstName: "",
        ownerLastName: "",
        ownerLicenseNumber: "",
        ownerAddress1: "",
        ownerAddress2: "",

        ownerCity: "",
        ownerState: "",
        ownerZipCode: "",
        ownerPhoneNumber: "",
        ownerEmail: "",

    })
    const [managingDoctor, setManagingDoctor] = useState({
        managerFirstName: "",
        managerLastName: "",
        managerLicenseNumber: "",
        managerLicenseState: ""
    })
    const [concate, setConcate] = useState({
        contactFirstName: "",
        contactLastName: "",
        contactPhoneNumber: "",
        contactEmail: ""
    })
    const [bankInfo, setBankInfo] = useState({
        bankName: "",
        bankRoutingNumber: "",
        bankAccountNumber: "",
        bankLetterImage: ""
    })
    useEffect(() => {
        
        if (props.location) {
            // setId(props.lid)
            setLocationInfo(props.location)
            // setBankInfo(props.location)
            // setConcate(props.location)
            // setManagingDoctor(props.location)
            // setOwner(props.location)
            // setMailing(props.location)
            // setPhysicalAddress(props.location)

        }


    }, [])
    const nextStep = (e) => {
        //next step check some validate
        // if(count==1){
        //        //
        // }else if(count==2){

        // }else if(count==3){

        // }else if(count==4){

        // }else if(count==5){

        // }else if(count==6){

        // }
        count++;

        setCount(count)
        switch (count) {
            case 2:
                setStepImg(step_2)
                break;
            case 3:
                setStepImg(step_3)
                break;
            case 4:
                setStepImg(step_4)

                break
            case 5:
                setStepImg(step_5)
                break
            case 6:
                setStepImg(step_6)

                break
            case 7:
                setStepImg(step_7)

                break
            default:
                setCount(1)
                setStepImg(step_1)
                    ;
        }



    }
    const lastStep = async () => {

        count--;
        setCount(count)
        switch (count) {
            case 1:
                setStepImg(step_1)
                break;
            case 2:
                setStepImg(step_2)
                break
            case 3:
                setStepImg(step_3)
                break
            case 4:
                setStepImg(step_4)
                break
            case 5:
                setStepImg(step_5)

                break
            case 6:
                setStepImg(step_6)

                break
            default:
                setStepImg(step_1)
                setCount(1)
                    ;
        }
    }
    const add = async () => {
        //chek count==7
        if (count == 7) {
            saveData()
        }

    }
    const saveData = async () => {
        // https://dev.dentiratenetwork.com/api/HealthcareGroup/UpsertHgSites?api-version=1.0
        let url = "/HealthcareGroup/UpsertHgSites"
        // 
        // let data = {
        //     ...locationInfo,
        //     ...physicalAddress,
        //     ...mailing,
        //     ...owner,
        //     ...managingDoctor,
        //     ...concate,
        //     ...bankInfo
        // }
        let data=locationInfo
        
        try {

            const res = await sendPostByJson(url, data)
            
            if (res.data) {
                toastSuccess("Update locations info success!", toastId);
                getUserInfoById()
                setTimeout(() => {
                    navigate("/locations")
                }, 2000);

            }
        } catch (error) {

        }

    }
    const getUserInfoById = async () => {
        const url = `/Account/User`
        try {
            const res = await sendGetKeyValue(url)
            if (res.status == 200) {
                // localStorage.clear("locations")
                setItem(
                    "locations",
                    res.data.data.membershipUser.hgSiteCollection
                      ? res.data.data.membershipUser.hgSiteCollection
                      : []
                  );
            }
        } catch (error) {
            // let user = getUserInfo()
            // setAccount({ ...user })
            
        }
    }
    const getLocationInfo = async () => {
        if (!id) return false
        const url = ""
        const data = {

        }
        const res = await sendPostByJson(url, data)
        if (res.data) {
            //set data to every step form info
        }
    }
    useEffect(() => {

    }, [])
    const stateCollection = [
        { label: "Alabama", value: "AL" },
        { label: "Alaska", value: "AK" },
        { label: "Arizona", value: "AZ" },
        { label: "Arkansas", value: "AR" },
        { label: "California", value: "CA" },
        { label: "Colorado", value: "CO" },
        { label: "Connecticut", value: "CT" },
        { label: "Delaware", value: "DE" },
        { label: "Florida", value: "FL" },
        { label: "Georgia", value: "GA" },
        { label: "Hawaii", value: "HI" },
        { label: "Idaho", value: "ID" },
        { label: "Illinois", value: "IL" },
        { label: "Indiana", value: "IN" },
        { label: "Iowa", value: "IA" },
        { label: "Kansas", value: "KS" },
        { label: "Kentucky", value: "KY" },
        { label: "Louisiana", value: "LA" },
        { label: "Maine", value: "ME" },
        { label: "Maryland", value: "MD" },
        { label: "Massachusetts", value: "MA" },
        { label: "Michigan", value: "MI" },
        { label: "Minnesota", value: "MN" },
        { label: "Mississippi", value: "MS" },
        { label: "Missouri", value: "MO" },
        { label: "Montana", value: "MT" },
        { label: "Nebraska", value: "NE" },
        { label: "Nevada", value: "NV" },
        { label: "New Hampshire", value: "NH" },
        { label: "New Jersey", value: "NJ" },
        { label: "New Mexico", value: "NM" },
        { label: "New York", value: "NY" },
        { label: "North Carolina", value: "NC" },
        { label: "North Dakota", value: "ND" },
        { label: "Ohio", value: "OH" },
        { label: "Oklahoma", value: "OK" },
        { label: "Oregon", value: "OR" },
        { label: "Pennsylvania", value: "PA" },
        { label: "Rhode Island", value: "RI" },
        { label: "South Carolina", value: "SC" },
        { label: "South Dakota", value: "SD" },
        { label: "Tennessee", value: "TN" },
        { label: "Texas", value: "TX" },
        { label: "Utah", value: "UT" },
        { label: "Vermont", value: "VT" },
        { label: "Virginia", value: "VA" },
        { label: "Washington", value: "WA" },
        { label: "West Virginia", value: "WV" },
        { label: "Wisconsin", value: "WI" },
        { label: "Wyoming", value: "WY" },
    ];
    return (<>
        <div className="layout">
            {/* <header>
                <div className="nav container">
                    <button className="btn_return me_3" onClick={() => navigate("/locations")} />
                    <div className="title">Edit Location</div>
                </div>
            </header> */}

            <TitleBar title={"Edit Location"} />

            <div className="container" >
                <div className="filter_box mt_2  d_none d_block_sm">
                    <div className="breadcrumbs">
                        <div className="nav">
                            <button className="btn_return me_3" onClick={() => navigate("/locations")} style={{ width: "24px", height: "24px", background: "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNS40ODgxIDQuNDMwNTdDMTUuODAyNiA0LjcwMDE0IDE1LjgzOSA1LjE3MzYxIDE1LjU2OTQgNS40ODgxMUw5Ljk4NzgxIDEyTDE1LjU2OTQgMTguNTExOUMxNS44MzkgMTguODI2NCAxNS44MDI2IDE5LjI5OTkgMTUuNDg4MSAxOS41Njk1QzE1LjE3MzYgMTkuODM5IDE0LjcwMDEgMTkuODAyNiAxNC40MzA2IDE5LjQ4ODFMOC40MzA1NiAxMi40ODgxQzguMTg5ODEgMTIuMjA3MiA4LjE4OTgxIDExLjc5MjggOC40MzA1NiAxMS41MTE5TDE0LjQzMDYgNC41MTE5MkMxNC43MDAxIDQuMTk3NDMgMTUuMTczNiA0LjE2MSAxNS40ODgxIDQuNDMwNTdaIiBmaWxsPSIjMzMzMzMzIi8+Cjwvc3ZnPgo=')" }} />
                            <div className="title">Edit Location</div>
                        </div>
                    </div>
                </div>
                <div className=" mb_4" style={{ marginTop: "5vh", marginLeft: "5px" }}>

                    <img className="mb_6" id="imgOne"
                        src={stepImg}
                        alt=""
                    />

                </div>
                <form className="form mb_2" id="formOne" style={{ display: `${count == 1 ? 'block' : 'none'}` }}>

                    <div className="row"><div className="mb_4 col col_2_sm col_2_md">
                        <label className="form_label" htmlFor="">
                            Corporate/Legal Name
                        </label>

                        <input
                            className="form_control"
                            type="text"
                            onChange={e => setLocationInfo({ ...locationInfo, corporateName: e.target.value })}
                            value={locationInfo.corporateName}
                        />
                    </div></div>
                    <div className="row"><div className="mb_4 col col_2_sm col_2_md">
                        <label className="form_label" htmlFor="">
                            Associated Practice Name or Store Number
                        </label>
                        <input
                            className="form_control"
                            type="text"
                            onChange={e => setLocationInfo({ ...locationInfo, storeName: e.target.value })}
                            value={locationInfo.storeName}

                        />
                    </div></div>
                    <div className="row">
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Office/DBA Name
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...locationInfo, officeName: e.target.value })}
                                value={locationInfo.officeName}
                            />
                        </div>
                    </div>
                    <div className="row"><div className="mb_4 col col_2_sm col_2_md">
                        <label className="form_label" htmlFor="">
                            Tax ID Number
                        </label>

                        <input
                            className="form_control"
                            type="text"
                            onChange={e => setLocationInfo({ ...locationInfo, taxId: e.target.value })}
                            value={locationInfo.taxId}
                        />
                    </div></div>
                    <div className="row">  <div className="mb_4 col col_2_sm col_2_md">
                        <label className="form_label" htmlFor="">
                            Type of Ownership
                        </label>

                        <input
                            className="form_control"
                            type="text"
                            onChange={e => setLocationInfo({ ...locationInfo, typeOfOwnership: e.target.value })}
                            value={locationInfo.typeOfOwnership}
                        />
                    </div></div>
                    <div className="row"><div className="mb_4 col col_2_sm col_2_md">
                        <label className="form_label" htmlFor="">
                            Total Number of Employees
                        </label>

                        <input
                            className="form_control"
                            type="number"
                            onChange={e => setLocationInfo({ ...locationInfo, numberOfEmployees: e.target.value })}
                            value={locationInfo.numberOfEmployees}
                        />
                    </div></div>
                    <div className="row"><div className="mb_4 col col_2_sm col_2_md">
                        <label className="form_label" htmlFor="">
                            Date Business Started
                        </label>
                        <LocalizationProvider
                            dateAdapter={AdapterMoment}
                            style={{ with: "100%" }}
                        >
                            <DatePicker
                                slotProps={{
                                    textField: { size: "small", fullWidth: true },
                                }}
                                value={moment(new Date(locationInfo.businessStartedDate))}
                                onChange={(newValue) =>
                                    setLocationInfo({
                                        ...locationInfo,
                                        businessStartedDate: moment(newValue).format("YYYY-MM-DD"),
                                    })
                                }
                            />
                        </LocalizationProvider>
                        {/* <input
                            className="form_control"
                            type="text"
                            onChange={e => setLocationInfo({ ...locationInfo, businessStartedDate: e.target.value })}
                            value={locationInfo.businessStartedDate}
                        /> */}
                    </div></div>
                    <div className="row"> <div className="mb_4 col col_2_sm col_2_md">
                        <label className="form_label" htmlFor="">
                            Practice/Medical Specialty, or Business Type
                        </label>

                        <input
                            className="form_control"
                            type="text"
                            onChange={e => setLocationInfo({ ...locationInfo, businessType: e.target.value })}
                            value={locationInfo.businessType}

                        />
                    </div></div>
                    <div className="row"> <div className="mb_4 col col_2_sm col_2_md">
                        <label className="form_label" htmlFor="">
                            Practice Management Software
                        </label>

                        <input
                            className="form_control"
                            type="text"
                            onChange={e => setLocationInfo({ ...locationInfo, practiceManagementSoftware: e.target.value })}
                            value={locationInfo.practiceManagementSoftware}

                        />
                    </div>
                    </div>
                    <div className="row">
                        <div className="mb_2 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Office Phone Number
                            </label>

                            <input
                                className="form_control"
                                type="number"

                                onChange={e => setLocationInfo({ ...locationInfo, phoneNumber: e.target.value })}
                                value={locationInfo.phoneNumber}
                            />
                        </div>
                        <div className="mb_2 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Office Website
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...locationInfo, officeWebsite: e.target.value })}
                                value={locationInfo.officeWebsite}

                            />
                        </div>

                    </div>

                    <div className="row">
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Fax Number
                            </label>

                            <input
                                className="form_control"
                                type="number"
                                onChange={e => setLocationInfo({ ...locationInfo, faxNumber: e.target.value })}
                                value={locationInfo.faxNumber}

                            />
                        </div>
                    </div>


                </form>

                <form className="form mb_2" id="formTwo" style={{ display: `${count == 2 ? 'block' : 'none'}` }}>
                    <div className="row">
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Address Line 1
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...locationInfo, address1: e.target.value })}
                                value={locationInfo.address1}

                            />
                        </div>
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Address Line 2
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...locationInfo, address2: e.target.value })}
                                value={locationInfo.address2}


                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mb_2 col col_3_sm col_3_md">
                            <label className="form_label" htmlFor="">
                                City
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...locationInfo, city: e.target.value })}
                                value={locationInfo.city}

                            />
                        </div>
                        <div className="mb_2 col col_3_sm col_3_md">
                            <label className="form_label" htmlFor="">
                                State
                            </label>
                            <Select
                                options={stateCollection}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        height: '48px',
                                        borderRadius: "16px"
                                    }),
                                }}
                                value={stateCollection.find(option => option.value === locationInfo.state)}
                                onChange={(e) => {
                                    setLocationInfo({
                                        ...locationInfo,
                                        state: e.value.trim(),
                                    });
                                }}
                            />
                            {/* <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...physicalAddress, state: e.target.value })}
                                value={physicalAddress.state}

                            /> */}
                        </div>
                        <div className="mb_4 col col_3_sm col_3_md">
                            <label className="form_label" htmlFor="">
                                Zip Code
                            </label>

                            <input
                                className="form_control"
                                type="number"
                                onChange={e => setLocationInfo({ ...locationInfo, zipCode: e.target.value.trim() })}
                                value={locationInfo.zipCode}

                            />
                        </div>
                    </div>





                </form>

                <form className="form mb_4" id="formThree" style={{ display: `${count == 3 ? 'block' : 'none'}` }}>
                    <div className="row">
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Address Line 1
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...locationInfo, mailingAddress1: e.target.value })}
                                value={locationInfo.mailingAddress1}

                            />
                        </div>
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Address Line 2
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...locationInfo, mailingAddress2: e.target.value })}
                                value={locationInfo.mailingAddress2}

                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mb_2 col col_3_sm col_3_md">
                            <label className="form_label" htmlFor="">
                                City
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...locationInfo, mailingCity: e.target.value })}
                                value={locationInfo.mailingCity}

                            />
                        </div>
                        <div className="mb_2 col col_3_sm col_3_md">
                            <label className="form_label" htmlFor="">
                                State
                            </label>

                            {/* <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...mailing, mailingState: e.target.value })}
                                value={mailing.mailingState}

                            /> */}
                            <Select
                                options={stateCollection}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        height: '48px',
                                        borderRadius: "16px"
                                    }),
                                }}
                                value={stateCollection.find(option => option.value === locationInfo.mailingState)}
                                onChange={(e) => {
                                    setLocationInfo({
                                        ...locationInfo,
                                        mailingState: e.value.trim(),
                                    });
                                }}
                            />
                        </div>
                        <div className="mb_4 col col_3_sm col_3_md">
                            <label className="form_label" htmlFor="">
                                Zip Code
                            </label>

                            <input
                                className="form_control"
                                type="number"
                                onChange={e => setLocationInfo({ ...locationInfo, mailingZipCode: e.target.value.trim() })}
                                value={locationInfo.mailingZipCode}

                            />
                        </div>
                    </div>
                </form>

                <form className="form mb_4" id="formFor" style={{ display: `${count == 4 ? 'block' : 'none'}` }}>
                    <div className="row">
                        <div className="mb_2 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                First Name
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...locationInfo, ownerFirstName: e.target.value })}
                                value={locationInfo.ownerFirstName}

                            />
                        </div>
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Last Name
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...locationInfo, ownerLastName: e.target.value })}
                                value={locationInfo.ownerLastName}

                            />
                        </div>

                    </div>
                    <div className="row">
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Medical/Business/State License Number
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...locationInfo, ownerLicenseNumber: e.target.value })}
                                value={locationInfo.ownerLicenseNumber}

                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="mb_2 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Address Line 1
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...locationInfo, ownerAddress1: e.target.value })}
                                value={locationInfo.ownerAddress1}

                            />
                        </div>
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Address Line 2
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...locationInfo, ownerAddress2: e.target.value })}
                                value={locationInfo.ownerAddress2}

                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mb_2 col col_3_sm col_3_md">
                            <label className="form_label" htmlFor="">
                                City
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...locationInfo, ownerCity: e.target.value })}
                                value={locationInfo.ownerCity}

                            />
                        </div>
                        <div className="mb_2 col col_3_sm col_3_md">
                            <label className="form_label" htmlFor="">
                                State
                            </label>

                            {/* <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...owner, ownerState: e.target.value })}
                                value={owner.ownerState}

                            /> */}
                            <Select
                                options={stateCollection}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        height: '48px',
                                        borderRadius: "16px"
                                    }),
                                }}
                                value={stateCollection.find(option => option.value === locationInfo.ownerState)}
                                onChange={(e) => {
                                    setLocationInfo({
                                        ...locationInfo,
                                        ownerState: e.value.trim(),
                                    });
                                }}
                            />
                        </div>
                        <div className="mb_4 col col_3_sm col_3_md">
                            <label className="form_label" htmlFor="">
                                Zip Code
                            </label>

                            <input
                                className="form_control"
                                type="number"
                                onChange={e => setLocationInfo({ ...locationInfo, ownerZipCode: e.target.value.trim() })}
                                value={locationInfo.ownerZipCode}

                            />
                        </div>
                    </div>
                    <div className="row">

                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Phone Number
                            </label>

                            <input
                                className="form_control"
                                type="number"
                                onChange={e => setLocationInfo({ ...locationInfo, ownerPhoneNumber: e.target.value.trim() })}
                                value={locationInfo.ownerPhoneNumber}

                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Email
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...locationInfo, ownerEmail: e.target.value })}
                                value={locationInfo.ownerEmail}

                            />
                        </div>
                    </div>
                </form>

                <form className="form mb_4" id="formFive" style={{ display: `${count == 5 ? 'block' : 'none'}` }}>
                    <div className="row">
                        <div className="mb_2 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                First Name
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...locationInfo, managerFirstName: e.target.value })}
                                value={locationInfo.managerFirstName}

                            />
                        </div>
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Last Name
                            </label>

                            <input
                                className="form_control"
                                type="select"
                                onChange={e => setLocationInfo({ ...locationInfo, managerLastName: e.target.value })}
                                value={locationInfo.managerLastName}

                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Medical License Number
                            </label>

                            <input
                                className="form_control"
                                type="select"
                                onChange={e => setLocationInfo({ ...locationInfo, managerLicenseNumber: e.target.value })}
                                value={locationInfo.managerLicenseNumber}

                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Medical License State
                            </label>

                            {/* <input
                                className="form_control"
                                type="select"
                                onChange={e => setLocationInfo({ ...managingDoctor, managerLicenseState: e.target.value })}
                                value={managingDoctor.managerLicenseState}

                            /> */}
                            <Select
                                options={stateCollection}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        height: '48px',
                                        borderRadius: "16px"
                                    }),
                                }}
                                value={stateCollection.find(option => option.value === locationInfo.managerLicenseState)}
                                onChange={(e) => {
                                    setLocationInfo({
                                        ...locationInfo,
                                        managerLicenseState: e.value.trim(),
                                    });
                                }}
                            />
                        </div>
                    </div>



                </form>

                <form className="form mb_4" id="formSix" style={{ display: `${count == 6 ? 'block' : 'none'}` }}>
                    <div className="row">
                        <div className="mb_2 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                First Name
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...locationInfo, contactFirstName: e.target.value })}
                                value={locationInfo.contactFirstName}

                            />
                        </div>
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Last Name
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...locationInfo, contactLastName: e.target.value })}
                                value={locationInfo.contactLastName}

                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Phone Number
                            </label>

                            <input
                                className="form_control"
                                type="number"
                                onChange={e => setLocationInfo({ ...locationInfo, contactPhoneNumber: e.target.value.trim() })}
                                value={locationInfo.contactPhoneNumber}

                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Email
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                onChange={e => setLocationInfo({ ...locationInfo, contactEmail: e.target.value })}
                                value={locationInfo.contactEmail}

                            />
                        </div>
                    </div>


                </form>
                <form className="form mb_4" id="formSeven" style={{ display: `${count == 7 ? 'block' : 'none'}` }}>
                    <div className="row">
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Bank Name
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                value={locationInfo.bankName}
                                onChange={e => setLocationInfo({ ...locationInfo, bankName: e.target.value })}

                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Routing Number
                            </label>

                            <input
                                className="form_control"
                                type="number"
                                value={locationInfo.bankRoutingNumber}
                                onChange={e => setLocationInfo({ ...locationInfo, bankRoutingNumber: e.target.value })}

                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Account Number
                            </label>

                            <input
                                className="form_control"
                                type="number"
                                value={locationInfo.bankAccountNumber}
                                onChange={e => setLocationInfo({ ...locationInfo, bankAccountNumber: e.target.value })}

                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="mb_4 col col_2_sm col_2_md">
                            <label className="form_label" htmlFor="">
                                Bank Letter or Void Check as An Attachment
                            </label>

                            <input
                                className="form_control"
                                type="text"
                                value={locationInfo.bankLetterImage}
                                onChange={e => setLocationInfo({ ...locationInfo, bankLetterImage: e.target.value })}
                            />
                        </div>
                    </div>




                </form>


                <div className="offcanvas_footer filter_footer" id="buttonThree" >
                    <div className="btn_group">
                        {
                            count > 1 ? <button className="btn btn-primary_line_transparent" onClick={lastStep}>Back</button> : <></>
                        }
                        {
                            count == 7 ? <button className="btn btn-primary ms_auto col" onClick={add} >Submit</button>
                                : <button className="btn btn-primary ms_auto col" onClick={nextStep} >Next</button>
                        }

                    </div>
                </div>
            </div>




        </div>
    </>);
};

LocationForm.propTypes = {};

export default LocationForm;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SelectAsyncControl from "./select-async-control";
import axios from "axios";

const url = "/api/HealthcareGroup/GetHgLendersPaginated";

const HgLendersSelectControl = (props) => {
  const {
    onChange = () => {},
    menuPlacement = "auto",
    value = null,
    multi = false,
    className = "",
    classNamePrefix = "",
    clearable = true,
  } = props;
  const [innerValue, setInnerValue] = useState(value);

  const records = 10;
  const loadOptions = async (query, loadedOptions, { page }) => {
    const response = await axios.get(url, {
      params: {
        start: page < 0 ? 0 : (page - 1) * records,
        records,
        query,
      },
    });
    const json = response;
    return {
      options: (json.data?.data?.lenderCollection || []).map((i) => ({
        ...i,
      })),
      hasMore:
        (json.data?.data?.totalCount || 0) >
        (json.data?.data?.lenderCollection || []).length,
      additional: {
        page: page + 1,
      },
    };
  };

  const innerChange = (o) => {
    setInnerValue(o);
    onChange(o);
  };
  useEffect(() => {
    setInnerValue(value);
  }, [value]);
  return (
    <SelectAsyncControl
      loadOptions={loadOptions}
      onChange={innerChange}
      getOptionLabel={(o) => (!o ? "–" : `${o.name}`)}
      value={innerValue}
      getOptionValue={(o) => (!o ? null : o.id)}
      menuPlacement={menuPlacement}
      placeholder={"Lenders"}
      multi={multi}
      className={className}
      classNamePrefix={classNamePrefix}
      clearable={clearable}
    />
  );
};
HgLendersSelectControl.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  onChange: PropTypes.func,
  menuPlacement: PropTypes.string,
  multi: PropTypes.bool,
};

export default HgLendersSelectControl;

import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import bank from "../../assets/bank.png";
import sponsore from "../../assets/sponsored.png";
import first from "../../assets/first.png";
import bouns from "../../assets/bouns.png";
import offer from "../../assets/offered.png";
import preferred from "../../assets/Preferred.png";
import {
  sendPost,
  sendPostByJson,
  sendPostByKeyValue,
  sendGetKeyValue,
} from "../../utils/httpUtils";

const LenderItem = (props) => {
  const dataList = props.dataList
 
  // const [dataList, setDataList] = useState(props.dataList)
  // 

  // let combined = [...dataList, ...(dataList.reduce((acc, item) => {
  //   if (item.loanOptionCollection) {
  //     acc.push(...item.loanOptionCollection.map(child => ({
  //       interestRate: child.interestRate,
  //       lenderLink: child.link,
  //       lenderName: child.lenderProductName,
  //     })));
  //   }
  //   return acc;
  // }, []))];
  // useEffect(() => {
  //   
  // }, [dataList])

 
  const clickApply = async (item) => {
    window.open(item.lenderLink, "_blank");
    props.showApply(true);
  };

  const clickImg = (item) => {
    
    
  };
 

  return (
    <ul className="mt_5">
      {dataList.map((v, i) => {
        return (
          <>
          <li className="card card_patient" key={i}>
            <div className="tag">
              {v.productType == "Sponsored" && <img src={sponsore} alt="" />}
              {v.productType == "FirstLook" && <img src={first} alt="" />}
              {v.productType == "Bonus" && <img src={bouns} alt="" />}
              {v.productType == "Offered" && <img src={offer} alt="" />}
              {v.productType == "Preferred" && <img src={preferred} alt="" />}
            </div>
            <div className="d_flex_sm justify_between align_center mt_4_sm mt_5_md">
              <div className="top mt_2 mt_0_sm">
                <div className="img_box me_3 me_0_sm">
                
                  
                  <img
                    src={v.logo}  
                    alt=""
                    onClick={() => clickImg(v.logo)}
                    key={i}
                    className="w_100 h_100"
                  />
                </div>
                <div className="mt_2_sm">
                  {/* <FormattedMessage id="Andhara bank" /> */}
                  <div className="name">
                    {v.lenderName ? v.lenderName : " "}
                  </div>
                  <div className="mt_2 mt_1_sm">
                    {/* <FormattedMessage id="Rated" /><span className="num fs_lg">{v.interestRate?v.interestRate:0}</span>/10 */}
                  </div>
                </div>
              </div>
              <div className="lh w_100 d_none_sm mt_3" />
              <div className="total my_3 d_none_sm">
                <FormattedMessage id="Monthly payment" />{" "}
                <span className="amount ms_3">
                  ${v.monthlyPayment ? v.monthlyPayment : 0}
                </span>
              </div>
              <div className="lv d_none d_block_sm"></div>
              <div className="t_left">
                <div className="my_3 my_4_md">
                  <FormattedMessage id="Financing type" /> :{" "}
                  <span className="fw_medium fs_lg">
                    {v.loanType ? v.loanType : ""}
                  </span>
                </div>
                <div className="my_3 my_4_md">
                  <FormattedMessage id="Financing amount" />:{" "}
                  <span className="fw_medium fs_lg">
                    ${v.approvedAmount ? new Intl.NumberFormat('en-US').format( v.approvedAmount) : 0}
                  </span>
                </div>
                <div className="my_3 my_4_md">
                  <FormattedMessage id="Interest rate" />:{" "}
                  <span className="fw_medium fs_lg">
                    {v.interestRate ? parseFloat(v.interestRate * 100).toFixed(2) : '0.0'}%                    
                  </span>
                </div>
              </div>
              <div className="lv d_none d_block_sm"></div>
              <div className="total my_3 d_none d_flex_sm">
                <FormattedMessage id="Monthly payment" />{" "}
                <span className="amount ms_3 ms_4_md">
                  ${v.monthlyPayment ? v.monthlyPayment : 0}
                </span>
              </div>
            </div>
            <div className="lh d_none d_block_sm mt_2 mt_3_md" />
            <button
              className="btn btn-primary mt_3 mb_2 mx_auto mt_3_sm mb_3_md"
              onClick={() => clickApply(v)}
            >
              <FormattedMessage id="Apply" />
            </button>
          </li>
          </>
        );
      })}
    </ul>
  );
};
export default LenderItem;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo-1.svg";
import { getItem, setItem } from "../../utils/storage.js";

import { sendGet, sendPost, sendPostByJson } from "../../utils/httpUtils.js";

import { setUserInfo } from "../../utils/storage.js";
import { set } from "lodash";

export function PatientStartErrorPage({ upStep }) {
  const navigate = useNavigate();
  // const searchValue = useLocation();
  const [message, setMessage] = useState("There is a problem!");
  const [datas, setDatas] = useState(
    JSON.parse(sessionStorage.getItem("patientData"))
  );
  useEffect(() => {
    //
    // if (searchValue.state && searchValue.state.datas) {
    // const datas = searchValue.state.datas
    if (datas) {
      let appStatus = datas.consumerApplication.applicationStatus;
      if (appStatus) {
        if (appStatus == "None" || appStatus == "Initiated") {
          setMessage("404");
        } else if (appStatus == "Submitted" || appStatus == "Processing") {
          setMessage(
            "Your application is being processed. We will let you know once it's ready."
          );
        } else if (
          appStatus == "ProcessedNoApproval" ||
          appStatus == "ProcessedNoLender"
        ) {
          setMessage("NO RESULT");
        } else {
          setMessage("There was an error in processing your application.");
        }
      }
    }
  });

  return (
    <>
      {/* <div className="logo">
                <img
                    alt=""
                    src={logo}
                />
            </div> */}
      <div className="t_welcome color_error">{message}</div>
      {!datas && (
        <div className="email mt_5 mt_6_md">
          <span>We cannot confirm your identity. Please contact </span>
          <a className="link_primary" href="support@dentiratenetwork.com">
            support@dentiratenetwork.com
          </a>
          <span> to resolve the issue</span>
        </div>
      )}

      {/* <button className="btn btn-primary_line mt_5 mt_6_md" onClick={() => { window.history.go(-1) }}>Back</button> */}
      <button
        className="btn btn-primary_line mt_5 mt_6_md"
        onClick={() => {
          upStep("start");
        }}
      >
        Try again
      </button>
    </>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import TitleBar from "../../components/header/titleBar.js";
import { LocationSearch, LocationList } from "../../components/LocationModule/Location.js";
import { checkIsEmail, toastInfo, toastError, } from "../../utils/check.js";
import { sendGet, sendPost, sendPostByFormData, sendPostByJson } from "../../utils/httpUtils.js";
import Details from "../../pages/LocationDetails";
import Footer from '../../components/Footer/footer.js';



import filter_Img from '../../assets/filterImg.png'
import search_Img from '../../assets/search.png'
import { getUserInfo } from "../../utils/storage.js";
import Loading from "../../components/Loading/loading.js";
import { useCommonContext } from "../../common-context/common-context-provider.js";
const Locations = (props) => {
  // const toastId = useRef(null);
  // const navigate = useNavigate();
  // const searchValue = useLocation();
  // const [showFilter, setShowFilter] = useState(false)
  // const [showPop, setShowPop] = useState(false)
  // const [queryParams, setQueryParams] = useState({
  //   keyWord: "",
  // })
  const [{ user }] = useCommonContext();

  const [loading, setLoading] = useState(true)
  const [locationList, setLocationList] = useState([])

  const updateLocationList = (list) => {
    setLocationList(list)
  }

  // const [taxId, setTaxId] = useState("")
  // const [delId, setDelId] = useState("")
  useEffect(() => {

    getLocations()
  }, [])

  const getLocations = async () => {
    const url = "/Account/User"
    // const data={}
    try {
      const res = await sendGet(url)
      if (res.status == 200) {
        updateLocationList(res.data.data.membershipUser.hgSiteCollection)
      }
    } catch (error) {

    } finally {
      setLoading(false)
    }
  }
  const [step, setStep] = useState("locations")
  const upStep = (step) => {
    
    setStep(step)
  }
  // const openPopup = (arg) => {
  //   
  //   setShowPop(true)
  //   //set
  // }


  // const deleteLocation = async () => {
  //   const url = ""
  //   const data = {}
  //   try {
  //     const res = await sendPostByJson(url, data)
  //     if (res.data) {
  //       //delete from locations
  //     }
  //   } catch (error) {

  //   }
  // }

  // const getDetail = (id) => {
  //   navigate(`/account/location-detail?id=${id}`)
  // }
  // const editLocation = (item) => {
  //   navigate(`/account/new-form?typeId=2`, { state: { item } })
  // }
  return (
    <>

      <ToastContainer limit={1} />
      {/* {step == "locations" && */}
        <div className="layout">
          <TitleBar user={user} title={"locations"} />

          <div className="main">
            <div className="container">
              <Loading loading={loading} />
              <LocationSearch locationList={locationList} updateLocationList={updateLocationList} />

              <LocationList locationList={locationList} updateLocationList={updateLocationList} upStep={upStep} />

            </div>
            <Footer />
          </div>
        </div>
      {/* }
      {step == "locationDetail" &&
        <Details upStep={upStep}/>
      } */}
    </>
  );
};

Locations.propTypes = {};

export default Locations;

import React, { useEffect, useState, useRef } from "react";
import { Header, PrivacyPolicyText } from "../../components/PrivacyAgreementModule/index.js"


const Privacy_Policy = (props) => {
    const back = () => {
        window.history.go(-1)
    }
    return (
        <>

            <div className="layout">
                <Header />
                <div className="main">
                    <div className="container ">
                        <PrivacyPolicyText />


                        {/* <div className="pos_fixed"> */}
                        {/* <button className="btn btn-primary w_100 mt_3 " onClick={confirm}>Confirm</button> */}

                        {/* </div> */}
                    </div>
                </div>
            </div>
        </>

    );
};

Privacy_Policy.propTypes = {};

export default Privacy_Policy;

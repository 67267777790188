import React, { useRef, useEffect, useState } from "react";
import styles from "./index.module.css";
// import 'toolcool-range-slider/dist/plugins/tcrs-generated-labels.min.js';
// import 'toolcool-range-slider/dist/plugins/tcrs-moving-tooltip.min.js';
// import 'toolcool-range-slider';

const RangSelect = (props) => {
  const sliderRef = useRef(null);
  const [valueMin, setValueMin] = useState(props.min || 0);
  const [valueMax, setValueMax] = useState(props.max || 100000);
  useEffect(() => {
    const slider = sliderRef.current;
    slider.formatTooltipValue = (value) => {
      return "$" + value;
    };
    const onChange = (evt) => {
      const customEvent = evt;
      let { value1, value2 } = customEvent.detail;
      props.changRang &&
        props.changRang({
          max: getMax(value1, value2),
          min: getMin(value1, value2),
        });
    };

    slider?.addEventListener("change", onChange);
    return () => {
      slider?.removeEventListener("change", onChange);
    };
  }, []);
  const getMax = (a, b) => {
    return Math.max(a, b);
  };
  const getMin = (a, b) => {
    return Math.min(a, b);
  };
  return (
    <div className={styles.rangContext}>
      <tc-range-slider
        id="slider-1"
        step="1"
        pointers-overlap="true"
        pointer-bg="#1F4A94"
        pointer-bg-hover="#347AF6"
        pointer2-bg="#1F4A94"
        pointer2-bg-hover="#347AF6"
        moving-tooltip="true"
        moving-tooltip-distance-to-pointer="20"
        moving-tooltip-width="55"
        moving-tooltip-height="20"
        moving-tooltip-bg="#fff"
        moving-tooltip-text-color="#000"
        moving-tooltip-units-type="prefix"
        formatTooltipValue="formatTooltipValue"
        mousewheel-disabled="true"
        ref={sliderRef}
        value1={valueMin}
        value2={valueMax}
        min="0"
        max="100000"
      />
      <div className={styles.value_rang}>
        <span>$0</span>
        <span>$100,000</span>
      </div>
    </div>
  );
};

export default RangSelect;

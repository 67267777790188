import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import styles from "./select-control.module.scss";
import { isMobile } from "react-device-detect";
import { Form } from "react-bootstrap";

const innerStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 100,
  }),
};

const SelectControl = React.forwardRef((props, ref) => {
  const {
    onKeyDown,
    options,
    value,
    disabled,
    multi,
    onChange,
    placeholder,
    clearable,
    customStyles,
    isSearchable,
    forceReactSelect,
    getOptionLabel,
    getOptionValue,
    customFilter,
  } = props;
  const dropdownStyles = useMemo(() => {
    const st = customStyles ? { ...innerStyles, ...customStyles } : innerStyles;
    return st;
  }, [customStyles]);

  //   const filterConfig = {
  //     ignoreCase: true,
  //     ignoreAccents: true,
  //     matchFrom: "any",
  //     stringify: (option) => `${option.label} ${option.value}`,
  //     trim: true,
  //   };
  // const innerFilter =
  //   typeof customFilter === "function"
  //     ? customFilter
  //     : createFilter(filterConfig);
  if (isMobile && !forceReactSelect) {
    return (
      <Form.Select
        multiple={multi}
        disabled={disabled}
        onChange={(e) => {
          if (multi) {
            const values = Array.from(e.target.selectedOptions, (option) =>
              options.find((i) => i.value.toString() === option.value)
            ).map((i) => i);
            onChange(values);
          } else {
            if (!e.currentTarget.value) {
              onChange(null);
            } else {
              const value = options.find(
                (i) => i.value.toString() === e.currentTarget.value
              );
              onChange(value);
            }
          }
        }}
        value={value ? value.value : ""}
      >
        <option>{placeholder && <>{placeholder}</>}</option>
        {Array.isArray(options) &&
          options.map((item) => (
            <option
              key={item.value}
              value={item.value}
              disabled={
                item && Object.prototype.hasOwnProperty.call(item, "disabled")
                  ? item.disabled
                  : false
              }
              // selected={
              //   !multi
              //     ? value && value.value === item.value
              //     : value && value.find((i) => i.value === item.value)
              // }
            >
              {typeof item.label === "function" ? item.label() : item.label}
            </option>
          ))}
      </Form.Select>
    );
  }
  return (
    <Select
      onKeyDown={(e) => {
        if (ref?.current?.props?.menuIsOpen) {
          e.stopPropagation();
          return;
        }
        onKeyDown(e);
      }}
      // filterOption={innerFilter}
      ref={ref}
      styles={dropdownStyles}
      className={styles["select-control"]}
      options={options}
      value={value}
      isDisabled={disabled}
      isMulti={multi}
      onChange={onChange}
      placeholder={placeholder}
      isClearable={clearable}
      blurInputOnSelect={isMobile}
      backspaceRemovesValue
      isSearchable={!isMobile && isSearchable}
      menuPlacement={"auto"}
      isOptionDisabled={(item) =>
        item && Object.prototype.hasOwnProperty.call(item, "disabled")
          ? item.disabled
          : false
      }
      // menuIsOpen
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
    />
  );
});

SelectControl.defaultProps = {
  options: [],
  value: null,
  disabled: false,
  multi: false,
  onChange: () => {},
  placeholder: "Select",
  clearable: true,
  customStyles: {},
  isSearchable: true,
  forceReactSelect: false,
  getOptionLabel: (o) => (o ? o.label : ""),
  getOptionValue: (o) => (o ? o.value : ""),
  onKeyDown: () => {},
  //   customFilter: null,
};
const optionType = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});
SelectControl.propTypes = {
  options: PropTypes.arrayOf(optionType),
  value: PropTypes.oneOfType([optionType, PropTypes.arrayOf(optionType)]),
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  multi: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  customStyles: PropTypes.shape({}),
  isSearchable: PropTypes.bool,
  forceReactSelect: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  onKeyDown: PropTypes.func,
  //   customFilter: PropTypes.func,
};

export default SelectControl;

import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const ValidationWrapper = ({
  isValid = true,
  children = <></>,
  title = null,
  inputId = "",
  errorMessage = "",
}) => {
  return (
    <div>
      <div
        className={`${styles["input-wrapper"]} ${
          isValid ? "" : "invalid-input"
        }`}
      >
        {!!title && (
          <label htmlFor={inputId} className={styles["input-title"]}>
            {title}
          </label>
        )}
        <>{children}</>
      </div>
      <div
        className={`alert alert-danger validation-message ${
          isValid ? "invisible" : ""
        } `}
      >
        {errorMessage}
      </div>
    </div>
  );
};

ValidationWrapper.propTypes = {
  children: PropTypes.node,
};

export default ValidationWrapper;

import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { checkIsEmail, toastInfo, toastError } from "../../utils/check.js";
import { sendGet, sendPost, sendPostByJson } from "../../utils/httpUtils.js";
import logo from "../../assets/logo-1.svg";
import { getItem, setItem } from "../../utils/storage.js";

export function PatientStartPage({ upStep }) {
  const toastId = useRef(null);
  const navigate = useNavigate();
  const [ssn, setSsn] = useState("");
  const { id } = useParams();
  const [error, setError] = useState(false);
  const [applicationId, setApplicationId] = useState(id);
  const [accessCode, setAccessCode] = useState("");
  const location = useLocation();
  useEffect(() => {
    // const { accessCode } = getURLParams()
    const params = new URLSearchParams(window.location.search);
    const accessKey = params.get("accessKey");
    setAccessCode(accessKey);
    // setApplicationId(applicationId)
  }, [location]);
  // const getURLParams = () => {

  //     const searchParams = new URLSearchParams(window.location.search);
  //
  //     const params = {};

  //     for (let param of searchParams.entries()) {
  //         params[param[0]] = param[1];
  //     }
  //     return params;
  // };
  const ssnLogin = async () => {
    if (!ssn) {
      // setSsn(true)
      toastError("please enter SSN last 4 digit", toastId);
      return;
    } else if (ssn.length !== 4) {
      // setSsn(true)
      toastError("please enter SSN last 4 digit", toastId);
      return;
    } else {
      setIsLoading(true);
      try {
        const url = "/Applicant/ApplicationByAccessKey";
        let data = {};
        data.id = applicationId;
        data.accessKey = accessCode;
        data.ssnLast4 = ssn;

        const res = await sendPostByJson(url, data);
        if (res.status == 200) {
          let datas = res.data.data;
          // setItem("patientData",datas)
          sessionStorage.setItem("patientData", JSON.stringify(datas));
          let appStatus = datas.consumerApplication.applicationStatus;
          if (appStatus && appStatus === "PendingApplicantSubmission") {
            // navigate("/account/patient-next", { state: { datas } });
            upStep("next");
          } else if (
            appStatus &&
            (appStatus === "ProcessedSuccessfulyWithApproval" ||
              appStatus === "LenderLinkClicked" ||
              appStatus === "ApplicantApplied")
          ) {
            navigate("/patient-home", { state: { datas } });
          } else {
            upStep("error");

            // navigate("/account/patient-start-error", { state: { datas } });
          }
        } else {
          sessionStorage.removeItem("patientData");
          upStep("error");
          // navigate("/account/patient-start-error");
        }
        setIsLoading(false);
      } catch (error) {
        sessionStorage.removeItem("patientData");
        setIsLoading(false);

        // navigate("/account/patient-start-error");
        upStep("error");

        toastError(error.response.statusText, toastId);
      }
      // setSsn(false)
    }
  };
  const login = async () => {
    const data = {};
    const url = "";
    try {
      const res = await sendPostByJson(url, data);
      if (res.data) {
      }
    } catch (error) {
      toastError("Login failed, please try again latter!", toastId);
    }
  };
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <div className='t_welcome'>Welcome to Dentirate</div>
      <p className='fw_bold mt_7 mt_8_md'>
        Please enter your SSN last 4 digit to continue:
      </p>
      {/* <form className="form mt_4 mt_6_md">
                <input
                    className="form_control"
                    type="text"
                    placeholder="SSN last 4 digit"
                    value={ssn}
                    maxLength={4}
                    onChange={e => setSsn(e.target.value.trim())}
                />
                <div className="fs_sm color_error mt_1" style={{ visibility: error ? 'visible' : 'hidden' }}>Please enter SSN last 4 digit</div>
            </form>
            <div className="service mt_5 mt_6_md">
                <span>By continuing, you agree to our </span>
                <a className="link_primary fw_bold" href="/terms-of-service">
                    Terms of Service
                </a>
            </div>
            <button className="btn btn-primary w_100 mt_3" onClick={ssnLogin} disabled={isLoading}>{isLoading ? "Loading..." : "Continue"}</button> */}
      <form className='form mt_4 mt_6_md' onSubmit={ssnLogin}>
        <input
          className='form_control'
          type='text'
          placeholder='SSN last 4 digit'
          value={ssn}
          maxLength={4}
          onChange={(e) => setSsn(e.target.value.trim())}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              ssnLogin();
            }
          }}
        />
        <div
          className='fs_sm color_error mt_1'
          style={{ visibility: error ? "visible" : "hidden" }}
        >
          Please enter SSN last 4 digit
        </div>
      </form>

      <div className='service mt_5 mt_6_md'>
        <span>By continuing, you agree to our </span>
        <a className='link_primary fw_bold' href='/terms-of-service'>
          Terms of Service
        </a>
      </div>
      <button
        className='btn btn-primary w_100 mt_3'
        onClick={ssnLogin}
        disabled={isLoading}
      >
        {isLoading ? "Loading..." : "Continue"}
      </button>
    </>
  );
}

export function Logo() {
  return (
    <div className='logo mb_4'>
      <img style={{ width: "128px", height: "30px" }} src={logo} alt='' />
    </div>
  );
}

export function PatientNextPage({ upStep }) {
  const toastId = useRef(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [datas, setDatas] = useState(
    JSON.parse(sessionStorage.getItem("patientData"))
  );
  const [messages1, setMessages1] = useState(
    (datas.lenderCollection || []).map((i) => ({
      text: (
        <>
          I have read and agreed to{" "}
          <a className='link_primary fw_bold' href={i.website}>
            {i.lenderName}
          </a>
          's{" "}
          <a className='link_primary fw_bold' href={i.termsConditions}>
            Terms and Conditions
          </a>
          , and{" "}
          <a className='link_primary fw_bold' href={i.privacyPolicy}>
            Privacy Policy
          </a>
          .
        </>
      ),
      checked: false,
    }))
  );
  const [messages12, setMessages] = useState([
    {
      text: "I have read and agreed to  ",
      textlink: "Terms and Conditions",
      link: "/terms-of-service",
      checked: false,
    },
    {
      text: "I have read and agreed to  ",
      textlink: "Privacy Policy",
      link: "/terms-of-service",
      checked: false,
    },
    {
      text: "I have read and agreed to   ",
      textlink: "Terms and Conditions",
      link: "/terms-of-service",
      checked: false,
    },
    {
      text: "I have read and agreed to  ",
      textlink: "Terms and Conditions",
      link: "/terms-of-service",
      checked: false,
    },
  ]);
  // const searchValue = useLocation();
  // if (searchValue.state && searchValue.state != null) {

  // const datas = searchValue.state.datas;
  // const datas =getItem("patientData")
  //   }
  useEffect(() => {}, []);

  const ssnLogin = async () => {
    let hasUncheckedMessage = messages1.some((message) => !message.checked);
    if (hasUncheckedMessage) {
      toastError("Consent is required for all services!", toastId);
      return false;
    }

    // if (!ssn) {
    //     // setSsn(true)
    //     toastError("please enter SSN last 4 digit", toastId)
    //     return
    // }
    // else if (ssn.length !== 4) {
    //     // setSsn(true)
    //     toastError("please enter SSN last 4 digit", toastId)
    //     return
    // }
    // else {
    // let a = {
    //     "accessKey": "7f56e7295cf942a6a49d39e9d8566ea5fee3392b5d714c26b3f8d7535165613a",
    //     "id": "656511f41667118a714bed81",
    //     "treatmentType": "ORTHO", "amount": 3000,
    //     "applicantInfo": { "firstName": "Juliana", "lastName": "Kasraee", "middleName": "Kobra", "ssn": "987654321", "ssnLast4": "4789", "doB": "1995-05-01T00:00:00.000Z", "identificationProvided": false, "identificationType": "driverslicense", "preferredLanguage": "en", "cellPhone": "2144071346", "annualIncome": 85000, "preferredMonthlyPayment": 375, "emailAddress": "fuwei007@gmail.com", "street1": "123 Bishops St", "zipcode": "90064", "state": "CA", "city": "Los Angeles", },
    //     "updateKey": "0c1d18e042364f308bacdbfa2b6e1791",
    //     "updateSsn": false
    // }
    setIsLoading(true);

    try {
      const url = "/Applicant/SubmitApplicationByApplicant";

      let postData = {
        accessKey: datas.consumerApplication.accessKey,
        id: datas.consumerApplication.id,
        treatmentType: datas.consumerApplication.treatmentType,
        amount: datas.consumerApplication.amount,
        applicantInfo: datas.consumerApplication.applicantInfo,
        updateKey: datas.consumerApplication.updateKey,
        updateSsn: false,
        ssn: datas.consumerApplication.applicantInfo.ssn,
      };
      // submitDate.applicantInfo.ssn = 123456789
      const res = await sendPostByJson(url, postData);

      if (res) {
        let datas = res.data.data;
        setTimeout(() => {
          navigate("/patient-home", { state: { datas } });
        }, 500);
      } else {
        toastError(res.data.message, toastId);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      // navigate("/account/patient-start-error");
      sessionStorage.removeItem("patientData");
      upStep("error");

      toastError(error.stack, toastId);
    }

    //     // setSsn(false)
    // }
  };
  const login = async () => {
    const data = {};
    const url = "";
    try {
      const res = await sendPostByJson(url, data);
      if (res.data) {
      }
    } catch (error) {
      toastError("Login failed, please try again latter!", toastId);
    }
  };
  const changeCheck = (i, e) => {
    let val = e.target.checked;
    let item = messages1[i];
    item.checked = val;
    messages1.splice(i, 1, item);
    setMessages1([...messages1]);

    // let locationIdCollection = []
    // if (val) {
    //     locationIdCollection.push(locations[i].id)

    // }
    // searchObj.locationIdCollection = locationIdCollection
    // fifterObj.locationIdCollection = locations
  };
  const renderLocationList = () => {
    return messages1.map((v, i) => {
      return (
        <li className='my_3 my_5_sm d_flex' key={i}>
          <input
            className='form_checkbox'
            type='checkbox'
            style={{ height: "18px", marginTop: 6 }}
            value={v.checked}
            checked={v.checked}
            onChange={(e) => changeCheck(i, e)}
          />
          <label className='checkbox_label mt_1 fs_sm ms_2'>{v.text}</label>
        </li>
      );
    });
  };

  return (
    <>
      <div className='t_welcome mt_7 mt_8_md'>We need your consent</div>
      <p className='fw_bold mt_7 mt_8_md'>
        Please carefully read the Terms and Conditions of our Lender partners:
      </p>
      <form className='form mt_4 mt_6_md'>{renderLocationList()}</form>
      <div className='service mt_5 mt_6_md'>
        <span>By continuing, you agree to our </span>
        <a className='link_primary fw_bold' href='/terms-of-service'>
          Terms of Service
        </a>
      </div>
      <button
        className='btn btn-primary w_100 mt_3'
        onClick={ssnLogin}
        disabled={isLoading}
      >
        {isLoading ? "Loading..." : "Submit"}
      </button>
    </>
  );
}

import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <ul className="footer-links">
        <li>
          <a href="https://www.dentirate.com/terms/" target="_blank" rel="noopener noreferrer">Terms of Use</a>
        </li>
        <li>
          <a href="https://www.dentirate.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        </li>
        <li>
          <a href="https://www.dentirate.com/contact/" target="_blank" rel="noopener noreferrer">Contact</a>
        </li>
        <li>
          <a href="https://www.dentirate.com/about-us/" target="_blank" rel="noopener noreferrer">About Us</a>
        </li>
      </ul>
      <div className="footer-note">
        {/* <p>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.</p> */}
        <p>© 2024 - <a href="https://www.dentirate.com/" target="_blank" rel="noopener noreferrer">DentiRate.com</a></p>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import { checkIsEmail } from "../../utils/check.js";
import TitleBar from "../../components/header/titleBar.js";
import Footer from '../../components/Footer/footer.js';

import {
  FormRow,
  HomeData,
  ChartContainer,
  MenuButton,
} from "../../components/Homemodule/index.js";
import { useCommonContext } from "../../common-context/common-context-provider.js";
import apiLoadingStatus from "../../infrastructure/constants/api/api-loading-status.js";
import Loading from "../../components/Loading/loading.js";
import { sendGetKeyValue } from "../../utils/httpUtils.js";
import KpiReport from "../../components/KpiReport/index.js";
import { getDateRangeForPeriod } from "../../infrastructure/helpers/date-helpers.js";
import _ from "lodash";

const getDynamicViewType = () => {
  const now = new Date();
  if (now.getDate() > 2) {
    return "MTD";
  }
  if (now.getDay() > 2) {
    return "WTD";
  }

  const startOfYear = new Date(now.getFullYear(), 0, 1);
  const daysSinceStartOfYear = Math.floor((now - startOfYear) / 86400000);
  const currentWeekNumber = Math.ceil(
    (daysSinceStartOfYear + startOfYear.getDay() + 1) / 7
  );
  const quarterStart = new Date(
    now.getFullYear(),
    Math.floor(now.getMonth() / 3) * 3,
    1
  );
  const daysSinceStartOfQuarter = Math.floor(
    (quarterStart - startOfYear) / 86400000
  );
  const weekOfQuarterStart = Math.ceil(
    (daysSinceStartOfQuarter + startOfYear.getDay() + 1) / 7
  );
  if (currentWeekNumber > weekOfQuarterStart) {
    return "QTD";
  }
  return "YTD";
};
const Home = (props) => {
  const [{ user }] = useCommonContext();
  const [homepageData, setHomepageData] = useState({
    overviewCount: null,
    trendCollection: [],
  });
  const [homepageFilter, setHomepageFilter] = useState({
    viewType: getDynamicViewType(),
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const [searchParams, setSearchParams] = useState({
    userIdCollection: [],
    siteIdCollection: [],
    lenderIdCollection: [],
    cityCollection: [],
    startDate: getDateRangeForPeriod(homepageFilter.viewType).startDate,
    endDate: getDateRangeForPeriod(homepageFilter.viewType).endDate,
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const [homepageLoadingStatus, setHomepageLoadingStatus] = useState(
    apiLoadingStatus.unloaded
  );

  window.onload = function () {
    var isPageHide = false;
    window.addEventListener("pageshow", function () {
      if (isPageHide) {
        window.location.reload();
      }
    });
    window.addEventListener("pagehide", function () {
      isPageHide = true;
    });
  };

  const loadSummaryData = async () => {
    const url =
      user.data.user.userType.toLowerCase() === "lender"
        ? "/ClientDashboard/Lender/GetLenderKpiOverview"
        : "/ClientDashboard/Hg/GetHgKpiOverview";
    setHomepageLoadingStatus(apiLoadingStatus.loading);
    sendGetKeyValue(url, homepageFilter)
      .then((res) => {
        if (!res.data?.data?.overviewCount) {
          setHomepageLoadingStatus(apiLoadingStatus.error);
          setHomepageData({
            ...homepageData,
            overviewCount: null,
            trendCollection: [],
          });
        }
        setHomepageData({
          ...homepageData,
          ...res.data.data,
        });
        setHomepageLoadingStatus(apiLoadingStatus.loaded);
      })
      .catch((err) => {
        setHomepageLoadingStatus(apiLoadingStatus.error);
        setHomepageData({
          ...homepageData,
          overviewCount: null,
          trendCollection: [],
        });
      })
      .finally({});
  };

  useEffect(() => {
    if (user.loadingStatus !== apiLoadingStatus.loaded || !!!user.data?.user) {
      return;
    }
    loadSummaryData();
  }, [user.loadingStatus, homepageFilter]);

  useEffect(() => {
    const temp = getDateRangeForPeriod(homepageFilter.viewType);
    const newSearchParams = {
      ...searchParams,
      startDate: temp.startDate,
      endDate: temp.endDate,
    };
    if (!_.isEqual(searchParams, newSearchParams)) {
      setSearchParams(newSearchParams);
    }
  }, [homepageFilter.viewType]);
  return (
    <>
      <div className='layout'>
        <TitleBar user={user} title={"Home"} />
        <div className='scroll_bar'>
          <div className='container'>
            <div className='home_content'>
              <Loading
                loading={homepageLoadingStatus === apiLoadingStatus.loading}
              />
              <HomeData
                userType={user.data?.user?.userType}
                overviewCount={homepageData.overviewCount}
                loadingStatus={homepageLoadingStatus}
                dataFilter={homepageFilter}
                onChangeFilter={(o) => {
                  if (o) {
                    setHomepageFilter({
                      ...homepageFilter,
                      viewType: o,
                    });
                  }
                }}
              />
              <ChartContainer
                userType={user.data?.user?.userType}
                trendCollection={homepageData.trendCollection || []}
                loadingStatus={homepageLoadingStatus}
                dataFilter={homepageFilter}
              />
              <MenuButton />
            </div>
            <div style={{ marginTop: '60px' }}></div> {/* Add space here */}
            <KpiReport 
              // loadUsersAndLocations={false}
              searchParam={searchParams}
              updateSearch={() => {}}
            />
          </div>
          <Footer />

        </div>
      </div>
    </>
  );
};

Home.propTypes = {};

export default Home;

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { toastInfo, toastError, toastSuccess, } from "../../utils/check.js";
import { sendGet, sendPost, sendPostByJson } from "../../utils/httpUtils.js";
import { getItem, getUserInfo, setUserInfo } from "../../utils/storage.js"

export function Header() {
    const navigate = useNavigate();


    return (
        <>
            <header>
                <div className="nav container">
                    <button className="btn_return me_3 " onClick={() => navigate("/user-profile")} />
                    <div className="title">Change password</div>
                </div>
            </header>
        </>
    )
}
export function ChangePasswordSubject() {
    const navigate = useNavigate();
    const toastId = useRef(null);
    const [oldErr, setOldErr] = useState(false)
    const [reEnterErr, setReEnterErr] = useState(false)
    const [newErr, setNewErr] = useState(false)
    const [error, setError] = useState({
        newFmtError: false,
        reNewFmtError: false
    })





    const [pwdInfo, setpwdInfo] = useState({ oldPwd: "", newPwd: "", reEnterPwd: "" });

    const inputOld = (e) => {
        let val = e.target.value
        setpwdInfo({ ...pwdInfo, oldPwd: val })
        if (!val) {
            setOldErr(true)
        } else {
            setOldErr(false)
        }

    }
    const inputNewPwd = (e) => {

        let val = e.target.value
        setpwdInfo({ ...pwdInfo, newPwd: val })
        if (!val) {
            setNewErr(true)
        } else {
            setNewErr(false)
        }
        let str = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,30}$/
        let f = str.test(val)
        if (!f) {
            setError({ ...error, newFmtError: true })
        } else {
            setError({ ...error, newFmtError: false })
        }
    }

    const inputRePwd = (e) => {

        let val = e.target.value
        setpwdInfo({ ...pwdInfo, reEnterPwd: val })
        if (!val) {
            setReEnterErr(true)
        } else {
            setReEnterErr(false)
        }
        let str = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,30}$/
        let f = str.test(val)
        if (!f) {
            setError({ ...error, reNewFmtError: true })
        } else {
            setError({ ...error, reNewFmtError: false })
        }
    }


    const confirm = () => {
        if (!pwdInfo.oldPwd.trim()) {
            toastError("Please enter the original password!", toastId)
            return
        }
        if (!pwdInfo.newPwd.trim()) {
            toastError("Please enter the new password!", toastId)
            return
        }
        if (!pwdInfo.reEnterPwd.trim()) {
            toastError("Please Re-enter the new password!", toastId)
            return
        }
        if (pwdInfo.reEnterPwd != pwdInfo.newPwd) {
            toastError("The new password entered twice does not match!", toastId)
            return
        }

        if (pwdInfo.newPwd.trim() == pwdInfo.oldPwd.trim()) {
            toastError("The new password cannot be the same as the old password", toastId)
            return
        }

        saveChange()



    }
    const saveChange = async () => {
        try {
            // let userInfoString = getUserInfo();
            let id=getItem("id")
            const res = await sendPostByJson("Account/UpdatePassword",
                { oldPassword: pwdInfo.oldPwd.trim(), newPassword: pwdInfo.newPwd.trim(), userId: id })
            if (res.status === 200) {
                toastSuccess("Update new password success,Please log in with new password! ", toastId)
                setTimeout(() => {
                    navigate("/user-profile")
                }, 1000);
            } else if (res.status === 412) {
                toastError("The current password is incorrect.", toastId)

            } else if (res.status === 400) {
                toastError("Password format error", toastId)
            }
        } catch (error) {

            let code = error.response.status
            if (code === 412) {
                toastError("change failed. The current password is incorrect.", toastId)

            } else if (code === 400) {
                toastError("Password format error", toastId)
            }
            else {
                toastError("Update new Password error,pleas try again latter!", toastId)

            }
        }
    }

    return (
        <>
            <form className="form mb_6 max_center">
                <div className="mb_4">
                    <label className="form_label" htmlFor="Currentpassword">
                        Current Password
                    </label>
                    <input
                        id="Currentpassword"
                        className="form_control"
                        type="password"
                        value={pwdInfo.oldPwd}
                        onChange={inputOld}
                        placeholder="Enter your current password"
                    />
                    <div className="color_error mb_1" style={{ visibility: oldErr ? 'visible' : "hidden" }}>
                        Please enter the original password!
                    </div>
                </div>
                <div className="mb_4">
                    <label className="form_label" htmlFor="Newpassword">
                        New Password
                    </label>
                    <input
                        id="password"
                        className="form_control"
                        type="password"
                        value={pwdInfo.newPwd}
                        onChange={inputNewPwd}
                        placeholder="Enter your new password"
                    />
                    <div className="color_error mb_1" style={{ visibility: newErr || error.newFmtError ? 'visible' : "hidden" }}>

                        {newErr ? ' Please enter the new  password!' : 'Please enter passwords between 8 and 30, including uppercase and lowercase letters, numbers, and special symbols'}
                    </div>
                </div>
                <div className="mb_4">
                    <label className="form_label" htmlFor="Repassword">
                        Re-enter New Password
                    </label>
                    <input
                        id="Repassword"
                        className="form_control"
                        type="password"
                        value={pwdInfo.reEnterPwd}
                        onChange={inputRePwd}
                        placeholder="Repeat your new password"
                    />
                    <div className="color_error mb_1" style={{ visibility: reEnterErr || error.reNewFmtError ? 'visible' : "hidden" }}>

                        {reEnterErr ? ' Please Re-enter the new  password!' : 'Please enter passwords between 8 and 30, including uppercase and lowercase letters, numbers, and special symbols'}
                    </div>
                </div>
            </form>
            <div className="pos_fixed">
                <button className="btn btn-primary w_100 mt_3 " onClick={confirm}>Confirm</button>

            </div>
        </>
    )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { getItem } from "../../utils/storage.js";
import { useNavigate } from "react-router-dom";
import RangSelect from "../../components/rangSelect";
import { sendPostByJson, sendGetKeyValue } from "../../utils/httpUtils.js";
import { toastError } from "../../utils/check.js";
import Loading from "../Loading/loading.js";
import Select from "react-select";
import TitleBreadcrumbs from "../../components/TitleBreadcrumbs";
import moment from "moment";
import { parsePhoneNumber } from "libphonenumber-js";
import TooltipControl from "../tooltip-control/tooltip-control.jsx";
import styles from "./styles.module.scss";
import { HgSitesSelectControl } from "../controls/select-control";

const activeStatusList = [
  { value: "Initiated", label: "Initiated" },
  {
    value: "PendingApplicantSubmission",
    label: "Pending Applicant Submission",
  },
  { value: "Submitted", label: "Submitted" },
  { value: "Processing", label: "Processing" },
  { value: "ProcessedSuccessfulyWithApproval", label: "Pre-Approved" },
  { value: "ProcessedNoApproval", label: "No Approval" },
  { value: "ProcessedNoLender", label: "No Lender Available" },
  { value: "LenderLinkClicked", label: "Applicant Selected a Lender" },
  { value: "ApplicantApplied", label: "Applicant Applied" },
  { value: "Abandoned", label: "Abandoned" },
  { value: "ProcessingError", label: "Error" },
];

const treatmentTypes = [
  { label: "General dentistry", value: "GeneralDentistry" },
  { label: "Orthodontics", value: "Orthodontics" },
  { label: "Other specialties", value: "Otherspecialties" },
];
const activeSortList = [
  { key: "SubmitDate", value: "Submit Date" },
  { key: "Amount", value: "Amount" },
  { key: "CreateDate", value: "Create Date" },
  { key: "AbandonDate", value: "Abandon Date" },
  { key: "LastName", value: "Last Name" },
  { key: "FirstName", value: "First Name" },
  { key: "ApplicationStatus", value: "Application Status" },
  { key: "ExpirationDate", value: "Expiration Date" },
  { key: "TreatmentType", value: "Treatment Type" },
  { key: "LastUpdateDate", value: "Last Update Date" },
];
export default function SearchBbox({
  searchObj,
  updateSearch,
  handleCloseFilter = () => {},
  locationCollection = [],
}) {
  const source = parseInt(getItem("source"));
  const [innerSearch, setInnerSearch] = useState({
    start: 0,
    records: 10,
    healthCareGroupId: 0,
    applicationStatusCollection: null,
    maxAmount: null,
    minAmount: null,
    treatmentType: null,
    query: null,
    sortField: "CreateDate",
    locationIdCollection: null,
    applicationStatusCollection: null,
    ...searchObj,
  });

  const addFifter = () => {
    handleCloseFilter();
    updateSearch({ ...innerSearch });
  };
  const changeRange = (arg) => {
    setInnerSearch({
      ...innerSearch,
      minAmount: arg.min,
      maxAmount: arg.max,
    });
  };

  const renderSortList = () => {
    return <></>;
    return activeSortList.map((v, i) => {
      return (
        <div
          className={`item ${innerSearch.sortField == v.key ? "active" : ""}`}
          key={i}
          onClick={() => {
            chooseActiveSort(v.key);
          }}
        >
          {v.value}
        </div>
      );
    });
  };
  const chooseActiveSort = (arg) => {
    setInnerSearch({ ...innerSearch, sortField: arg });
  };

  const onChangeActiveStatus = (e) => {
    setInnerSearch({
      ...innerSearch,
      applicationStatusCollection:
        !!e && e.length > 0 ? e.map((i) => i.value) : null,
    });
  };

  return (
    <>
      <div className={`offcanvas show`}>
        <div className='offcanvas_box'>
          <div className='offcanvas_header'>
            <span className='title'>Filters</span>
            <button className='btn_close ms_auto' onClick={handleCloseFilter} />
          </div>
          <div className='offcanvas_content filter_content'>
            {/* <div className='mt_5 mt_7_sm mt_4_md'>
              <div className='filter_title'>Sort by</div>
              <div className='sort_list my_3'>{renderSortList()}</div>
            </div>
            <div className='lh' /> */}
            <div className='filter_container'>
              <div className='mt_5 mt_4_md'>
                <div className='filter_title mb_3'>Amount</div>
                <div className='row'>
                  <div className='col col_2_sm col_2_md'>
                    <RangSelect changRang={changeRange} min={0} max={100000} />
                    {/* <input type="text" className="js-range-slider"  name="my_range" defaultValue={0} /> */}
                  </div>
                </div>
                <div className='my_3' />
              </div>
              {(source === 101 || source === 100) && (
                <div className='mt_5 mt_4_md'>
                  <div className='filter_title'>Status</div>
                  <div className=''>
                    <Select
                      isClearable
                      isMulti
                      name='colors'
                      options={activeStatusList}
                      className='basic-multi-select'
                      classNamePrefix='select'
                      value={
                        !!innerSearch.applicationStatusCollection &&
                        innerSearch.applicationStatusCollection.length > 0
                          ? activeStatusList.filter(
                              (i) =>
                                innerSearch.applicationStatusCollection.indexOf(
                                  i.value
                                ) > -1
                            )
                          : null
                      }
                      onChange={(e) => {
                        setInnerSearch({
                          ...innerSearch,
                          applicationStatusCollection:
                            !!e && e.length > 0 ? e.map((i) => i.value) : null,
                        });
                      }}
                    />
                  </div>
                </div>
              )}

              <div className='mt_2'>
                <label className='filter_title' htmlFor=''>
                  Treatment Type
                </label>
                <div className=''>
                  <Select
                    // value={"None"}
                    isClearable={true}
                    name='colors'
                    options={treatmentTypes}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    onChange={(e) => {
                      // debugger;
                      setInnerSearch({
                        ...innerSearch,
                        treatmentType: e ? e.value : null,
                      });
                    }}
                    value={
                      !!innerSearch.treatmentType
                        ? treatmentTypes.find(
                            (i) => innerSearch.treatmentType === i.value
                          )
                        : null
                    }
                  />
                </div>
              </div>
              <div className='mt_5 mt_4_md'>
                <div className='filter_title'>Locations</div>
                <div className=''>
                  <Select
                    isMulti
                    isClearable={true}
                    name='colors'
                    options={locationCollection}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    onChange={(e) => {
                      setInnerSearch({
                        ...innerSearch,
                        locationIdCollection:
                          !!e && e.length > 0 ? e.map((i) => i.value) : null,
                      });
                    }}
                    value={
                      !!innerSearch.locationIdCollection &&
                      innerSearch.locationIdCollection.length > 0
                        ? locationCollection.filter(
                            (o) =>
                              innerSearch.locationIdCollection.indexOf(o.id) >
                              -1
                          )
                        : null
                    }
                  />
                  {/* <HgSitesSelectControl
                    multi
                    onChange={(e) => {
                      onChangeLocations(e);
                    }}
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className='offcanvas_footer filter_footer'>
            <div className='btn_group'>
              <button
                className='btn btn-primary_line_transparent'
                onClick={handleCloseFilter}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary ms_auto col'
                onClick={addFifter}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function ApplicationList({
  locationCollection = [],
  dataList,
  isExternalLoading,
  isMoreLoading,
}) {
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();
  const source = parseInt(getItem("source"));
  const toastId = useRef(null);
  const goUpdate = (applicantsData) => {
    // if (source == 101) {

    //     toastError("No permission to edit Application!", toastId)
    //     return false
    // }
    navigate("/account/new-form?typeId=5", { state: { applicantsData } });
  };
  const duplicateApplication = async (applicantsData) => {
    if (
      !window.confirm("Are you sure you want to duplicate this application?")
    ) {
      return false;
    }

    if (source == 101) {
      toastError("No permission to edit Application.", toastId);
      return false;
    }
    setLoading(true);
    try {
      const url = "/Application/DuplicateConsumerApplication";
      const data = {
        id: applicantsData.id,
      };
      const res = await sendPostByJson(url, data);
      if (res.status == 200 && !!res.data?.data?.id) {
        let application = res.data.data;
        navigate(`/applications/edit/${application.id}`);
      } else {
        toastError("Could not duplicate the application.");
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const renderAppList = ({ locationCollection }) => {
    if (isExternalLoading) {
      return <div>Loading...</div>;
    }
    if (!dataList || !Array.isArray(dataList) || dataList.length === 0) {
      return (
        <div className='t_center'>
          {(isMoreLoading && <>Loading</>) || <b>No data found.</b>}
        </div>
      );
    }
    return dataList.map((item, i) => {
      // Format amount and preferred monthly payment as comma-separated dollar values without decimals
      const amount = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
      }).format(item.amount);
      const preferredMonthlyPayment = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
      }).format(item.preferredMonthlyPayment);

      // Modify/edit treatmentType names
      let treatmentTypeName = item.treatmentType;
      if (item.treatmentType === "OTHERSPECIALTIES") {
        treatmentTypeName = "OTHER SPECIALTIES";
      }
      if (item.treatmentType === "GENERALDENTISTRY") {
        treatmentTypeName = "GENERAL DENTISTRY";
      }

      return (
        <li className='col' key={i}>
          <div className='card'>
            <div className='card_title'>
              <div className='text'>
                <span className='content_overlength'>
                  {item.firstName || ""} {item.lastName || ""}
                </span>
                <span className='spacer'></span> {/* Add a spacer element */}
                {/* <span className="color_orange suffix">&nbsp;({v.applicationStatus})</span> */}
                {item.applicationStatus == "None" && (
                  <span className='color_orange suffix'>&nbsp;(Initiated)</span>
                )}
                {item.applicationStatus == "Initiated" && (
                  <span className='color_orange suffix'>&nbsp;(Initiated)</span>
                )}
                {item.applicationStatus == "PendingApplicantSubmission" && (
                  <span className='color_orange suffix'>&nbsp;(Pending)</span>
                )}
                {item.applicationStatus == "Submitted" && (
                  <span className='color_orange suffix'>&nbsp;(Submitted)</span>
                )}
                {item.applicationStatus == "Processing" && (
                  <span className='color_orange suffix'>&nbsp;(Submitted)</span>
                )}
                {item.applicationStatus == "ProcessedNoLender" && (
                  <span className='color_orange suffix'>&nbsp;(Pending)</span>
                )}
                {item.applicationStatus == "ProcessingError" && (
                  <span className='color_orange suffix'>&nbsp;(Pending)</span>
                )}
                {item.applicationStatus ==
                  "ProcessedSuccessfulyWithApproval" && (
                  <span className='color_green suffix'>
                    &nbsp;(Pre-approved)
                  </span>
                )}
                {item.applicationStatus == "LenderLinkClicked" && (
                  <span className='color_green suffix'>&nbsp;(Applied)</span>
                )}
                {item.applicationStatus == "ApplicantApplied" && (
                  <span className='color_green suffix'>&nbsp;(Applied)</span>
                )}
                {item.applicationStatus == "ProcessedNoApproval" && (
                  <span className='color_red suffix'>&nbsp;(Declined)</span>
                )}
                {item.applicationStatus == "Abandoned" && (
                  <span className='color_red suffix'>&nbsp;(Canceled)</span>
                )}
              </div>
              <div className='card_btn_actions'>
                <div className={styles["application-date"]}>
                  {item.createDate && moment(item.createDate).isValid()
                    ? moment(item.createDate).format("MM/DD/yyyy")
                    : "–"}
                </div>
                {(source === 100 || source === 101) && (
                  <TooltipControl placement='left' content='Duplicate'>
                    <button
                      className='btn copy ms_auto btn-icon'
                      onClick={() => {
                        duplicateApplication(item);
                      }}
                    />
                  </TooltipControl>
                )}
                {(item.applicationStatus == "Initiated" ||
                  item.applicationStatus == "PendingApplicantSubmission") && (
                  <TooltipControl placement='left' content='Edit'>
                    <button
                      className='btn edit btn-icon'
                      onClick={() => {
                        navigate(`/applications/edit/${item.id}`);
                      }}
                    />
                  </TooltipControl>
                )}
              </div>
            </div>
            <div className='card_content d_flex_sm justify_between my_2_md'>
              <table className='table'>
                <tbody className=''>
                  <tr>
                    <td className='first_col color_gray fs_sm'>
                      Application ID:
                    </td>
                    <td className={`fw_bold ${styles["application-id"]}`}>
                      {item.id}
                    </td>
                  </tr>
                  <tr>
                    <td className='first_col color_gray fs_sm '>Office:</td>
                    <td className='fw_bold'>
                      {!!item.locationId
                        ? locationCollection.find(
                            (i) => i.id === item.locationId
                          )?.nickname || ""
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td className='first_col color_gray fs_sm no_wrap wd_tr_sm'>
                      Phone Number:
                    </td>
                    <td className='fw_bold'>
                      {parsePhoneNumber(item.cellPhone, "US")?.formatNational()}
                    </td>
                  </tr>
                  <tr>
                    <td className='first_col color_gray fs_sm'>Email: </td>
                    <td className='fw_bold'>
                      {!!item.emailAddress && (
                        <>
                          <a href={`mailto:${item.emailAddress}`}>
                            {item.emailAddress}
                          </a>
                        </>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* <div className='lv dash d_none d_block_sm' /> */}
              <table className='table'>
                <tbody className=''>
                  <tr>
                    <td className='first_col color_gray fs_sm'>Chart No:</td>
                    <td className='fw_bold'>{item.HgPatientReferenceId}</td>
                  </tr>
                  <tr>
                    <td className='first_col color_gray fs_sm'>Amount:</td>
                    <td className='fw_bold'>${amount}</td>
                  </tr>
                  <tr>
                    <td className='first_col color_gray fs_sm'>
                      Treatment Type:
                    </td>
                    <td className='fw_bold'>{treatmentTypeName}</td>
                  </tr>
                  <tr>
                    <td className='first_col color_gray fs_sm no_wrap'>
                      Preferred Payment:
                    </td>
                    <td className='fw_bold'>
                      ${preferredMonthlyPayment} per month
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </li>
      );
    });
  };
  return (
    <>
      <ul className='row mt_5'>
        {renderAppList({ locationCollection: locationCollection })}
      </ul>
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import { join } from "lodash";

const StickyHeader = ({
  initalOffset = 0,
  children = <></>,
  disabled = false,
  containerStickyClass = "",
  onStickyChange = () => {},
}) => {
  const headerRef = useRef(null);
  const offsetRef = useRef(initalOffset);
  const stickyActive = useRef(false);
  const [isStickyActive, setIsStickyActive] = useState(false);
  const handleScroll = () => {
    if (!disabled && headerRef.current) {
      const sticky = headerRef.current.offsetTop;
      if (window.scrollY > sticky) {
        setIsStickyActive(true);
        headerRef.current.classList.add(styles["sticky"]);
        onStickyChange(true);
      } else {
        setIsStickyActive(false);
        headerRef.current.classList.remove(styles["sticky"]);
        onStickyChange(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div
      ref={headerRef}
      className={[
        styles["container"],
        !isStickyActive ? "" : containerStickyClass || "",
      ].join(" ")}
      style={{ top: offsetRef.current }}
    >
      {children}
    </div>
  );
};

StickyHeader.propTypes = {
  initalOffset: PropTypes.number,
  children: PropTypes.node,
};

export default StickyHeader;

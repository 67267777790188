const EN = {
  language: "Language:",
  Amount: "Amount",
  TreatmentType: "Treatment Type",
  preferredMonthlyPayment: "Preferred Monthly Payment",
  AdvertisementDisclosure: "Advertisement Disclosure",
  "there is no preapproval for your request":
    "There is no preapproval for your request",
  "contact suggested lender for further processing":
    "Contact suggested lender for further processing",
  "You can fill your ptreferred application":
    "You can fill your ptreferred application",
  here: "Here",
  "it may help you with lenders": "It may help you with lenders",
  Congratulation: "Congratulations!",
  "We got you pre-approved with": "We got you pre-approved for",
  lenders: "financial products",
  "Andhara bank": "Andhara bank",
  Rated: "Rated",
  "Monthly payment": "Monthly Payment",
  "Financing type": "Financing Type",
  "Financing amount": "Financing Amount",
  "Number of installments": "Number of installments",
  "Interest rate": "Interest Rate",
  Apply: "Apply",
  ApplyNow: "Apply Now",
  "About Us": "About Us",
  "Terms of Service": "Terms of Service",
  "Privacy Policy": "Privacy Policy",
  "Terms Conditions": "Terms Conditions",
  "Help Center": "Help Center",
  "Cookies Settings": "Cookies Settings",

  TreatmentType_GENERALDENTISTRY: "General Dentistry",
  TreatmentType_ORTHODONTICS: "Othodontics",
  TreatmentType_OTHERSPECIALTIES: "Other Specialties",

  Recommended: "Recommended",
  MinimumMonthlyPayment: "Minimum Monthly Payment",
  BestInterestRate: "Best Interest Rate",
  HighestAmount: "Highest Amount",

  LoanType_NONE: "None",
  LoanType_CREDITLINE: "Credit Line",
  LoanType_LOAN: "Loan",
  LoanType_CREDITREVOLVER: "Credit Revolver",
  LoanType_INSTALLMENTPLAN: "Installment Plan",
  LoanType_OTHER: "Other",

  "Errors.UnknownError": "An Error Occured",
  "Errors.InvalidLink": "Invalid Link",
  "Errors.InvalidApplication": "Invalid application",
  "Errors.Initiated": "This application is yet to be completed by the office.",
  "Errors.Submitted":
    "This application is being processed. Please refresh the page later.",
  "Errors.Processing":
    "This application is being processed. Please refresh the page later.",
  "Errors.Abandoned": "This application has been abandoned.",
  "Errors.ProcessingError":
    "There was an error in processing the application. Please ask the office to fill out a new application for you.",
  "Errors.NotAccessible":
    "This application is expired and no longer accessible.",
  "Errors.UnableToLoadApplication": "Unable to load the application.",
  "Errors.ApplicantTooManyAttempts":
    "This link is no longer valid because of too many unsuccessful attempts.",
  "Errors.ApplicationExpired":
    "This application is expired and no longer accessible.",
  "Errors.InvalidEntries":
    "Invalid entries. Please review your information and try again.",
  "Errors.Validation.YearOfBirth": "Invalid year of birth",
  "Errors.Validation.ZipCode": "Invalid ZIP code",
  "Errors.Validation.Consent":
    "You need to agree with the terms and conditions",

  WelcomeToDentirate: "Welcome to DentiRate",
  YearOfBirth: "Year of Birth",
  ZipCode: "Zip Code ",
  Submit: "Submit",
  And: "and",

  "ContinuingAgreement.Part1": "By continuing, you agree to our ",
  "ContinuingAgreement.Part2": "Terms of Service",

  "Consent.WeNeedYourConsent": "We need your consent",
  "Consent.ReadLenderPartners":
    "Please carefully read the Terms and Conditions of our Lender partners.",
  "Consent.ContinuingAgreement.Part1":
    "By continuing, you agree to all above items and our ",
  "Consent.ContinuingAgreement.Part2": "Terms of Service",
  "ConsentItem.ReadAndAgreed": "I have read and agreed to",
  "ConsentItem.TermsConditions": "Terms and Conditions",
  "ConsentItem.PrivacyPolicy": "Privacy Policy",
  "ConsentItem.LenderNamePossessive": "{lenderName}'s",
};
export default EN;

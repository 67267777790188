import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next) // Use initReactI18next
  .init({
    // Your configuration here...
    ns: ["common", "validation"],
    default: "common",
    fallbackLng: {
      "en-US": ["en"],
      "es-ES": ["es"],
      default: ["en"],
    },
    load: "languageOnly", // This will load only the language part of the locale
    whitelist: ["en", "es"], // Define your supported languages
    nonExplicitWhitelist: true, // This allows 'en-US' to be resolved as 'en'
    detection: {
      // Detection options
      order: [
        "querystring",
        "cookie",
        "localStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      lookupQuerystring: "lng",
      lookupCookie: "d_lan",
      lookupLocalStorage: "d_lan",
      // Cache user language on
      caches: ["localStorage", "cookie"],
      excludeCacheFor: ["cimode"], // Languages to not persist (e.g., a debug language)
    },
    backend: {
      // loadPath:
      //   "https://static-dev.dentiratenetwork.com/locales/{{lng}}/{{ns}}.json",
    },
   
    react: {
      useSuspense: false,
    },
  });

export default i18n;

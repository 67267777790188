import actions from "./common-context-actions";

const commonContextReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;
    case actions.updateConfig:
      return {
        ...state,
        config: action.payload,
      };

    case actions.updateUser:
      return {
        ...state,
        user: action.payload,
      };
    case actions.updateLogo:
      return {
        ...state,
        logo: action.payload,
      };
  }
};

export default commonContextReducer;

import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

const SortSign = ({
  className = "",
  currentColumnName = "",
  refColumnName = "",
  direction = "asc",
}) => {
  if (refColumnName != currentColumnName) {
    return <></>;
  }
  return (
    <FontAwesomeIcon
      icon={direction === "asc" ? faArrowUp : faArrowDown}
      className={className}
    />
  );
};

SortSign.propTypes = {};

export default SortSign;

import React, { useEffect, useState, useRef } from "react";
import { ToastContainer } from 'react-toastify';
import { UpdatePassWord ,Logo} from "../../components/RecoveryModule/RecoveryChange.js"
const NewPassword = (props) => {

  return (
    <>

      <div className="container">
        <div className="login_container">
          <Logo/>
          <UpdatePassWord />

        </div>
        <ToastContainer limit={1} />
      </div>
    </>

  );
};

NewPassword.propTypes = {};

export default NewPassword;

import { toast } from "react-toastify";

export function checkIsEmail(email) {
  var reg = /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/; //
  if (!reg.test(email)) {
    //not
    return false;
  }
  return true;
}

export function toastInfo(text, toastId) {
  if (!toast.isActive(toastId?.current || "")) {
    toast.info(text, {
      toastId: toastId,
      position: "top-right",
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}
export function toastError(text, toastId) {
  if (!toast.isActive(toastId?.current || "")) {
    toast.error(text, {
      toastId: toastId,
      position: "top-right",
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } else {
  }
}
export function toastWarn(text, toastId) {
  if (!toast.isActive(toastId?.current || "")) {
    toast.warn(text, {
      toastId: toastId,
      position: "top-right",
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}
export function toastSuccess(text, toastId) {
  if (!toast.isActive(toastId?.current || "")) {
    toast.success(text, {
      toastId: toastId,
      position: "top-right",
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
}

import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { useIntl } from "react-intl";

const ConsentItem = ({
  lenderName = "",
  lenderLink = "",
  termsConditions = "",
  privacyPolicy = "",
}) => {
  const intl = useIntl();
  const getIntlMessage = useCallback(
    (id) => intl.formatMessage({ id }),
    [intl]
  );

  const lenderNamePossessive = getIntlMessage(
    "ConsentItem.LenderNamePossessive"
  ).replace("{lenderName}", lenderName);

  return (
    <div>
      <FontAwesomeIcon icon={faCircle} size={"2xs"} />{" "}
      {getIntlMessage("ConsentItem.ReadAndAgreed")}{" "}
      <a className='link_primary fw_bold' href={lenderLink} target='_blank'>
        {lenderNamePossessive} <FontAwesomeIcon icon={faExternalLink} />
      </a>{" "}
      <a
        className='link_primary fw_bold'
        href={termsConditions}
        target='_blank'
      >
        {getIntlMessage("ConsentItem.TermsConditions")}{" "}
        <FontAwesomeIcon icon={faExternalLink} />
      </a>{" "}
      {getIntlMessage("And")}{" "}
      <a className='link_primary fw_bold' href={privacyPolicy} target='_blank'>
        {getIntlMessage("ConsentItem.PrivacyPolicy")}{" "}
        <FontAwesomeIcon icon={faExternalLink} />
      </a>
      .
    </div>
  );
};

ConsentItem.propTypes = {
  lenderName: PropTypes.string,
  lenderLink: PropTypes.string,
  termsConditions: PropTypes.string,
  privacyPolicy: PropTypes.string,
};

export default ConsentItem;

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PatientStartErrorPage } from "../../components/PatientModule/PatientStartError";

const PatientStartError = (props) => {
  const navigate = useNavigate();
 


  return (
    <>
    <div className="container">
      <div className="login_container applicant_error_container">
        <PatientStartErrorPage/>
      </div>
    </div>
  </>
  



  );
};

PatientStartError.propTypes = {};

export default PatientStartError;

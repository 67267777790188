import React from "react";

function Loading({ loading, delay = 2000 }) {
  if (!loading) {
    return <></>;
  }
  return (
    <div className='loading-overlay'>
      <div className='loading-content'>
        <div className='lds-roller'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
export default Loading;

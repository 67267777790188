// ShowMoreLess.jsx
import React, { useState, useRef, useEffect, useMemo } from "react";
import styles from "./styles.module.scss";
import { Button } from "react-bootstrap";
import SortSign from "../sort-sign";

const ShowMoreLess = ({
  children,
  initialShowMore = false,
  collapsedHeight = 100,
  getLabel = (e) => (e ? "Show Less" : "Show More"),
  isActive = false,
}) => {
  const [expanded, setExpanded] = useState(initialShowMore);
  const contentRef = useRef(null);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  //   console.log(isActive);
  const showMore = useMemo(() => expanded && isActive, [isActive, expanded]);
  return (
    <div className={styles["container"]}>
      <div
        ref={contentRef}
        className={
          showMore ? `${styles.content} ${styles.expanded}` : styles.content
        }
        style={{
          maxHeight: showMore ? "initial" : `${collapsedHeight}px`,
        }}
      >
        {children}
      </div>
      {isActive && (
        <div className={styles["view-more-container"]}>
          <Button
            variant='link'
            className={`relative ${styles["view-more"]}`}
            onClick={toggleExpanded}
          >
            {getLabel(expanded)}{" "}
            <SortSign
              refColumnName='view-more'
              currentColumnName={"view-more"}
              className={styles["sort-icon"]}
              direction={!expanded ? "desc" : "asc"}
            />
          </Button>
        </div>
      )}
    </div>
  );
};

export default ShowMoreLess;

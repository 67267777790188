import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import Loading from "../Loading/loading.js";
import { ToastContainer, toast } from "react-toastify";
import {
  sendGet,
  sendPost,
  sendPostByJson,
  sendPostByKeyValue,
  sendGetKeyValue,
} from "../../utils/httpUtils.js";
import { setUserInfo, getUserInfo, getItem } from "../../utils/storage.js";
import TitleBar from "../../components/header/titleBar.js";
import PropTypes from "prop-types";
import {
  checkIsEmail,
  toastInfo,
  toastError,
  toastSuccess,
} from "../../utils/check.js";
import Select from "react-select";
import LenderHGsSelectControl from "../controls/select-control/lender-hgs-select-control.jsx";
import UserLocations from "../locationItems";
import LenderHGSiteSelectControl from "../controls/select-control/lender-hg-site-select-control.jsx";

const UserManagementFormUpdata = (props) => {
  const { user: userData } = props;
  const locationCollection = userData?.hgSiteCollection || [];
  const toastId = useRef(null);
  const navigate = useNavigate();
  const [active, setActive] = useState(true);
  const [hcgList, setHCGList] = useState([]);
  const [locations, setLocations] = useState(locationCollection);
  const [isLoading, setLoading] = useState(false);
  const [selectedHg, setSelectedHg] = useState(null);

  useEffect(() => {
    // get user list
    let user = userData;
    if (user && user.hgSiteCollection && locations) {
      const updatedLocations = locations.map((location) => {
        const foundInUser = user.hgSiteCollection.find(
          (site) => site.id === location.id
        );
        if (foundInUser) {
          return { ...location, checked: true };
        }
        return location;
      });
      setLocations(updatedLocations);
    }
    setUserInfo(user);
  }, []);

  const searchLocations = async (e) => {
    const url = "/Lender/GetLenderHgSitesPaginated";
    try {
      let fifterObj = {
        start: 0,
        records: 999999,
      };
      const filteredEntries = Object.entries(fifterObj).filter(
        ([key, value]) => value !== undefined
      );
      const params = new URLSearchParams(filteredEntries);
      for (let i = 0; i < e.length; i++) {
        params.append("hgIdCollection", e[i].value);
      }
      const res = await sendGetKeyValue(url, params);

      // data.data.hg_SiteCollection
      if (res.status === 200) {
        // let hcgList = res.data.data.hg_SiteCollection.map(item => ({
        //     label: item.name,
        //     value: item.id
        // }))
        let user = userData;
        if (user && user.hgSiteCollection) {
          const updatedLocations = res.data.data.hg_SiteCollection.map(
            (location) => {
              const foundInUser = user.hgSiteCollection.find(
                (site) => site.id === location.id
              );
              if (foundInUser) {
                return { ...location, checked: true };
              }
              return location;
            }
          );

          setLocations(updatedLocations);
        }

        // setLoations(res.data.data.hg_SiteCollection)
      }
    } catch (error) {}
  };

  const getHCGList = async () => {
    const url = "/Lender/GetLenderHgsPaginated";
    try {
      const res = await sendGetKeyValue(url, { start: 0, records: 99999 });
      if (res.status === 200) {
        let hcgList = res.data.data.hgCollection.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setHCGList(hcgList);
      }
    } catch (error) {}
  };

  const [user, setUserInfo] = useState({
    firstName: "",
    id: 0,
    lastName: "",
    phoneNumber: "",
    state: "",
    city: "",
    address1: "",
    address2: "",
    zipCode: "",
  });
  // const loginUser = getUserInfo()

  const [step, setStep] = useState();

  // filter loctions
  const filterLocations = (e) => {
    e.preventDefault();
  };
  const nextStep = (e) => {
    add();
  };

  const add = async () => {
    navigate("/user-management", {
      state: { sortBy: "sort3", statuse: "All", treatmentType: "3 week" },
    });
  };

  //set active
  const changeAct = () => {
    setActive(!active);
  };
  //check validate
  const chekeError = () => {
    if (!user.firstName) {
      toastError("Please enter first name", toastId);
      return false;
    }
    if (!user.lastName) {
      toastError("Please enter last name", toastId);
      return false;
    }
    if (!user.phoneNumber) {
      toastError("Please enter phone number", toastId);
      return false;
    }
    if (user.phoneNumber.length != 10) {
      toastError("Please enter a phone number with a length of 10", toastId);
      return false;
    }
    return true;
  };

  const renderLocationList = () => {
    if (locations.length == 0) {
      return false;
    }
    return locations.map((v, i) => {
      return (
        <li className='my_3 my_5_sm d_flex' key={i}>
          <input
            className='form_checkbox'
            type='checkbox'
            value={v.checked}
            checked={v.checked}
            onChange={(e) => changeCheck(i, e)}
          />
          <label className='checkbox_label mt_1 fs_sm ms_2'>
            <div className='color_primary700'>
              <span className='fw_medium'>{v.nickname || ""}</span> (
              {v.ownerFirstName || ""}&nbsp;{v.ownerLastName}&nbsp;)
            </div>
            <div className='mt_2 fs_sm'>
              {v.address1 ? v.address1 + "," : " "}
              {v.address2 ? v.address2 + "," : " "}
              {v.city ? v.city + "," : " "}
              {v.state || " "}
              {v.zipCode || " "}
            </div>
          </label>
        </li>
      );
    });
  };
  const changeCheck = (i, e) => {
    let val = e.target.checked;

    let item = locations[i];
    item.checked = val;
    locations.splice(i, 1, item);
    setLocations([...locations]);
  };
  //save update
  const saveUpdate = async () => {
    setLoading(true);
    const flag = chekeError();
    if (flag) {
      try {
        const url = "/Account/UpdateUserBio";
        let data = {
          ...user,
        };
        data.id = user.id;
        const res = await sendPostByJson(url, data);
        if (res.data) {
          if (locations && locations.length > 0) {
            upsertUsersInHgSites(data);
          } else {
            setLoading(false);
            setTimeout(() => {
              navigate("/user-management");
            }, 200);
          }
        } else {
          toastError("Update user info failed!", toastId);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toastError(error.response.statusText, toastId);
      }
    }
  };
  const upsertUsersInHgSites = async (datas) => {
    const source = parseInt(getItem("source"));

    try {
      var url = "";
      if (source === 100 || source == 101) {
        url = "/HealthcareGroup/UpsertUsersInHgSites";
      } else if (source == 200 || source == 201) {
        url = "/Lender/UpsertUsersInHgSites";
      }
      let data = {
        updateCollection: [],
      };
      // let ids = locations.map(item => item.checked === true ? item.id : null);
      // for (let i = 0; i < ids.length; i++) {
      //     let update = {}
      //     if (ids[i]) {
      //         update = {
      //             siteId: ids[i],
      //             userId: datas.id,
      //             isActive: true
      //         }
      //         data.updateCollection.push(update)
      //     }

      // }

      let updates = locations.map((item) => {
        let isChecked = item.isActive;
        return {
          siteId: item.id,
          userId: datas.id,
          isActive: isChecked,
        };
      });

      data.updateCollection.push(...updates);
      const res = await sendPostByJson(url, data);
      if (res.data) {
        toastSuccess("Update user InHgSites success!", toastId);
        setTimeout(() => {
          navigate("/user-management");
        }, 200);
      } else {
        toastError("Update user InHgSites failed!", toastId);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      toastError(error.message, toastId);
    }
  };
  //cancle
  const cancelUpdate = () => {
    navigate("/user-management");
  };
  // const hcgList = [
  //     { label: "Grapes ", value: "grapes" },
  //     { label: "Mango ", value: "mango" },
  //     { label: "Strawberry ", value: "strawberry" },
  //     { label: "Watermelon ", value: "watermelon" },
  //     { label: "Pear ", value: "pear", disabled: true },
  //     { label: "Apple ", value: "apple" },
  //     { label: "Tangerine ", value: "tangerine" },
  //     { label: "Pineapple ", value: "pineapple" },
  //     { label: "Peach ", value: "peach" }
  // ];
  const stateCollection = [
    { label: "Alabama", value: "AL" },
    { label: "Alaska", value: "AK" },
    { label: "Arizona", value: "AZ" },
    { label: "Arkansas", value: "AR" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "Delaware", value: "DE" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Hawaii", value: "HI" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Iowa", value: "IA" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Maine", value: "ME" },
    { label: "Maryland", value: "MD" },
    { label: "Massachusetts", value: "MA" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Mississippi", value: "MS" },
    { label: "Missouri", value: "MO" },
    { label: "Montana", value: "MT" },
    { label: "Nebraska", value: "NE" },
    { label: "Nevada", value: "NV" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "New York", value: "NY" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Vermont", value: "VT" },
    { label: "Virginia", value: "VA" },
    { label: "Washington", value: "WA" },
    { label: "West Virginia", value: "WV" },
    { label: "Wisconsin", value: "WI" },
    { label: "Wyoming", value: "WY" },
  ];
  return (
    <>
      <Loading loading={isLoading} />

      <ToastContainer limit={1} />
      <div className='layout'>
        {/* <header>
                <div className="nav container">
                    <button className="btn_return me_3" onClick={() => navigate("/user-management")} />
                    <div className="title">User management</div>
                </div>
            </header> */}
        <TitleBar title={"User management"} />

        <div className='container mb_2'>
          <div className='filter_box mt_2  d_none d_block_sm'>
            <div className='breadcrumbs'>
              <div className='nav'>
                <button
                  className='btn_return me_3'
                  onClick={() => navigate("/user-management")}
                  style={{
                    width: "24px",
                    height: "24px",
                    background:
                      "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNS40ODgxIDQuNDMwNTdDMTUuODAyNiA0LjcwMDE0IDE1LjgzOSA1LjE3MzYxIDE1LjU2OTQgNS40ODgxMUw5Ljk4NzgxIDEyTDE1LjU2OTQgMTguNTExOUMxNS44MzkgMTguODI2NCAxNS44MDI2IDE5LjI5OTkgMTUuNDg4MSAxOS41Njk1QzE1LjE3MzYgMTkuODM5IDE0LjcwMDEgMTkuODAyNiAxNC40MzA2IDE5LjQ4ODFMOC40MzA1NiAxMi40ODgxQzguMTg5ODEgMTIuMjA3MiA4LjE4OTgxIDExLjc5MjggOC40MzA1NiAxMS41MTE5TDE0LjQzMDYgNC41MTE5MkMxNC43MDAxIDQuMTk3NDMgMTUuMTczNiA0LjE2MSAxNS40ODgxIDQuNDMwNTdaIiBmaWxsPSIjMzMzMzMzIi8+Cjwvc3ZnPgo=')",
                  }}
                />
                <div className='title'>User management</div>
              </div>
            </div>
          </div>
          <form className='form mb_4' id='formOne'>
            <div className='row'>
              <div className='mb_4 col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  First Name
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='enter first name'
                  value={user.firstName || ""}
                  onChange={(e) => {
                    setUserInfo({ ...user, firstName: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='mb_4 col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  Last Name
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='enter last name'
                  value={user.lastName || ""}
                  onChange={(e) => {
                    setUserInfo({ ...user, lastName: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='mb_4 col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  Phone Number
                </label>

                <input
                  className='form_control'
                  type='number'
                  placeholder='enter phone number'
                  maxLength={10}
                  value={user.phoneNumber || ""}
                  onChange={(e) => {
                    setUserInfo({
                      ...user,
                      phoneNumber: e.target.value.trim(),
                    });
                  }}
                />
              </div>
            </div>

            <div className='row'>
              <div className='mb_4 col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  State
                </label>

                {/* <input
                                className="form_control"
                                type="text"

                                placeholder="enter state"
                                // maxLength={10}
                                value={user.state}
                                onChange={e => { setUserInfo({ ...user, state: e.target.value }) }}
                            /> */}
                <Select
                  options={stateCollection}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: "48px",
                      borderRadius: "16px",
                    }),
                  }}
                  value={stateCollection.find(
                    (option) => option.value === user.state
                  )}
                  onChange={(e) => {
                    setUserInfo({
                      ...user,
                      state: e.value.trim(),
                    });
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='mb_4 col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  City
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='enter city'
                  // maxLength={10}
                  value={user.city || ""}
                  onChange={(e) => {
                    setUserInfo({ ...user, city: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='mb_4 col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  Address1
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='enter address1'
                  // maxLength={10}
                  value={user.address1 || ""}
                  onChange={(e) => {
                    setUserInfo({ ...user, address1: e.target.value });
                  }}
                />
              </div>
            </div>

            <div className='row'>
              <div className='mb_4 col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  Address2
                </label>

                <input
                  className='form_control'
                  type='text'
                  placeholder='enter address2'
                  // maxLength={10}
                  value={user.address2 || ""}
                  onChange={(e) => {
                    setUserInfo({ ...user, address2: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className='row'>
              <div className='mb_4 col col_2_sm col_2_md'>
                <label className='form_label' htmlFor=''>
                  Zip Code
                </label>

                <input
                  className='form_control'
                  type='number'
                  placeholder='enter zipCode'
                  // maxLength={10}
                  value={user.zipCode || ""}
                  onChange={(e) => {
                    setUserInfo({ ...user, zipCode: e.target.value });
                  }}
                />
              </div>
            </div>
            {/* getItem('source') */}
            {(getItem("source") == "200" || getItem("source") == "201") && (
              <>
                <div className='row'>
                  <div className='mb_4 col col_2_sm col_2_md'>
                    <label className='form_label' htmlFor=''>
                      Healthcare Groups
                    </label>
                    <LenderHGsSelectControl
                      value={selectedHg}
                      onChange={(x) => {
                        setSelectedHg(x);
                      }}
                    />
                  </div>
                </div>
                <div className='row'>
                  {!!selectedHg && (
                    <div className='mb_4 col col_2_sm col_2_md'>
                      <label className='form_label' htmlFor=''>
                        Healthcare Locations
                      </label>
                      <LenderHGSiteSelectControl
                        hgIdCollection={[selectedHg.id]}
                        excludedHgSiteIdCollection={(locations || []).map(
                          (i) => i.id
                        )}
                        onChange={(i) =>
                          setLocations([
                            ...(locations || []).concat([
                              {
                                ...i,
                                notSaved: !locationCollection.find(
                                  (k) => k.id === i.id && k.isActive
                                ),
                              },
                            ]),
                          ])
                        }
                        value={null}
                        reloadResults={[
                          selectedHg.id,
                          (locations || []).map((i) => i.id),
                        ].join("-")}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
            <UserLocations
              locationCollection={(locations || []).filter((i) => i.isActive)}
              enableDeletion
              onDelete={(id) => {
                const t = locations.filter((i) => {
                  if (i.id === id) {
                    i.isActive = false;
                  }
                  return i;
                });
                setLocations([...t]);
              }}
            />
          </form>

          <div className=' filter_footer'>
            <div className='btn_group'>
              <button
                className='btn btn-primary_line_transparent btn_same_size'
                onClick={cancelUpdate}
              >
                Cancel
              </button>
              <button
                className='btn btn-primary ms_auto col btn_same_size'
                onClick={saveUpdate}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

UserManagementFormUpdata.propTypes = {
  locationCollection: PropTypes.arrayOf(PropTypes.any),
};

export default UserManagementFormUpdata;

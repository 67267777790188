import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TitleBar from "../../components/header/titleBar.js";
import { ToastContainer, toast } from 'react-toastify';
import { checkIsEmail, toastInfo, toastError, ToastSucces, } from "../../utils/check.js";
import { sendGet, sendPost, sendPostByJson, sendGetKeyValue } from "../../utils/httpUtils.js";
import { setUserInfo, getUserInfo } from "../../utils/storage.js"
import InfiniteScroll from 'react-infinite-scroll-component';
import Vector from '../../assets/Vector.png'
import { UserManagementSearch, UserManagementList } from "../../components/UserManagement/index.js";
import { useCommonContext } from "../../common-context/common-context-provider.js";
import Footer from '../../components/Footer/footer.js';


const UserManagement = (props) => {
  const [showFilter, setShowFilter] = useState(false)
  const [isPending, setPending] = useState(false)
  const [{ user }] = useCommonContext();

  const [searchObj, setSearchObj] = useState({
    keyword: "",
    state: undefined,
    location: "",
    locationStr: ""
  })
  const [userList, setUserList] = useState([

  ])

  const updateUserList = (userList) => {
    setUserList(userList)
  }
  // const [locations, setLoations] = useState([])
  const [hasMore, sethasMore] = useState(false);
  const updateHashMore = (hasMore) => {
    sethasMore(hasMore)
  }
  const [current, setCurrent] = useState(0)
  const updateCurrent = (current) => {
    setCurrent(current)
  }

  const getMoreData = () => {
    setTimeout(async () => {

      const url = "/Account/PaginatedUserList"
      const res = await sendGetKeyValue(url, { start: current, records: 20, isActive: searchObj.state, query: searchObj.keyword })
      if (res.status === 200) {

        setTimeout(() => {
          if (userList.length < res.data.data.totalCount) {
            setUserList(userList.concat(res.data.data.userList))
            updateHashMore(true)
            updateCurrent(current + 20)
          } else {
            updateHashMore(false)
          }
        }, 1000);

      }
    }, 1000);



  }
  useEffect(() => {
    // get user list

    // getUserList()


  }, [])


  const getUserList = async () => {
    const url = "/Account/PaginatedUserList"
    try {

      const res = await sendGetKeyValue(url, { start: current, records: 20, isActive: searchObj.state, query: searchObj.keyword })
      if (res.status === 200) {
        setUserList(res.data.data.userList)
        // setShowFilter(false)
        if (res.data.data.userList.length < res.data.data.totalCount) {
          updateCurrent(20)
          updateHashMore(true)
        }
      }
    } catch (error) {

    }
    //filter user list

  };




  return (
    <>
      <ToastContainer limit={1} />
      <div className="layout">
        <TitleBar user={user} title={"User Management"} />
        <div className="main" id="morePage">
          <div className="container">

            <UserManagementSearch
              userList={userList}
              updateUserList={updateUserList}
              updateHashMore={updateHashMore}
              updateCurrent={updateCurrent} current={current}
              searchObj={searchObj} setSearchObj={setSearchObj}
              setPending={setPending}
            />


            <InfiniteScroll
              dataLength={userList.length}
              next={getMoreData}
              hasMore={hasMore}
              style={{ overflow: "" }}
              scrollableTarget="morePage"
              loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}
              // endMessage={
              //   <p style={{ textAlign: 'center' }}>
              //     <b>Loaded all</b>
              //   </p>
              // }
            >
              <UserManagementList
                userList={userList}
                updateUserList={updateUserList}
                searchObj={searchObj}
                updateHashMore={updateHashMore}
                updateCurrent={updateCurrent} 
                setPending={setPending}
                isPending={isPending}
                />
            </InfiniteScroll>

          </div>
          <Footer />

        </div>
      </div>



    </>



  );
};

UserManagement.propTypes = {};

export default UserManagement;

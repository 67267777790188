import React, { useMemo } from "react";
import { useCommonContext } from "../common-context";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { apiLoadingStatus } from "../infrastructure/constants/api";
import Login from "../pages/login";
import ForgotPwd from "../pages/forgotPassword";
import NewPassword from "../pages/newPassword";
import Home from "../pages/home";
import Applications from "../pages/applications";
import Locations from "../pages/locations";
import UserManagement from "../pages/user-management";
import UserProfile from "../pages/userProfile";
import NewForm from "../pages/newForm";
import ChangePassword from "../pages/changePassword";
import PatientStart from "../pages/patientStart";
import PatientStartError from "../pages/patientStartError";
import PatientHome from "../pages/patientHome";
import Billing from "../pages/billing";
import BillingDetail from "../pages/billingDetail";
import Dashboard from "../pages/dashboard";
import Leads from "../pages/Leads";
import Details from "../pages/LocationDetails";
import AccountUpdate from "../pages/user-management/accountUpdate";
import AdvertiserDisclosure from "../pages/Advertiser_Disclosure";
import PrivacyPolicy from "../pages/Privacy_Policy";
import AboutUs from "../pages/AboutUs";
import CookiesSettings from "../pages/CookiesSettings";
import HelpCenter from "../pages/HelpCenter";
import TermConditions from "../pages/TermConditions";
import PatientStartNext from "../pages/patientStartNext";
import NotFind from "../pages/404";
import { redirect } from "react-router-dom";
import ApplicationForm from "../pages/applications/form";
import Applicant from "../pages/applicant";

const AuthorizedRoutes = (props) => {
  const [{ user, config }] = useCommonContext();

  const location = useLocation();

  if (
    user.loadingStatus === apiLoadingStatus.loading ||
    user.loadingStatus === apiLoadingStatus.unloaded
  ) {
    return <></>;
  }
  if (
    location.pathname.startsWith("/account/login") ||
    location.pathname.startsWith("/account/recovery") ||
    location.pathname.startsWith("/account/account-update") ||
    location.pathname.startsWith("/account/patient-next") ||
    location.pathname.startsWith("/account/patient-start-error") ||
    location.pathname.startsWith("/account/update-password") ||
    location.pathname.startsWith("/application/") ||
    location.pathname.startsWith("/app1/") ||
    location.pathname.startsWith("/patient-home") ||
    location.pathname.startsWith("/advertiser-disclosure") ||
    location.pathname.startsWith("/privacy-policy") ||
    location.pathname.startsWith("/about-us") ||
    location.pathname.startsWith("/help-center") ||
    location.pathname.startsWith("/cookies-settings") ||
    location.pathname.startsWith("/terms-conditions")
  ) {
    return (
      <Routes>
        <Route path='/account/login' element={<Login />} />
        <Route path='/account/recovery' element={<ForgotPwd />} />
        <Route
          path='/account/update-password/:code'
          element={<NewPassword />}
        />
        <Route
          path='/account/account-update/:code'
          element={<AccountUpdate />}
        ></Route>
        <Route path='/app1/:id' element={<PatientStart />} />
        <Route path='/application/:applicationId' element={<Applicant />} />
        <Route path='/account/patient-next' element={<PatientStartNext />} />
        <Route
          path='/account/patient-start-error'
          element={<PatientStartError />}
        />
        <Route path='/patient-home' element={<PatientHome />} />
        <Route
          path='/advertiser-disclosure'
          element={<AdvertiserDisclosure />}
        />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-conditions' element={<TermConditions />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/help-center' element={<HelpCenter />} />
        <Route path='/cookies-settings' element={<CookiesSettings />} />
      </Routes>
    );
  }
  if (!!!user.data?.user) {
    document.location.href = "/account/login";
    return <></>;
  }
  return (
    <Routes>
      <Route path='/applications/new' element={<ApplicationForm />} />
      <Route
        path='/applications/edit/:applicationId'
        element={<ApplicationForm />}
      />
      <Route path='/applications' element={<Applications />} />
      <Route path='/locations' element={<Locations />} />
      <Route path='/user-management' element={<UserManagement />} />
      <Route path='/user-profile' element={<UserProfile />} />
      <Route path='/account/new-form' element={<NewForm />} />
      <Route path='/account/change-password' element={<ChangePassword />} />
      <Route path='/patient-home' element={<PatientHome />} />
      <Route path='/billing' element={<Billing />} />
      <Route path='/billing-detail' element={<BillingDetail />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path='/leads' element={<Leads />} />
      <Route path='/account/location-detail' element={<Details />} />
      <Route path='/404' element={<NotFind />} />
      <Route path='/' element={<Home />} />
      <Route element={<Navigate to='/404' replace />} />
    </Routes>
  );
};

AuthorizedRoutes.propTypes = {};

export default AuthorizedRoutes;

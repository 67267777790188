
import logo from '../../assets/logo-1.svg'
import { FormattedMessage } from 'react-intl';

const PageFooter =(props)=>{

    return (
        <div className="footer_patient">
            <div className="container">
              <div className="row justify_between mx_0">
                <div className="footer_logo">
                  <img
                    className="logo" style={{width:"120px"}}
                    src={logo}
                    alt=""
                  />
                </div>
                <div className="">
                  <a className="link link_white" href="/about-us">
                    
                    <FormattedMessage id="About Us" /> 
                  </a>
                  <a className="link link_white" href="/terms-of-service">
                    
                    <FormattedMessage id="Terms of Service" />
                  </a>
                  <a className="link link_white" href="/privacy-policy">
                    
                    <FormattedMessage id="Privacy Policy" />
                  </a>
                  <a className="link link_white" href="/terms-conditions">
                    <FormattedMessage id="Terms Conditions" />
                  </a>
                </div>
                <div className="">
                  {/* <a className="link link_white" href="/advertiser-disclosure">
                    Advertisement Disclosure
                  </a> */}
                  <a className="link link_white" href="/help-center">
                    <FormattedMessage id="Help Center" />
                  </a>
                  <a className="link link_white" href="/cookies-settings">
                    
                    <FormattedMessage id="Cookies Settings" />
                  </a>
                </div>
              </div>
            </div>
          </div>
    )
}

export default PageFooter;
import React, { useContext, useReducer } from "react";
import PropTypes from "prop-types";
import getCommonContextInitialValue from "./common-context-initial-value";
import commonContextReducer from "./common-context-reducer";

const CommonContext = React.createContext(null);

const CommonContextProvider = ({ children = <></> }) => {
  const initialValue = getCommonContextInitialValue();
  const [state, dispatch] = useReducer(
    (state, action) => commonContextReducer(state, action),
    initialValue
  );

  return (
    <CommonContext.Provider value={[state, dispatch]}>
      {children}
    </CommonContext.Provider>
  );
};

CommonContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const useCommonContext = () => {
  const [state, dispatch] = useContext(CommonContext);

  return [state, dispatch];
};

export { CommonContextProvider, useCommonContext };

// LoanOptions.jsx
import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faBalanceScale,
  faDollar,
  faDollarSign,
  faExternalLink,
  faExternalLinkSquare,
  faLightbulb,
  faPercent,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Row, Table } from "react-bootstrap";
import {
  commaSeperatedCurrency,
  commaSeperatedDecimal,
} from "../../infrastructure/helpers";
import { usePrevious } from "../../hooks";
import _ from "lodash";
import SortSign from "../../components/sort-sign";
import ShowMoreLess from "../../components/show-more-less";
import StickyHeader from "../../components/sticky-header";
import { FormattedMessage } from "react-intl";

const handleSort = (arr, direction, column) => {
  if (direction === "asc") {
    return _.orderBy(arr, column, "asc");
  } else {
    return _.orderBy(arr, column, "desc");
  }
};
const LoanOptions = ({ prequalicationCollection = [] }) => {
  const [collection, setCollection] = useState(prequalicationCollection);
  const [sort, setSort] = useState({
    direction: "asc",
    column: "score",
  });
  const previousSort = usePrevious(sort);
  const previousCollection = usePrevious(prequalicationCollection);
  const [viewMore, setViewMore] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const switchSort = useCallback(
    (column, direction) => {
      if (column === sort.column) {
        setSort({
          ...sort,
          direction: direction || (sort.direction === "asc" ? "desc" : "asc"),
        });
      } else {
        setSort({
          ...sort,
          column,
          direction:
            direction ||
            (column === "minMonthlyPayment" || column === "minInterestRate"
              ? "asc"
              : "desc"),
        });
      }
    },
    [sort]
  );

  useEffect(() => {
    if (
      _.isEqual(previousSort, sort) &&
      _.isEqual(previousCollection, prequalicationCollection)
    ) {
      return;
    }
    const arr =
      handleSort(prequalicationCollection, sort.direction, sort.column) || [];
    setCollection([...arr]);
  }, [sort, prequalicationCollection]);
  return (
    <div className={`${styles["loan-option-container"]} py-4`}>
      <StickyHeader
        initalOffset={0}
        // onStickyChange={(v) => setIsSticky(v)}
        containerStickyClass={styles["sticky-header-container"]}
      >
        <div
          className={`row justify-content-center ${
            styles["filter-container"]
          } ${isSticky ? styles["sticky"] : ""}`}
        >
          <div className={styles.filters}>
            <div className={styles.inner}>
              <button
                className={`btn btn-success ${styles.btnFilter} ${
                  sort.column === "score" && styles.active
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  e.currentTarget.blur();
                  switchSort("score", "desc");
                }}
              >
                <FontAwesomeIcon icon={faLightbulb} />{" "}
                <FormattedMessage id='Recommended' />
                {/* <SortSign
                  refColumnName='score'
                  currentColumnName={sort.column}
                  className={styles["sort-icon"]}
                  direction={sort.direction}
                /> */}
              </button>
              <button
                className={`btn btn-success ${styles.btnFilter} ${
                  sort.column === "minMonthlyPayment" && styles.active
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  e.currentTarget.blur();
                  switchSort("minMonthlyPayment", "asc");
                }}
              >
                <FontAwesomeIcon icon={faDollarSign} />{" "}
                <FormattedMessage id='MinimumMonthlyPayment' />
                {/* <SortSign
                  refColumnName='minMonthlyPayment'
                  currentColumnName={sort.column}
                  className={styles["sort-icon"]}
                  direction={sort.direction}
                /> */}
              </button>
              <button
                className={`btn btn-outline-success ${styles.btnFilter} ${
                  sort.column === "minInterestRate" && styles.active
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  e.currentTarget.blur();
                  switchSort("minInterestRate", "asc");
                }}
              >
                <FontAwesomeIcon icon={faPercent} />{" "}
                <FormattedMessage id='BestInterestRate' />
                {/* <SortSign
                  refColumnName='minInterestRate'
                  currentColumnName={sort.column}
                  className={styles["sort-icon"]}
                  direction={sort.direction}
                /> */}
              </button>
              <button
                className={`btn btn-outline-success ${styles.btnFilter} ${
                  sort.column === "maxFinancingAmount" && styles.active
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  e.currentTarget.blur();
                  switchSort("maxFinancingAmount", "desc");
                }}
              >
                <FontAwesomeIcon icon={faBalanceScale} />{" "}
                <FormattedMessage id='HighestAmount' />
                {/* <SortSign
                  refColumnName='maxFinancingAmount'
                  currentColumnName={sort.column}
                  className={styles["sort-icon"]}
                  direction={sort.direction}
                /> */}
              </button>
            </div>
          </div>
        </div>
      </StickyHeader>
      <div className='row justify-content-center mt-3'>
        <div className='col-12 col-md-8 col-lg-6'>
          {(collection || []).map((item, index) => (
            <div key={index} className={`card ${styles.card}`}>
              <div className='card-body'>
                <div
                  className={`text-center ${styles["company-title"]} mb-3 pb-3`}
                >
                  <div
                    // href={item.link}
                    // target='_blank'
                    className={styles["logo-link"]}
                  >
                    <img
                      src={item.logoUrl}
                      alt={`${item.lenderName} Logo`}
                      className='img-fluid mx-auto d-block'
                      style={{ width: "150px" }}
                    />
                    <h5 className='text-center'>
                      <span>{item.lenderName}</span>
                      {/* <FontAwesomeIcon icon={faExternalLink} /> */}
                    </h5>
                  </div>
                </div>
                <div className={`${styles.info} mb-2`}>
                  <span className={styles.infoLabel}>
                    <FormattedMessage id='Financing type' />:
                  </span>
                  <span className={styles.infoValue}>
                    {!!item.loanType && (
                      <b>
                        <FormattedMessage
                          id={`LoanType_${item.loanType.toUpperCase()}`}
                        />
                      </b>
                    )}
                  </span>
                </div>
                <div className={`${styles.info} mb-2`}>
                  <span className={styles.infoLabel}>
                    <FormattedMessage id='Financing amount' />:
                  </span>
                  <span className={styles.infoValue}>
                    {(typeof item.maxFinancingAmount === "number" && (
                      <>
                        <b>
                          {commaSeperatedCurrency(item.maxFinancingAmount, 0)}
                        </b>
                      </>
                    )) || <>–</>}
                  </span>
                </div>
                {["loan", "InstallmentPlan".toLowerCase()].indexOf(
                  (item.loanType || "_random_").toLowerCase()
                ) > -1 && (
                  <div className={`${styles.info} mb-2`}>
                    <span className={styles.infoLabel}>
                      <FormattedMessage id='Number of installments' />:
                    </span>
                    <span className={styles.infoValue}>
                      <b>
                        {item.numberOfInstallment || item.initialPeriodMonths}
                      </b>
                    </span>
                  </div>
                )}
                <div className={`${styles.info} mb-2`}>
                  <span className={styles.infoLabel}>
                    <FormattedMessage id='Interest rate' />:
                  </span>
                  <span className={styles.infoValue}>
                    {(typeof item.minInterestRate === "number" && (
                      <>
                        <b>
                          {commaSeperatedDecimal(
                            item.minInterestRate * 100,
                            item.minInterestRate > 0 ? 1 : 0
                          )}
                          %
                        </b>
                      </>
                    )) || <>–</>}
                  </span>
                </div>
                <div className={`${styles.info} mb-4`}>
                  <span className={styles.infoLabel}>
                    <FormattedMessage id='Monthly payment' />:
                  </span>
                  <span className={styles.infoValue}>
                    {(typeof item.minMonthlyPayment === "number" && (
                      <>
                        <b>
                          {commaSeperatedCurrency(item.minMonthlyPayment, 0)}
                        </b>
                      </>
                    )) || <>–</>}
                  </span>
                </div>
                <div className='d-grid mt-2'>
                  <button
                    className={`btn btn-success ${styles["apply-button"]}`}
                    onClick={() => {
                      document.location.href = item.redirectLink;
                      return;
                    }}
                  >
                    <FormattedMessage id='ApplyNow' />
                  </button>
                </div>
                {Array.isArray(item.loanOptionCollection) &&
                  item.loanOptionCollection.length > 1 && (
                    <>
                      <div
                        className={`mt-1 ${styles["view-more-details-container"]}`}
                      >
                        <ShowMoreLess
                          getLabel={(e) =>
                            e
                              ? "Show Less"
                              : `Show More (${item.loanOptionCollection.length})`
                          }
                          initialShowMore={false}
                          collapsedHeight={75}
                          isActive
                        >
                          <Table className={styles["view-more-table"]}>
                            <thead>
                              <tr>
                                <td>Type</td>
                                <td>Amount</td>
                                <td>Interest Rate</td>
                                <td>Monthly Payment</td>
                                <td></td>
                              </tr>
                            </thead>
                            <tbody>
                              {item.loanOptionCollection.map((i, idx2) => (
                                <tr key={`${index}-${idx2}`}>
                                  <td>
                                    {!!i.loanType && (
                                      <FormattedMessage
                                        id={`LoanType_${i.loanType.toUpperCase()}`}
                                      />
                                    )}
                                  </td>
                                  <td>
                                    {(typeof item.maxFinancingAmount ===
                                      "number" && (
                                      <div>
                                        {commaSeperatedCurrency(
                                          item.maxFinancingAmount,
                                          0
                                        )}
                                      </div>
                                    )) ||
                                      "–"}
                                  </td>
                                  <td className='t_center'>
                                    {(typeof i.interestRate === "number" && (
                                      <div>
                                        {commaSeperatedDecimal(
                                          i.interestRate * 100,
                                          1
                                        )}
                                        %
                                      </div>
                                    )) ||
                                      "–"}
                                  </td>
                                  <td className='t_center'>
                                    {(typeof i.monthlyPayment === "number" && (
                                      <div>
                                        {commaSeperatedCurrency(
                                          i.monthlyPayment,
                                          0
                                        )}
                                      </div>
                                    )) ||
                                      "–"}
                                  </td>
                                  <td className='pb-0 pt-2'>
                                    <Button
                                      size='sm'
                                      className={`btn btn-success ${styles["apply-button"]} ${styles["small"]} ${styles["outline"]}`}
                                    >
                                      Apply
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </ShowMoreLess>
                      </div>
                    </>
                  )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoanOptions;

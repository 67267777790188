import React, { useState } from "react";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { sendGet, sendPost, sendPostByJson } from "../../utils/httpUtils.js";
import ApplicationsForm from "../../components/applicationsForm/applicationsForm.js";
import ApplicationsFormUpdate from "../../components/applicationsForm/applicationsFormUpdate.js";
import LocationForm from "../../components/locationForm/locationForm.js";
import UserManagementForm from "../../components/userManagementForm/userManagementForm.js";
import UserManagementFormUpdata from "../../components/userManagementForm/userManagementFormUpdata.js";

const NewForm = (props) => {
  const navigate = useNavigate();
  // const [step,setStep] =useState();
  const [searchParams] = useSearchParams();
  const searchValue = useLocation();
  let userData = {};
  let locationCollection = {};
  let applicantsData = {};
  if (searchValue.state && searchValue.state != null) {
    userData = searchValue.state.userData;
    locationCollection = searchValue.state.item;
    applicantsData = searchValue.state.applicantsData;
  }
  const type = parseInt(searchParams.get("typeId"));

  const renderForm = (arg) => {
    switch (arg) {
      case 1:
        return <ApplicationsForm />;
      case 5:
        return <ApplicationsFormUpdate applicantsData={applicantsData} />;
      case 2:
        return <LocationForm location={locationCollection} />;

      case 3:
        return <UserManagementForm />;
      case 4:
        return (
          <UserManagementFormUpdata
            user={userData}
            locationCollection={locationCollection}
          />
        );
      default:
        return null;
    }
  };
  return <>{renderForm(type)}</>;
};

NewForm.propTypes = {};

export default NewForm;

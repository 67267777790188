import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DetailHeader, BillingDetails } from "../../components/BillingModule/index.js"

const BillingDetail = (props) => {
  const navigate = useNavigate();
  const searchValue = useLocation();
  

  if (searchValue != null) {
    //fifter param
    //Refresh load list
  }

  return (
    <>
      <div className="layout">
        <DetailHeader />
        <div className="main">
          <div className="container">
            <BillingDetails />

          </div>
        </div>
      </div>
    </>


  );
};

BillingDetail.propTypes = {};

export default BillingDetail;

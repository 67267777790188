import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Header, BillingData } from "../../components/BillingModule/index.js";
import TitleBar from "../../components/header/titleBar.js";
import { useCommonContext } from "../../common-context/common-context-provider.js";
import Footer from '../../components/Footer/footer.js';

const Billing = (props) => {
  const navigate = useNavigate();
  const searchValue = useLocation();
  const [{ user }] = useCommonContext();

  if (searchValue != null) {
    //fifter param
    //Refresh load list
  }

  return (
    <>
      <div className='layout'>
        {/* <Header /> */}
        <TitleBar user={user} title={"Billing"} />
        {/* <div className='main'>
          <div className='container'> */}
            <BillingData />
          {/* </div>
        </div> */}
        <Footer />

      </div>
    </>
  );
};

Billing.propTypes = {};

export default Billing;

import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { CreateUserForm } from "../../components/AccountInfoUpdateModule";
const AccountInfoUpdate = (props) => {
    
    return (<>
        <ToastContainer limit={1} />
        <div className="layout">
            <div className="container" >
               
                <CreateUserForm/>


            </div>
        </div>

        

    </>);
};

AccountInfoUpdate.propTypes = {};

export default AccountInfoUpdate;

import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// import { checkIsEmail, toastInfo, toastError, } from "../../utils/check.js";
import { sendGet, sendPost, sendPostByFormData, sendPostByJson } from "../../utils/httpUtils.js";
import TitleBreadcrumbs from "../../components/TitleBreadcrumbs";

import filter_Img from '../../assets/filterImg.png'
import search_Img from '../../assets/search.png'
// import { getUserInfo } from "../../utils/storage.js";

export function LocationSearch({ locationList,updateLocationList }) {
    const navigate = useNavigate();
    const openFifter = () => {
        setShowFilter(true)
    };
    const closeFifter = () => {
        setShowFilter(false)
    }
    const addFifter = () => {
        setShowFilter(true)
    }
    const [showFilter, setShowFilter] = useState(false)
    const [queryParams, setQueryParams] = useState({
        keyWord: "",
    })

    const getLocations = async () => {
        const url = "/Account/User"
        // const data={}
        try {
            const res = await sendGet(url)
            if (res.status == 200) {
                updateLocationList(res.data.data.membershipUser.hgSiteCollection)
            }
        } catch (error) {

        }
    }

    return (
        <>
            <div className="filter_box mt_2">
            <TitleBreadcrumbs menu={'Locations'} />
                {/* <button className="btn btn_filter btn_md" onClick={openFifter}>
                    <img
                        src={filter_Img}
                        alt=""
                    />
                    <span className="d_none d_inline_block_sm">Filters</span>
                </button> */}
                <div className="search_bar location_search_bar">
                    <input
                        className="form_control"
                        type="text"
                        value={queryParams.keyWord}
                        onChange={(e) => setQueryParams({ ...queryParams, keyWord: e.target.value.trim() })}
                        placeholder="Search locations..."
                    />
                    <button className="btn btn_icon btn_search" onClick={getLocations}>
                        <img
                            src={search_Img}
                            alt=""
                        />
                    </button>
                </div>
                {/* <button className="btn btn-primary add_new ms_auto" onClick={() => navigate('/account/new-form?typeId=2')}>
                    + New Location
                </button> */}

            </div>

            <div className={`offcanvas ${showFilter ? 'show' : ""}`}>
                <div className="offcanvas_box">
                    <div className="offcanvas_header">
                        <span className="title">Filters</span>
                        <button className="btn_close ms_auto" onClick={closeFifter} />
                    </div>
                    <div className="offcanvas_content filter_content">
                        <div className="mt_5 mt_7_sm mt_4_md">
                            <div className="filter_title">Sort by</div>
                            <div className="sort_list my_3">
                                <div className="item active">Date</div>
                                <div className="item">Sort 2</div>
                                <div className="item">Sort 3</div>
                            </div>
                        </div>
                        <div className="lh" />
                        <div className="mt_5 mt_4_md">
                            <div className="filter_title">Status</div>
                            <div className="sort_list my_3">
                                <div className="item active">All</div>
                                <div className="item">Preapproved</div>
                                <div className="item">Preapproved</div>
                                <div className="item">Preapproved</div>
                                <div className="item">Preapproved</div>
                                <div className="item">Preapproved</div>
                            </div>
                        </div>
                        <div className="filter_container">




                            <div className="mt_5 mt_4_md">
                                <div className="filter_title">Locations</div>
                                <div className="search_bar mt_3">
                                    <input
                                        className="form_control"
                                        type="text"

                                        placeholder="Search applicants..."
                                    />
                                    <button className="btn btn_icon">
                                        <img
                                            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzQ0MDhfMjUyNykiPgo8cGF0aCBkPSJNMTUuNTAwNiAxNC4wMDA2SDE0LjcxMDZMMTQuNDMwNiAxMy43MzA2QzE1LjYzMDYgMTIuMzMwNiAxNi4yNTA2IDEwLjQyMDYgMTUuOTEwNiA4LjM5MDYzQzE1LjQ0MDYgNS42MTA2MyAxMy4xMjA2IDMuMzkwNjMgMTAuMzIwNiAzLjA1MDYzQzYuMDkwNjMgMi41MzA2MyAyLjUzMDYzIDYuMDkwNjMgMy4wNTA2MyAxMC4zMjA2QzMuMzkwNjMgMTMuMTIwNiA1LjYxMDYzIDE1LjQ0MDYgOC4zOTA2MyAxNS45MTA2QzEwLjQyMDYgMTYuMjUwNiAxMi4zMzA2IDE1LjYzMDYgMTMuNzMwNiAxNC40MzA2TDE0LjAwMDYgMTQuNzEwNlYxNS41MDA2TDE4LjI1MDYgMTkuNzUwNkMxOC42NjA2IDIwLjE2MDYgMTkuMzMwNiAyMC4xNjA2IDE5Ljc0MDYgMTkuNzUwNkMyMC4xNTA2IDE5LjM0MDYgMjAuMTUwNiAxOC42NzA2IDE5Ljc0MDYgMTguMjYwNkwxNS41MDA2IDE0LjAwMDZaTTkuNTAwNjMgMTQuMDAwNkM3LjAxMDYzIDE0LjAwMDYgNS4wMDA2MyAxMS45OTA2IDUuMDAwNjMgOS41MDA2M0M1LjAwMDYzIDcuMDEwNjMgNy4wMTA2MyA1LjAwMDYzIDkuNTAwNjMgNS4wMDA2M0MxMS45OTA2IDUuMDAwNjMgMTQuMDAwNiA3LjAxMDYzIDE0LjAwMDYgOS41MDA2M0MxNC4wMDA2IDExLjk5MDYgMTEuOTkwNiAxNC4wMDA2IDkuNTAwNjMgMTQuMDAwNloiIGZpbGw9IiM4RThFOEUiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF80NDA4XzI1MjciPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg=="
                                            alt=""
                                        />
                                    </button>
                                </div>
                                <ul className="">
                                    <li className="my_3 my_5_sm d_flex">
                                        <input className="form_checkbox" type="checkbox" />
                                        <label className="checkbox_label mt_1 ms_2">
                                            <div className="color_primary700">
                                                <span className="fw_medium">legal name</span> (owner name)
                                            </div>
                                            <div className="mt_2 fs_sm">
                                                4000 La Rica Ave Suite D Baldwin Park, CA 91706
                                            </div>
                                        </label>
                                    </li>
                                    <li className="my_3 my_5_sm d_flex">
                                        <input className="form_checkbox" type="checkbox" />
                                        <label className="checkbox_label mt_1 fs_sm ms_2">
                                            <div className="color_primary700">
                                                <span className="fw_medium">legal name</span> (owner name)
                                            </div>
                                            <div className="mt_2 fs_sm">
                                                4000 La Rica Ave Suite D Baldwin Park, CA 91706
                                            </div>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="offcanvas_footer filter_footer">
                        <div className="btn_group">
                            <button className="btn btn-primary_line_transparent" onClick={closeFifter}>Cancel</button>
                            <button className="btn btn-primary ms_auto col" onClick={addFifter} >Apply</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export function LocationList({ locationList,updateLocationList,upStep }) {
    const navigate = useNavigate();

    const openPopup = (arg) => {
        setShowPop(true)
        //set
    }
    const [showPop, setShowPop] = useState(false)
    const getDetail = (item) => {
        // upStep("locationDetail")
        // sessionStorage.setItem("locationDetail",JSON.stringify(item))
        navigate(`/account/location-detail`,{ state: { item } })
        
    }
    const editLocation = (item) => {
        navigate(`/account/new-form?typeId=2`, { state: { item } })
    }
    return (
        <>
            <ul className="row mt_5">
                {
                    locationList.map((v, i) => {
                        return (
                            <li className="col col_2_sm" key={i}>
                                <div className="card h_100">
                                    <div className="card_title">
                                        <div className="text">
                                            <span>{v.nickname || ''}</span>
                                            
                                            <span className={v.isActive?"color_green  suffix":"color_orange suffix"}> ({v.isActive? 'Active' : 'Inactive'})</span>
                                        </div>
                                        <button className="btn edit ms_auto" onClick={() => editLocation(v)} />
                                        {/* <button className="btn delete" onClick={() => openPopup(v)} /> */}
                                    </div>
                                    <div className="card_content my_2 my_4_md">
                                        <span>{v.ownerFirstName || ''}&nbsp;{v.ownerLastName || ''}&nbsp;</span>
                                        <div className="d_flex mt_3 mt_4_md">
                                            <span>{v.city || ''} , {v.state || ''}</span>
                                            <span className="ms_auto">{v.zipCode || ''}</span>
                                        </div>
                                        <div className="mt_3 mt_4_md">
                                            <i className="icon i_location" style={{width: '20px',height:"19px"}}/>
                                            <span className="t_middle">
                                                {v.address1 || ''}
                                            </span>
                                        </div>
                                        <div className="d_flex mt_3 mt_4_md">
                                            <a className="link_primary ms_auto" onClick={() => getDetail(v)} href="">More details</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )
                    })
                }


            </ul>
        </>
    )
}
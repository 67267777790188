import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import SelectAsyncControl from "./select-async-control";
import axios from "axios";

const url = "/api/Lender/GetLenderHgsPaginated";

const LenderHGsSelectControl = (props) => {
  const { onChange, menuPlacement, value, reloadResults = 0 } = props;
  const [innerValue, setInnerValue] = useState(value);
  const selectRef = useRef(null);

  const records = 10;

  const forceRefreshOptions = () => {
    if (selectRef.current) {
      selectRef.current.select.cacheOptions = {};
      selectRef.current.loadOptions("", (options) => {
        selectRef.current.select.setState({ options });
      });
    }
  };
  const loadOptions = async (query, loadedOptions, { page }) => {
    const response = await axios.get(url, {
      params: {
        start: page < 0 ? 0 : (page - 1) * records,
        records,
        query,
      },
    });
    const json = await response;
    return {
      options: (json.data?.data?.hgCollection || []).map((i) => ({
        ...i,
      })),
      hasMore:
        (json.data?.data?.totalCount || 0) >
        (json.data?.data?.hgCollection || []).length,
      additional: {
        page: page + 1,
      },
    };
  };

  const innerChange = (o) => {
    setInnerValue(o);
    onChange(o);
  };
  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  useEffect(() => {
    if (reloadResults > 0) {
      forceRefreshOptions();
    }
  }, []);
  return (
    <SelectAsyncControl
      ref={selectRef}
      loadOptions={loadOptions}
      onChange={innerChange}
      getOptionLabel={(o) => (!o ? "–" : `${o.name}`)}
      value={innerValue}
      getOptionValue={(o) => (!o ? null : o.id)}
      menuPlacement={menuPlacement}
      placeholder={"Healthcare Groups"}
    />
  );
};
LenderHGsSelectControl.defaultProps = {
  onChange: () => {},
  menuPlacement: "auto",
  value: null,
};
LenderHGsSelectControl.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  onChange: PropTypes.func,
  menuPlacement: PropTypes.string,
  reloadResults: PropTypes.number,
};

export default LenderHGsSelectControl;

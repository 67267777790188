import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TitleBar from "../../components/header/titleBar.js";
import PageFooter from "../../components/pageFooter";
import LenderItem from "../../components/lenderItem";
import { checkIsEmail } from "../../utils/check.js";
import tip from "../../assets/tip.png";

import { sendGetKeyValue, sendPostByJson } from "../../utils/httpUtils.js";

import { setUserInfo } from "../../utils/storage.js";
import Loading from "../../components/Loading/loading.js";

import {
  Header,
  Advertisement,
  PatientError,
  PatientOk,
} from "../../components/PatientHomeModule/index.js";

const PatientHome = (props) => {
  const [loading, setLoading] = useState(true);
  const searchValue = useLocation();
  

  const [dataList, setDataList] = useState([]);
  const [patient, setPatient] = useState();
  const [imgList, setImgList] = useState([]);
  useEffect(() => {
    if (searchValue.state && searchValue.state != null) {
      var datas = searchValue.state.datas;

      getLenders(datas);
    }
  }, [searchValue]);
  const getLenders = async (res) => {
    setPatient(res);
    if (
      res &&
      res.consumerApplication &&
      res.consumerApplication.prequalificationCollection
    ) {
      // let data = res.consumerApplication.prequalificationCollection
      // let combined = [...data, ...(data.reduce((acc, item) => {
      //   if (item.loanOptionCollection) {
      //     acc.push(...item.loanOptionCollection.map(child => ({
      //       interestRate: child.interestRate,
      //       lenderLink: child.link,
      //       lenderName: child.lenderProductName,
      //       approvedAmount: item.approvedAmount,
      //       productType: child.productType,
      //       monthlyPayment: child.monthlyPayment,
      //       loanType: child.loanType,
      //       lenderId: item.lenderId,
      //       logo: ""
      //     })));
      //   }
      //   return acc;
      // }, []))];
      let data = res.consumerApplication.prequalificationCollection;
      let combined = [];

      data.forEach((item) => {
        if (item.loanOptionCollection) {
          item.loanOptionCollection.forEach((child) => {
            combined.push({
              interestRate: child.interestRate,
              lenderLink: child.link,
              lenderName: child.lenderProductName,
              approvedAmount: item.approvedAmount,
              productType: child.productType,
              monthlyPayment: child.monthlyPayment,
              loanType: child.loanType,
              lenderId: child.lenderId,
              logo: "",
              bankName: item.lenderName,
            });
          });
        }
      });

      
      let qustData = filterQusetData(combined);
      await getImgUrl(qustData, combined);
    } else {
      setLoading(false);
    }
  };
  const filterQusetData = (data) => {
    let result = [...new Set(data.map((v) => v.lenderId))].map((v) => {
      return {
        organizationId: v,
        organizationType: v.toString()[0] == "1" ? "Lender" : "HealthcareGroup",
      };
    });
    return result;
  };

  const getImgUrl = async (data, combined) => {
    let param = {
      requestCollection: data,
    };
    try {
      const res = await sendPostByJson("Organization/Logos", param);
      if (res.status == 200) {
        let imgList = res.data.data.logoCollection;
        combined.forEach((v) => {
          let item = imgList.find((i) => i.organizationId == v.lenderId);
          if (item) {
            v.logo = item.logoCollection[0].imageCdnUrl;
          }
        });
        
        setDataList([...combined]);
      }
    } catch (error) {
      
    } finally {
      setLoading(false);
    }
  };

  const renderApplyResult = (arg) => {
    // if (arg) {
    //   setApplyOk(true)
    //   setShowls(false)
    // } else {
    //   setApplyOk(false)
    //   setShowls(true)
    //   setShowTip(true)
    // }
  };

  return (
    <>
      <div className='layout'>
        <Header patient={patient} />

        <div className='main main_patient'>
          <div className='main_patient_box'>
            <Advertisement patient={patient} />
            <div className='container'>
              <Loading loading={loading} />
              {dataList.length > 0 ? (
                <PatientOk dataList={dataList} />
              ) : (
                <>{!loading && <PatientError />}</>
              )}

              <LenderItem showApply={renderApplyResult} dataList={dataList} />
            </div>
          </div>
          <PageFooter />
        </div>
      </div>
    </>
  );
};

PatientHome.propTypes = {};

export default PatientHome;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef ,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { commonContextActions, useCommonContext } from '../../common-context'
import { ToastContainer } from 'react-toastify';
import { checkIsEmail, toastInfo, toastError, } from "../../utils/check.js";
import { sendGet, sendPost, sendPostByJson } from "../../utils/httpUtils.js";
import { setItem, setUserInfo, setToken } from "../../utils/storage.js"
import logo from "../../assets/logo-1.svg"

export  function ReturnButton() {
    const navigate = useNavigate();


    return (
        <>

            <button className="btn login_return" onClick={() => navigate("/account/login")}>
                <img
                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMwLjk3NjIgOC44NjExNEMzMS42MDUyIDkuNDAwMjggMzEuNjc4IDEwLjM0NzIgMzEuMTM4OSAxMC45NzYyTDE5Ljk3NTYgMjRMMzEuMTM4OSAzNy4wMjM4QzMxLjY3OCAzNy42NTI4IDMxLjYwNTIgMzguNTk5OCAzMC45NzYyIDM5LjEzODlDMzAuMzQ3MiAzOS42NzggMjkuNDAwMiAzOS42MDUyIDI4Ljg2MTEgMzguOTc2MkwxNi44NjExIDI0Ljk3NjJDMTYuMzc5NiAyNC40MTQ1IDE2LjM3OTYgMjMuNTg1NiAxNi44NjExIDIzLjAyMzhMMjguODYxMSA5LjAyMzg0QzI5LjQwMDIgOC4zOTQ4NSAzMC4zNDcyIDguMzIyMDEgMzAuOTc2MiA4Ljg2MTE0WiIgZmlsbD0iIzMzMzMzMyIvPgo8L3N2Zz4K"
                    alt=""
                />
            </button>
        </>
    );
}

export function ForgotPasswordSubject({upStep}) {
    const navigate = useNavigate();
    const toastId = useRef(null);
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)
    const signHandle = () => {

        if (!email || (email && !checkIsEmail(email))) {
            setEmailError(true)
        } else {
            setEmailError(false)
            loginHandle()
        }

    }
    const loginHandle = async () => {
        try {

            const res = await sendPostByJson("Account/RecoveryInit", { contactText: email })
            
            if (res.status === 200) {
                // toastSuccess("The verification email has been sent, please check your email !",toastId)
                localStorage.setItem("email", email)
                setTimeout(() => {
                    // navigate("/account/recovery/change")
                    upStep("forgotPwd2")
                }, 500);

            } else {
                toastError("No email found or wrong email", toastId)
            }
        } catch (error) {
            
            toastError("Email sending error, please try again later ! ", toastId)
        }
    }

    return (
        <>
            <div className="logo mb_4">
                <img style={{ width: "128px", height: "30px" }}
                    src={logo}
                    alt=""
                />
            </div>
            <h5 className="t_center mb_8">Forget Password</h5>
            <form className="form mb_5">
                <div className="mb_6">
                    <label className="form_label" htmlFor="">
                        Email
                    </label>
                    <input
                        className="form_control"
                        type="text"
                        placeholder="Enter your Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="color_error mt_2" style={{ visibility: emailError ? 'visible' : "hidden" }}> Please enter your correct email address! </div>
                </div>

            </form>
            <button className="btn btn-primary w_100 mt_3" onClick={signHandle}>Countinue</button>

        </>
    );
}


export function ForgotPassword2Subject({upStep}){
    const navigate = useNavigate();
    const [email, setEmail] = useState("")
    useEffect(() => {
      let email = localStorage.getItem("email")
      if (email) {
        setEmail(email)
        localStorage.removeItem('email')
      }
    }, [])
    return (
        <>
        <div className="container">
        {/* <button className="btn login_return" onClick={() => navigate("/account/recovery")}> */}
        <button className="btn login_return" onClick={() => upStep("forgotPwd")}>

          <img
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMwLjk3NjIgOC44NjExNEMzMS42MDUyIDkuNDAwMjggMzEuNjc4IDEwLjM0NzIgMzEuMTM4OSAxMC45NzYyTDE5Ljk3NTYgMjRMMzEuMTM4OSAzNy4wMjM4QzMxLjY3OCAzNy42NTI4IDMxLjYwNTIgMzguNTk5OCAzMC45NzYyIDM5LjEzODlDMzAuMzQ3MiAzOS42NzggMjkuNDAwMiAzOS42MDUyIDI4Ljg2MTEgMzguOTc2MkwxNi44NjExIDI0Ljk3NjJDMTYuMzc5NiAyNC40MTQ1IDE2LjM3OTYgMjMuNTg1NiAxNi44NjExIDIzLjAyMzhMMjguODYxMSA5LjAyMzg0QzI5LjQwMDIgOC4zOTQ4NSAzMC4zNDcyIDguMzIyMDEgMzAuOTc2MiA4Ljg2MTE0WiIgZmlsbD0iIzMzMzMzMyIvPgo8L3N2Zz4K"
            alt=""
          />
        </button>
        <div className="login_container">
          <div className="logo mb_4">
            <img style={{ width: "128px", height: "30px" }}
              src={logo}
              alt=""
            />
          </div>
          <h5 className="t_center mb_8">Forgot Password</h5>
          <div className="w_100" style={{ display: "none" }}>
            <form className="form mb_6">
              <label className="form_label" htmlFor="">
                Email
              </label>
              <input
                className="form_control"
                type="text"
                placeholder="Enter your Email"
              />
            </form>
            <button className="btn btn-primary w_100">Countinue</button>
          </div>
        </div>
        <div className="login_check_email">
          <h5 className="fw_regular mb_6">
            Please open the link that we sent to <span>{email}</span>
          </h5>
          <button className="btn btn-primary_line" onClick={() => navigate("/account/login")}>Back</button>
        </div>
      </div>
        </>
    )
}
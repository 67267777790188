import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import SelectAsyncControl from "./select-async-control";
import axios from "axios";
import qs from "qs";

const url = "/api/Lender/GetLenderHgSitesPaginated";

const LenderHGSiteSelectControl = ({
  onChange,
  menuPlacement,
  value,
  hgIdCollection = [],
  excludedHgIdCollection = [],
  hgSiteIdCollection = [],
  excludedHgSiteIdCollection = [],
  reloadResults = "",
}) => {
  // const [innerValue, setInnerValue] = useState(value);
  const hasMoreRef = useRef(false);
  const queryRef = useRef(null);
  const selectRef = useRef(null);

  const records = 10;
  const loadOptions = async (query, loadedOptions, { page }) => {
    if (!hasMoreRef.current) {
      return [];
    }
    const params = {
      start: page < 0 ? 0 : (page - 1) * records,
      records,
      query,
    };
    if (hgIdCollection && hgIdCollection.length > 0) {
      params.hgIdCollection = hgIdCollection;
    }
    if (excludedHgIdCollection && excludedHgIdCollection.length > 0) {
      params.excludedHgIdCollection = excludedHgIdCollection;
    }
    if (hgSiteIdCollection && hgSiteIdCollection.length > 0) {
      params.hgSiteIdCollection = hgSiteIdCollection;
    }
    if (excludedHgSiteIdCollection && excludedHgSiteIdCollection.length > 0) {
      params.excludedHgSiteIdCollection = excludedHgSiteIdCollection;
    }
    const response = await axios.get(url, {
      params: params,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    });
    const json = await response;
    hasMoreRef.current =
      (json.data?.data?.totalCount || 0) >
      (json.data?.data?.hg_SiteCollection || []).length;
    return {
      options: (json.data?.data?.hg_SiteCollection || []).map((i) => ({
        ...i,
      })),
      hasMore:
        (json.data?.data?.totalCount || 0) >
        (json.data?.data?.hg_SiteCollection || []).length,
      additional: {
        page: page + 1,
      },
    };
  };
  const forceRefreshOptions = () => {
    if (selectRef.current) {
      selectRef.current.select.cacheOptions = {};
      selectRef.current.loadOptions("", (options) => {
        selectRef.current.select.setState({ options });
      });
    }
  };
  const innerChange = (o) => {
    // setInnerValue(o);
    onChange(o);
  };

  useEffect(() => {
    hasMoreRef.current = true;
  }, [
    queryRef.current,
    hgIdCollection,
    excludedHgIdCollection,
    hgSiteIdCollection,
    excludedHgSiteIdCollection,
  ]);
  // useEffect(() => {
  //   // setInnerValue(value);
  // }, [value]);

  return (
    <SelectAsyncControl
      key={reloadResults}
      ref={selectRef}
      loadOptions={loadOptions}
      onChange={innerChange}
      getOptionLabel={(o) => (!o ? "–" : `${o.officeName}`)}
      value={value}
      getOptionValue={(o) => (!o ? null : o.id)}
      menuPlacement={menuPlacement}
      placeholder={"Healthcare Locations"}
    />
  );
};
LenderHGSiteSelectControl.defaultProps = {
  onChange: () => {},
  menuPlacement: "auto",
  value: null,
};
LenderHGSiteSelectControl.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  onChange: PropTypes.func,
  menuPlacement: PropTypes.string,
  hgIdCollection: PropTypes.arrayOf(PropTypes.number),
  excludedHgIdCollection: PropTypes.arrayOf(PropTypes.number),
  reloadResults: PropTypes.string,
};

export default LenderHGSiteSelectControl;

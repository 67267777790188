import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { commonContextActions, useCommonContext } from "./common-context";
import _ from "lodash";
import { apiLoadingStatus } from "./infrastructure/constants/api";
import { sendGetKeyValue } from "./utils/httpUtils";
import Loading from "./components/Loading/loading";
import { setItem } from "./utils/storage";

const CommonAppActions = (props) => {
  const [{ user, config }, dispatchCommonContext] = useCommonContext();
  const [userApiStatus, setUserApiStatus] = useState(apiLoadingStatus.unloaded);

  const getUserInfo = () => {
    const url = `/Account/User`;
    try {
      if (userApiStatus === apiLoadingStatus.loading) {
        return;
      }
      setUserApiStatus(apiLoadingStatus.loading);

      dispatchCommonContext({
        type: commonContextActions.updateUser,
        payload: {
          ...user,
          loadingStatus: apiLoadingStatus.loading,
        },
      });
      sendGetKeyValue(url)
        .then((res) => {
          setUserApiStatus(apiLoadingStatus.loaded);
          if (res.status == 200) {
            const tempUser = res.data.data.membershipUser;
            let group = tempUser.userType;
            let roleInOrganization = tempUser.roleInOrganization;
            let source = 0;
            if (group === "DentiRate") {
              source = 1;
            } else if (group === "HealthcareGroup") {
              if (
                roleInOrganization === "Administrator" ||
                roleInOrganization === "SuperAdministrator"
              ) {
                source = 100;
              } else {
                source = 101;
              }
            } else if (group === "Lender") {
              if (
                roleInOrganization === "Administrator" ||
                roleInOrganization === "SuperAdministrator"
              ) {
                source = 200;
              } else {
                source = 201;
              }
            } else if (group === "Regular") {
              source = 300;
            }
            setItem("source", source);
            dispatchCommonContext({
              type: commonContextActions.updateUser,
              payload: {
                data: { user: tempUser, source },
                loadingStatus: apiLoadingStatus.loaded,
              },
            });
          }
        })
        .catch((error) => {
          setUserApiStatus(apiLoadingStatus.error);
          dispatchCommonContext({
            type: commonContextActions.updateUser,
            payload: {
              data: null,
              loadingStatus: apiLoadingStatus.error,
            },
          });
        })
        .finally(() => {});
    } catch (error) {
      // let user = getUserInfo()
      // setAccount({ ...user })
      //
    }
  };

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/client_config.json");
        const data = await response.json();
        dispatchCommonContext({
          type: commonContextActions.updateConfig,
          payload: data,
        });
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };

    // fetchConfig();
  }, []);

  // this is where you load the user.
  useEffect(() => {
    if (userApiStatus === apiLoadingStatus.unloaded) {
      setUserApiStatus(apiLoadingStatus.loading);
      getUserInfo();
    }
  }, [userApiStatus]);

  const { children } = props;
  return (
    <>
      <Loading loading={user.loadingStatus === apiLoadingStatus.loading} />
      {children}
    </>
  );
};

CommonAppActions.propTypes = {
  children: PropTypes.any,
};

export default CommonAppActions;

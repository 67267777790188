import React, { useState, useRef } from "react";
import { ToastContainer } from "react-toastify";
import LoginSubject from "../../components/LoginModule";

import logbg from "../../assets/login-r-bg.png";
const Login = (props) => {
  return (
    <>
      <div className="container">
        <div className="mb_ff">
          <div className="login_container " >
            <LoginSubject />
          </div>
          <div className="img_container d_none  d_inline_block_sm">
            <img src={logbg} className="w_100 h_100" />
          </div>
        </div>
        <ToastContainer limit={1} />
      </div>
    </>
  );
};

Login.propTypes = {};

export default Login;

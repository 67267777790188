const ES = {
  language: "Idiomas:",
  Amount: "Monto",
  TreatmentType: "Tipo de tratamiento",
  preferredMonthlyPayment: "Pago mensual preferido",
  AdvertisementDisclosure: "Divulgación del anuncio",
  "there is no preapproval for your request":
    "No hay aprobación previa para su solicitud",
  "contact suggested lender for further processing":
    "Póngase en contacto con el prestamista sugerido para su posterior procesamiento",
  "You can fill your ptreferred application":
    "Puedes llenar tu solicitud preferida",
  here: "Aquí",
  "it may help you with lenders": "Puede ayudarle con los prestamistas",
  Congratulation: "Felicitaciones!",
  "We got you pre-approved with": "Te hemos preaprobado para",
  lenders: "productos financieros",
  "Andhara bank": "Banco andhra",
  Rated: "Clasificado",
  "Monthly payment": "Mensualidad",
  "Financing type": "Tipo de financiación",
  "Financing amount": "Monto de financiamiento",
  "Number of installments": "Número de plazos",
  "Interest rate": "Tasa de interés",
  Apply: "Aplicar",
  ApplyNow: "Solicitar Ahora",
  "About Us": "Sobre nosotros",
  "Terms of Service": "Términos de servicio",
  "Privacy Policy": "Política de privacidad",
  "Terms Conditions": "Términos y condiciones",
  "Help Center": "Centro de ayuda",
  "Cookies Settings": "Configuración de cookies",

  TreatmentType_GENERALDENTISTRY: "Odontología General",
  TreatmentType_ORTHODONTICS: "Ortodoncia",
  TreatmentType_OTHERSPECIALTIES: "Otras especialidades",
  Recommended: "Recomendado",
  MinimumMonthlyPayment: "Pago Mensual Mínimo",
  BestInterestRate: " Mejor Tasa de Interés",
  HighestAmount: "Monto Más Alto",

  LoanType_NONE: "Ninguno",
  LoanType_CREDITLINE: "Línea de Crédito",
  LoanType_LOAN: "Préstamo",
  LoanType_CREDITREVOLVER: "Crédito Rotativo",
  LoanType_INSTALLMENTPLAN: "Plan de Cuotas",
  LoanType_OTHER: "Otro",

  "Errors.UnknownError": "Ocurrió un error",
  "Errors.InvalidLink": "Enlace inválido",
  "Errors.InvalidApplication": "Aplicación inválida",
  "Errors.Initiated":
    "Esta aplicación aún no ha sido completada por la oficina.",
  "Errors.Submitted":
    "Esta solicitud está siendo procesada. Por favor, actualice la página más tarde.",
  "Errors.Processing":
    "Esta solicitud está siendo procesada. Por favor, actualice la página más tarde.",
  "Errors.Abandoned": "Esta aplicación ha sido abandonada.",
  "Errors.ProcessingError":
    "Hubo un error al procesar la aplicación. Por favor, solicite a la oficina que llene una nueva aplicación para usted.",
  "Errors.NotAccessible": "Esta aplicación está caducada y ya no es accesible.",
  "Errors.UnableToLoadApplication": "No se puede cargar la aplicación.",
  "Errors.ApplicantTooManyAttempts":
    "Este enlace ya no es válido debido a demasiados intentos fallidos.",
  "Errors.ApplicationExpired":
    "Esta aplicación está caducada y ya no es accesible.",
  "Errors.InvalidEntries":
    "Entradas inválidas. Por favor, revise su información e intente de nuevo.",
  "Errors.Validation.YearOfBirth": "Año de nacimiento inválido",
  "Errors.Validation.ZipCode": "Código postal inválido",
  "Errors.Validation.Consent": "Debe aceptar los términos y condiciones",

  WelcomeToDentirate: "Bienvenido a DentiRate",
  YearOfBirth: "Año de nacimiento",
  ZipCode: "Código postal",
  Submit: "Enviar",
  And: "y",

  "ContinuingAgreement.Part1": "Al continuar, acepta nuestros ",
  "ContinuingAgreement.Part2": "Términos de servicio",

  "Consent.WeNeedYourConsent": "Necesitamos su consentimiento",
  "Consent.ReadLenderPartners":
    "Por favor, lea detenidamente los Términos y Condiciones de nuestros socios prestamistas.",
  "Consent.ContinuingAgreement.Part1":
    "Al continuar, acepta todos los elementos anteriores y nuestros ",
  "Consent.ContinuingAgreement.Part2": "Términos de servicio",
  "ConsentItem.ReadAndAgreed": "He leído y aceptado",
  "ConsentItem.TermsConditions": "Términos y condiciones",
  "ConsentItem.PrivacyPolicy": "Política de privacidad",
  "ConsentItem.LenderNamePossessive": "de {lenderName}",
};
export default ES;

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { checkIsEmail, toastInfo, toastError, toastSuccess } from "../../utils/check.js";
import { sendGet, sendPost, sendPostByJson } from "../../utils/httpUtils.js";
import logo from "../../assets/logo-1.svg"

export function UpdatePassWord() {
    const toastId = useRef(null);
    const navigate = useNavigate();

    const [pwdInfo, setpwdInfo] = useState({ oldPassword: "", newPassword: "" });
    const [encryptedCode, setEncryptedCode] = useState("")
    const [error, setError] = useState({
        LengthError: false,
        UppercaseError: false,
        LowercaseError: false,
        SymbolError: false,
        NumberError: false,
    })
    const [dif, setDif] = useState(false)
    useEffect(() => {
        const { code } = getURLParams();
        if (code) {
            setEncryptedCode(code)
            
        }
    }, [])
    const getURLParams = () => {

        const searchParams = new URLSearchParams(window.location.search);
        const params = {};

        for (let param of searchParams.entries()) {
            params[param[0]] = param[1];
        }

        return params;
    };
    const changePwd = async () => {

        let vals = Object.values(error)

        let f = vals.every(v => v)
        if (pwdInfo.newPassword && pwdInfo.oldPassword && pwdInfo.newPassword != pwdInfo.oldPassword) {
            return toastError("The new password entered twice does not match", toastId)
        }
        // if(dif&&f){

        let url = "Account/RecoveryChange"
        const res = await sendPostByJson(url, { encryptedCode: encryptedCode, newPassword: pwdInfo.newPassword })
        
        if (res.status == 200) {
            toastSuccess("Successfully set new password, please log in with the new password", toastId)
            setTimeout(() => {
                navigate('/account/login')
            }, 1000);
        } else {
            toastError("Error setting new password, please try again", toastId)
        }
        // }else{
        //    toastError("The new password entered twice does not match",toastId)
        // }
    }
    const handler = (e) => {
        let old = e.target.value
        setpwdInfo({ ...pwdInfo, oldPassword: old })
        validate(old)

    }
    const handlerNew = (e) => {
        let newPwd = e.target.value
        setpwdInfo({ ...pwdInfo, newPassword: newPwd })
        validate(newPwd)
        // if(pwdInfo.newPassword&&pwdInfo.oldPassword&&pwdInfo.newPassword!=pwdInfo.oldPassword){
        //   setDif(true)
        //   }else{
        //     setDif(false)
        //   }
    }
    const validate = (pwd) => {
        if (!pwd) {
            setError({
                LengthError: false,
                UppercaseError: false,
                LowercaseError: false,
                SymbolError: false,
                NumberError: false,
            })
            return
        }
        
        if (pwd.length > 7 && pwd.length < 31) {

            //  setError({...error,LengthError:false})
            error.LengthError = true
        } else {
            error.LengthError = false

        }

        if (/[A-Z]+/g.test(pwd)) {
            //  setError({...error,UppercaseError:false})
            error.UppercaseError = true

        } else {
            //  setError({...error,UppercaseError:true})
            error.UppercaseError = false

        }



        if (/[a-z]+/.test(pwd)) {
            error.LowercaseError = true
        } else {
            error.LowercaseError = false
            // setError({...error,LowercaseError:true})
        }
        if (/[!@#$%^&*()+=\-[\]{};':"\\|,.<>/?]/g.test(pwd)) {
            // setError({...error,SymbolError:false})
            error.SymbolError = true
        } else {
            error.SymbolError = false
        }
        if (/\d/.test(pwd)) {
            error.NumberError = true
        } else {
            error.NumberError = false
        }

        setError({ ...error })
        
    }

    return (
        <>
            <h5 className="t_center mb_8">Add new password</h5>
            <form className="form">
                <div className="mb_6">
                    <label className="form_label" htmlFor="">
                        New password
                    </label>
                    <input
                        className="form_control"
                        type="password"
                        placeholder="Please enter new password"
                        value={pwdInfo.oldPassword}
                        onChange={e => handler(e)}

                    />
                </div>
                <div className="mb_4">
                    <label className="form_label" htmlFor="">
                        Re-enter new password
                    </label>
                    <input className="form_control" type="password" placeholder="Please re-enter new password"
                        value={pwdInfo.newPassword}
                        onChange={e => handlerNew(e)}
                    />
                    <div className="color_error mt_2 fs_lg mx_2" style={{ visibility: dif ? 'visible' : "hidden" }}>
                        {/* new password and the re-entered password do not match */}
                    </div>
                </div>
                <ul className="password_rule mb_6">
                    <li className={`mt_2 ${error.LengthError ? 'active' : ''}`}>Leanth: 8-30</li>
                    <li className={`mt_2 ${error.UppercaseError ? 'active' : ''}`}>Use uppercase </li>
                    <li className={`mt_2 ${error.LowercaseError ? 'active' : ''}`}>Use lowercase </li>
                    <li className={`mt_2 ${error.SymbolError ? 'active' : ''}`}>Use symbol</li>
                    <li className={`mt_2 ${error.NumberError ? 'active' : ''}`}>Use number</li>
                </ul>

            </form>
            <button className="btn btn-primary w_100"

                onClick={changePwd}
            >
                Confirm
            </button>
        </>
    )
}

export function Logo() {
    return (
        <div className="logo mb_4">
            <img style={{ width: "128px", height: "30px" }}
                src={logo}
                alt=""
            />
        </div>
    )
}
import React, { useState, useEffect } from "react";
import Footer from '../../components/Footer/footer.js';

import {
  Header,
  UserInfo,
  Logout,
  ChangePwd,
} from "../../components/userProfile";
import TitleBreadcrumbs from "../../components/TitleBreadcrumbs";
import TitleBar from "../../components/header/titleBar.js";
import { useCommonContext } from "../../common-context/common-context-provider.js";
const UserProfile = (props) => {
  const [{ user }] = useCommonContext();

  return (
    <>
      <div className='layout'>
        {/* <Header /> */}
        <TitleBar user={user} title={"Account"} />
        <div className='main'>
          <div className='container profile_container'>
            <TitleBreadcrumbs menu={"Account"} />
            <UserInfo user={user.data?.user || {}} />
            <ChangePwd />
            <Logout />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

UserProfile.propTypes = {};

export default UserProfile;

import React from "react";
import styles from "./styles.module.scss";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import { isMobile, isMobileOnly } from "react-device-detect";
const ConsentText = () => {
  return (
    <div className={styles["mobile-friendly"]}>
      <div
        className={`alert alert-primary noprint ${styles["consent-instruction"]}`}
      >
        <h4>IMPORTANT NOTE</h4>
        <span>
          You are required to acquire the applicant's consent before proceeding
          to the next step
        </span>
        <div className={styles["print-container"]}>
          <Button variant='dark' onClick={() => window.print()}>
            <FontAwesomeIcon icon={faPrint} variant={"dark"} />{" "}
            {(isMobile && <></>) || <>Print</>}
          </Button>
        </div>
      </div>
      <div style={{ paddingBottom: "68px" }}>
        <p className={`consent_title print-center`}>
          Consumer Consent Disclosure for Dentirate.com
        </p>
        <p
          className='consent_text'
          style={{ textIndent: "2em", marginBottom: "20px" }}
        >
          By providing your email address and/or mobile phone number, you
          consent to receive electronic communications from{" "}
          <b> Dentirate.com ("we", "our")</b>. Below are details regarding the
          communications you may receive and how to manage your preferences.
        </p>

        <p className='consent_title'>Types of Communications:</p>
        <ul style={{ marginLeft: "2em", marginBottom: "20px" }}>
          <li className='consent_text'>
            <b>Transactional:</b> Information about your financing offers.
          </li>
          <li className='consent_text'>
            <b>Notifications:</b> Reminders and alerts
          </li>
          <li className='consent_text'>
            <b>Marketing:</b> Promotional materials, updates, offers, and
            newsletters
          </li>
          <li className='consent_text'>
            <b>Feedback &amp; Surveys:</b> Requests for feedback and
            participation in surveys or other data-gathering exercises.
          </li>
          <li className='consent_text'>
            <b>General Updates:</b> Any other information we believe may benefit
            or interest you.
          </li>
        </ul>

        <p className='consent_title'>Consent Details:</p>
        <ul style={{ marginLeft: "2em", marginBottom: "20px" }}>
          <li className='consent_text'>
            By providing your <b>email</b>, you permit us to send emails to the
            address you've shared.
          </li>
          <li className='consent_text'>
            By sharing your <b>mobile number</b>, you agree to receive text
            messages from us.
          </li>
        </ul>

        <p className='consent_title'>Message &amp; Data Charges:</p>
        <p
          style={{ marginLeft: "2em", marginBottom: "20px" }}
          className='consent_text'
        >
          Please be aware that certain mobile carriers may charge you for
          receiving our messages. Dentirate.com is not liable for any such
          charges.
        </p>

        <p className='consent_title'>Managing Your Preferences:</p>
        <ul style={{ marginLeft: "2em", marginBottom: "20px" }}>
          <li className='consent_text'>
            <b>Emails:</b> To stop receiving emails, click the “unsubscribe”
            link found at the bottom of our emails.
          </li>
          <li className='consent_text'>
            <b>Texts:</b> Reply with "STOP" to any text message you receive from
            us to opt out.
          </li>
          <li className='consent_text'>
            Please allow up to 10 business days for changes to your preferences
            to take effect.
          </li>
        </ul>

        <p className='consent_title'>Electronic Record Access:</p>
        <p
          style={{ textIndent: "2em", marginBottom: "20px" }}
          className='consent_text'
        >
          Your agreement to this disclosure confirms that you can access this
          consent, other electronic records, and the emails we send.
        </p>

        <p
          style={{ textIndent: "2em", marginBottom: "20px" }}
          className='consent_text noprint'
        >
          For additional details, please review our <b>Terms of Use</b>
          &nbsp; and <b>Privacy Policy</b>.
        </p>

        <div className={`${styles["applicant-signature"]} printonly`}>
          <span>
            Applicant's Name (Printed): __________________________
          </span>
          <span>Applicant's Signature: __________________________</span>
          <span>Date: {moment().format("MM/DD/YYYY")}</span>
        </div>
      </div>
    </div>
  );
};

export default ConsentText;

import React, { useEffect, useState } from "react";
import { FormattedMessage } from 'react-intl';

import { commonContextActions, useCommonContext } from "../../common-context"
import {getItem,setItem} from '../../utils/storage.js'
import {  toastInfo, toastError, } from "../../utils/check.js";
import en_img from '../../assets/en.png'
import es_img from '../../assets/es.png'

const SlectLang=(props)=>{
   
    const [{ config }, dispatchCommonContext] = useCommonContext();
    const [lang,setLang]=useState(getItem('lang')||'en')
    const selectLanguage=(type)=>{
        if(lang==type){
            return false
        }
        let data={
            lang:type
        }
        setLang(type)
        dispatchCommonContext({
            type: commonContextActions.updateConfig,
            payload: data,
          });
        localStorage.setItem('lang',type)
      
    }
   useEffect(()=>{
        setLang(config.lang)
        localStorage.setItem('lang',config.lang)
   },[config])
    return(
        <>
            <div className="nav_right">
              <div className="label me_3">
                <FormattedMessage id="language"/>
                </div>
                  <div className="language">
                        <div className={`tab ${lang=="en"?'select':''} `} onClick={()=>{selectLanguage('en')}}>
                           <img src={en_img} alt=""/>
                        </div>
                        <div className={`tab ${lang=="es"?'select':''}`} onClick={()=>{selectLanguage('es')}}>
                           <img src={es_img} alt=""/>
                        </div>
                    </div>
            </div>
        </>
    )
}


export default SlectLang
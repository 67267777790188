import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  checkIsEmail,
  toastInfo,
  toastError,
  toastSuccess,
} from "../../utils/check.js";
import {
  sendGet,
  sendPost,
  sendPostByJson,
  sendGetKeyValue,
} from "../../utils/httpUtils.js";
import { getUserInfo } from "../../utils/storage.js";
import Select from "react-select";

import showPng from "../../assets/showpwd.png";
import hiedPng from "../../assets/hidepwd.png";

export function CreateUserForm() {
  const toastId = useRef(null);
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    invitationCode: "",
    organizationId: 0,
    state: "",
    city: "",
    address1: "",
    address2: "",
    zipCode: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [lsStr, setLsStr] = useState("");
  const [locationList, setLocationList] = useState([]);
  //get userinfo from url and user change to save

  // const [step,setStep] =useState();

  useEffect(() => {
    const { invitationCode, email } = getURLParams();
    if (invitationCode) {
      getUserByCode(invitationCode, email);
    }
  }, []);

  const getURLParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};

    for (let param of searchParams.entries()) {
      params[param[0]] = param[1];
    }
    return params;
  };

  //get userInfo by code
  const getUserByCode = async (code, email) => {
    const url = "/Account/InvitiationCode";
    const data = {
      encryptedCode: code,
      email: email,
    };
    setIsLoading(true);
    try {
      const res = await sendGetKeyValue(url, data);
      if (res.status == 200) {
        user.email = email;
        user.invitationCode = code;
        setUser(user);
      }
    } catch (error) {
      setIsError(true);
      // toastError(
      //   "Account information error, Please contact the administrator for processing ",
      //   toastId
      // );
    } finally {
      setIsLoading(false);
    }
  };

  const openPwd = () => {
    setShowPwd(!showPwd);
  };
  const nextStep = (e) => {
    e.preventDefault();

    if (!user.firstName) {
      toastError("Please enter firstName", toastId);
      return false;
    }
    if (!user.lastName) {
      return toastError("Please enter lastName", toastId);
    }
    if (!user.email) {
      return toastError("Please enter email", toastId);
    }

    if (user.phoneNumber.length !== 10) {
      return toastError("Please enter a 10 digit phone number", toastId);
    }
    // if(!user.password){
    //     return   toastError('Please enter password', toastId)
    // }
    let str = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,30}$/;
    if (!str.test(user.password)) {
      let errStr =
        "Please enter passwords between 8 and 30, including uppercase and lowercase letters, numbers, and special symbols";
      return toastError(errStr, toastId);
    }
    saveDate();
  };
  const closePopup = () => {
    // $(".popup").removeClass("show");
    setShowToast(false);
  };
  const openPopup = () => {
    // $(".popup").addClass("show");
    setShowToast(true);
  };
  const saveDate = async () => {
    const url = "/Account/CreateUser";
    try {
      let data = {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        password: user.password,
        state: user.state,
        city: user.city,
        address1: user.address1,
        address2: user.address2,
        zipCode: user.zipCode,
        organizationId: 0,
        invitationCode: user.invitationCode,
        createDate: new Date().toISOString(),
      };
      const res = await sendPostByJson(url, data);
      if (res.status == 201) {
        toastSuccess(
          "Account information updated successfully, please log in ",
          toastId
        );
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
      // else if(res.code==409 ){
      //     toastError("The email has already been used, please switch to another one!",toastId)
      // }else if(res.code==400){
      //     toastError("Phone number not verified!",toastId)
      // }
    } catch (error) {
      toastError(
        "Update  user information failed,please try again latter!",
        toastId
      );
    }
  };

  const filterLocation = (e) => {
    e.preventDefault();
    
  };

  const changeAct = () => {
    let f = user.isActive;
    setUser({ ...user, isActive: !f });
  };
  const goback = (arg) => {
    if (arg == 1) {
      setShowToast(false);
      navigate("/");
    } else {
      //stay page and save data
      setShowToast(false);
    }
  };
  const stateCollection = [
    { label: "Alabama", value: "AL" },
    { label: "Alaska", value: "AK" },
    { label: "Arizona", value: "AZ" },
    { label: "Arkansas", value: "AR" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "Delaware", value: "DE" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Hawaii", value: "HI" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Iowa", value: "IA" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Maine", value: "ME" },
    { label: "Maryland", value: "MD" },
    { label: "Massachusetts", value: "MA" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Mississippi", value: "MS" },
    { label: "Missouri", value: "MO" },
    { label: "Montana", value: "MT" },
    { label: "Nebraska", value: "NE" },
    { label: "Nevada", value: "NV" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "New York", value: "NY" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Vermont", value: "VT" },
    { label: "Virginia", value: "VA" },
    { label: "Washington", value: "WA" },
    { label: "West Virginia", value: "WV" },
    { label: "Wisconsin", value: "WI" },
    { label: "Wyoming", value: "WY" },
  ];

  if (isLoading) {
    return (
      <div className='login_container applicant_error_container'>
        <div className='email mt_5 mt_6_md'>
          <span>Please wait</span>
        </div>
      </div>
    );
  }
  if (isError) {
    return (
      <div className='login_container applicant_error_container'>
        <div className='t_welcome color_error'>
          There is a problem with this link
        </div>
      </div>
    );
  }
  return (
    <>
      <form className='form mb_4 mt_4' id='formOne'>
        <div className='row'>
          <div className='mb_4 col col_2_sm col_2_md'>
            <label className='form_label' htmlFor=''>
              First Name
            </label>

            <input
              className='form_control'
              type='text'
              placeholder='Enter first name'
              value={user.firstName}
              onChange={(e) => {
                setUser({ ...user, firstName: e.target.value });
              }}
            />
          </div>
          <div className='mb_4 col col_2_sm col_2_md'>
            <label className='form_label' htmlFor=''>
              Last Name
            </label>

            <input
              className='form_control'
              type='text'
              placeholder='Enter last name'
              value={user.lastName}
              onChange={(e) => {
                setUser({ ...user, lastName: e.target.value });
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='mb_4 col col_2_sm col_2_md'>
            <label className='form_label' htmlFor=''>
              Email
            </label>

            <input
              className='form_control'
              type='text'
              disabled
              placeholder='Enter email address'
              value={user.email}
              onChange={(e) => {
                setUser({ ...user, email: e.target.value.trim() });
              }}
            />
          </div>
        </div>

        <div className='row'>
          <div className='mb_4 col col_2_sm col_2_md'>
            <label className='form_label' htmlFor=''>
              Phone Number
            </label>

            <input
              className='form_control'
              type='number'
              placeholder='Enter phone number'
              value={user.phoneNumber}
              onChange={(e) => {
                setUser({ ...user, phoneNumber: e.target.value.trim() });
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div
            className='mb_4 col col_2_sm col_2_md'
            style={{ position: "relative" }}
          >
            <label className='form_label' htmlFor=''>
              Password
            </label>

            <input
              className='form_control'
              type={showPwd ? "text" : "password"}
              placeholder='Enter password'
              value={user.password}
              onChange={(e) => {
                setUser({ ...user, password: e.target.value.trim() });
              }}
            />
            <div
              className='eye'
              onClick={openPwd}
              style={{
                position: "absolute",
                right: "28px",
                top: "38px",
                zIndex: "0",
              }}
            >
              <img
                src={showPwd ? showPng : hiedPng}
                style={{ width: "18px", height: "16px" }}
              ></img>
            </div>
          </div>
        </div>
        {/* <p className="mb_2">Locations</p> */}
        <div className='row'>
          <div className='col col_2_sm col_2_md'>
            <div className='search_bar'>
              {/* <input
                                    className="form_control"
                                    type="text"
                                    value={lsStr}
                                    onChange={(e)=>{setLsStr(e.target.value.trim())}}
                                    placeholder="Search location..."
                                />
                                <button className="btn btn_icon" onClick={filterLocation}>
                                    <img
                                        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzQ0MDhfMjUyNykiPgo8cGF0aCBkPSJNMTUuNTAwNiAxNC4wMDA2SDE0LjcxMDZMMTQuNDMwNiAxMy43MzA2QzE1LjYzMDYgMTIuMzMwNiAxNi4yNTA2IDEwLjQyMDYgMTUuOTEwNiA4LjM5MDYzQzE1LjQ0MDYgNS42MTA2MyAxMy4xMjA2IDMuMzkwNjMgMTAuMzIwNiAzLjA1MDYzQzYuMDkwNjMgMi41MzA2MyAyLjUzMDYzIDYuMDkwNjMgMy4wNTA2MyAxMC4zMjA2QzMuMzkwNjMgMTMuMTIwNiA1LjYxMDYzIDE1LjQ0MDYgOC4zOTA2MyAxNS45MTA2QzEwLjQyMDYgMTYuMjUwNiAxMi4zMzA2IDE1LjYzMDYgMTMuNzMwNiAxNC40MzA2TDE0LjAwMDYgMTQuNzEwNlYxNS41MDA2TDE4LjI1MDYgMTkuNzUwNkMxOC42NjA2IDIwLjE2MDYgMTkuMzMwNiAyMC4xNjA2IDE5Ljc0MDYgMTkuNzUwNkMyMC4xNTA2IDE5LjM0MDYgMjAuMTUwNiAxOC42NzA2IDE5Ljc0MDYgMTguMjYwNkwxNS41MDA2IDE0LjAwMDZaTTkuNTAwNjMgMTQuMDAwNkM3LjAxMDYzIDE0LjAwMDYgNS4wMDA2MyAxMS45OTA2IDUuMDAwNjMgOS41MDA2M0M1LjAwMDYzIDcuMDEwNjMgNy4wMTA2MyA1LjAwMDYzIDkuNTAwNjMgNS4wMDA2M0MxMS45OTA2IDUuMDAwNjMgMTQuMDAwNiA3LjAxMDYzIDE0LjAwMDYgOS41MDA2M0MxNC4wMDA2IDExLjk5MDYgMTEuOTkwNiAxNC4wMDA2IDkuNTAwNjMgMTQuMDAwNloiIGZpbGw9IiM4RThFOEUiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF80NDA4XzI1MjciPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg=="
                                        alt=""
                                    />
                                </button> */}
              {/* <ul className="">
                                    <li className="my_3 my_5_sm d_flex">
                                        <input className="form_checkbox" type="checkbox" />
                                        <label className="checkbox_label mt_1 ms_2">
                                            <div className="color_primary700">
                                                <span className="fw_medium">legal name</span> (owner name)
                                            </div>
                                            <div className="mt_2 fs_sm">
                                                4000 La Rica Ave Suite D Baldwin Park, CA 91706
                                            </div>
                                        </label>
                                    </li>
                                    <li className="my_3 my_5_sm d_flex">
                                        <input className="form_checkbox" type="checkbox" />
                                        <label className="checkbox_label mt_1 fs_sm ms_2">
                                            <div className="color_primary700">
                                                <span className="fw_medium">legal name</span> (owner name)
                                            </div>
                                            <div className="mt_2 fs_sm">
                                                4000 La Rica Ave Suite D Baldwin Park, CA 91706
                                            </div>
                                        </label>
                                    </li>
                                </ul> */}
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='mb_4 col col_2_sm col_2_md'>
            <label className='form_label' htmlFor=''>
              State
            </label>

            {/* <input
                            className="form_control"
                            type="text"
                            placeholder="Enter state"
                            value={user.state}
                            onChange={(e) => { setUser({ ...user, state: e.target.value }) }}
                        /> */}
            <Select
              options={stateCollection}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: "48px",
                  borderRadius: "16px",
                }),
              }}
              value={stateCollection.find(
                (option) => option.value === user.state
              )}
              onChange={(e) => {
                setUser({
                  ...user,
                  state: e.value.trim(),
                });
              }}
            />
          </div>
          <div className='mb_4 col col_2_sm col_2_md'>
            <label className='form_label' htmlFor=''>
              City
            </label>

            <input
              className='form_control'
              type='text'
              placeholder='Enter city'
              value={user.city}
              onChange={(e) => {
                setUser({ ...user, city: e.target.value });
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='mb_4 col col_2_sm col_2_md'>
            <label className='form_label' htmlFor=''>
              Address 1
            </label>

            <input
              className='form_control'
              type='text'
              placeholder='Enter address1'
              value={user.address1}
              onChange={(e) => {
                setUser({ ...user, address1: e.target.value });
              }}
            />
          </div>
          <div className='mb_4 col col_2_sm col_2_md'>
            <label className='form_label' htmlFor=''>
              Address 2
            </label>

            <input
              className='form_control'
              type='text'
              placeholder='Enter address2'
              value={user.address2}
              onChange={(e) => {
                setUser({ ...user, address2: e.target.value });
              }}
            />
          </div>
        </div>
        <div className='row'>
          <div className='mb_4 col col_2_sm col_2_md'>
            <label className='form_label' htmlFor=''>
              Zip Code
            </label>

            <input
              className='form_control'
              type='number'
              placeholder='Enter zipCode'
              value={user.zipCode}
              onChange={(e) => {
                setUser({ ...user, zipCode: e.target.value });
              }}
            />
          </div>
        </div>
        <div className='offcanvas_footer filter_footer'>
          <div className='btn_group'>
            <button className='btn btn-primary ms_auto col' onClick={nextStep}>
              Create User
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

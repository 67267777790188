import _ from "lodash";

const removeUndefinedProperties = (obj) => {
  if (_.isObject(obj)) {
    return _.pickBy(obj, _.identity, _.isObject);
  }
  return obj;
};
const removeNullOrUndefinedProperties = (obj) => {
  if (_.isObject(obj)) {
    return _.pickBy(obj, (value) => !(_.isNull(value) || _.isUndefined(value)));
  }
  return obj;
};

const getObjectPropertyNames = (obj, parentPrefix = "") => {
  return _.flatMap(_.keys(obj), (key) => {
    const value = obj[key];
    const currentKey = parentPrefix ? `${parentPrefix}.${key}` : key;
    if (_.isPlainObject(value)) {
      return getObjectPropertyNames(value, currentKey);
    }
    return currentKey;
  });
};

const createValidationObject = (obj) => {
  const propertyNames = getObjectPropertyNames(obj);
  const output = _.cloneDeep(obj);
  propertyNames.forEach((propertyName) => {
    _.set(output, propertyName, true);
  });
  return output;
};

export {
  removeUndefinedProperties,
  removeNullOrUndefinedProperties,
  getObjectPropertyNames,
  createValidationObject,
};

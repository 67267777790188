import axios from "axios";
import { getToken } from "./storage";

function serializeParams(params) {
  const queryString = Object.keys(params)
    .map((key) => {
      const value = params[key];
      if (value === null || value === undefined) {
        return null;
      }
      if (Array.isArray(value)) {
        return value
          .map(
            (item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`
          )
          .join("&");
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .filter(part => part !== null)  // Exclude null parts
    .join("&");

  return queryString;
}

// create an axios instance
// import {getToken} from './storage'
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8"; //
// const baseUrl="https://dev.dentiratenetwork.com/api"
//const baseUrl = "https://localhost:44377/api";
const baseUrl = "/api";
// const baseUrl="http://45.23.111.227:5000/api"

const service = axios.create({
  baseURL: baseUrl, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 120000, // request timeout
  paramsSerializer: (params) => serializeParams(params),
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    if (getToken()) {
      //
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers["Token"] = getToken();
    }
    return config;
  },
  (error) => {
    // do something with request error
    // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response;

    // if the custom code is not 20000, it is judged as an error.
    if (res.code && res.code !== 200) {
      //   Message({
      //     message: res.message || 'Error',
      //     type: 'error',
      //     duration: 5 * 1000
      //   })

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      //   if (res.code === 401) {
      //     // to re-login
      //     MessageBox.confirm('', '', {
      //       confirmButtonText: '',
      //       cancelButtonText: '',
      //       type: 'warning'
      //     }).then(() => {
      //       store.dispatch('user/resetToken').then(() => {
      //         location.reload()
      //       })
      //     })
      //   }
      return Promise.reject(new Error(res || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    // for debug

    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error);
  }
);

export default service;

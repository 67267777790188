import { Link, useNavigate } from "react-router-dom";
import { getItem } from "../../utils/storage.js";
import React, { useRef } from "react";
import { toastError } from "../../utils/check.js";
import logoPc from "../../assets/logo.svg";
import logoMobile from "../../assets/logo2.svg";

export function Header() {
  const back = () => {
    window.history.go(-1);
  };

  return (
    <>
      <header>
        <div className='nav container'>
          <button className='btn_return me_3 ' onClick={back} />
          {/* <div className="title">Change password</div> */}
          <div className='ms_auto ms_0_sm' onClick={back}>
            <img className='logo' src={logoPc} alt='' />
          </div>
        </div>
      </header>
    </>
  );
}

export function TermServiceText() {
  return (
    <>
      <div>
        <p
          style={{
            fontSize: "18pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "18pt",
              fontWeight: "bold",
            }}
          >
            - TERMS OF USE-
          </span>
        </p>
        <p
          style={{
            fontSize: "18pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "18pt",
              fontWeight: "bold",
            }}
          >
            Dentirate, LLC
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            Last updated September 14, 2023
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Welcome to{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            !{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            DentiRate.com is an online platform dedicated to empowering
            consumers with information and choices for their dental care
            financing needs. Understanding that dental procedures can be both
            unexpected and expensive, we offer a comprehensive guide tailored to
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            each individual's
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            requirements.
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Our mission at DentiRate.com is not just about connecting patients
            with financing options; it's about ensuring that every patient,
            regardless of their financial situation, can access quality dental
            care. Through extensive research, user-friendly tools, and
            partnerships with trusted financial institutions, we are dedicated
            to making dental healthcare more accessible and affordable for all.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            BY USING THIS WEBSITE OR RELATED SERVICES, YOU WARRANT AND REPRESENT
            THAT YOU ARE AT LEAST 18 YEARS. YOU UNDERSTAND AND AGREE THAT
            SATISFYING THE ABOVE REQUIREMENTS DOES NOT GUARANTEE THAT YOU WILL
            RECEIVE SERVICES THROUGH{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            DENTIRATE.COM
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            TERMS OF USE
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            {" "}
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , LLC
          </span>
          <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}> </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            and its officers, directors, employees, consultants, affiliates,
            subsidiaries
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and agents
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            (
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            “Dentirate,”{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            “
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,” “we,” “us,” “our”) provide its services to you through our
            website located at{" "}
          </span>
          <a style={{ color: "#000000" }} href='http://www.zillmeter.com'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                textDecoration: "underline",
              }}
            >
              www.
            </span>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                textDecoration: "underline",
              }}
            >
              Dentirate.
            </span>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                textDecoration: "underline",
              }}
            >
              com
            </span>
          </a>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            or{" "}
          </span>
          <a style={{ color: "#000000" }} href='http://www.zillmeter.com'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                textDecoration: "underline",
              }}
            >
              www.portal.Dentirate.com
            </span>
          </a>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            (the “Site”) and through various mobile applications and related
            technology. These{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            (“
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ”) apply to all{" "}
          </span>
          <a name='_Int_qk0D9UEO'>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Dentirate
            </span>
          </a>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            entities and services, whether in existence now or in the
            future.&nbsp; Other{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            websites and services will incorporate these
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            entirely by reference.&nbsp; Collectively, our services, including
            any new features and applications, and the Site, and any other
            service provided by us{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            are
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            subject to the following{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            reserves the right, at our sole discretion, to change, alter, or
            modify any of these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            at any time and for any{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            reason. If you use our Service after{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            the{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            new{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            are published by{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , you have implicitly accepted all terms in the new{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            YOU MUST READ THESE{" "}
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            TERMS OF USE
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            {" "}
            CAREFULLY AND UNDERSTAND THEM FULLY, AS THEY INCLUDE AN AGREEMENT TO
            ARBITRATE AND OTHERWISE DEFINE AND LIMIT YOUR LEGAL RIGHTS,
            REMEDIES, AND OBLIGATIONS.
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            OUR ARBITRATION AGREEMENT REQUIRES (WITH LIMITED EXCEPTION) THAT YOU{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            SUBMIT
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            ANY AND ALL CLAIMS YOU HAVE AGAINST US
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            TO BINDING AND FINAL ARBITRATION. FURTHER, (1) YOU MAY ONLY BE
            PERMITTED TO PURSUE CLAIMS AGAINST{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            DENTIRATE
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            ON A PERSONAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS
            OR REPRESENTATIVE ACTION OR PROCEEDING, (2) YOU WILL ONLY BE
            PERMITTED TO PURSUE RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
            DECLARATORY RELIEF) ON A PERSONAL BASIS, AND (3) YOU WILL NOT BE
            ABLE TO PURSUE ANY CLAIMS AGAINST US THAT WOULD REQUIRE
            DETERMINATION BY A JURY OR BY ANY COURT OF LAW.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            When using some of our services, additional terms will apply to some
            services provided by{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            . These may be posted on the S
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ite
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            from time to time, including, without limitation, the Privacy Policy
            located at{" "}
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              textDecoration: "underline",
            }}
          >
            Dentirate
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              textDecoration: "underline",
            }}
          >
            .
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              textDecoration: "underline",
            }}
          >
            com/privacy
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            (the “Privacy Policy”). All such terms are hereby incorporated by
            reference into these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .{" "}
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            DISCLAIMERS
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            IT IS IMPERATIVE FOR USERS TO COMPREHEND THAT FINANCING, IN ANY
            FORM, INHERENTLY CARRIES CERTAIN RISKS. WHEN CONSIDERING FINANCIAL
            OPTIONS, ESPECIALLY FOR MEDICAL OR DENTAL TREATMENTS, USERS SHOULD
            BE FULLY AWARE AND INFORMED OF THE POTENTIAL IMPLICATIONS AND
            CHALLENGES.
          </span>
        </p>
        <ul type='disc' style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Interest rates: many financing options come with interest rates.{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Over
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              time, these rates can substantially increase the total amount
              owed.{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              It’s
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              crucial to understand the rate you're agreeing to and calculate
              the long-term implications{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              for
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              your financial situation.
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Credit implications: engaging in financing can have both positive
              and negative effects on one's credit score.{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Timely
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              repayments can bolster a credit rating, while missed or late
              payments can harm it, making future borrowing more challenging or
              costly.
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Terms and conditions: every financial agreement comes with terms
              and conditions.{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              it's
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              essential to read, understand, and, if needed, consult with a
              financial advisor about these terms to avoid unexpected
              consequences.
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Potential for debt accumulation: without a clear understanding and
              plan for repayment, it's easy for debts to accumulate, leading to
              a financial strain.
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Hidden fees: some financing options might have hidden fees,
              charges for early repayment, or other costs not evident at the
              outset. users should be diligent in their research and ask
              questions to avoid unexpected expenses.
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 8pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Impact of long-term commitments: some dental or medical treatments
              might necessitate long-term financial commitments.{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              It’s
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              vital to assess whether such a commitment aligns with your future
              financial plans and capabilities.
            </span>
          </li>
        </ul>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            At Dentirate.com, while we strive to provide comprehensive
            information and guidance on financing options, it remains the user's
            responsibility to make informed decisions. We strongly advise all
            users to do their due diligence, ask questions, and, if possible,
            consult with financial professionals before committing to any
            financing option. This proactive approach ensures that users are
            well-equipped to make decisions that align with their personal and
            financial well-being.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            The site is provided on an as-is and as-available basis. By agreeing
            to these terms of use, you also agree that your use of the site
            and/or other services will be at your sole risk. We make no
            representation regarding the accuracy or completeness of any data
            used by our algorithms. To the fullest extent permitted by law, we
            disclaim all warranties, express or implied, in connection with the
            site and your use thereof, including, without limitation, the
            implied warranties of merchantability, fitness for a par
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ticular purpose, and non-infringement.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            W
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            e make no warranties or representations about the accuracy or
            completeness of the{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            S
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ite’s content or the content of any websites linked to this site and
            we will assume no liability or responsibility for any (1) errors,
            mistakes, or inaccuracies of content and materials, (2) personal
            injury or property damage, of any nature whatsoever, resulting from
            your access to and use of the site, (3) any unauthorized access to
            or use of our secure servers and/or any and all personal information
            and/or financial information stored therein, (4) any interruption or
            cessation of transmission to or from the site, (5) any bugs,
            viruses, trojan horses, or the like which may be transmitted to or
            through the site by any third party, and/or (6) any errors or
            omissions in any content and materials or for any{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            loss or damage of any kind incurred as a result of the use of any
            content posted, transmitted, or otherwise made available via the
            site.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We do not warrant, endorse, guarantee, or assume responsibility for
            any product or service advertised or offered by a third party
            through the site, any hyperlinked website, or any website or mobile
            application featured in any banner or other advertising, and we will
            not be a party to or in any way be responsible for monitoring any
            transaction between you and any third-party providers of products or
            services. As with the purchase of a product or service through any
            medium or in any environment, you should us
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            e your best judgment and exercise caution where appropriate.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            LIMITATIONS OF LIABILITY
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate makes no warranty that (
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            i
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ) the service will meet your needs, (ii) the service will be
            continuous, timely, private, secure, or error-free, (iii) the
            results that may be obtained from the use of the service will be
            accurate, complete, or reliable, or (iv) the quality of any
            products, services, information, or other material purchased or
            obtained by you through the service will meet your expectations.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Y
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ou expressly understand and agree that
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            D
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            entirate will not be liable for any indirect, incidental, special,
            consequential, exemplary damages, or damages for loss of profits
            including but not limited to, damages for loss of goodwill, use,
            data
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or other intangible losses (even if{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            D
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            entirate has been advised of the possibility of such damages),
            whether based on contract, tort, negligence, strict liability or
            otherwise, resulting from (
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            i
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ) the use or the inability to use the service; (ii) the cost of
            procurement of substitute goods and services resulting from any
            goods, data, information or services purchased or obtained or
            messages received or transactions entered into through or from the
            service; (iii) unauthorized access to or alteration of your
            transmissions or data; (iv) statements or conduct of any third party
            on the service; or (v) any other matter relating to the service. In
            no event will{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            D
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            entirate’s
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            total liability to you for all damages, losses
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or causes of action exceed the amount you have paid dentirate.com in
            the last six (6) months, or, if greater, one hundred dollars ($100).{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            S
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ome jurisdictions do not allow the disclaimer or exclusion of
            certain warranties or the limitation or exclusion of liability for
            incidental or consequential damages. Accordingly, some of the above
            limitations set forth above may not apply to you or be enforceable
            with respect to you. If you are{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            unhappy or dissatisfied with any portion of
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            D
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            entirate’s
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            service or with any of these terms of use, your only remedy is to
            stop using{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            the Site
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "108%",
            margin: "0pt 0pt 8pt",
          }}
        >
          <br style={{ pageBreakBefore: "always", clear: "both" }} />
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            AGREEMENT TO TERMS
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            These{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            constitute a legally binding agreement made between you, whether
            personally or on behalf of an entity (“you”)
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , LLC
          </span>
          <span style={{ fontFamily: "Calibri", fontSize: "12pt" }}> </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            and its officers, directors, employees, consultants, affiliates,
            subsidiaries
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and agents
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            (
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            “
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            Dentirate,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ”{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            “
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            Company
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            ,
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            ” “We
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            ,
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            ” “us
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            ,
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            ” or “Our”),{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            concerning your access to and use of{" "}
          </span>
          <a style={{ color: "#000000" }} href='http://www.zillmeter.com/'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                textDecoration: "underline",
              }}
            >
              www.
            </span>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                textDecoration: "underline",
              }}
            >
              Dentirate.com
            </span>
          </a>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            and/or{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            www.portal.Dentirate.com
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              textDecoration: "none",
            }}
          >
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            websit
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            es
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            as well as any other media form, media channel, mobile website or
            mobile application related, linked, or otherwise connected thereto
            (collectively, the “Site”). By accessing the Site or using any of
            our Services, you are confirming and acknowledging that you have
            read,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            understand,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and agree to be bound by{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            all of
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            . IF YOU DO NOT AGREE WITH ALL OF THESE
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            TERMS OF USE
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST
            DISCONTINUE USE IMMEDIATELY.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Supplemental terms and conditions or documents that may be posted on
            the Site from time to time are hereby expressly incorporated herein
            by reference.
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            reserves all rights, in our sole discretion, to change or{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            modify
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            for any reason. We will alert you about any changes by updating the
            “Last updated” date of these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , and you waive any right to receive specific notice of each such
            change. It is your responsibility to periodically review these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            to stay informed of updates. You will be subject to and will be
            deemed to have been made aware of and to have accepted, the changes
            in any revised
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            by your continued use of the Site after the date such revised{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            are posted.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            The information provided on the Site is not intended to be used or
            distributed by any person or entity in any jurisdiction or country
            where such distribution or use would be illegal in any way or would
            subject us to any registration requirement within such jurisdiction
            or country. Accordingly, those persons who choose to access the Site
            from other locations do so on their own initiative and are solely
            responsible for compliance with local laws, if and to the extent
            local laws are applicable.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            The Site is not tailored to comply with industry-specific
            regulations such as the General Data Protection Act (EU) (GDRP)
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Health Insurance Portability and Accountability Act (HIPAA),
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or the Sarbanes-Oxley Act (SOC). If your use of our service would be
            subjected to such laws, you should not use this Site. Further, you
            may not use the Site in a way that would violate the
            Gramm-Leach-Bliley Act (GLBA).
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            USER REPRESENTATIONS
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            The use of our Site and Services indicates agreement that you
            represent and warrant that: (1) all registration information
            submitted by you is true, accurate, current, and complete; (2) you
            will maintain the accuracy of such information and promptly update
            such registration information as necessary; (3) you have the legal
            capacity and you agree to these Terms of Use; (4) you are not a
            minor in the jurisdiction in which you reside; (5) you will not
            access the Site through automated or non-human means, whether
            through a bot, script or otherwise; (6) you will not use the Site
            for any illegal or unauthorized purpose; and (7) your use of the
            Site will not violate any applicable law or regulation.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            If you provide any information that is untrue, inaccurate, not
            current, or incomplete, we may, at our sole discretion, suspend or
            terminate your account and refuse{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            any and all
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            current or future use of the Site (or any portion thereof).
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            PRODUCTS/SERVICES
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            DENTIRATE DOES NOT, AND THE SERVICES ARE NOT INTENDED TO PROVIDE
            FINANCIAL OR{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            HEALTHCARE
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            ADVICE. DENTIRATE.COM IS NOT A LENDER OR
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            CRED
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            IT
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            BROKER REPRESENTING YOU OR ANOTHER USER.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com offers a technology service and platform through which{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            you
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            access{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            U.S. financial services
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ; platforms that enable{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            you
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            to locate and contact U.S. financial services; discussion forums,
            community forums, bulletin boards,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            and{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            review services; and enable
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            s
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            you
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            to apply to pre-qualify for certain credit card or loan offers.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            CONSUMERS
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            When a consumer needs financial assistance for their dental
            procedures, dental care providers facilitate the process by
            presenting them with an electronic form via the Dentirate.com
            platform. This form is designed to capture essential details about
            the consumer's specific financing needs.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Once the consumer completes and submits this form, Dentirate.com{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            l
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            everag
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            es
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            its
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            network of financial institutions
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            forward
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            s
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            the consumer's details to{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            the
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            partners for pre-qualification. This step ensures that the consumer
            is matched with financial products tailored to their{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            need
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , enhancing the likelihood of{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            approval
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and minimizing potential financial stress.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Within a short timeframe, our platform aggregates and displays a
            list of financial products for which the consumer is pre-qualified.
            This could range from specialized dental loans to specific credit
            card offers suitable for medical expenses. We ensure that each
            option is presented, allowing the consumer to compare interest
            rates, terms, and other vital criteria.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Empowered with this information, the consumer can make an informed
            choice about their preferred financing option. Upon selection, a
            seamless integration redirects them to the chosen financial
            institution's official website. Here, they can finalize the
            application process for their credit or loan. This streamlined
            process not only simplifies financial access for dental procedures
            but also ensures that consumers receive the best possible financial
            solutions tailored to their needs.
          </span>
          <span
            style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}
          ></span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            Dental care providers
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dental care professionals invest significant time and expertise in
            examining patients and meticulously crafting treatment plans
            tailored to{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            each individual
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            . Yet, despite their best efforts, many treatments don't move
            forward. A major reason is the financial hurdle patients face,
            combined with a process that often feels cumbersome and
            intimidating.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            By incorporating Dentirate into their workflow, dental care
            providers can ensure a smoother, more accessible financial journey
            for their patients. When patients encounter a frictionless
            experience, from diagnosis to financing, they're more inclined to
            commit to and follow through with their treatment plans.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            In essence, Dentirate doesn't just facilitate financial solutions.
            It enhances the overall patient experience, ensuring that the
            expertise dental professionals bring to the table culminates in
            actual treatment rather than potential plans left on paper. By
            bridging this gap, Dentirate ensures that both providers and
            patients find success and satisfaction.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            Lenders
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Lenders partnering with Dentirate enjoy a distinct competitive edge.
            By receiving applications specifically from consumers who have been
            pre-screened and are more apt to gain approval, lenders can optimize
            their processes, reducing both time and resources on less promising
            leads. This not only boosts their efficiency but also reinforces
            their position in the market, as a high acceptance rate is often
            equated with reliability and trustworthiness by consumers.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            To further empower our partner lenders, Dentirate offers an
            intuitive and comprehensive dashboard. This tool allows lenders to
            delve deep into data analytics, offering insights into how their
            financial products are faring across diverse geographical regions.
            Moreover, they can discern patterns or preferences among different
            dental care providers. Such granularity in data can be pivotal for
            lenders to refine their strategies, customize their offerings, and
            target their products more effectively.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            As a testament to the value Dentirate brings to the table, lenders
            compensate us through a Lead Fee. This fee not only acknowledges the
            quality of leads provided but also the added advantage of market
            analytics, ensuring that our partners always stay a step ahead in
            the ever-evolving financial landscape.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            NO ENDORSEMENT
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate does not endorse any{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            of the
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            financial{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            institutions
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ; platforms to locate and contact U.S. financial services;{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            credit card or loan offers
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ; and
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            /or any other financial products
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            . We do not conduct background{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            analysis
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            on any{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            of the offered products
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and are not responsible for any damage or harm resulting from your
            contracting with any third
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            party. Users of Dentirate are not employees, agents
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or otherwise contractors of Dentirate, but are independent people
            and businesses that want to connect with others.{" "}
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            ADVERTISERS
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Like most websites, we allow advertisers to display their
            advertisements and other information in certain areas of the Site,
            such as sidebar advertisements or banner advertisements. If you are
            an advertiser, you admit full responsibility for any advertisements
            you place on the Site and any services provided on the Site or
            products sold through those advertisements. Further, as an
            advertiser, you warrant and represent that you possess all
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            the rights
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and authority to place advertisements on the Site, including, but
            not limited to, intellectual property rights, publicity rights, and
            contractual rights. We simply provide the space to place such
            advertisements, and we have no other relationship with advertisers
            or their products or services.{" "}
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            THIRD-PARTY WEBSITES AND CONTENT
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com contain
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            s
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            links to other websites ("Third-Party Websites") as well as
            articles, photographs, text, graphics, pictures, designs, music,
            sound, video, information, applications, software, and other content
            or items belonging to or originating from third parties
            ("Third-Party Content"). Such Third-Party Websites and Third-Party
            Content are not investigated, monitored, or checked for accuracy,
            appropriateness, or completeness by us, and we are not responsible
            for any Third-Party Websites accessed through the Site or any
            Third-Party Content posted on, available through, or installed from
            the Site, including the content, accuracy, offensiveness, opinions,
            reliability, privacy practices, or other policies of or contained in
            the Third-Party Websites or the Third-Party Content. Inclusion of,
            linking to, or permitting the use or installation of any Third-Party
            Websites or any Third-Party Content does not imply approval or
            endorsement thereof by us. If you decide to leave the Site and
            access a Third-Party Website or to use or install any Third-Party
            Content, you do so at your own risk, and you should be aware these
            Terms of Use no longer govern.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Any purchases or services provided by Third-Party Websites will be
            through other websites and from other companies. Dentirate.com takes
            no responsibility whatsoever in relation to such transactions which
            are exclusively between you and the applicable third party. You
            agree and acknowledge that we do not endorse the products or
            services offered on Third-Party Websites and you shall hold us
            harmless from any harm caused by your purchase of such products or
            services. Additionally, you shall hold us harmless from any losses
            sustained by you or harm caused to you relating to or resulting in
            any way from any Third-Party Content or any contact with Third-Party
            Websites.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            CERTAIN THIRD-PARTY SERVICES
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We are not responsible for the content, functionality
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or technological safety of any Third-Party Sites or services to
            which you link from{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            the Site
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , or that Dentirate may choose to embed in their websites and
            applications. If you decide to access any such Third Parties, you do
            so entirely at your own risk. If you are accessing a Third-Party
            Site through a link on our Site, you are advised to read the
            conditions of use and privacy policy of such Third-Party Site before
            you use such site.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com does not endorse and is not
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            responsible
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or liable for any Content, data, advertising, products,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            goods,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or services available or unavailable from, or through, any
            Third-Party Service{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Provider. By agreeing to these Terms of Use, you are agreeing that
            should you use or rely on such content, data, advertisement,
            products, goods
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or services, available or unavailable from, or through any
            Third-Party Service Provider, Dentirate is not responsible or
            liable,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            indirectly
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or directly, for any damage or loss caused or alleged to be caused
            by or in connection with such use or reliance. Your dealings with,
            or participation in promotions of any Third-Party Service Provider,
            and any other terms, conditions,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            representations,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or warranties associated with such dealings, are between you and
            such Third-Party Service Provider exclusively and do not involve
            Dentirate in any way. You should make whatever investigation or
            other resources that you deem necessary or appropriate before hiring
            or engaging any Third-Party Service Provider.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Although we take certain steps to examine the credentials of the
            Third-Party Service Providers listed on our website or whom you may
            book using our Services, we make no guarantees, warranties
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or representations regarding the skills or undertakings of such
            Third-Party Service Providers or the quality of the job that he or
            she may perform for you if you elect to retain their services.
            Dentirate does not check or monitor the license status of any
            Third-Party Service and recommend
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            s
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            that you confirm that each person involved in your transaction is
            licensed appropriately. Dentirate does not endorse or recommend the
            services of any
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            particular Third-Party
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            Service Provider. It is entirely up to you to evaluate the
            Third-Party Service Provider and the Third-Party Service Providers’
            qualifications and to
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            enter into
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            a direct contract or otherwise reach{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            agreement
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            with a Third-Party Service Provider. We do not guarantee or warrant
            any Third-Party Service Provider’s performance on the{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            job,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or the outcome or quality of the services performed. Third-Party
            Service Providers are not employees or agents of Dentirate, nor is
            Dentirate an agent of any Third-Party Service Provider.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            References on our Site to products, services, trademarks,
            tradenames, design marks, or processes of third parties and/or links
            to Third Party Sites do not constitute or imply endorsement,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            sponsorship
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            recommendation by us of such third party or its products or
            services. We assume no responsibility to screen or investigate, and
            we make no representation concerning the content that third parties{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            submit
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            for publication on the Site. We are not responsible for the content,
            functionality, or technological safety of Third-Party Sites to which
            you may link from this Site. If you decide to access any such
            Third-Party Sites, you do so entirely at your own risk. If you are{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            accessing a Third-Party Site through a link on this Site, you are{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            advised to read
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            the conditions of use and privacy policy of such Third-Party Site
            before you use such site.
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            Here are the conditions of use and privacy policy of our major
            partners that you{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            should review:
          </span>
        </p>
        <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Alpha
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              e
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              on
            </span>
            <ol type='a' style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: "12pt",
                  lineHeight: "150%",
                  margin: "0pt 0pt 0pt 31.33pt",
                  paddingLeft: "4.67pt",
                  textAlign: "justify",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}
                >
                  Terms and Use at
                  https://www.breadfinancial.com/en/terms-of-service.html
                </span>
              </li>
              <li
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: "12pt",
                  lineHeight: "150%",
                  margin: "0pt 0pt 8pt 32pt",
                  paddingLeft: "4pt",
                  textAlign: "justify",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}
                >
                  Privacy Policy at
                  https://www.breadfinancial.com/en/privacy.html
                </span>
              </li>
            </ol>
          </li>
        </ol>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Financial Products: If you decide to contact a bank, lender,
            financial institution, originator, loan broker, or other financial
            professional (“Financial Professionals”) through{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate’s
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Site
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , you hereby authorize Dentirate to provide important account and
            other user information to the Third-Party Provider. If you include
            your name, contact information
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and other information in a request, your identity will no longer be
            anonymous to the Third-Party Provider. Your submission of
            information and any request for quotes through the Services is not
            an application for a loan or credit in any way. Dentirate only
            provides an administrative service to consumers
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            participating Lenders
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and other financial professionals. If a financial professional
            contacts a consumer, such contacts are made by participating
            financial professionals and not Dentirate.{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Any non
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            -binding quotes are not intended to be official
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            l
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            oan{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            e
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            stimates.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            By using these features, the disclosures and consent required under
            certain state laws are deemed to be provided, received, and agreed
            to. Interest rates displayed through the Services are for
            information purposes only and reflect non-binding customized quotes
            of the terms a financial professional may offer a borrower fitting a
            consumer’s anonymous profile. Actual interest rates may vary. Loan
            approval standards are established and maintained solely by
            individual Lenders. Consumers should rely on their own judgment in
            deciding which available loan product, terms, and Third-Party
            Provider best suit their needs and financial means.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate: (a) is not a Lender, loan originator, loan processor
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or underwriter; (b) does not aid or assist
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            consumers
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            in obtaining loans, solicit{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            consumers
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            or Lenders for loans, offer or negotiate terms of loans; (c) does
            not take{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            credit or loan
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            applications, make loans or credit decisions or pre-approve{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            consumers
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            for loans; (d) is not an agent of either any consumer or any Lender;
            (e) does not endorse, refer or recommend any Third-Party Provider;
            (f) is not responsible for any errors or delays caused by consumers
            or any Third-Party Provider in the loan process; and (g) does not
            guarantee offer of, or acceptance into, any particular loan program
            or specific loan terms, conditions, or rates with any Third-Party
            Provider, or that any rates or terms will be the best available.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dental Care Providers: Our service facilitates connections between
            you and{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Third-Party{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            dental care providers. These encompass a range of professionals in
            the dental care domain. Should you decide to reach out to any dental
            care provider via Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            – be it through a contact form, request
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            form, or by expressing your interest in establishing contact with a
            Third-Party Provider (including rate quote requests) – you give
            Dentirate permission to share the information you submit with that
            Third-Party Provider. If your request includes identifiable details
            like your name or contact information, your identity will be
            revealed to the Third-Party Provider. Dentirate merely offers an
            administrative function, aiding consumers and participating dental
            care providers. The decision regarding which dental care provider
            contacts consumers is solely at the discretion of the dental care
            provider and not Dentirate.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com:
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            (a) is not a dental clinic or care provider;
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            (b) does not facilitate negotiations of individual contractual terms
            between service users or dental care professionals;
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            (c) has no say in the approval or rejection of any bid or
            third-party service;
          </span>
          <span
            style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}
          ></span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            (d) does not act as an agent for either consumers or dental care
            providers;
          </span>
          <span
            style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}
          ></span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            (e) does not endorse, recommend, or vouch for any Third-Party
            Provider;
          </span>
          <span
            style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}
          ></span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            (f) bears no responsibility for errors or delays resulting from
            actions of consumers or any Third-Party Provider
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ; and{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            (
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            g
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            )
          </span>
          <span style={{ fontFamily: "Calibri", fontSize: "11pt" }}> </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            d
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            oes not offer clinical advice, diagnoses, or recommendations in any
            capacity.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            USER REGISTRATION
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            If you want to use some features of{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate’s
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            Site and Services, you must create an account ("Account"). You can
            do this via the Site or through your account with certain
            third-party social networking services such as{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Google
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            (each, an "SNS Account"). If you choose the SNS Account option, we
            will create your Account by extracting personal information from
            your SNS Account. Your SNS Account may require additional input and
            information for you to access the full functionality of the
            Services.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Accurate, complete
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and up-to-date information for your Account is important to us, and
            by using our Services, you agree to update such information, as
            needed, to keep it accurate, complete
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and up to date. If you don't, Dentirate may suspend or terminate
            your Account. You should never give your Account password to anyone,
            and by agreeing to these Terms of Use, you agree to keep your
            password strictly private, and you agree to notify us of any
            unauthorized use of your Account. Your Account is your
            responsibility, and you maintain all responsibility for all
            activities that occur under your Account, whether you know about or
            consent to them or not.{" "}
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            SPECIAL NOTICE FOR INTERNATIONAL USE; EXPORT CONTROLS
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Software and tools available in connection with Dentirate and the
            transmission of applicable data, if any,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            are
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            subject to United States export controls. No software may be
            downloaded from Dentirate or otherwise exported or re-exported in
            violation of U.S. export laws. Downloading or using Dentirate
            software is at your sole risk. Recognizing the global nature of the
            Internet, you agree to comply with all local rules and laws
            regarding your use of our service, including as it concerns online
            conduct and acceptable content.{" "}
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            COMMERCIAL USE
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            You agree not to display, distribute, license, perform, publish,
            reproduce, duplicate, copy, create derivative works from, modify,
            sell, resell, exploit, transfer
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or upload for any commercial purposes, any portion of the Service,
            use of the Service, or access to the Service. The Service is for
            your personal use only. Any commercial use of Dentirate, in any way,
            requires advance, written approval.&nbsp;{" "}
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            INTELLECTUAL PROPERTY RIGHTS
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Content, Software
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and Trademarks: By using our service, you confirm,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            understand,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and agree that the Service may contain content or features
            (“Content”) that are protected by copyright, patent, trademark,
            trade secret
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or other proprietary rights and laws. Except as expressly authorized
            by{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , you agree not to modify, copy, frame, scrape, rent, lease, loan,
            sell, distribute
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or create derivative works based on the Service or the Content, in
            whole or in part.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            By agreeing to these terms, you agree that you will not engage in or
            use any data mining, robots, scraping
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or similar data gathering or extraction methods. If you are blocked
            by{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            from accessing the Service (including by blocking your IP address),
            you agree not to implement any measures to circumvent such blocking
            (e.g., by masking your IP address or using a proxy IP address). Any
            use of the Service or the Content other than as specifically
            authorized herein is strictly prohibited. The technology and
            software underlying the Service or distributed in connection
            therewith are the property of{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , our affiliates
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and our partners (the “Software”). You agree not to copy, modify,
            create a derivative work of, reverse engineer, reverse assemble
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or otherwise attempt to discover any source code, sell, assign,
            sublicense, or otherwise transfer any right in the Software. Any
            rights not expressly granted herein are reserved by{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            The{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .com
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            name and logos are trademarks and service marks of{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            (collectively the “
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            Trademarks”). Other company, product, and service names and logos
            used and displayed via the Service may be trademarks or service
            marks of their respective owners who may or may not endorse or be
            affiliated with or connected to{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            . Nothing in this{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or the Service should be construed as granting, by implication,
            estoppel, or otherwise, any license or right to use any of{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            Trademarks displayed on the Service, without our prior written
            permission in each instance. All goodwill generated from the use of{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            Trademarks will inure to our exclusive benefit.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Third
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            -
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Party Material:{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            will not be liable, under any circumstances
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or in any way for any content or materials of any third parties
            (including users). This includes, but is not limited to, any errors
            or omissions in any content, or for any loss or damage of any kind
            incurred{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            as a result of
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            the use of any such content. By using our service, you acknowledge
            that{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            does not pre-screen content and that{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and its designees will have the right (but not the obligation) in
            their sole discretion to refuse or remove any content that is
            available via the Service. Without limiting the foregoing,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and its{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            designees
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            will have the right to remove any content that violates these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or is deemed by{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , in its sole discretion, to be otherwise objectionable. You agree
            that you must evaluate, and bear all risks associated with, the use
            of any content, including any reliance on the accuracy,
            completeness, or usefulness of such content.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            User Content Transmitted Through the Service: Regarding the content,
            data
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and/or other materials input or uploaded by a user through the
            Service (collectively, “User Content”), you represent and warrant
            that you possess all rights, titles
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and interests in such User Content, including all copyrights and
            intellectual property contained therein. By using our Site and
            Services, you grant
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and its affiliate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            s
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            a nonexclusive, worldwide, royalty
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            -
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            free, fully
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            paid, transferable, sublicensable, perpetual, irrevocable license to
            copy, display, upload, perform, distribute, store, modify
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and otherwise use your User Content in connection with the operation
            of the Service, and the advertising, marketing
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and promotion thereof.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            You agree that any questions, comments, suggestions, ideas,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            feedback,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or other information about the Service (“Submissions”) provided by
            you to{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            are non-confidential.{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            will have the right to the unrestricted use and distribution of
            these Submissions for any purpose, without attribution or
            compensation to you.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            may store User Content and may disclose such content if required to
            do so by law or in the good faith belief that such storage or
            disclosure is reasonably necessary to: (a) comply with legal
            process, applicable laws
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or government requests; (b) enforce these
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ; (c) respond to claims that any content violates the rights of
            third parties; or (d) protect the rights, property, or personal
            safety of{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , its users and the public. You understand that the technical
            processing and transmission of the Service, including your content,
            may involve (a) transmissions over various networks; and (b) changes
            to conform and adapt to{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            the{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            technical requirements of connecting networks or devices.{" "}
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            RETURN/REFUNDS POLICY
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            may, at its own discretion, decide to
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            fully or partially refund any disputed purchases
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            on a case-by-case basis.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            PROHIBITED ACTIVITIES
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            You are solely responsible for all content (e.g., code, video,
            images, information, data, text, software, music, sound,
            photographs, graphics, messages
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or other materials) that you upload, post, publish
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or display (hereinafter, “upload”) or email or otherwise use via the
            Service. The following are illustrative examples of content and/or
            use that is illegal or prohibited by{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            reserves the right to investigate and take appropriate legal action
            against any user who, in{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ’s
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            sole discretion, violates this provision, including without
            limitation, removing the offending content from the Service,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            suspending
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or terminating the account of such violators and reporting you to
            the law enforcement authorities. You agree to not use the Service
            to:{" "}
          </span>
        </p>
        <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Republish material from this Service (including republication on
              another platform
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              );
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Sell, rent
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              or sub-license material from the{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Site;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Show any material from the platform in
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              public;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Reproduce, duplicate, copy
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              or otherwise exploit material on{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Dentirate.com
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              for any commercial{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              purpose;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Edit or otherwise modify any material on
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Dentirate.com
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Redistribute material from this Site, except for content
              specifically and expressly made available for{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              redistribution;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Use{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Dentirate.com
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              in any way that causes, or may cause, damage to{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Dentirate.com
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              or impairment of availability or accessibility; or in any way
              which is unlawful, illegal, fraudulent or harmful, or in
              connection with any unlawful, illegal, fraudulent
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              or harmful purpose or{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              activity;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Use{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Dentirate.com
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              to copy, store, host, transmit, send, use, publish
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              or distribute any material which consists of (or is linked to) any
              spyware, computer virus, Trojan horse, worm, keystroke logger,
              rootkit
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              or other malicious computer{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              software;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              You must not conduct any systematic or automated data collection
              activities (including without limitation scraping, data mining,
              data extraction
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              and data harvesting) on or in relation to this platform without
              our express written{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              consent;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Use this platform to transmit or send unsolicited commercial{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              communications;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Infringe any intellectual property or other proprietary rights of
              any{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              party;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Violate any applicable local, state, national
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              or international law, or any regulations having the force of{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              law;
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Impersonate any person or entity, or falsely state or otherwise
              misrepresent your affiliation with a person or{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              entity;
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Further or promote any criminal activity or enterprise or provide
              instructional information about illegal{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              activities;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Obtain or attempt to access or otherwise obtain any materials or
              information through{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              any
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
        </ol>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 0pt 36pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            means not intentionally made available or provided for through the{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Service;
          </span>
        </p>
        <ol start={16} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Systematically retrieve data or other content from the Site to
              create or compile, directly or indirectly, a collection,
              compilation, database, or directory without written permission
              from{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              us;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Make any unauthorized use of the Site, including collecting
              usernames and/or email addresses of users by electronic or other
              means for the purpose of sending unsolicited email
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              s
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              , or creating user accounts by automated means or under false{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              pretenses;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Use the Site to advertise or offer to sell goods and services
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              without
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Dentirate
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              's
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              written{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              approval
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Circumvent, disable, or otherwise interfere with security-related
              features of the Site, including features that prevent or restrict
              the use or copying of any Content or enforce limitations on the
              use of the Site and/or the Content contained
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              therein;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Engage in unauthorized framing of or linking to the{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Site;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Trick, defraud, or mislead us and other users, especially in any
              attempt to learn sensitive account information such as user{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              passwords;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Make improper use of our support services or submit false reports
              of abuse or misconduct.
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Engage in any automated use of the system, such as using scripts
              to send comments or messages, or using any data mining, robots, or
              similar data gathering and extraction tools.
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Attempt to impersonate another user or person or use the username
              of another{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              user;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Interfere with, disrupt, or create an undue burden on the Site or
              the networks or services connected to the{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Site;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Sell or otherwise transfer your{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              profile;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Use any information obtained from the Site in order to harass,
              abuse, or harm another{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              person;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Use the Site as part of any effort to compete with us or otherwise
              use the Site and/or the Content for any revenue-generating
              endeavor or commercial{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              enterprise;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Decipher, decompile, disassemble, or reverse engineer any of the
              software comprising or in any way making up a part of the{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Site;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Attempt to bypass any measures of the Site designed to prevent or
              restrict access to the Site, or any portion of the{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Site;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Harass, annoy, intimidate, or threaten any of our employees or
              agents engaged in providing any portion of the Site to{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              you;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Delete the copyright or other proprietary rights notice from any{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Content;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Copy or adapt the Site’s software, including but not limited to
              Flash, PHP, HTML, JavaScript, or other{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              code;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Upload or transmit (or attempt to upload or to transmit) viruses,
              Trojan horses, or other material, including excessive use of
              capital letters and spamming (continuous posting of repetitive
              text), that interferes with any party’s uninterrupted use and
              enjoyment of the Site or modifies, impairs, disrupts, alters, or
              interferes with the use, features, functions, operation, or
              maintenance of the{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Site;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Upload or transmit (or attempt to upload or to transmit) any
              material that acts as a passive or active information collection
              or transmission mechanism, including without limitation, clear
              graphics interchange formats (“gifs”), 1×1 pixels, web bugs,
              cookies, or other similar{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              devices (sometimes referred to as “spyware” or “passive collection
              mechanisms” or “
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              pcms
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ”
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              );
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Except as may be the result of standard search engine or Internet
              browser usage, use, launch, develop, or distribute any automated
              system, including without limitation, any spider, robot, cheat
              utility, scraper, or offline reader that accesses the Site, or
              using or launching any unauthorized script or other{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              software;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Use the Site in a manner inconsistent with any applicable laws or
              regulations; or
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Disparage, tarnish, or otherwise harm, in our opinion, us and/or
              the Site.
            </span>
          </li>
        </ol>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            CALL RECORDING AND MONITORING
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            You consent to the recording and monitoring, for quality assurance,
            training, risk management
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and/or collection purposes, of any call that you place with us (or
            our agents, representatives, affiliates, third parties
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or anyone calling on our behalf) or that we (or our agents,
            representatives, affiliates, third parties or anyone calling on our
            behalf) place to you.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            USER
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            -
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            GENERATED CONTRIBUTIONS
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            The Site may allow users to chat, contribute to, or participate in
            blogs, message boards, online forums, and other functionality, and
            may provide you with the opportunity to create, submit, post,
            display, transmit, perform, publish, distribute, or broadcast
            content and materials to us or on the Site, including but not
            limited to text, writings, video, audio, photographs, graphics,
            comments, suggestions
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , reviews
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , or personal information or other material (collectively,
            "Contributions"). Contributions may be viewable by other users of
            the site and through third-party websites. As such, any
            Contributions you transmit are non-confidential and non-proprietary.
            When you create or post any Contributions, you promise and guarantee
            that:
          </span>
        </p>
        <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              The creation, distribution, transmission, public display, or
              performance, and the accessing, downloading, or copying of your
              Contributions do not and will not infringe the proprietary rights,
              including but not limited to the copyright, patent, trademark,
              trade secret, or moral rights of any third
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              party;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              You are the creator and owner of or have the necessary licenses,
              rights, consents, releases, and permissions to use and to
              authorize us, the Site, and other users of the Site to use your
              Contributions in any manner contemplated by the Site and these{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Terms of{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Use
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              You have the written consent, release, and/or permission of each
              and every identifiable individual person in your Contributions to
              use the name or likeness of each and every such identifiable
              individual person to enable inclusion and use of your
              Contributions in any manner contemplated by the Site and these{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Terms of{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Use
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Your Contributions are not false, inaccurate, or{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              misleading;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Your Contributions are not unsolicited or unauthorized
              advertising, promotional materials, pyramid schemes, chain
              letters, spam, mass mailings, or other forms of{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              solicitation;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Your Contributions are not obscene, lewd, lascivious, filthy,
              violent, harassing, libelous, slanderous, or otherwise
              objectionable (as determined by us
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              );
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Your Contributions do not ridicule, mock, bully, disparage,
              intimidate, or abuse{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              anyone;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Your Contributions are not used to harass or threaten (in the
              legal sense of those terms) any other person and to promote
              violence against a specific person or class of{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              people;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Your Contributions do not violate any applicable law, regulation,
              or{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              rule;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Your Contributions do not violate the privacy or publicity rights
              of any third{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              party;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Your Contributions do not contain any material that solicits
              personal information from anyone under the age of 18 or exploits
              people under the age of 18 in a sexual or violent{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              manner;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Your Contributions do not violate any applicable law concerning
              child pornography, or otherwise intended to protect the health or
              well-being of{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              minors;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Your Contributions do not include any offensive comments that are
              connected to race, national origin, gender, sexual preference, or
              physical handicap; or
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 36pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Your Contributions do not otherwise violate, or link to material
              that violates, any provision of these{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Terms of Use
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              , or any applicable law or regulation.
            </span>
          </li>
        </ol>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Any use of the Site in violation of the foregoing violates these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and may result in, among other things,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            termination
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or suspension of your rights to use the Site.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            CONTRIBUTION LICENSE
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            By posting your Contributions to any part of the Site or making
            Contributions accessible to the Site by linking your account from
            the Site to any of your social networking accounts, you
            automatically grant, and you represent and warrant that you have the
            right to grant, to us an unrestricted, unlimited, irrevocable,
            perpetual, non-exclusive, transferable, royalty-free, fully-
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            paid, worldwide right, and license to host, use, copy, reproduce,
            disclose, sell, resell, publish, broadcast, retitle, archive, store,
            cache, publicly perform, publicly display, reformat, translate,
            transmit, excerpt (in whole or in part), and distribute such
            Contributions (including, without limitation, your image and voice)
            for any purpose, commercial, advertising, or otherwise, and to
            prepare derivative works of, or incorporate into other works, such
            Contributions, and grant and authorize sublicenses of the foregoing.
            The use and distribution may occur in any media format and through
            any media channels.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            This license will apply to any form, media, or technology now known
            or hereafter developed, and includes our use of your name, company
            name, and franchise name, as applicable, and any of the trademarks,
            service marks, trade names, logos, and personal and commercial
            images you provide. You waive all moral rights in your
            Contributions, and you warrant that moral rights have not otherwise
            been asserted in your Contributions.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We are not liable for any statements or representations in your
            Contributions provided by you in any area on the Site. You are
            solely responsible for your Contributions to the{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Site
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and you expressly agree to exonerate us from any and all
            responsibility and to refrain from any legal action against us
            regarding your Contributions.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We have the right, in our sole and absolute discretion, (1) to edit,
            redact, or otherwise change any Contributions; (2) to re-categorize
            any Contributions to place them in more appropriate locations on the
            Site; and (3) to pre-screen or delete any Contributions at any time
            and for any reason, without notice. We have no obligation to monitor
            your Contributions.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            GUIDELINES FOR REVIEWS
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We may provide you{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            with{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            areas on the Site{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            or send surveys{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            to leave reviews or ratings. When posting a review, you must comply
            with the following criteria:{" "}
          </span>
        </p>
        <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              You should have firsthand experience with the person/entity being{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              reviewed;
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Your reviews should not contain offensive profanity, or abusive,
              racist, offensive, or hate{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              language;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Your reviews should not contain discriminatory references based on
              religion, race, gender, national origin, age, marital status,
              sexual orientation, or{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              disability;
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Your reviews should not contain references to illegal{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              activity;
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              You should not be affiliated with competitors if posting negative{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              reviews;
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              You should not make any conclusions as to the legality of{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              conduct;
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              You may not post any false or misleading statements; and
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              You may not organize a campaign encouraging others to post
              reviews, whether positive or negative.
            </span>
          </li>
        </ol>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We may accept, reject, or remove reviews
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            at
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            our sole discretion. We have absolutely no obligation to screen
            reviews or to delete reviews, even if anyone considers reviews
            objectionable or inaccurate. Reviews are not endorsed by us and do
            not necessarily represent our opinions or the views of any of our
            affiliates or partners. We do not assume liability for any review or
            for any claims, liabilities, or losses resulting from any review. By
            posting a review, you hereby grant to us a perpetual, non-exclusive,
            worldwide, royalty-free,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            fully-paid
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , assignable, and sublicensable right and license to reproduce,
            modify, translate, transmit by any means, display, perform, and/or
            distribute all content relating to reviews.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            MOBILE APPLICATION LICENSE
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Use License
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            :{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            If you access our services via a mobile application, then we grant
            you a revocable, non-exclusive, non-transferable, limited right to
            install and use the mobile application on wireless electronic
            devices owned or controlled by you, and to access and use the mobile
            application on such devices strictly in accordance with the terms
            and conditions of this mobile application license contained in these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            . You shall not:{" "}
          </span>
        </p>
        <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Decompile, reverse engineer, disassemble, attempt to derive the
              source code of, or decrypt the{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              application;
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Make any modification, adaptation, improvement, enhancement,
              translation, or derivative work from the{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              application;
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Violate any applicable laws, rules, or regulations in connection
              with your access or use of the{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              application;
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Remove, alter, or obscure any proprietary notice (including any
              notice of copyright or trademark) posted by us or the licensors of
              the
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              application;
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Use the application for any revenue
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              -
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              generating endeavor, commercial enterprise, or other purpose for
              which it is not designed or{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              intended;
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Make the application available over a network or other environment
              permitting access or use by multiple devices or users at the same
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              time;
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Use the application for creating a product, service, or software
              that is, directly or indirectly, competitive with or in any way a
              substitute for the{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              application;
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Use the application to send automated queries to any website or to
              send any unsolicited commercial e-mail; or{" "}
            </span>
          </li>
        </ol>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Use any proprietary information or any of our interfaces or our
            other intellectual property in the design, development, manufacture,
            licensing, or distribution of any applications, accessories, or
            devices for use with the application.
          </span>
        </p>
        <h3
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "2pt 0pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            Apple and Android Devices
          </span>
        </h3>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            The following terms apply when you use a mobile application obtained
            from either the Apple Store or Google Play (each an “App
            Distributor”) to access the Site:{" "}
          </span>
        </p>
        <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              T
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              he license granted to you for our mobile application is limited to
              a non-transferable license to use the application on a device that
              utilizes the Apple iOS or Android operating systems, as
              applicable, and in accordance with the usage rules set forth in
              the applicable App Distributor’s{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Terms of{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Use
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ;
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              W
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              e are responsible for providing any maintenance and support
              services with respect to the mobile application as specified in
              the terms and conditions of this mobile application license
              contained in these{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Terms of Use
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              or as otherwise required under applicable law, and you acknowledge
              that each App Distributor has no obligation whatsoever to furnish
              any maintenance and support services with respect to the mobile{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              application;
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              I
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              n the event of any failure of the mobile application to conform to
              any applicable warranty, you may notify the applicable App
              Distributor, and the App Distributor, in accordance with its terms
              and policies, may refund the purchase price, if any, paid for the
              mobile application, and to the maximum extent permitted by
              applicable law, the App Distributor will have no other warranty
              obligation whatsoever with respect to the mobile application;{" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Y
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ou represent and warrant that (
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              i
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ) you are not located in a country that is subject to a U.S.
              government embargo, or that has been designated by the U.S.
              government as a “terrorist supporting” country and (ii) you are
              not listed on any U.S. government list of prohibited or restricted{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              parties;
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Y
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ou must comply with applicable third-party terms of agreement when
              using the mobile application, e.g., if you have a VoIP
              application, then you must not be in violation of their wireless
              data service agreement when using the mobile application; and{" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Y
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ou acknowledge and agree that the App Distributors are third-party
              beneficiaries of the terms and conditions in this mobile
              application license contained in these{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Terms of Use
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              , and that each App Distributor will have the right (and will be
              deemed to have accepted the right) to enforce the terms and
              conditions in this mobile application license contained in these{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Terms of Use
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              against you as a third-party beneficiary thereof.
            </span>
          </li>
        </ol>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            SOCIAL MEDIA
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            As part of the functionality of the Site, you may link your account
            with online accounts you have with third-party service providers
            (each such account, a “Third-Party Account”) by either: (1)
            providing your Third-Party Account login information through the
            Site; or (2) allowing us to access your Third-Party Account, as is
            permitted under the applicable terms and conditions that govern your
            use of each Third-Party Account. You represent and warrant that you
            are entitled to disclose your Third-Party Account login information
            to us and/or grant us access to your Third-Party Account, without
            breach by you of any of the terms and conditions that govern your
            use of the applicable Third-Party Account, and without obligating us
            to pay any fees or making us subject to any usage limitations
            imposed by the third-party service provider of the Third-Party
            Account.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            By granting us access to any Third-Party Accounts, you understand
            that (1) we may access, make available, and store (if applicable)
            any content that you have provided to and stored in your Third-Party
            Account (the “Social Network Content”) so that it is available on
            and through the Site via your account, including without limitation
            any friend lists and (2) we may submit to and receive from your
            Third-Party Account additional information to the extent you are
            notified when you link your account with the Third-Party Account.
            Depending on the Third-Party Accounts you choose and subject to the
            privacy settings that you have set in such Third-Party Accounts,
            personally identifiable information that you post to your
            Third-Party Accounts may be available on and through your account on
            the Site. Please note that if a Third-Party Account or associated
            service becomes unavailable or our access to such Third-Party
            Account is terminated by the third-party service provider, then
            Social Network Content may no longer be available on and through the
            Site. You will have the ability to disable the connection between
            your account on the Site and your{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Third-Party Accounts at any time. PLEASE NOTE THAT YOUR RELATIONSHIP
            WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR
            THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH
            SUCH THIRD-PARTY SERVICE PROVIDERS.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We make no effort to review any Social Network Content for any
            purpose, including but not limited to, for accuracy, legality, or
            non-infringement, and we are not responsible for any Social Network
            Content. You acknowledge and agree that we may access your email
            address book associated with a Third-Party Account and your contacts
            list stored on your mobile device or tablet computer solely for
            purposes of identifying and informing you of those contacts who have
            also registered to use the Site. You can deactivate the connection
            between the Site and your Third-Party Account by contacting us using
            the contact information below or through your account settings (if
            applicable). We will attempt to delete any information stored on our
            servers that was obtained through such{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            a{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Third-Party Account, except the username and profile picture that
            become associated with your account.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            SUBMISSIONS
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            You acknowledge and agree that any questions, comments, suggestions,
            ideas, feedback, or other information regarding the Site
            ("Submissions") provided by you to us are non-confidential and{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            shall
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            become our sole property. We shall own exclusive rights, including
            all intellectual property rights, and shall be entitled to the
            unrestricted use and dissemination of these Submissions for any
            lawful purpose, commercial or otherwise, without acknowledgment or
            compensation to you. You hereby waive all moral rights to any such
            Submissions, and you hereby warrant that any such Submissions are
            original{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            with
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            you or that you have the right to submit such Submissions. You agree
            there shall be no recourse against us for any alleged or actual
            infringement or misappropriation of any proprietary right in your
            Submissions.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            NON-TRANSFERABILITY
          </span>
        </h1>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "normal",
            }}
          >
            Except as otherwise permitted under the Terms of Use, benefits under
            this Agreement may not be sold, transferred, assigned, pledged,
            attached, or otherwise encumbered. No assignment or transfer of any
            benefits of this Agreement, including but not limited to sales
            leads, professional contacts, and/or of the rights represented
            thereby, whether voluntary or involuntary, by operation of law or
            otherwise (except to a designated beneficiary, upon death, by will
            or the laws of descent and distribution), shall vest in any assignee
            or transferee any interest or right herein whatsoever.
          </span>
        </h1>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            ASSIGNMENT
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 12pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            This Agreement and{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            all of
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            the provisions hereof shall be binding upon and inure to the benefit
            of the parties hereto and their respective successors and permitted
            assigns.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            SITE MANAGEMENT
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We reserve the right, but not the obligation, to (1) monitor the
            Site for violations of these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ; (2) take appropriate legal action against anyone who, in our sole
            discretion, violates the law or these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , including without limitation, reporting such user to law
            enforcement authorities; (3) in our sole discretion and without
            limitation, refuse, restrict access to, limit the availability of,
            or disable (to the extent technologically feasible) any of your
            Contributions or any portion thereof; (4) in our sole discretion and
            without limitation, notice, or liability, to remove from the Site or
            otherwise disable all files and content that are excessive in size
            or are in any way burdensome to our systems; and (5) otherwise
            manage the Site in a manner designed to protect our rights and
            property and to facilitate the proper functioning of the Site.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            PRIVACY POLICY
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            cares about data privacy and security for all users. By using the
            Site, you agree to be bound by our Privacy Policy posted on the Site{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            which
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            is incorporated into these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            . Please be advised the Site is hosted in the United States. If you
            access the site from any other region of the world with laws or
            other requirements governing personal data collection, use, or
            disclosure that differ from applicable laws in the United States,
            then through your continued use of the Site, you are transferring
            your data to the United States, and you expressly consent to have
            your data transferred to and processed in the United States.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Your Registration Obligations: You may be required to register with{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            in order to
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            access and use certain features of the Service. If you choose to
            register for the Service, you agree to provide and maintain true,
            accurate, current
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and complete information about yourself as prompted by the Service’s
            registration form. Registration data and certain other information
            about you are governed by{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            the{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Privacy Policy. If you are under 18 years of age, you are not
            authorized to use the Service, with or without registering.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            You are responsible for maintaining the confidentiality of your
            password and account, if any, and are fully responsible for{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            any and all
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            activities that occur under your password or account. You agree to
            (a) immediately notify{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            of any unauthorized use of your password or account or any other
            breach of security, and (b) ensure that you exit from your account
            at the end of each session when accessing the Service.{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            will not be liable for any loss or damage arising from your failure
            to comply with this Section.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            COPYRIGHT INFRINGEMENTS
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            respects the intellectual property rights of others. If you believe
            that any material available on or through the Site infringes upon
            any copyright you own or control, please immediately notify us using
            the contact information provided below (a “Notification”). A copy of
            your Notification will be sent to the person who posted or stored
            the material addressed in the Notification. Please be advised that
            pursuant to applicable law you may be held liable for damages if you
            make material misrepresentations in a Notification. Thus, if you are
            not sure that material located on or linked to by the Site infringes
            your copyright, you should consider first contacting an attorney.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            TERM AND TERMINATION
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            These{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            shall remain in full force and effect while you use the Site.
            WITHOUT LIMITING ANY OTHER PROVISION OF THESE{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            TERMS OF USE
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE
            OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING
            CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
            REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
            REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            TERMS OF USE
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            OR OF ANY APPLICABLE LAW OR{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE
            OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU
            POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            If we terminate or suspend your account for any reason, you are
            prohibited from registering and creating a new account under your
            name, a fake or borrowed name, or the name of any third party, even
            if you may be acting on behalf of the third party. In addition to
            terminating or suspending your account, we reserve the right to take
            appropriate legal action, including without limitation pursuing
            civil, criminal, and injunctive redress.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            MODIFICATIONS AND INTERRUPTIONS
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We reserve the right to change, modify, or remove the contents of
            the Site at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any
            information on our Site. We also reserve the right to modify or
            discontinue all or part of the Site without notice at any time. We
            will not be liable to you or any third party for any modification,
            price change, suspension, or discontinuance of the Site.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We cannot guarantee the Site will{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            be available at all times
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            . We may experience hardware, software, or other problems or need to
            perform maintenance related to the Site, resulting in interruptions,
            delays, or errors. We reserve the right to change, revise, update,
            suspend, discontinue, or otherwise modify the Site at any time or
            for any reason without notice to you. You agree that we have no
            liability whatsoever for any loss, damage, or inconvenience caused
            by your inability to access or use the Site during any downtime or
            discontinuance of the Site. Nothing in these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            will be construed to obligate us to maintain and support the Site or
            to supply any corrections, updates, or releases in connection
            therewith.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            DISPUTE RESOLUTION
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            Binding Arbitration:
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            If the Parties are unable to resolve any Dispute through informal
            negotiations, the Dispute will be finally and exclusively resolved
            by binding arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION,
            YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The
            arbitration shall be commenced and conducted under the Commercial
            Arbitration Rules of the American Arbitration Association ("AAA")
            and, where appropriate, the AAA’s Supplementary Procedures for
            Consumer Related{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Disputes ("AAA Consumer Rules"), both of which are available at the
            AAA website -&nbsp;{" "}
          </span>
          <a style={{ color: "#000000" }} href='http://www.adr.org/'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                textDecoration: "underline",
              }}
            >
              www.adr.org
            </span>
          </a>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            . Your arbitration fees and your share of arbitrator compensation
            shall be governed by the AAA Consumer Rules and, where appropriate,
            limited by the AAA Consumer Rules. If such costs are determined by
            the arbitrator to be excessive, we will pay all arbitration fees and
            expenses. The arbitration may be conducted in person, through the
            submission of documents, by phone, or online. The arbitrator will{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            make a decision
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            in writing but need not provide a statement of reasons unless
            requested by either Party. The arbitrator must follow applicable
            law, and any award may be challenged if the arbitrator fails to do
            so. Except where otherwise required by the applicable AAA rules or
            applicable law, the arbitration will take place in Los Angeles,
            California. Except as otherwise provided herein, the Parties may
            only litigate in court to compel arbitration, stay proceedings
            pending arbitration, or confirm, modify, vacate, or enter judgment
            on the award entered by the arbitrator.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            If for any reason, a Dispute proceeds in court rather than
            arbitration, the Dispute shall be commenced or prosecuted in the
            state and federal courts located in Los Angeles, California, and the
            Parties hereby consent to, and waive all defenses of lack of
            personal jurisdiction, and{" "}
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            forum non{" "}
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            conveniens
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            with respect to venue and jurisdiction in such state and federal
            courts. Application of the United Nations Convention on Contracts
            for the International Sale of Goods and the Uniform Computer
            Information Transaction Act (UCITA) are excluded from these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            In no event shall any Dispute brought by either Party related in any
            way to the Site be commenced more than one (1) year after the cause
            of action arose. If this provision is found to be illegal or
            unenforceable, then neither Party will elect to arbitrate any
            Dispute falling within that portion of this provision found to be
            illegal or unenforceable and such Dispute shall be decided by a
            court of competent jurisdiction within the courts listed for
            jurisdiction above, and the Parties agree to submit to the personal
            jurisdiction of that court.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            Restrictions:&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            The Parties agree that any arbitration shall be limited to the
            Dispute between the Parties individually to the full extent
            permitted by law and that (a) no arbitration shall be joined with
            any other proceeding; (b) there is no right or authority for any
            Dispute to be arbitrated on a class-action basis or to utilize class
            action procedures; and (c) there is no right or authority for any
            Dispute to be brought in a purported representative capacity on
            behalf of the general public or any other persons.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            YOU AND{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            DENTIRATE
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN
            INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
            PURPORTED CLASS OR REPRESENTATIVE ACTION OR PROCEEDING. UNLESS BOTH
            YOU AND{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            DENTIRATE
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE
            THAN ONE PERSON’S OR PARTY’S CLAIMS AND MAY NOT OTHERWISE PRESIDE
            OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS
            PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING
            MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE
            INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO
            PROVIDE RELIEF NECESSITATED BY THAT PARTY’S INDIVIDUAL CLAIM(S),
            EXCEPT THAT YOU MAY PURSUE A CLAIM FOR AND THE ARBITRATOR MAY AWARD
            PUBLIC INJUNCTIVE RELIEF UNDER APPLICABLE LAW TO THE EXTENT REQUIRED
            FOR THE ENFORCEABILITY OF THIS PROVISION.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            is always interested in resolving disputes amicably and efficiently,
            and{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            all
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            customer concerns can be resolved quickly and to the customer’s
            satisfaction by emailing{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            a written Notice of Dispute (“Notice”)
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            to{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            customer support at{" "}
          </span>
          <a style={{ color: "#0563c1" }} href='mailto:Support@Dentirate.com'>
            <span
              style={{
                color: "#0563c1",
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                textDecoration: "underline",
              }}
            >
              Support@Dentirate.com
            </span>
          </a>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            . A
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            party who intends to seek arbitration must first send
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            the Notice
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            to{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            customer support at{" "}
          </span>
          <a style={{ color: "#0563c1" }} href='mailto:Support@Dentirate.com'>
            <span
              style={{
                color: "#0563c1",
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                textDecoration: "underline",
              }}
            >
              Support@Dentirate.com
            </span>
          </a>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            . The Notice must (
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            i
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ) describe the nature and basis of the claim or dispute and (ii) set
            forth the specific relief sought. If{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and you do not resolve the claim within sixty (60) calendar days
            after the Notice is received, you or{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            may commence an arbitration proceeding. During the arbitration, the
            amount of any settlement offer made by{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or you shall not be disclosed to the arbitrator until after the
            arbitrator determines the amount, if any, to which you or{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            is entitled.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            If a court or the arbitrator decides that any term or provision of{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            the
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            Arbitration{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            provision
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            (other than “Prohibition of Class and Representative Actions and
            Non-Individualized Relief” above) is invalid or unenforceable, the
            parties agree to replace such term or provision with a term or
            provision that is valid and enforceable and that comes closest to
            expressing the intention of the invalid or unenforceable term or
            provision, and th
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            e
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            Arbitration{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            provision
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            shall be enforceable as so modified. If a court or the arbitrator
            decides that any of the provisions of “Prohibition of Class{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            and Representative Actions and Non-Individualized Relief” are
            invalid or unenforceable, then the entirety of{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            th
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            e
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            Arbitration{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            provision
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            shall be null and void, unless such provisions are deemed to be
            invalid or unenforceable solely with respect to claims for public
            injunctive relief. The remainder of the{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            will continue to apply.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            Choice of Law; Disputes:
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            &nbsp; These{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            are governed by the laws of the State of California, without giving
            effect to its conflict of{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            laws
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            provisions. You agree to submit to the personal and exclusive
            jurisdiction and venue in the state and federal courts sitting in
            Los Angeles County, California for{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            any and all
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            disputes, claims
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and actions arising from or in connection with
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and/or these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            operates the services from its offices in California, and we make no
            representation that the services are appropriate or available for
            use in other locations.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            CORRECTIONS
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            There may be information on the Site that contains typographical
            errors, inaccuracies, or omissions, including descriptions, pricing,
            availability, and various other information. We reserve the right to
            correct any errors, inaccuracies, or omissions and to change or
            update the information on the Site at any time, without prior
            notice.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            INDEMNIFICATION
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            You agree to defend, indemnify, and hold harmless, including{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ’s
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys’ fees
            and expenses, made by any third party due to or arising out of (1)
            your Contributions; (2) use of the Site; (3) breach of these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ; (4) any breach of your representations and warranties set forth in
            these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ; (5) your violation of the rights of a third party, including but
            not limited to intellectual property rights; or (6) any overt
            harmful act toward any other user of the Site with whom you
            connected via the Site. Notwithstanding the foregoing, we reserve
            the right, at your expense, to assume the exclusive defense and
            control of any matter for which you are required to indemnify us,
            and you agree to cooperate, at your expense, with our defense of
            such claims. We will use reasonable efforts to notify you of any
            such claim, action, or proceeding which is subject to this
            indemnification upon becoming aware of it.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            USER DATA
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We will maintain certain data that you transmit to the Site for the
            purpose of managing the performance of the Site, as well as data
            relating to your use of the Site. Although we perform regular
            routine backups of data, you are solely responsible for all data
            that you transmit or that relates to any activity you have
            undertaken using the Site. You agree that we shall have no liability
            to you for any loss or corruption of any such data, and you hereby
            waive any right of action against us arising from any such loss or
            corruption of such data.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Visiting the Site, sending us emails, and completing online forms
            constitute electronic communications. You consent to receive
            electronic communications, and you agree that all agreements,
            notices, disclosures, and other communications we provide to you
            electronically, via email
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and on the Site, satisfy any legal requirement that such
            communication be in writing. YOU HEREBY AGREE TO THE USE OF
            ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ELECTRONIC
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED
            OR COMPLETED BY US OR VIA THE SITE. You hereby waive any rights or
            requirements under any statutes, regulations, rules, ordinances, or
            other laws in any jurisdiction which require an original signature
            or delivery or retention of non-electronic records, or to payments
            or the granting of credits by any means other than electronic means.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            TCPA Consent
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            :
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            DENTIRATE
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            WILL NOT BE LIABLE FOR ANY DATA STORAGE OR TRANSFER CHARGES YOU
            INCUR FROM YOUR MOBILE DATA/TELEPHONE PROVIDER FOR USING OR
            ACCESSING OUR SITE OR SERVICES. By using{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate.com
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and agreeing to these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , you give consent to receiving calls and messages, including{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            auto-dialed
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and pre-recorded message calls, and SMS messages (including text
            messages) from us, our affiliates, agents
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and others calling, to any telephone numbers that you have provided
            or may provide in the future (including any cellular telephone
            numbers). Your cellular or mobile telephone provider will charge you
            according to the type of plan you carry.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Additional Mobile Technology Requirements:
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            DENTIRATE
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            WILL NOT BE LIABLE FOR ANY DATA STORAGE OR TRANSFER CHARGES YOU
            INCUR FROM YOUR MOBILE{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            DATA/TELEPHONE PROVIDER FOR USING OR ACCESSING OUR SITE OR SERVICES.
            If you are accessing our Site and the Disclosures electronically via
            a mobile device (such as a smartphone or tablet), in addition to the
            above requirements you must make sure that you have software on your
            mobile device that allows you to print and save the Disclosures
            presented to you during the application process. These applications
            can be found for most mobile devices in the device’s respective “app
            store”. If you do not have these capabilities on your mobile device,
            please access our Site through a device that provides these
            capabilities.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            CONSENTING TO DO BUSINESS ELECTRONICALLY: BEFORE YOU DECIDE TO DO
            BUSINESS ELECTRONICALLY WITH{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            DENTIRATE
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            OR OUR AFFILIATES, YOU SHOULD CONSIDER WHETHER YOU HAVE THE REQUIRED
            HARDWARE AND SOFTWARE CAPABILITIES DESCRIBED BELOW.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Hardware &amp; Software Requirements: In order to access and retain
            Disclosures electronically, you must satisfy the following computer
            hardware and software requirements: access to the Internet; an email
            account and related software capable of receiving email through the
            Internet; supported Web browsing software (three most recent
            versions of Google Chrome, Microsoft Edge or Firefox, Internet
            Explorer version 11 or higher, or Safari version 10 or higher); and
            hardware capable of running this software. These requirements are
            subject to change at any time.&nbsp;{" "}
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            CALIFORNIA USERS AND RESIDENTS
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            If any complaint with us is not satisfactorily resolved, you can
            contact the Complaint Assistance Unit of the Division of Consumer
            Services of the California Department of Consumer Affairs in writing
            at 1625 North Market Blvd., Suite N 112, Sacramento, California
            95834 or by telephone at (800) 952-5210 or (916) 445-1254.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            MISCELLANEOUS
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            These{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and any policies or operating rules posted by us on the Site or{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            with
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            respect to the Site constitute the entire agreement and
            understanding between you and us. Our failure to exercise or enforce
            any right or provision of these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            shall not operate as a waiver of such right or provision. These{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            operate to the fullest extent
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            permissible by law. We may assign any or
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            all of
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            our rights and obligations to others at any time. We shall not be
            responsible or liable for any loss, damage, delay, or failure to act
            caused by any cause beyond our reasonable{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            control. If any provision or part of a provision of these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            is determined to be unlawful, void, or unenforceable, that provision
            or part of the provision is deemed severable from these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and does not affect the validity and enforceability of any remaining
            provisions. There is no joint venture, partnership, employment
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or agency relationship created between you and us
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            as a result of
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or use of the Site. You agree that these
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            will not be construed against us by virtue of having drafted them.
            You hereby waive{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            any and all
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            defenses you may have based on the electronic form of these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and the lack of signing by the parties hereto to execute these{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "108%",
            margin: "12pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            CONTACT US
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            In order to
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            resolve a complaint regarding the Site or to receive further
            information regarding{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            the{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            use of the Site, please contact us at:
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <a style={{ color: "#000000" }} href='mailto:Info@zillmeter.com'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              Support
            </span>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              @
            </span>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              Dentirate
            </span>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              .com
            </span>
          </a>
        </p>
      </div>
    </>
  );
}

export function AdvertiserDisclosureText() {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <p
          style={{
            fontSize: "11pt",
            lineHeight: "108%",
            margin: "0pt 0pt 8pt",
          }}
        >
          <span
            style={{
              color: "#222222",
              fontFamily: "Arial",
              fontSize: "11pt",
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: "bold",
              textTransform: "none",
            }}
          >
            Advertiser Disclosure
          </span>
        </p>
        <p
          style={{
            fontSize: "11pt",
            lineHeight: "108%",
            margin: "0pt 0pt 8pt",
          }}
        >
          <br />
          <span
            style={{
              color: "#222222",
              fontFamily: "Arial",
              fontSize: "11pt",
              fontStyle: "normal",
              fontVariant: "normal",
              textTransform: "none",
            }}
          >
            Dentiate.com operates as an autonomous comparison platform,
            supported by advertising revenues. Our affiliations with certain
            companies can lead to compensation, which may influence the
            presentation and order of their offers on our site. While placement
            may be influenced by compensation received, other considerations
            also play a role, including our website's internal policies, and the
            chances of credit approval for potential applicants. It's crucial to
            note that Dentiate.com doesn't represent an exhaustive list of every
            available financial or credit offering.
          </span>
          <br />
          <span
            style={{
              color: "#222222",
              fontFamily: "Arial",
              fontSize: "11pt",
              fontStyle: "normal",
              fontVariant: "normal",
              textTransform: "none",
            }}
          >
            DentiRate.com provides no guarantees regarding approval or any other
            outcomes related to the services and products featured.
          </span>
          <br />
          <span
            style={{
              color: "#222222",
              fontFamily: "Arial",
              fontSize: "11pt",
              fontStyle: "normal",
              fontVariant: "normal",
              textTransform: "none",
            }}
          >
            We value transparency and would like to disclose that we have
            partnerships with several issuers, including but not limited to
            Alphaeon Credit.
          </span>
          <br />
          <br />
          <span
            style={{
              color: "#222222",
              fontFamily: "Arial",
              fontSize: "11pt",
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              textTransform: "none",
            }}
          >
            Privacy Policy
          </span>
          <br />
          <span
            style={{
              color: "#222222",
              fontFamily: "Arial",
              fontSize: "11pt",
              fontStyle: "normal",
              fontVariant: "normal",
              textTransform: "none",
            }}
          >
            By submitting a request to connect with a provider, you consent to
            be contacted by the selected 3rd party provider and/or Dentiate.com.
            For a deeper understanding of our privacy practices, click here for
            our{" "}
          </span>
          <Link
            style={{
              color: "#0070c0",
              fontFamily: "Arial",
              fontSize: "11pt",
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              textTransform: "none",
            }}
            to={"/privacy-policy"}
          >
            Privacy Policy
          </Link>
          <span
            style={{
              color: "#222222",
              fontFamily: "Arial",
              fontSize: "11pt",
              fontStyle: "normal",
              fontVariant: "normal",
              textTransform: "none",
            }}
          >
            , and here for our{" "}
          </span>
          <span
            style={{
              color: "#0070c0",
              fontFamily: "Arial",
              fontSize: "11pt",
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "underline",
              textTransform: "none",
            }}
            onClick={() => navigate("/terms-of-service")}
          >
            Terms of Use
          </span>
          <span
            style={{
              color: "#222222",
              fontFamily: "Arial",
              fontSize: "11pt",
              fontStyle: "normal",
              fontVariant: "normal",
              textTransform: "none",
            }}
          >
            .
          </span>
          <span
            style={{
              color: "#222222",
              fontFamily: "Arial",
              fontSize: "11pt",
              fontStyle: "normal",
              fontVariant: "normal",
              textTransform: "none",
            }}
          ></span>
        </p>
      </div>
    </>
  );
}

export function PrivacyPolicyText() {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <p
          style={{
            fontSize: "18pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "18pt",
              fontWeight: "bold",
            }}
          >
            - Privacy Policy -
          </span>
        </p>
        <p
          style={{
            fontSize: "18pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "18pt",
              fontWeight: "bold",
            }}
          >
            Dentirate, LLC
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            Last updated September 14, 2023
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Welcome to Dentirate.com!{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Thank you for choosing{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            (
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            “
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,”
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            “
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Company
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,” “
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            we
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,” “
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            us
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,”
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            “
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            our
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ”
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ). We are committed to protecting your personal information and your
            right to privacy.{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            owns and operates the{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Site
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            located
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            at www.
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .com (the "
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Site
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            "),{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            as well as
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            any
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            subsidiary{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Site
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            s, access to any optimized version of the
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Site
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            via a wireless device, and any related mobile application provided
            by us (collectively, the "Platform").{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            In this privacy notice, we seek to explain to you in the clearest
            way possible what information we collect, how we use it
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and what rights you have in relation to it.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            By accessing and using the Platform, you consent to{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            the collection, use, and storage of your information by us in the
            manner described in this Privacy Policy.&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We reserve the right to change and update this Privacy Policy and
            such updated versions will be posted on the Platform.&nbsp; In
            addition to this Privacy Policy, your use of our Site is governed by
            our Terms of{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            (
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              textDecoration: "underline",
            }}
          >
            Dentirate
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              textDecoration: "underline",
            }}
          >
            .com/terms
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ).
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            &nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            When you use any of our services (the "Services"), we appreciate
            that you are trusting us with your personal information. We take
            your privacy very seriously.{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            You are required
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            to{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            take some time to read through{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            our Privacy Policy
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            carefully, as it is important. If there are any terms in this
            privacy notice that you do not agree with,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            you must
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            discontinue{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            the{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            use of our{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Platform and Services
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            immediately.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            This privacy notice applies to all information collected through our
            Services as well{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            as,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            any related services, sales, marketing
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or events.
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            &nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Please read this privacy notice carefully as it will help you
            understand what we do with the information that we collect.
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            If you have any questions or concerns about this privacy notice or
            our practices{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            with regard to
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            your personal information, please contact us at{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Support
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            @
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .com.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "12pt 0pt 0pt 36pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
            textIndent: "-18pt",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            1.
          </span>
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            HOW DO WE USE THE INFORMATION THAT WE COLLEST
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            ?
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We may use the information we collect from you when you register,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            provide
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            them
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            to the dental care provider
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            partners
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , sign up for our newsletter, respond to a survey or marketing
            communication, surf the{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Site
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , or use certain other site features in the following ways:
          </span>
        </p>
        <ul type='disc' style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              To process your transactions
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              To send periodic emails regarding your order or other products and
              services
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              To follow up with them after correspondence (live chat,{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              email
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              or phone inquiries)
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Verifying your identity
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Confirming your location
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Administering your account
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Fulfilling your requests
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Processing your payments
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Carry out, improve, and manage the Services
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Facilitating your movement through Service
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              s
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Facilitating your use of the Service
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              s
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              and/or products or services offered through the{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Services
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Communicating with you by letter, email, text, telephone
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              or other forms of communication
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Providing you with information about
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Dentirate
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              financial institutions
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              dental care providers
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              and/or their businesses, products
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              and services by letter, email, text, telephone
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              or other forms of communication
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Providing you with customer support
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Providing you with information about third-party businesses,
              products
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              and services by letter, email, text, telephone
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              or other forms of communication
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Developing, testing
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              or improving the{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Services
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              and content, features
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              and/or products or services offered via the
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Services
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Identifying or creating new products, services, marketing
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              and/or promotions for{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Dentirate
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              or the{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Services
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Promoting and marketing{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Dentirate
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              , the{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Services
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              , and the products and/or services offered via the{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Services
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Improving user experiences with the
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Services
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Analyzing traffic to and through
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Services
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Analyzing user behavior and activity on or through the{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Services
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Engage in internal research to understand the effectiveness of our
              Services, improve our Services, and better understand our user
              base. If we publish or provide the results of this research to
              others, such research will be presented in a de-identified and
              aggregate form such that individual users cannot be identified
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Monitoring the activities of you and others on or through the{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Services
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Placing and tracking{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              applications
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              for products or services
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Protecting or enforcing{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Dentirate’s
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              rights and properties
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Protecting or enforcing the rights and properties of others (which
              may include you)
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              When required by applicable law, court order
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              or other governmental authority (including, without limitation and
              by way of example only, in response to a subpoena or other legal
              process)
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              We
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              believe in good faith that such use is otherwise necessary or
              advisable (including, without limitation and by way of example
              only, to investigate, prevent, or take legal action against
              someone who may be causing injury to, interfering with, or
              threatening the rights, obligations or properties of{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Dentirate
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              , a user of the{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Services
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              , which may include you, or anyone else who may be harmed by such
              activities or to further{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Dentirate’s
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              legitimate business interests).
            </span>
          </li>
        </ul>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We use, create, and sell such information
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            without any restriction as permitted{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            by applicable law.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "12pt 0pt 0pt 36pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
            textIndent: "-18pt",
          }}
        >
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <a name='_Toc56511674'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              2. WHAT INFORMATION DO WE COLLECT?
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          ></span>
        </h1>
        <h2
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "2pt 0pt 0pt 36pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
            textIndent: "-18pt",
          }}
        >
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;{" "}
          </span>
          <a name='_Toc56511675'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              A. PERSONAL INFORMATION YOU DISCLOSE TO US
            </span>
          </a>
        </h2>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
            textIndent: "18pt",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          >
            In Short:
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            {" "}
            We collect personal information that you provide to us.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We collect personal information that you voluntarily provide to us
            when you register{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            or
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            express an interest in obtaining information about us or our
            products and Services when you participate in activities on the
            (such as by posting messages in our online forums or entering
            competitions,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            contests
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or giveaways) or otherwise when you contact us.
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Additionally, we receive personal data passed to us from dental{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            care{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            providers and our lender partners.
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            Personal information is any information that identifies or can be
            used on its own or with other information to identify, contact, or
            locate a single person, or to identify an individual in context. If
            you wish to{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            use{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Platform or{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            any of{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            our{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Services, you will be asked to enter your name, email address,
            mailing address, phone number,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            social security number,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            income,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            requested financing amount
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            and
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            other{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            personal{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            details.&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We collect any information you provide when you use the Service or
            participate in various activities related to the Platform,
            including, but not limited to:
          </span>
        </p>
        <h2
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "2pt 0pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <a name='_Toc56511676'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              Personal Data:
            </span>
          </a>
        </h2>
        <ul type='disc' style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Personal information such as your name
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              , date of birth,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              social security number (SSN)
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              and contact data such as{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              mailing address, phone number,{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              and{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              email address
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Your login and password and other account ("Account") registration
              details
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Demographic data such as your gender, hometown, interests,{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              income, marital status,{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              date of birth
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              and zip code
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
        </ul>
        <h2
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "2pt 0pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <a name='_Toc56511677'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              Derivative Data:
            </span>
          </a>
        </h2>
        <ul type='disc' style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Computer, mobile device
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              and/or browser information (e.g., IP address, mobile device ID
              information, operating system, connection speed, bandwidth,
              browser type, referring/exist
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ing
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              web pages, web page requests, cookie information, hardware
              attributes, software attributes)
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Device location
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Usage activity concerning your interactions with the Service
              and/or third-party websites, networks
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              or applications accessed through the Service (e.g., viewing
              habits, viewing preferences, viewing history, number of clicks on
              a page or feature, amount of time spent on a page or feature,
              identify of third-party websites, networks, etc.)
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
        </ul>
        <h2
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "2pt 0pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <a name='_Toc56511678'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              Financial Data
            </span>
          </a>
        </h2>
        <ul type='disc' style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Electronic signature
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              R
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              equested financing amount
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              C
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              redit score
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              L
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              oan history
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Income
            </span>
          </li>
        </ul>
        <h2
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "2pt 0pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <a name='_Toc56511680'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              Third-Party Data
            </span>
          </a>
        </h2>
        <ul type='disc' style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Third-party website, network, platform, server and/or application
              information (e.g.,{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Google
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              )
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ;
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Information about third parties that you refer to us (e.g., name,
              email, and/or other contact information, relationship)
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
        </ul>
        <h2
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "2pt 0pt 0pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
          }}
        >
          <a name='_Toc56511681'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              Data from Contests, Giveaways, and Surveys
            </span>
          </a>
        </h2>
        <ul type='disc' style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Personal and other information you may provide when entering
              contests or giveaways and/or responding to surveys
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
        </ul>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            The personal information that we collect depends on the context of
            your interactions with us{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            and{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            the choices you make{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            regarding
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            the products and features you use. All personal information that you
            provide to us must be true, complete
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and accurate, and you must notify us of any changes to such personal
            information.
          </span>
        </p>
        <h2
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "2pt 0pt 0pt 36pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
            textIndent: "-18pt",
          }}
        >
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <a name='_Toc56511682'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              B. INFORMATION AUTOMATICALLY COLLECTED
            </span>
          </a>
        </h2>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 0pt 18pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          >
            In Short:
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            {" "}
            Some information — such as your Internet Protocol (IP) address
            and/or browser and device characteristics — is collected
            automatically when you visit our{" "}
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            site or use our apps
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            .
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We automatically collect certain information when you visit, use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or navigate{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            our{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Site
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or mobile applications
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            . This information does not reveal your specific identity (like your
            name or contact information) but may include device and usage
            information, such as your IP address, browser and device
            characteristics, operating system, language preferences, referring
            URLs, device name, country, location, information about how and when
            you use our and other technical information. This information is
            primarily needed to maintain the security and operation of{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and for our internal analytics and reporting purposes.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Like many businesses, we also collect information through cookies
            and similar technologies.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "12pt 0pt 0pt 36pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
            textIndent: "-18pt",
          }}
        >
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <a name='_Toc56511683'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            {" "}
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 0pt 18pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          >
            In Short:
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            Yes, w
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            e
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            {" "}
            share information with your consent, to comply with laws, to provide
            you with services, to protect your rights, or to fulfill
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            our
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            {" "}
            business obligations.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            disclose
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            s
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            your personal information to third parties in connection with the
            provision of our Services or as otherwise permitted or required by
            law. For example, we may disclose your information to:
          </span>
        </p>
        <ul type='disc' style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Our lender partners{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              who{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              collaborate with us to offer{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              personalized
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              credit and loan{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              products
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              and services
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Our third-party and service providers (collectively "vendors")
              that provide services to enable us to provide the Services, such
              as the hosting of the Services, data analysis, IT services and
              infrastructure, customer service, e-mail delivery, and other
              similar services
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Our{" "}
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              vendors that
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              provide services to enable us to run our business and
              administrative operations, such as legal and financial advisory
              services, auditing services, analytics
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              ,
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              and similar services
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Our vendors that provide services to enable us to promote and
              advertise the Services and the products and/or services offered
              via the Services, such as ad platforms or ad-retargeting services,
              as well as comply with contact removal requests or requirements,
              such as mailing list removal services, do not call registries, and
              similar services
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
          </li>
          <li
            style={{
              fontFamily: "serif",
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 28.52pt",
              paddingLeft: "7.48pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              Vendors
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
              as we believe necessary or appropriate to comply with applicable
              laws
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              .
            </span>
            <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
              {" "}
            </span>
          </li>
        </ul>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            disclose{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            your information for any purpose not prohibited by applicable law.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 8pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "normal",
            }}
          >
            This Privacy Policy does not address or apply to, and we are not
            responsible for, the privacy, information
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "normal",
            }}
          >
            ,
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "normal",
            }}
          >
            {" "}
            or other practices of any third parties, including, without
            limitation, the Providers, the manufacturer of your mobile device,
            and any other third-party mobile application or website to which our
            Services may{" "}
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "normal",
            }}
          >
            contain
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "normal",
            }}
          >
            {" "}
            a link. These third parties may at times gather information from or
            about you. We do not control and are not responsible for the privacy
            practices of these third parties. We encourage you to review{" "}
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "normal",
            }}
          >
            each third
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "normal",
            }}
          >
            {" "}
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "normal",
            }}
          >
            party’s Privacy Policy.
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: "normal",
              textTransform: "none",
            }}
          >
            Here are the conditions of use and privacy policy of our major
            partners that you should review:
          </span>
        </p>
        <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              lineHeight: "150%",
              margin: "0pt 0pt 0pt 32pt",
              paddingLeft: "4pt",
              textAlign: "justify",
              textIndent: "0pt",
            }}
          >
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontStyle: "normal",
                fontVariant: "normal",
                fontWeight: "normal",
                textTransform: "none",
              }}
            >
              Alphaeon
            </span>
            <ol type='a' style={{ margin: "0pt", paddingLeft: "0pt" }}>
              <li
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: "12pt",
                  lineHeight: "150%",
                  margin: "0pt 0pt 0pt 31.33pt",
                  paddingLeft: "4.67pt",
                  textAlign: "justify",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: "12pt",
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: "normal",
                    textTransform: "none",
                  }}
                >
                  Terms and Use at{" "}
                </span>
                <a
                  style={{ color: "#0563c1" }}
                  href='https://www.breadfinancial.com/en/terms-of-service.html'
                >
                  <span
                    style={{
                      color: "#0563c1",
                      fontFamily: '"Times New Roman"',
                      fontSize: "12pt",
                      fontStyle: "normal",
                      fontVariant: "normal",
                      fontWeight: "normal",
                      textDecoration: "underline",
                      textTransform: "none",
                    }}
                  >
                    https://www.breadfinancial.com/en/terms-of-service.html
                  </span>
                </a>
              </li>
              <li
                style={{
                  fontFamily: '"Times New Roman"',
                  fontSize: "12pt",
                  lineHeight: "150%",
                  margin: "0pt 0pt 8pt 32pt",
                  paddingLeft: "4pt",
                  textAlign: "justify",
                  textIndent: "0pt",
                }}
              >
                <span
                  style={{
                    fontFamily: '"Times New Roman"',
                    fontSize: "12pt",
                    fontStyle: "normal",
                    fontVariant: "normal",
                    fontWeight: "normal",
                    textTransform: "none",
                  }}
                >
                  Privacy Policy at{" "}
                </span>
                <a
                  style={{ color: "#0563c1" }}
                  href='https://www.breadfinancial.com/en/privacy.html'
                >
                  <span
                    style={{
                      color: "#0563c1",
                      fontFamily: '"Times New Roman"',
                      fontSize: "12pt",
                      fontStyle: "normal",
                      fontVariant: "normal",
                      fontWeight: "normal",
                      textDecoration: "underline",
                      textTransform: "none",
                    }}
                  >
                    https://www.breadfinancial.com/en/privacy.html
                  </span>
                </a>
              </li>
            </ol>
          </li>
        </ol>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            may need to process your data or share your personal information in
            the following situations:{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <a name='_Toc56511684'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              Business Transfers
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            .
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We may share or transfer your information in connection with or
            during negotiations{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            of,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            any merger, sale of company assets, financing, or acquisition of all
            or a portion of our business to another company.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <a name='_Toc56511685'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              Affiliates
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            .{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We may share your information with our affiliates, in which case we
            will require those affiliates to honor this privacy notice.
            Affiliates include our parent company and any subsidiaries, joint
            venture partners
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or other companies that we control or that are under common control{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            directly or indirectly{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            with us.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <a name='_Toc56511686'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              Business Partners
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            .
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We share your information with our business partners{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            (e.g., lenders)
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            to offer you certain products, services
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or promotions.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <a name='_Toc56511687'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              Other Users
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            .{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            When you share{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            some{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            personal information or otherwise interact with public areas of{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            such personal information may be viewed by all users and may be
            publicly made available outside the in perpetuity. If you interact
            with other users of our{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Site
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or other Services
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            through a social network (such as Facebook), your contacts on the
            social network will see your name, profile photo, and descriptions
            of your activity. Similarly, other users will be able to view
            descriptions of your activity, communicate with you within our{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            platform
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , and view your profile.{" "}
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "12pt 0pt 0pt 36pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
            textIndent: "-18pt",
          }}
        >
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <a name='_Toc56511688'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            {" "}
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 0pt 18pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          >
            In Short:
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            Yes, w
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            e use cookies and other tracking technologies to collect and store
            your information.{" "}
          </span>
        </p>
        <p style={{ margin: "0pt 0pt 12pt" }}>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Like most websites, we use the various technologies to automatically
            collect the following types of information about
            you:&nbsp;&nbsp;&nbsp;{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 0pt 36pt",
            textAlign: "justify",
            textIndent: "-18pt",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            •
          </span>
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Cookies
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , which
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            are small data files (e.g., text files) stored on the browser or
            device you use to view a website or message. They may help store
            user preferences and activity and may allow a website to recognize a
            particular browser or device. There are several types of cookies,
            including, for example, browser cookies, session cookies, and
            persistent cookies. Cookies may record information you access on one
            page of a website to simplify subsequent interaction with that
            website, or to help streamline your transactions on related pages of
            that website. Most major browsers are set up so that they will
            initially accept cookies, but you might be able to adjust your
            browser's or device's preferences to issue you an alert when a
            cookie is downloaded, or to block, reject, disable, delete or manage
            the use of some or all cookies on your browser or device. Cookies
            can be set by the website owner (i.e., us), or they can be set by
            third parties (e.g., Facebook, Google, etc.) Cookies are used to
            help us speed up your future activities or to improve your
            experience by remembering the information that you have already
            provided to us. Third
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            -
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            party cookies may also be used to enable analytics (
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            e.g.
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            Google Analytics) or advertising functionality (e.g., ad
            re-targeting on third-party websites) that enables more customized
            services and advertising by tracking your interaction with our
            Services and collecting information about how you use the Services.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 0pt 36pt",
            textAlign: "justify",
            textIndent: "-18pt",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            •
          </span>
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Flash cookies, which are cookies written using Adobe Flash, and
            which may be permanently stored on your device. Like regular
            cookies, Flash cookies may help store user preferences and activity
            and may allow a website to recognize a particular browser or device.
            Flash cookies are not managed by the same browser settings that are
            used for regular cookies.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 0pt 36pt",
            textAlign: "justify",
            textIndent: "-18pt",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            •
          </span>
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Web beacons, which are pieces of code embedded in a website or email
            to monitor your activity on the website or your opening of the
            email, and which can pass along information such as the IP address
            of the computer or device you use to view the website or open the
            email, the URL page on which the web beacon is located, the type of
            browser that was used
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            to access the website, and previously set cookie values. Web beacons
            are sometimes used to collect advertising data, such as counting
            page views, promotion views
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or advertising responses. Disabling your computer's, device's
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or browser's cookies may prevent some web beacons from tracking or
            recording certain information about your activities.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 0pt 36pt",
            textAlign: "justify",
            textIndent: "-18pt",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            •
          </span>
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Scripts, which are pieces of code embedded in a website to define
            how the website behaves in response to certain key or click requests
            sent by the user. Scripts are sometimes used to collect information
            about the user's interactions with the website, such as the links
            the user clicks on. Scripts are often times temporarily downloaded
            to the user's computer or device from the website server, active
            only while the user is connected to the Site and/or the App, and
            deactivated or deleted when the user disconnects from the website.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 0pt 36pt",
            textAlign: "justify",
            textIndent: "-18pt",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            •
          </span>
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Analytic tools and services, which are sometimes offered by third
            parties, and which track, measure
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and/or generate information about a website's or program's traffic,
            sales, audience
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and similar information, and which may be used for various reasons,
            such as, for example, statistical research, marketing research, and
            content rating research, and conversion tracking. Examples of the
            analytic tools and services which we might use include Google
            Analytics, SDK tools, and{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Taplytics
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            . we may also use other third-party analytic tools and services.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "12pt 0pt 0pt 36pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
            textIndent: "-18pt",
          }}
        >
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <a name='_Toc56511689'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              5. CONTROLS FOR DO-NOT-TRACK FEATURES
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          ></span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            no uniform technology standard for recognizing and implementing DNT
            signals has been finalized. As such, we do not currently respond to
            DNT browser signals or any other mechanism that automatically
            communicates your choice not to be tracked online. If a standard for
            online tracking is adopted that we must follow in the future, we
            will inform you about that practice in a revised version of this
            privacy notice.{" "}
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "12pt 0pt 0pt 36pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
            textIndent: "-18pt",
          }}
        >
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <a name='_Toc56511690'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          ></span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 0pt 18pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          >
            In Short:
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            If you choose to register or log in to our services using a social
            account, we may have access to certain information about you.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Our{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Site
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and mobile applications{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            offer you the ability to register and login using your third-party
            social account details (like your Facebook or{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Google
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            logins).{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Where
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            you choose to do this, we will receive certain profile information
            about you from your{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            account{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            service
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            provider. The profile{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            i
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            nformation we receive may vary depending on the
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            account service
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            provider concerned, but will often include your name, email address,
            friends list, profile picture as well as other information you
            choose to make public on such social media platform.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We will use the information we receive only for the purposes that
            are described in this privacy notice or that are otherwise made
            clear to you
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            on
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            the relevant{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Terms of Service{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            (
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              textDecoration: "underline",
            }}
          >
            Dentirate
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              textDecoration: "underline",
            }}
          >
            .com/terms
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            )
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            . Please note that we do not control, and are not responsible for,
            other uses of your personal information by your third-party social
            media provider. We recommend that you review their privacy{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            notice
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            to understand how they collect, use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and share your personal information, and how you can set your
            privacy preferences on their sites and apps.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "12pt 0pt 0pt 36pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
            textIndent: "-18pt",
          }}
        >
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <a name='_Toc56511691'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            {" "}
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 0pt 18pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          >
            In Short:
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            We may transfer, store, and process your information in countries
            other than your own.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Our servers{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            are located in
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            the United States
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            . If you are accessing our
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            Services
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            from outside
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            the United States
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , please be aware that your information may be transferred to,
            stored, and processed by us in our facilities and by those third
            parties with whom we may share your personal information (see "WILL
            YOUR INFORMATION BE SHARED WITH ANYONE?" above), in
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            the{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            United States
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .{" "}
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "12pt 0pt 0pt 36pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
            textIndent: "-18pt",
          }}
        >
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <a name='_Toc56511692'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              8. HOW LONG DO WE KEEP YOUR INFORMATION?
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            {" "}
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 0pt 18pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          >
            In Short:
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            We keep your information for as long as necessary to fulfill the
            purposes outlined in this privacy notice unless otherwise required
            by law.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice unless a
            longer retention period is required or permitted by law (such as
            tax, accounting
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            or other legal requirements). No purpose in this notice will require
            us{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            to keep
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            your personal information for longer{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            than{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            10 years after
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            your account{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            is being
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            inactive
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            &nbsp;
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.{" "}
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "12pt 0pt 0pt 36pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
            textIndent: "-18pt",
          }}
        >
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          <a name='_Toc56511693'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              9. DO WE COLLECT INFORMATION FROM MINORS?
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            {" "}
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
            textIndent: "18pt",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          >
            In Short:
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            No,{" "}
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            w
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            e do not knowingly collect data from{" "}
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            or
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            {" "}
            market to{" "}
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            children under 18 years of age.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We do not knowingly solicit data from{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            or
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            market to children under 18 years of age. By using{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            , you represent that you are at least 18 or that you are the parent
            or guardian of such a minor and consent to such minor dependent’s
            use of the. If we learn that personal information from users less
            than 18 years of age has been collected, we will deactivate the
            account and take reasonable measures to promptly delete such data
            from our records. If you become aware of any{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            data
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            we may have collected from children under
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            the{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            age{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            of{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            18, please contact us at{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Support@
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .com
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "12pt 0pt 0pt 36pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
            textIndent: "-18pt",
          }}
        >
          <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
          <a name='_Toc56511694'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              10. WHAT ARE YOUR PRIVACY RIGHTS?
            </span>
          </a>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
            textIndent: "18pt",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          >
            In Short:
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            &nbsp; You may review, change, or terminate your account at any
            time.&nbsp;{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <a name='_Toc56511695'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              Account Information
            </span>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              :
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            &nbsp;
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our Terms of{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Service
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            and/or comply with applicable legal requirements.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <a name='_Toc56511696'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              Opting out of email marketing:
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          ></span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            You can unsubscribe from our marketing email list at any time by
            clicking on the unsubscribe link in the emails that we send or by
            contacting us using the details provided below. You will then be
            removed from the marketing email list — however, we may still
            communicate with you, for example
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ,
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            to send you service-related emails that are necessary for the
            administration and use of your account, to respond to service
            requests, or for other non-marketing purposes.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            If you are a resident{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            of
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            the European Economic Area and you believe we are unlawfully
            processing your personal information, you also have the right to
            complain to your local data protection supervisory authority. You
            can find their contact details here:
            http://ec.europa.eu/justice/data-
            protection/bodies/authorities/index_en.htm.{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            If you are a resident{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            of
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            Switzerland, the contact{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            details for the data protection authorities are available here:
            https://www.edoeb.admin.ch/edoeb/en/home.html.{" "}
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "12pt 0pt 0pt 36pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
            textIndent: "-18pt",
          }}
        >
          <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
          <a name='_Toc56511697'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            {" "}
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt 0pt 0pt 18pt",
            textAlign: "justify",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          >
            In Short:
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            Yes, if you are a resident of California, you are granted specific
            rights regarding access to your personal information.
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            California Civil Code Section 1798.83, also known as the "Shine The
            Light" law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below.{" "}
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "12pt 0pt 0pt 36pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
            textIndent: "-18pt",
          }}
        >
          <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
          <a name='_Toc56511698'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              12. DO WE MAKE UPDATES TO THIS NOTICE?
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          ></span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
            textIndent: "18pt",
          }}
        >
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
              fontWeight: "bold",
            }}
          >
            In Short:
          </span>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontStyle: "italic",
            }}
          >
            Yes, we will update this notice as necessary to stay compliant with
            relevant laws.{" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.{" "}
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "12pt 0pt 0pt 36pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
            textIndent: "-18pt",
          }}
        >
          <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
          <a name='_Toc56511700'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              13. JURISDICTIONAL ISSUES
            </span>
          </a>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ’s
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            s
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            ervice
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            s
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            are only offered in the United States
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            as described in our Terms
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            of Use
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            . Accordingly, this Privacy Policy, and our collection, use, and
            disclosure of your information,{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            are
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            governed by U.S. law.
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "12pt 0pt 0pt 36pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
            textIndent: "-18pt",
          }}
        >
          <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
          <a name='_Toc56511701'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            {" "}
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it in some circumstances. To
            request to review, update, or delete your personal information,
            please{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            send us
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            an{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            e
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            mail with your request to{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Support@
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .com.
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
            We will respond to your request within
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            6
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            0 days.{" "}
          </span>
        </p>
        <h1
          style={{
            fontSize: "12pt",
            fontWeight: "normal",
            lineHeight: "150%",
            margin: "12pt 0pt 0pt 36pt",
            pageBreakAfter: "avoid",
            pageBreakInside: "avoid",
            textIndent: "-18pt",
          }}
        >
          <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
          <a name='_Toc56511702'>
            <span
              style={{
                fontFamily: '"Times New Roman"',
                fontSize: "12pt",
                fontWeight: "bold",
              }}
            >
              15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </span>
          </a>
          <span
            style={{
              fontFamily: '"Times New Roman"',
              fontSize: "12pt",
              fontWeight: "bold",
            }}
          >
            {" "}
          </span>
        </h1>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            If you have questions or comments about this notice, you may email
            us at{" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Support
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            @
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            Dentirate
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            .com
          </span>
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            {" "}
          </span>
        </p>
        <p
          style={{
            fontSize: "12pt",
            lineHeight: "150%",
            margin: "0pt",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Times New Roman"', fontSize: "12pt" }}>
            &nbsp;
          </span>
        </p>
      </div>
    </>
  );
}

export function ConsentText() {
  const navigate = useNavigate();
  const source = parseInt(getItem("source"));
  const toastId = useRef(null);

  const goAdd = () => {
    // if (source==101) {

    //     toastError("No permission to add Application!", toastId)

    //     setTimeout(() => {
    //         return false
    //     }, 2000);
    // }
    navigate("/account/new-form?typeId=1");
  };
  return (
    <>
      <div style={{ paddingBottom: "68px", marginTop: "20px" }}>
        <p className='consent_title'>
          Consumer Consent Disclosure for Dentirate.com
        </p>
        <p
          className='consent_text'
          style={{ textIndent: "2em", marginBottom: "20px" }}
        >
          By providing your email address and/or mobile phone number, you
          consent to receive electronic communications from{" "}
          <b> Dentirate.com ("we", "our")</b>. Below are details regarding the
          communications you may receive and how to manage your preferences.
        </p>

        <p className='consent_title'>Types of Communications:</p>
        <ul style={{ marginLeft: "2em", marginBottom: "20px" }}>
          <li className='consent_text'>
            <b>Transactional:</b> Information about your financing offers.
          </li>
          <li className='consent_text'>
            <b>Notifications:</b> Reminders and alerts
          </li>
          <li className='consent_text'>
            <b>Marketing:</b> Promotional materials, updates, offers, and
            newsletters
          </li>
          <li className='consent_text'>
            <b>Feedback &amp; Surveys:</b> Requests for feedback and
            participation in surveys or other data-gathering exercises.
          </li>
          <li className='consent_text'>
            <b>General Updates:</b> Any other information we believe may benefit
            or interest you.
          </li>
        </ul>

        <p className='consent_title'>Consent Details:</p>
        <ul style={{ marginLeft: "2em", marginBottom: "20px" }}>
          <li className='consent_text'>
            By providing your <b>email</b>, you permit us to send emails to the
            address you've shared.
          </li>
          <li className='consent_text'>
            By sharing your <b>mobile number</b>, you agree to receive text
            messages from us.
          </li>
        </ul>

        <p className='consent_title'>Message &amp; Data Charges:</p>
        <p
          style={{ marginLeft: "2em", marginBottom: "20px" }}
          className='consent_text'
        >
          Please be aware that certain mobile carriers may charge you for
          receiving our messages. Dentirate.com is not liable for any such
          charges.
        </p>

        <p className='consent_title'>Managing Your Preferences:</p>
        <ul style={{ marginLeft: "2em", marginBottom: "20px" }}>
          <li className='consent_text'>
            <b>Emails:</b> To stop receiving emails, click the “unsubscribe”
            link found at the bottom of our emails.
          </li>
          <li className='consent_text'>
            <b>Texts:</b> Reply with "STOP" to any text message you receive from
            us to opt out.
          </li>
          <li className='consent_text'>
            Please allow up to 10 business days for changes to your preferences
            to take effect.
          </li>
        </ul>

        <p className='consent_title'>Electronic Record Access:</p>
        <p
          style={{ textIndent: "2em", marginBottom: "20px" }}
          className='consent_text'
        >
          Your agreement to this disclosure confirms that you can access this
          consent, other electronic records, and the emails we send.
        </p>

        <p
          style={{ textIndent: "2em", marginBottom: "20px" }}
          className='consent_text'
        >
          For additional details, please review our <b>Terms of Use</b>
          &nbsp; and <b>Privacy Policy</b>.
        </p>
      </div>
      <br></br>
      <br></br>
      <div className='consent_footer'>
        <button
          className='btn btn-primary  ms_auto consent_btn'
          onClick={goAdd}
        >
          Consent
        </button>
      </div>
    </>
  );
}

export function AboutUsText() {
  return (
    <>
      <div>About us</div>
    </>
  );
}

export function TermConditionsText() {
  return (
    <>
      <div>Term Conditions</div>
    </>
  );
}
export function HelpCenterText() {
  return (
    <>
      <div>Help Center</div>
    </>
  );
}
export function CookiesSettingsText() {
  return (
    <>
      <div>Cookies Settings</div>
    </>
  );
}

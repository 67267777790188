import moment from "moment/moment";

function getDateRangeForPeriod(period) {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const day = today.getDate();
  const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)

  let startDate, endDate;
  switch (period) {
    case "WTD":
      startDate = new Date(today);
      startDate.setDate(day - dayOfWeek); // Start of the week (Sunday) //(dayOfWeek === 0 ? -6 : 1)
      break;
    case "MTD":
      startDate = new Date(year, month, 1);
      break;
    case "QTD":
      const quarterStartMonth = Math.floor(month / 3) * 3;
      startDate = new Date(year, quarterStartMonth, 1);
      break;
    case "YTD":
      startDate = new Date(year, 0, 1);
      break;
    default:
      throw new Error("Invalid period. Please use 'MTD', 'QTD', or 'YTD'.");
  }

  return {
    startDate: moment(startDate).format("yyyy-MM-DD"),
    endDate: moment(new Date()).add(1, "days").format("yyyy-MM-DD"),
  };
}
const getDynamicViewType = () => {
  const now = new Date();
  if (now.getDate() > 2) {
    return "MTD";
  }
  if (now.getDay() > 2) {
    return "WTD";
  }

  const startOfYear = new Date(now.getFullYear(), 0, 1);
  const daysSinceStartOfYear = Math.floor((now - startOfYear) / 86400000);
  const currentWeekNumber = Math.ceil(
    (daysSinceStartOfYear + startOfYear.getDay() + 1) / 7
  );
  const quarterStart = new Date(
    now.getFullYear(),
    Math.floor(now.getMonth() / 3) * 3,
    1
  );
  const daysSinceStartOfQuarter = Math.floor(
    (quarterStart - startOfYear) / 86400000
  );
  const weekOfQuarterStart = Math.ceil(
    (daysSinceStartOfQuarter + startOfYear.getDay() + 1) / 7
  );
  if (currentWeekNumber > weekOfQuarterStart) {
    return "QTD";
  }
  return "YTD";
};
export { getDateRangeForPeriod, getDynamicViewType };

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import TitleBar from "../../components/header/titleBar.js";
import { getItem, setUserInfo } from "../../utils/storage.js"
import { Header,LocationDetail } from "../../components/LocationModule/LocationDetail.js";

const Details = ({upStep}) => {
      // const [location, setLocation] = useState({

      // })
      // const [locationId, setLocationId] = useState("")


      //effect
      // useEffect(() => {

      // }, [])




      return (
            <>
                  <div className="layout">
                        <Header  upStep={upStep}/>

                        <div className="main">
                            <LocationDetail/>  
                        </div>
                  </div>




            </>


      );
};

Details.propTypes = {};

export default Details;

import { apiLoadingStatus } from "../infrastructure/constants/api";

const getCommonContextInitialValue = () => {
  const user = {
    loadingStatus: apiLoadingStatus.unloaded,
    data: null,
  };
  return {
    user,
    config: {
      lang: localStorage.getItem("lang") || "en",
    },
    logo: {
      organizationId: null,
      url: null,
    },
  };
};

export default getCommonContextInitialValue;

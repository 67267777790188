import React from "react";
import { CommonContextProvider } from "./common-context";
import CommonAppActions from "./common-app-actions";
import RoutingHandler from "./router/routing-handler";
import "react-toastify/dist/ReactToastify.css";
import "./i18n";
import i18n from "i18next";
import { ToastContainer } from "react-toastify";

const App = () => {
  i18n.changeLanguage("es");
  window.i18n = i18n;
  return (
    // <div className={styles.wrapper}>
    <CommonContextProvider>
      <CommonAppActions>
        <ToastContainer />
        <RoutingHandler />
      </CommonAppActions>
    </CommonContextProvider>
    // </div>
  );
};
export default App;

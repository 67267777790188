import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css"

const NotFindPage=(props)=>{
    const navigate=useNavigate()
    const goBack=()=>{
        navigate('/')
    }

    return (
    
            <div className={`${styles.bg} ${ styles.content_404}`}>
                <div> Please enter the correct link address, or click  
                    <span onClick={goBack} className={`${styles.back}`}>here</span> to return and log in
                 </div>
                     
            </div>
            
        
    )
}
export default NotFindPage
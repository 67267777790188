import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from "jquery";
import KpiReport from "../../components/KpiReport/index.js";
import {
  DownloadCenter,
  KPIDefinitions,
  DashboardText,
  SummaryReport,
  Header,
} from "../../components/DashBoardModule/index.js";
import TitleBar from "../../components/header/titleBar.js";
import { useCommonContext } from "../../common-context/common-context-provider.js";
import { ToastContainer, toast } from "react-toastify";
import Footer from "../../components/Footer/footer.js";
import {
  getDateRangeForPeriod,
  getDynamicViewType,
} from "../../infrastructure/helpers/date-helpers.js";
import Select from "react-select";
import _ from "lodash";
import styles from "./styles.module.scss";

const Dashboard = (props) => {
  const navigate = useNavigate();
  const [{ user }] = useCommonContext();
  const [homepageFilter, setHomepageFilter] = useState({
    viewType: getDynamicViewType(),
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const [searchParams, setSearchParams] = useState({
    userIdCollection: [],
    siteIdCollection: [],
    lenderIdCollection: [],
    cityCollection: [],
    // lenderKpiType: undefined,
    startDate: getDateRangeForPeriod(homepageFilter.viewType).startDate,
    endDate: getDateRangeForPeriod(homepageFilter.viewType).endDate,
    timeZoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const updateSearch = (searchObj) => {
    setSearchParams(searchObj);
  };
  const closePopup = () => {
    $(".popup").removeClass("show");
  };
  const openPopup = () => {
    $(".popup").addClass("show");
  };
  const openFifter = () => {
    $(".offcanvas").addClass("show");
  };

  const delLocation = () => {
    $(".popup").removeClass("show");
  };
  const options = useMemo(() => {
    return [
      { label: "WTD", value: "WTD" },
      { label: "MTD", value: "MTD" },
      { label: "QTD", value: "QTD" },
      { label: "YTD ", value: "YTD" },
    ];
  }, []);
  useEffect(() => {
    const temp = getDateRangeForPeriod(homepageFilter.viewType);
    const newSearchParams = {
      ...searchParams,
      startDate: temp.startDate,
      endDate: temp.endDate,
    };
    if (!_.isEqual(searchParams, newSearchParams)) {
      setSearchParams(newSearchParams);
    }
  }, [homepageFilter.viewType]);

  return (
    <>
      <div className='layout'>
        {/* <Header /> */}
        <TitleBar user={user} title={"Dashboard"} />
        <div className='main'>
          <div className='container'>
            <div className={styles["header-container"]}>
              <div className='dash_kpi_date'>
                {new Date().toLocaleDateString()}
              </div>
              <div className='form_select'>
                <Select
                  // defaultValue={[hcgList[2], hcgList[3]]}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      width: "200px",
                    }),
                  }}
                  name='colors'
                  options={options}
                  value={
                    options.find(
                      (i) => i.value === homepageFilter?.viewType?.toUpperCase()
                    ) || options[0]
                  }
                  className='basic-multi-select'
                  classNamePrefix='select'
                  onChange={(o) => {
                    if (o) {
                      setHomepageFilter({
                        ...homepageFilter,
                        viewType: o?.value,
                      });
                    }
                  }}
                />
              </div>
            </div>
            <KpiReport searchParam={searchParams} updateSearch={updateSearch} />
            {/* <SummaryReport /> */}
            <DownloadCenter
              searchParam={searchParams}
              updateSearch={updateSearch}
            />
            <KPIDefinitions searchParam={searchParams} />
            <DashboardText />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

Dashboard.propTypes = {};

export default Dashboard;

const commaSeperatedValues = (value) =>
  typeof value === "number"
    ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : typeof value === "string"
    ? value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "";
const commaSeperatedDecimal = (value, numberOfDecimals = 2) => {
  return typeof value === "number"
    ? `${commaSeperatedValues(value.toFixed(numberOfDecimals))}`
    : typeof value === "string"
    ? `${commaSeperatedValues(parseFloat(value).toFixed(numberOfDecimals))}`
    : "";
};
const commaSeperatedCurrency = (value, numberOfDecimals = 2) => {
  if (value >= 0) {
    return `$${commaSeperatedDecimal(value, numberOfDecimals)}`;
  }
  return `$(${commaSeperatedDecimal(Math.abs(value), numberOfDecimals)})`;
};

export { commaSeperatedCurrency, commaSeperatedDecimal, commaSeperatedValues };

import React, { useState, useRef } from "react";
import { ToastContainer } from 'react-toastify';
import { PatientStartPage, Logo,PatientNextPage } from "../../components/PatientModule/PatientStart.js";
import { PatientStartErrorPage } from "../../components/PatientModule/PatientStartError";

const PatientStart = (props) => {
  const [step, setStep] = useState("start")
  const upStep = (step) => {
    setStep(step)
  }
  return (
    <>
      <ToastContainer limit={1} />
       <div className="container">
        <div className="login_container applicant_container">
          <Logo />
          {step == "start" &&  <PatientStartPage upStep={upStep}/>}
          {step == "next" &&  <PatientNextPage upStep={upStep}/>}
          {step == "error" && <PatientStartErrorPage upStep={upStep}/>}
        </div>
      </div>
    </>




  );
};

PatientStart.propTypes = {};

export default PatientStart;

import React, { useEffect, useState } from "react";
import { useCommonContext } from "../common-context";
import { IntlProvider } from "react-intl";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";

import En from "../locals/en";
import Es from "../locals/es";

import Login from "../pages/login";
import ForgotPwd from "../pages/forgotPassword";
import ForgotPwd2 from "../pages/forgotPassword2";
import Email from "../pages/email";
import NewPassword from "../pages/newPassword";
import Home from "../pages/home";
import Applications from "../pages/applications";
import Consent from "../pages/applications/consent";
import Locations from "../pages/locations";
import UserManagement from "../pages/user-management";
import UserProfile from "../pages/userProfile";
import NewForm from "../pages/newForm";
import ChangePassword from "../pages/changePassword";
import PatientConsent from "../pages/patientConsent";
import PatientStart from "../pages/patientStart";
import PatientStartError from "../pages/patientStartError";
import PatientHome from "../pages/patientHome";
import Billing from "../pages/billing";
import BillingDetail from "../pages/billingDetail";
import Dashboard from "../pages/dashboard";
import Leads from "../pages/Leads";
import Details from "../pages/LocationDetails";
import Test from "../pages/Test";
import AccountUpdate from "../pages/user-management/accountUpdate";
import AdvertiserDisclosure from "../pages/Advertiser_Disclosure";
import PrivacyPolicy from "../pages/Privacy_Policy";
import AboutUs from "../pages/AboutUs";
import CookiesSettings from "../pages/CookiesSettings";
import HelpCenter from "../pages/HelpCenter";
import TermConditions from "../pages/TermConditions";
import PatientStartNext from "../pages/patientStartNext";

import TermService from "../pages/termService";
import NotFind from "../pages/404";
import Temp from "../pages/temp";
import AuthorizedRoutes from "./authorized-routes";

const RoutingHandler = (props) => {
  // for example, if you need user, load it from here:
  const [{ user, config }] = useCommonContext();
  const [langInfo, setLangInfo] = useState({
    ...En,
  });
  let flag = !!user.loadingStatus || !!localStorage.getItem("isLogin");

  useEffect(() => {
    switch (config.lang) {
      case "es":
        setLangInfo({ ...Es });
        break;
      default:
        setLangInfo({ ...En });
        break;
    }
  }, [config]);

  return (
    <IntlProvider messages={langInfo} locale={config.lang}>
      <BrowserRouter>
        <AuthorizedRoutes />
      </BrowserRouter>
    </IntlProvider>
  );
  return (
    <IntlProvider messages={langInfo} locale={config.lang}>
      <BrowserRouter>
        <Routes>
          {/* React v6!!!!  */}
          <Route
            path='/'
            element={flag ? <Home /> : <Navigate to='/account/login' replace />}

            // element={<Navigate to='/' replace />}
          />
          <Route path='/temp' element={<Temp />} />
          <Route path='/account/login' element={<Login />} />
          <Route path='/account/recovery' element={<ForgotPwd />} />
          {/* <Route path='/account/recovery/change' element={<ForgotPwd2 />} /> */}
          {/* <Route path='/account/send-email/:code' element={<Email />} /> */}
          <Route
            path='/account/update-password/:code'
            element={<NewPassword />}
          />
          <Route
            path='/account/account-update/:code'
            element={<AccountUpdate />}
          ></Route>
          <Route path='/application/:id' element={<PatientStart />} />
          <Route path='/account/patient-next' element={<PatientStartNext />} />
          <Route
            path='/account/patient-start-error'
            element={<PatientStartError />}
          />
          <Route path='/terms-of-service' element={<TermService />} />
          <Route
            path='/advertiser-disclosure'
            element={<AdvertiserDisclosure />}
          />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/cookies-settings' element={<CookiesSettings />} />
          <Route path='/help-center' element={<HelpCenter />} />
          <Route path='/terms-conditions' element={<TermConditions />} />
          <Route
            path='/'
            element={flag ? <Home /> : <Navigate to='/account/login' replace />}
          />
          <Route
            path='/applications'
            element={
              <>
                {flag ? (
                  <Applications />
                ) : (
                  <Navigate to='/account/login' replace />
                )}
              </>
            }
          />
          {/* <Route
            path='/account/consent'
            element={
              <>
                {flag ? <Consent /> : <Navigate to='/account/login' replace />}
              </>
            }
          /> */}

          <Route
            path='/locations'
            element={
              <>
                {flag ? (
                  <Locations />
                ) : (
                  <Navigate to='/account/login' replace />
                )}
              </>
            }
          />
          <Route
            path='/user-management'
            element={
              <>
                {flag ? (
                  <UserManagement />
                ) : (
                  <Navigate to='/account/login' replace />
                )}
              </>
            }
          />

          <Route
            path='/user-profile'
            element={
              <>
                {flag ? (
                  <UserProfile />
                ) : (
                  <Navigate to='/account/login' replace />
                )}
              </>
            }
          />
          <Route
            path='/account/new-form'
            element={
              <>
                {flag ? <NewForm /> : <Navigate to='/account/login' replace />}
              </>
            }
          />
          <Route
            path='/account/change-password'
            element={
              <>
                {flag ? (
                  <ChangePassword />
                ) : (
                  <Navigate to='/account/login' replace />
                )}
              </>
            }
          />

          {/* <Route
            path='/account/patient-consent'
            element={
              <>
                {flag ? (
                  <PatientConsent />
                ) : (
                  <Navigate to='/account/login' replace />
                )}
              </>
            }
          /> */}
          <Route
            path='/patient-home'
            element={<PatientHome />}
            // element={
            //   <>
            //     {flag ? (
            //       <PatientHome />
            //     ) : (
            //       <Navigate to='/account/login' replace />
            //     )}
            //   </>
            // }
          />

          <Route
            path='/billing'
            element={
              <>
                {flag ? <Billing /> : <Navigate to='/account/login' replace />}
              </>
            }
          />
          <Route
            path='/billing-detail'
            element={
              <>
                {flag ? (
                  <BillingDetail />
                ) : (
                  <Navigate to='/account/login' replace />
                )}
              </>
            }
          />
          <Route
            path='/dashboard'
            element={
              <>
                {flag ? (
                  <Dashboard />
                ) : (
                  <Navigate to='/account/login' replace />
                )}
              </>
            }
          />
          <Route
            path='/leads'
            element={
              <>{flag ? <Leads /> : <Navigate to='/account/login' replace />}</>
            }
          />

          <Route
            path='/account/location-detail'
            element={
              <>
                {flag ? <Details /> : <Navigate to='/account/login' replace />}
              </>
            }
          />

          <Route path='/account/test' element={<Test />}></Route>
          <Route path='/account/test' element={<Test />}></Route>
          <Route path='/application/*' element={<Test />}></Route>
          <Route path='/404' element={<NotFind />}></Route>
          <Route path='*' element={<Navigate to='/404' replace />}></Route>
        </Routes>
      </BrowserRouter>
    </IntlProvider>
  );
};

RoutingHandler.propTypes = {};

export default RoutingHandler;

import React, { useState, useRef } from "react";
import { ToastContainer } from 'react-toastify';
import { PatientNextPage } from "../../components/PatientModule/PatientStart.js";

const patientStartNext = (props) => {
  
  return (
    <>
      <ToastContainer limit={1} />
      <div className="container">
        <div className="login_container applicant_container">
          <PatientNextPage/>
        </div>
      </div>
    </>




  );
};

patientStartNext.propTypes = {};

export default patientStartNext;

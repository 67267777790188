import React, { forwardRef, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./select-control.module.scss";
import { AsyncPaginate } from "react-select-async-paginate";

const innerStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 100,
  }),
};

const SelectAsyncControl = forwardRef((props, ref) => {
  const {
    onKeyDown,
    value,
    disabled,
    multi,
    onChange,
    placeholder,
    clearable,
    customStyles,
    isSearchable,
    getOptionLabel,
    getOptionValue,
    loadOptions,
    menuPlacement,
    tabIndex,
    className = "",
    classNamePrefix = "",
  } = props;

  const dropdownStyles = customStyles
    ? { ...innerStyles, ...customStyles }
    : innerStyles;

  return (
    <AsyncPaginate
      tabIndex={tabIndex}
      selectRef={ref}
      className={`${styles["select-control"]} ${className}`}
      loadOptions={loadOptions}
      styles={dropdownStyles}
      value={value}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      onChange={onChange}
      isSearchable={isSearchable}
      isClearable={clearable}
      placeholder={placeholder}
      isMulti={multi}
      isDisabled={disabled}
      additional={{
        page: 1,
      }}
      menuPlacement={menuPlacement}
      onKeyDown={(e) => {
        if (ref.current?.props?.menuIsOpen) {
          e.stopPropagation();
          return;
        }
        onKeyDown(e);
      }}
      classNamePrefix={classNamePrefix}
    />
  );
  // <Select
  //   styles={dropdownStyles}
  //   className={styles["select-control"]}
  //   options={options}
  //   value={value}
  //   isDisabled={disabled}
  //   isMulti={multi}
  //   onChange={onChange}
  //   placeholder={placeholder}
  //   isClearable={clearable}
  //   blurInputOnSelect={isMobile}
  //   backspaceRemovesValue
  //   isSearchable={isSearchable}
  //   menuPlacement={"auto"}
  // />
});

SelectAsyncControl.defaultProps = {
  options: [],
  value: null,
  disabled: false,
  multi: false,
  onChange: () => {},
  placeholder: "Select",
  clearable: true,
  customStyles: {},
  isSearchable: true,
  getOptionLabel: (o) => (o ? o.label : ""),
  getOptionValue: (o) => (o ? o.label : ""),
  menuPlacement: "auto",
  onKeyDown: () => {},
  tabIndex: 0,
};
const optionType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});
SelectAsyncControl.propTypes = {
  options: PropTypes.arrayOf(optionType),
  value: PropTypes.oneOfType([optionType, PropTypes.arrayOf(optionType)]),
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  multi: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  customStyles: PropTypes.shape({}),
  isSearchable: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  loadOptions: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  onKeyDown: PropTypes.func,
  tabIndex: PropTypes.number,
};

export default SelectAsyncControl;

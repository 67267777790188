import React, { useEffect, useState, useRef } from "react";
import { Header, CookiesSettingsText } from "../../components/PrivacyAgreementModule/index.js"


const CookiesSettings = (props) => {
    const back = () => {
        window.history.go(-1)
    }
    return (
        <>

            <div className="layout">
                <Header />
                <div className="main">
                    <div className="container ">
                        <CookiesSettingsText />

                    </div>
                </div>
            </div>
        </>

    );
};

CookiesSettings.propTypes = {};

export default CookiesSettings;
